import {
  ButtonGroup,
  Card,
  Checkbox,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Box, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useMemo} from 'react';

import {Nullish} from 'shared';

import {useGetCustomerV2Query} from '../../../api/customerApi';
import {useBatchCreateConsentMutation, useGetConsentTypesQuery} from '../../../api/gdprApi';
import i18n from '../../../i18n/i18n';
import {ConsentTemplate, ConsentType} from '../../../types/api/api';
import {handleApiError} from '../../../utils/handleApiError';
import {NoCustomerAlert} from './NoCustomerAlert';

type AddConsentsFormValues = {
  consents: {
    isChecked: boolean;
    consentTypeId: string;
    templateIndex: number;
    templates: ConsentTemplate[];
  }[];
  customerContractInformationId: string;
};
interface AddGdprConsentTypeFormProps {
  customerId: string;
  businessCaseId: string | null;
}

export function AddConsentForm(props: AddGdprConsentTypeFormProps) {
  const {data} = useGetCustomerV2Query({
    customerId: props.customerId,
  });

  const {
    data: consentTypes,
    isLoading,
    isError,
  } = useGetConsentTypesQuery(null, {
    selectFromResult: (result) => ({
      ...result,
      data: result.data?.filter((type) => !!type.templates.length),
    }),
  });
  const [create] = useBatchCreateConsentMutation();

  const defaults: AddConsentsFormValues = useMemo(
    () => ({
      customerContractInformationId: data?.contractInformation?.[0]?.id ?? '',
      consents: (consentTypes ?? []).map((consentType) => ({
        isChecked: false,
        consentTypeId: consentType.id,
        templates: consentType.templates,
        templateIndex: getPreselectedTemplateIndex(consentType),
      })),
    }),
    [consentTypes, data?.contractInformation]
  );

  const contractInformationOptions = useMemo(
    () =>
      data?.contractInformation
        ?.filter((info) => info.legalForm === 'NATURAL_PERSON')
        .map((info) => ({
          label: [info.person?.firstName, info.person?.lastName].join(' '),
          value: info.id,
        })),
    [data?.contractInformation]
  );

  const onSubmit: FormSubmitHandler<AddConsentsFormValues> = async (values) => {
    const selectedConsents = values.consents.filter((consent) => consent.isChecked);
    const body = selectedConsents?.map((consent) => ({
      customerContractInformationId: values.customerContractInformationId,
      templateFileId: consent.templates[consent.templateIndex].fileId ?? '',
      templateTitle: consent.templates[consent.templateIndex].title,
      consentTypeId: consent.consentTypeId,
      customerId: props.customerId,
      businessCaseId: props.businessCaseId,
    }));

    await create({body, customerId: props.customerId})
      .unwrap()
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  return (
    <DataStatus spacing={4} isError={isError} isLoading={isLoading} isEmpty={!consentTypes?.length}>
      <Form onSubmit={onSubmit} daty-cy="add-consent-form" defaultValues={defaults} schema={schema}>
        {(control, api) => (
          <VStack spacing={4}>
            <NoCustomerAlert
              visible={contractInformationOptions?.length === 0}
              customerId={props.customerId}
            />
            <Box width="50%">
              <FormField
                type="choice"
                control={control}
                name="customerContractInformationId"
                data-testid="add-consent-template-contract"
                label={i18n.t('entity.customer.labels.customer')}
                options={contractInformationOptions}
                menuInPortal
              />
            </Box>

            {consentTypes?.map((type, index) => {
              const isChecked = !!api.watch(`consents.${index}.isChecked`);

              const onCheckboxChange = (value: boolean) =>
                api.setValue(`consents.${index}.isChecked`, value);

              return (
                <Card key={type.id} variant="inlineGrey">
                  <VStack spacing={4}>
                    <Checkbox
                      data-testid="add-consent-checkbox"
                      onChange={onCheckboxChange}
                      value={isChecked}
                      label={type.name}
                    />
                    {isChecked && (
                      <FormField
                        type="choice"
                        control={control}
                        data-testid="add-consent-template"
                        name={`consents.${index}.templateIndex` as const}
                        label={i18n.t('entity.gdpr.labels.consentTemplate')}
                        options={getTemplateOptions(type.templates)}
                        menuInPortal
                      />
                    )}
                  </VStack>
                </Card>
              );
            })}
            <ButtonGroup align="right">
              <FormButton
                control={control}
                variant="secondary"
                onClick={closeCurrentDialog}
                data-testid="consent-form-cancel"
                title={i18n.t('general.actions.cancel')}
              />
              <FormButton
                control={control}
                type="submit"
                variant="primary"
                data-testid="consent-form-submit"
                title={i18n.t('general.actions.add')}
                isDisabled={!isAnyConsentTypeSelected(api.getValues().consents)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const isAnyConsentTypeSelected = (consents: AddConsentsFormValues['consents'] | Nullish) =>
  !!consents?.some((consent) => !!consent.isChecked);

function getPreselectedTemplateIndex(consentType: ConsentType) {
  const index = consentType.templates?.findIndex((template) => template.isPrimary);
  return 0 < index ? index : 0;
}

function getTemplateOptions(templates: ConsentTemplate[]) {
  return templates.map((template, index) => ({
    label: getTemplateTitle(template),
    value: index,
  }));
}

function getTemplateTitle(template: ConsentTemplate | null) {
  return template?.isPrimary
    ? i18n.t('entity.gdpr.labels.template.defaultTemplateTitle', {title: template.title})
    : (template?.title ?? null);
}

const schema = Yup.object().shape({
  customerContractInformationId: Yup.string().required(
    i18n.t('general.validations.fieldIsRequired')
  ),
});
