import {
  Action,
  Card,
  DataStatus,
  EmptyStatus,
  openDialog,
  showNotification,
} from 'platform/components';
import {Hide, Show, VStack} from 'platform/foundation';

import {defaultTo, isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  usePostServiceCheckInMutation,
  usePatchServiceCheckInMutation,
  PostServiceCheckInApiArg,
  handleApiError,
  useGetServiceCheckInQuery,
  useGetCustomerV2Query,
  useGetMetadaServiceCaseQuery,
  GetServiceCheckInApiResponse,
  isCustomerNaturalPerson,
  useGetVehicleQuery,
  usePartialUpdateVehicleMutation,
  useCreateInspectionMutation,
  InspectionType,
} from '@omnetic-dms/shared';

import {parseDate, buildArray, getApiDateString, useBoolean} from 'shared';

import {CheckInHandOverForm} from '../../../../../components/CheckInHandOverForm/CheckInHandOverForm';
import {CheckInHandOverParameter} from '../../../../../components/CheckInHandOverParameter/CheckInHandOverParameter';
import {EmailSmsNotificationBoundary} from '../../../../../components/EmailSmsNotifications/EmailSmsNotificationBoundary';
import {NotificationCategory} from '../../../../../components/EmailSmsNotifications/types/EmailSmsNotificationForm';
import {useWorkshopUrl} from '../../../../../hooks/useWorkshopUrl';
import {Inspection} from './Inspection';

interface CheckInProps {
  vehicleId: string;
}

export function CheckIn(props: CheckInProps) {
  const {serviceCaseId: id} = useWorkshopUrl();
  const {
    data: checkIn,
    isLoading: isCheckInLoading,
    isError: isCheckInError,
  } = useGetServiceCheckInQuery({serviceCaseId: id});
  const {
    data: serviceCase,
    isLoading: isServiceCaseLoading,
    isError: iseServiceCaseError,
  } = useGetMetadaServiceCaseQuery({serviceCaseId: id});
  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: isCustomerError,
  } = useGetCustomerV2Query(
    {customerId: defaultTo('', serviceCase?.customerId)},
    {skip: !serviceCase?.customerId}
  );
  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
  } = useGetVehicleQuery(
    {vehicleId: defaultTo('', serviceCase?.vehicleId)},
    {skip: !serviceCase?.vehicleId}
  );
  const [postServiceCheckIn] = usePostServiceCheckInMutation();
  const [patchServiceCheckIn, patchServiceCheckInQuery] = usePatchServiceCheckInMutation();
  const [partialUpdateVehicle] = usePartialUpdateVehicleMutation();
  const [createInspection, {isLoading: isCreatingInspection, isError: isInspectionError}] =
    useCreateInspectionMutation();

  const [isEditing, setEditing, cancelEditing] = useBoolean();

  const inspectionId = checkIn?.inspectionId?.[0];

  const handleSaveCheckIn = async (data: PostServiceCheckInApiArg['body']) => {
    if (isNotNil(data) && inspectionId) {
      data.inspectionId = [inspectionId];
    }

    if (data?.technicalInspection && vehicle) {
      partialUpdateVehicle({
        vehicleId: vehicle.id,
        patchVehicleRequestBody: {
          state: {
            technicalInspectionValidUntil: getApiDateString(parseDate(data?.technicalInspection)),
          },
        },
      })
        .unwrap()
        .catch(handleApiError);
    }
    if (checkIn) {
      await patchServiceCheckIn({body: data, serviceCaseId: id})
        .unwrap()
        .then(() => {
          showNotification.success();
          cancelEditing();
        })
        .catch(handleApiError);
      return;
    }

    await postServiceCheckIn({body: data, serviceCaseId: id})
      .unwrap()
      .then(() => {
        showNotification.success();
        cancelEditing();
      })
      .catch(handleApiError);
  };

  const vehicleDefaultValues: GetServiceCheckInApiResponse = {
    technicalInspection: vehicle?.state?.technicalInspectionValidUntil,
  };

  const emptyDefaultValues: GetServiceCheckInApiResponse =
    customer && isCustomerNaturalPerson(customer)
      ? {
          driverFirstname: customer.foundingPerson?.firstName,
          driverLastname: customer.foundingPerson?.lastName,
          driverEmail: customer.foundingPerson?.emails[0]?.email,
          driverPhonePrefix: customer.foundingPerson?.phoneNumbers?.[0]?.prefix,
          driverPhoneNo: customer.foundingPerson?.phoneNumbers?.[0]?.number,
          driverIdentityCard: customer.foundingPerson?.identityCards?.[0]?.cardNumber,
          consentToTestDrive: true,
          ...vehicleDefaultValues,
        }
      : vehicleDefaultValues;

  const isLoading =
    isCheckInLoading || isCustomerLoading || isServiceCaseLoading || isVehicleLoading;
  const isError = isCheckInError || isCustomerError || iseServiceCaseError || isVehicleError;
  const isFormView = !checkIn || isEditing;

  const cardActions = buildArray<Action>().whenNot(isFormView, {
    type: 'button',
    variant: 'link',
    leftIcon: 'image/edit',
    title: i18n.t('general.actions.edit'),
    onClick: setEditing,
    'data-testid': testIds.workshop.serviceCaseDetail('checkInEdit'),
  });

  return (
    <VStack spacing={4}>
      <Card title={i18n.t('general.labels.basicInformation')} actions={cardActions}>
        <DataStatus
          isLoading={isLoading}
          isError={isError}
          data-testid={testIds.workshop.serviceCaseDetail('checkInStatus')}
        >
          <Show when={isFormView}>
            <CheckInHandOverForm
              data-testid={testIds.workshop.serviceCaseDetail('checkIneditForm')}
              defaultValues={checkIn ?? emptyDefaultValues}
              onSubmit={handleSaveCheckIn}
              isCheckin
              isEditing={isEditing}
              onEditDiscard={cancelEditing}
            />
          </Show>
          <Hide when={isFormView}>
            <CheckInHandOverParameter
              data={checkIn}
              data-testid={testIds.workshop.serviceCaseDetail('checkInlist')}
              isCheckin
            />
          </Hide>
        </DataStatus>
      </Card>
      <Show when={isNil(inspectionId)}>
        <Card
          title={i18n.t('page.Inspection.labels.title')}
          actions={[
            {
              type: 'button',
              variant: 'link',
              leftIcon: 'content/add_circle',
              title: i18n.t('general.labels.createNew'),
              isDisabled: isNil(checkIn),
              isLoading: isCreatingInspection || patchServiceCheckInQuery.isLoading,
              onClick: () => {
                createInspection({
                  vehicleId: props.vehicleId,
                  createAuditRequestBody: {
                    inspectionType: InspectionType.CHECKIN,
                  },
                })
                  .unwrap()
                  .then(({id}) =>
                    patchServiceCheckIn({
                      body: {
                        ...checkIn,
                        inspectionId: [id],
                      },
                      serviceCaseId: serviceCase?.id || '',
                    }).unwrap()
                  )
                  .then(() => showNotification.success())
                  .catch(handleApiError);
              },
            },
          ]}
        >
          <EmptyStatus headline={i18n.t('entity.order.actions.checkInEmpty')} />
        </Card>
      </Show>
      <Show when={!!checkIn && !!inspectionId}>
        <Inspection
          inspectionId={inspectionId || ''}
          vehicleId={props.vehicleId}
          isCompletionDisabled={isNil(checkIn?.inspectionId?.[0])}
          isError={isInspectionError}
          data-testid={testIds.workshop.serviceCaseDetail('checkinInspection')}
          onInspectionDeleted={() => {
            if (!checkIn) {
              return;
            }

            const {inspectionId: _, ...rest} = checkIn;

            patchServiceCheckIn({serviceCaseId: id, body: rest})
              .unwrap()
              .then(() => showNotification.success())
              .catch(handleApiError);
          }}
          onSendEmailSmsNotification={() => {
            openDialog(
              <EmailSmsNotificationBoundary
                serviceCaseId={id}
                dialogId="email-sms-notification-dialog-checkin"
                data-testid={testIds.workshop.serviceCaseDetail('emailSmsNotificationHandover')}
                defaultCategory={NotificationCategory.CHECKIN}
              />,
              {
                id: 'email-sms-notification-dialog-checkin',
                size: 'large',
                title: i18n.t('entity.order.actions.sendEmailSms'),
              }
            );
          }}
        />
      </Show>
    </VStack>
  );
}
