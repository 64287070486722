import {useLightbox} from 'platform/lightbox';

import {sortBy} from 'ramda';

import {
  useGetPromoPhotoForVehicleQuery,
  useVehicleCoverPhoto,
  VehiclePromoPhotoResponseBody,
} from '@omnetic-dms/shared';

export const useVehicleHeaderImage = (vehicleId: string) => {
  const {photos, coverPhoto} = useVehicleCoverPhoto(vehicleId);

  const {data: vehiclePromoPhotos} = useGetPromoPhotoForVehicleQuery({
    vehicleId,
  });

  const [lightboxControls, {onOpen}] = useLightbox('vehicleHeader');

  const sortedPhotos = sortBy(
    (item: VehiclePromoPhotoResponseBody) => item.position,
    vehiclePromoPhotos ?? []
  );

  const allPhotos = [...photos, ...sortedPhotos];

  const vehicleImages = allPhotos.map((image) => ({
    id: image.id,
    url: image.url.replace('resize', 'get'),
    resizeUrl: image.url,
    name: image.fileId,
  }));

  const onCoverPhotoClick = () => {
    const index = vehicleImages?.findIndex((slide) => slide.name === coverPhoto?.fileId);
    onOpen(index ?? 0);
  };

  return [lightboxControls, coverPhoto?.url, onCoverPhotoClick, vehicleImages] as const;
};
