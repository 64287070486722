/**
 * Any is used to supress typescript error when dnymicaly importing svg files.
 * This error is present only in apps/* projects.
 * error TS2322: Type 'Promise<typeof import("*.svg")>' is not assignable to type 'Promise<{ default: ComponentType<any>; }>'.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import {lazy} from 'react';

import {ActionIcons} from './action/actionsIcons';
import {AlertIcon} from './alert/alertIcons';
import {AutomotiveIcon} from './automotive/automotiveIcons';
import {AVIcon} from './AV/avIcons';
import {CommunicationIcon} from './communication/communicationIcons';
import {ConditionIcon} from './condition/conditionIcons';
import {ContentIcon} from './content/contentIcons';
import {CustomIcon} from './custom/customIcons';
import {EditorIcon} from './editor/editorIcons';
import fallbackIcon from './fallback.svg';
import {FileIcons} from './filesIcons';
import {FlagIcons} from './flags/flagsIcons';
import {FlagLocaleIcons} from './flags/flagsLocaleIcons';
import {HardwareIcon} from './hardware/hardwareIcons';
import {ImageIcon} from './image/imageIcons';
import {MapsIcon} from './maps/mapsIcons';
import {NavigationIcons} from './navigation/navigationIcons';
import {OtherIcon} from './otherIcons';
import {SidebarIcon} from './sidebar/sidebarIcons';
import {SocialIcon} from './social/socialIcons';
import {SpecificationIcon} from './specificationIcons';

const lazyIconLoaderFactory = <T>(importFn: () => Promise<any>, icon: T) =>
  lazy<any>(
    () =>
      new Promise((res, rej) =>
        importFn()
          .then((module) => res({default: module.iconsPack[icon]}))
          .catch(rej)
      )
  );

const lazyActionIcons = (icon: ActionIcons) =>
  lazyIconLoaderFactory(() => import('./action/actionsIcons'), icon);

const lazyAlertIcons = (icon: AlertIcon) =>
  lazyIconLoaderFactory(() => import('./alert/alertIcons'), icon);

const lazyAutomotiveIcons = (icon: AutomotiveIcon) =>
  lazyIconLoaderFactory(() => import('./automotive/automotiveIcons'), icon);

const lazyAVIcons = (icon: AVIcon) => lazyIconLoaderFactory(() => import('./AV/avIcons'), icon);

const lazyCommunicationIcons = (icon: CommunicationIcon) =>
  lazyIconLoaderFactory(() => import('./communication/communicationIcons'), icon);

const lazyConditionIcons = (icon: ConditionIcon) =>
  lazyIconLoaderFactory(() => import('./condition/conditionIcons'), icon);

const lazyContentIcons = (icon: ContentIcon) =>
  lazyIconLoaderFactory(() => import('./content/contentIcons'), icon);

const lazyCustomIcons = (icon: CustomIcon) =>
  lazyIconLoaderFactory(() => import('./custom/customIcons'), icon);

const lazyFlagsIcons = (icon: FlagIcons) =>
  lazyIconLoaderFactory(() => import('./flags/flagsIcons'), icon);

const lazyFlagsLocaleIcons = (icon: FlagLocaleIcons) =>
  lazyIconLoaderFactory(() => import('./flags/flagsLocaleIcons'), icon);

const lazyFileIcons = (icon: FileIcons) =>
  lazyIconLoaderFactory(() => import('./filesIcons'), icon);

const lazyNavigationIcons = (icon: NavigationIcons) =>
  lazyIconLoaderFactory(() => import('./navigation/navigationIcons'), icon);

const lazyEditorIcons = (icon: EditorIcon) =>
  lazyIconLoaderFactory(() => import('./editor/editorIcons'), icon);

const lazyImageIcons = (icon: ImageIcon) =>
  lazyIconLoaderFactory(() => import('./image/imageIcons'), icon);

const lazySocialIcons = (icon: SocialIcon) =>
  lazyIconLoaderFactory(() => import('./social/socialIcons'), icon);

const lazyHardwareIcons = (icon: HardwareIcon) =>
  lazyIconLoaderFactory(() => import('./hardware/hardwareIcons'), icon);

const lazyMapsIcons = (icon: MapsIcon) =>
  lazyIconLoaderFactory(() => import('./maps/mapsIcons'), icon);

const lazySpecificationIcons = (icon: SpecificationIcon) =>
  lazyIconLoaderFactory(() => import('./specificationIcons'), icon);

const lazyOtherIcons = (icon: OtherIcon) =>
  lazyIconLoaderFactory(() => import('./otherIcons'), icon);

const lazySidebarIcons = (icon: SidebarIcon) =>
  lazyIconLoaderFactory(() => import('./sidebar/sidebarIcons'), icon);

export const themeIcons = {
  'action/3d_rotation': lazyActionIcons('action/3d_rotation'),
  'action/accessibility_new': lazyActionIcons('action/accessibility_new'),
  'action/accessibility': lazyActionIcons('action/accessibility'),
  'action/accessible_forward': lazyActionIcons('action/accessible_forward'),
  'action/accessible': lazyActionIcons('action/accessible'),
  'action/account_balance_wallet': lazyActionIcons('action/account_balance_wallet'),
  'action/account_balance': lazyActionIcons('action/account_balance'),
  'action/account_box': lazyActionIcons('action/account_box'),
  'action/account_circle': lazyActionIcons('action/account_circle'),
  'action/add_shopping_cart': lazyActionIcons('action/add_shopping_cart'),
  'action/alarm_add': lazyActionIcons('action/alarm_add'),
  'action/alarm_off': lazyActionIcons('action/alarm_off'),
  'action/alarm_on': lazyActionIcons('action/alarm_on'),
  'action/alarm': lazyActionIcons('action/alarm'),
  'action/all_inbox': lazyActionIcons('action/all_inbox'),
  'action/all_out': lazyActionIcons('action/all_out'),
  'action/android': lazyActionIcons('action/android'),
  'action/announcement': lazyActionIcons('action/announcement'),
  'action/arrow_right_alt': lazyActionIcons('action/arrow_right_alt'),
  'action/aspect_ratio': lazyActionIcons('action/aspect_ratio'),
  'action/assessment': lazyActionIcons('action/assessment'),
  'action/assignment': lazyActionIcons('action/assignment'),
  'action/autorenew': lazyActionIcons('action/autorenew'),
  'action/backup': lazyActionIcons('action/backup'),
  'action/book': lazyActionIcons('action/book'),
  'action/bookmark_border': lazyActionIcons('action/bookmark_border'),
  'action/bookmark': lazyActionIcons('action/bookmark'),
  'action/bookmarks': lazyActionIcons('action/bookmarks'),
  'action/bug_report': lazyActionIcons('action/bug_report'),
  'action/build': lazyActionIcons('action/build'),
  'action/cached': lazyActionIcons('action/cached'),
  'action/calendar_today': lazyActionIcons('action/calendar_today'),
  'action/calendar_view_day': lazyActionIcons('action/calendar_view_day'),
  'action/camera_enhance': lazyActionIcons('action/camera_enhance'),
  'action/change_history': lazyActionIcons('action/change_history'),
  'action/check_circle_outline': lazyActionIcons('action/check_circle_outline'),
  'action/check_circle': lazyActionIcons('action/check_circle'),
  'action/chrome_reader_mode': lazyActionIcons('action/chrome_reader_mode'),
  'action/class': lazyActionIcons('action/class'),
  'action/code': lazyActionIcons('action/code'),
  'action/commute': lazyActionIcons('action/commute'),
  'action/compare_arrows': lazyActionIcons('action/compare_arrows'),
  'action/contact_support': lazyActionIcons('action/contact_support'),
  'action/copyright': lazyActionIcons('action/copyright'),
  'action/credit_card': lazyActionIcons('action/credit_card'),
  'action/dashboard': lazyActionIcons('action/dashboard'),
  'action/date_range': lazyActionIcons('action/date_range'),
  'action/date_timer': lazyActionIcons('action/date_timer'),
  'action/delete_forever': lazyActionIcons('action/delete_forever'),
  'action/delete_outline': lazyActionIcons('action/delete_outline'),
  'action/delete': lazyActionIcons('action/delete'),
  'action/description': lazyActionIcons('action/description'),
  'action/dns': lazyActionIcons('action/dns'),
  'action/done_all': lazyActionIcons('action/done_all'),
  'action/done_outline': lazyActionIcons('action/done_outline'),
  'action/done': lazyActionIcons('action/done'),
  'action/donut_large': lazyActionIcons('action/donut_large'),
  'action/donut_small': lazyActionIcons('action/donut_small'),
  'action/drag_indicator': lazyActionIcons('action/drag_indicator'),
  'action/eject': lazyActionIcons('action/eject'),
  'action/euro_symbol': lazyActionIcons('action/euro_symbol'),
  'action/event_seat': lazyActionIcons('action/event_seat'),
  'action/event': lazyActionIcons('action/event'),
  'action/exit_to_app': lazyActionIcons('action/exit_to_app'),
  'action/explore_off': lazyActionIcons('action/explore_off'),
  'action/explore': lazyActionIcons('action/explore'),
  'action/extension': lazyActionIcons('action/extension'),
  'action/face_unlock': lazyActionIcons('action/face_unlock'),
  'action/face': lazyActionIcons('action/face'),
  'action/favorite_border': lazyActionIcons('action/favorite_border'),
  'action/favorite': lazyActionIcons('action/favorite'),
  'action/feedback': lazyActionIcons('action/feedback'),
  'action/find_in_page': lazyActionIcons('action/find_in_page'),
  'action/find_replace': lazyActionIcons('action/find_replace'),
  'action/fingerprint': lazyActionIcons('action/fingerprint'),
  'action/flight_land': lazyActionIcons('action/flight_land'),
  'action/flight_takeoff': lazyActionIcons('action/flight_takeoff'),
  'action/flip_to_back': lazyActionIcons('action/flip_to_back'),
  'action/flip_to_front': lazyActionIcons('action/flip_to_front'),
  'action/g_translate': lazyActionIcons('action/g_translate'),
  'action/gavel': lazyActionIcons('action/gavel'),
  'action/get_app': lazyActionIcons('action/get_app'),
  'action/gif': lazyActionIcons('action/gif'),
  'action/grade': lazyActionIcons('action/grade'),
  'action/group_work': lazyActionIcons('action/group_work'),
  'action/help_outline': lazyActionIcons('action/help_outline'),
  'action/help': lazyActionIcons('action/help'),
  'action/highlight_off': lazyActionIcons('action/highlight_off'),
  'action/history': lazyActionIcons('action/history'),
  'action/home': lazyActionIcons('action/home'),
  'action/horizontal_split': lazyActionIcons('action/horizontal_split'),
  'action/hourglass_empty': lazyActionIcons('action/hourglass_empty'),
  'action/hourglass_full': lazyActionIcons('action/hourglass_full'),
  'action/http': lazyActionIcons('action/http'),
  'action/https': lazyActionIcons('action/https'),
  'action/important_devices': lazyActionIcons('action/important_devices'),
  'action/income': lazyActionIcons('action/income'),
  'action/info_outline': lazyActionIcons('action/info_outline'),
  'action/info': lazyActionIcons('action/info'),
  'action/input': lazyActionIcons('action/input'),
  'action/invert_colors': lazyActionIcons('action/invert_colors'),
  'action/language': lazyActionIcons('action/language'),
  'action/launch': lazyActionIcons('action/launch'),
  'action/lightbulb_outline': lazyActionIcons('action/lightbulb_outline'),
  'action/line_style': lazyActionIcons('action/line_style'),
  'action/line_weight': lazyActionIcons('action/line_weight'),
  'action/list': lazyActionIcons('action/list'),
  'action/lock_open': lazyActionIcons('action/lock_open'),
  'action/lock_outline': lazyActionIcons('action/lock_outline'),
  'action/lock': lazyActionIcons('action/lock'),
  'action/loyalty': lazyActionIcons('action/loyalty'),
  'action/markunread_mailbox': lazyActionIcons('action/markunread_mailbox'),
  'action/maximize': lazyActionIcons('action/maximize'),
  'action/minimize': lazyActionIcons('action/minimize'),
  'action/motorcycle': lazyActionIcons('action/motorcycle'),
  'action/note_add': lazyActionIcons('action/note_add'),
  'action/offline_bolt': lazyActionIcons('action/offline_bolt'),
  'action/offline_pin': lazyActionIcons('action/offline_pin'),
  'action/opacity': lazyActionIcons('action/opacity'),
  'action/open_in_browser': lazyActionIcons('action/open_in_browser'),
  'action/open_in_new': lazyActionIcons('action/open_in_new'),
  'action/open_with': lazyActionIcons('action/open_with'),
  'action/pageview': lazyActionIcons('action/pageview'),
  'action/pan_tool': lazyActionIcons('action/pan_tool'),
  'action/payment': lazyActionIcons('action/payment'),
  'action/pets': lazyActionIcons('action/pets'),
  'action/picture_in_picture_alt': lazyActionIcons('action/picture_in_picture_alt'),
  'action/picture_in_picture': lazyActionIcons('action/picture_in_picture'),
  'action/play_for_work': lazyActionIcons('action/play_for_work'),
  'action/polymer': lazyActionIcons('action/polymer'),
  'action/power_settings_new': lazyActionIcons('action/power_settings_new'),
  'action/pregnant_woman': lazyActionIcons('action/pregnant_woman'),
  'action/print': lazyActionIcons('action/print'),
  'action/query_builder': lazyActionIcons('action/query_builder'),
  'action/question_answer': lazyActionIcons('action/question_answer'),
  'action/receipt': lazyActionIcons('action/receipt'),
  'action/record_voice_over': lazyActionIcons('action/record_voice_over'),
  'action/redeem': lazyActionIcons('action/redeem'),
  'action/remove_shopping_cart': lazyActionIcons('action/remove_shopping_cart'),
  'action/reorder': lazyActionIcons('action/reorder'),
  'action/report_problem': lazyActionIcons('action/report_problem'),
  'action/restore_from_trash': lazyActionIcons('action/restore_from_trash'),
  'action/restore_page': lazyActionIcons('action/restore_page'),
  'action/restore': lazyActionIcons('action/restore'),
  'action/room': lazyActionIcons('action/room'),
  'action/rounded_corner': lazyActionIcons('action/rounded_corner'),
  'action/rowing': lazyActionIcons('action/rowing'),
  'action/schedule': lazyActionIcons('action/schedule'),
  'action/search': lazyActionIcons('action/search'),
  'action/settings_applications': lazyActionIcons('action/settings_applications'),
  'action/settings_backup_restore': lazyActionIcons('action/settings_backup_restore'),
  'action/settings_bluetooth': lazyActionIcons('action/settings_bluetooth'),
  'action/settings_brightness': lazyActionIcons('action/settings_brightness'),
  'action/settings_cell': lazyActionIcons('action/settings_cell'),
  'action/settings_ethernet': lazyActionIcons('action/settings_ethernet'),
  'action/settings_input_antenna': lazyActionIcons('action/settings_input_antenna'),
  'action/settings_input_component': lazyActionIcons('action/settings_input_component'),
  'action/settings_input_composite': lazyActionIcons('action/settings_input_composite'),
  'action/settings_input_hdmi': lazyActionIcons('action/settings_input_hdmi'),
  'action/settings_input_svideo': lazyActionIcons('action/settings_input_svideo'),
  'action/settings_overscan': lazyActionIcons('action/settings_overscan'),
  'action/settings_phone': lazyActionIcons('action/settings_phone'),
  'action/settings_power': lazyActionIcons('action/settings_power'),
  'action/settings_remote': lazyActionIcons('action/settings_remote'),
  'action/settings_voice': lazyActionIcons('action/settings_voice'),
  'action/settings': lazyActionIcons('action/settings'),
  'action/shop_two': lazyActionIcons('action/shop_two'),
  'action/shop': lazyActionIcons('action/shop'),
  'action/shopping_basket': lazyActionIcons('action/shopping_basket'),
  'action/shopping_cart': lazyActionIcons('action/shopping_cart'),
  'action/speaker_notes_off': lazyActionIcons('action/speaker_notes_off'),
  'action/speaker_notes': lazyActionIcons('action/speaker_notes'),
  'action/spellcheck': lazyActionIcons('action/spellcheck'),
  'action/star_rate_18px': lazyActionIcons('action/star_rate_18px'),
  'action/stars': lazyActionIcons('action/stars'),
  'action/store': lazyActionIcons('action/store'),
  'action/subject': lazyActionIcons('action/subject'),
  'action/supervised_user_circle': lazyActionIcons('action/supervised_user_circle'),
  'action/supervisor_account': lazyActionIcons('action/supervisor_account'),
  'action/swap_horiz': lazyActionIcons('action/swap_horiz'),
  'action/swap_horizontal_circle': lazyActionIcons('action/swap_horizontal_circle'),
  'action/swap_vert': lazyActionIcons('action/swap_vert'),
  'action/swap_vertical_circle': lazyActionIcons('action/swap_vertical_circle'),
  'action/system_vertical_alt': lazyActionIcons('action/system_vertical_alt'),
  'action/tab_unselected': lazyActionIcons('action/tab_unselected'),
  'action/tab': lazyActionIcons('action/tab'),
  'action/theaters': lazyActionIcons('action/theaters'),
  'action/thumb_down': lazyActionIcons('action/thumb_down'),
  'action/thumb_up': lazyActionIcons('action/thumb_up'),
  'action/thumbs_up_down': lazyActionIcons('action/thumbs_up_down'),
  'action/timeline': lazyActionIcons('action/timeline'),
  'action/toc': lazyActionIcons('action/toc'),
  'action/today': lazyActionIcons('action/today'),
  'action/toll': lazyActionIcons('action/toll'),
  'action/touch_app': lazyActionIcons('action/touch_app'),
  'action/track_changes': lazyActionIcons('action/track_changes'),
  'action/translate': lazyActionIcons('action/translate'),
  'action/trending_down': lazyActionIcons('action/trending_down'),
  'action/trending_flat': lazyActionIcons('action/trending_flat'),
  'action/trending_up': lazyActionIcons('action/trending_up'),
  'action/turned_in_not': lazyActionIcons('action/turned_in_not'),
  'action/turned_in': lazyActionIcons('action/turned_in'),
  'action/update': lazyActionIcons('action/update'),
  'action/verified_user': lazyActionIcons('action/verified_user'),
  'action/vertical_split': lazyActionIcons('action/vertical_split'),
  'action/view_agenda': lazyActionIcons('action/view_agenda'),
  'action/view_array': lazyActionIcons('action/view_array'),
  'action/view_carousel': lazyActionIcons('action/view_carousel'),
  'action/view_column': lazyActionIcons('action/view_column'),
  'action/view_day': lazyActionIcons('action/view_day'),
  'action/view_headline': lazyActionIcons('action/view_headline'),
  'action/view_list': lazyActionIcons('action/view_list'),
  'action/view_module': lazyActionIcons('action/view_module'),
  'action/view_quilt': lazyActionIcons('action/view_quilt'),
  'action/view_stream': lazyActionIcons('action/view_stream'),
  'action/view_week': lazyActionIcons('action/view_week'),
  'action/visibility_off': lazyActionIcons('action/visibility_off'),
  'action/visibility': lazyActionIcons('action/visibility'),
  'action/voice_over_off': lazyActionIcons('action/voice_over_off'),
  'action/watch_later': lazyActionIcons('action/watch_later'),
  'action/work_off': lazyActionIcons('action/work_off'),
  'action/work_outline': lazyActionIcons('action/work_outline'),
  'action/work': lazyActionIcons('action/work'),
  'action/youtube_searched_for': lazyActionIcons('action/youtube_searched_for'),
  'action/zoom_in': lazyActionIcons('action/zoom_in'),
  'action/zoom_out': lazyActionIcons('action/zoom_out'),
  'action/magic_wand': lazyActionIcons('action/magic_wand'),
  'action/rotateRight': lazyActionIcons('action/rotateRight'),
  'action/rotateLeft': lazyActionIcons('action/rotateLeft'),
  'action/list_alt_add': lazyActionIcons('action/list_alt_add'),
  'action/deployed': lazyActionIcons('action/deployed'),
  'automotive/calendar': lazyAutomotiveIcons('automotive/calendar'),
  'automotive/differential': lazyAutomotiveIcons('automotive/differential'),
  'automotive/engine': lazyAutomotiveIcons('automotive/engine'),
  'automotive/fuel_type': lazyAutomotiveIcons('automotive/fuel_type'),
  'automotive/location': lazyAutomotiveIcons('automotive/location'),
  'automotive/mileage': lazyAutomotiveIcons('automotive/mileage'),
  'automotive/paid': lazyAutomotiveIcons('automotive/paid'),
  'automotive/transmission': lazyAutomotiveIcons('automotive/transmission'),
  'automotive/car': lazyAutomotiveIcons('automotive/car'),
  'alert/error_outline': lazyAlertIcons('alert/error_outline'),
  'alert/error': lazyAlertIcons('alert/error'),
  'alert/notification_important': lazyAlertIcons('alert/notification_important'),
  'alert/warning': lazyAlertIcons('alert/warning'),
  arrow: lazyOtherIcons('arrow'),
  'AV/branding_watermark': lazyAVIcons('AV/branding_watermark'),
  'AV/fiber_manual_record': lazyAVIcons('AV/fiber_manual_record'),
  'AV/play_arrow': lazyAVIcons('AV/play_arrow'),
  'AV/replay': lazyAVIcons('AV/replay'),
  'communication/business': lazyCommunicationIcons('communication/business'),
  'communication/call_made': lazyCommunicationIcons('communication/call_made'),
  'communication/call_merge': lazyCommunicationIcons('communication/call_merge'),
  'communication/call_split': lazyCommunicationIcons('communication/call_split'),
  'communication/comment': lazyCommunicationIcons('communication/comment'),
  'communication/email': lazyCommunicationIcons('communication/email'),
  'communication/location_on': lazyCommunicationIcons('communication/location_on'),
  'communication/person_add_disabled': lazyCommunicationIcons('communication/person_add_disabled'),
  'communication/textsms': lazyCommunicationIcons('communication/textsms'),
  'communication/vpn_key': lazyCommunicationIcons('communication/vpn_key'),
  /**
   * Do not remove condition/* icons
   * BE uses them and it's not typed
   */
  'condition/backbench': lazyConditionIcons('condition/backbench'),
  'condition/column_trim_on_the_left': lazyConditionIcons('condition/column_trim_on_the_left'),
  'condition/column_trim_on_the_right': lazyConditionIcons('condition/column_trim_on_the_right'),
  'condition/dashboard': lazyConditionIcons('condition/dashboard'),
  'condition/drivers_seat': lazyConditionIcons('condition/drivers_seat'),
  'condition/driving_wheel': lazyConditionIcons('condition/driving_wheel'),
  'condition/front_bumper': lazyConditionIcons('condition/front_bumper'),
  'condition/front_hood': lazyConditionIcons('condition/front_hood'),
  'condition/l_mirror': lazyConditionIcons('condition/l_mirror'),
  'condition/lf_door_trim': lazyConditionIcons('condition/lf_door_trim'),
  'condition/lf_door': lazyConditionIcons('condition/lf_door'),
  'condition/lf_fender': lazyConditionIcons('condition/lf_fender'),
  'condition/lf_light': lazyConditionIcons('condition/lf_light'),
  'condition/lr_door_trim': lazyConditionIcons('condition/lr_door_trim'),
  'condition/lr_door': lazyConditionIcons('condition/lr_door'),
  'condition/lr_fender': lazyConditionIcons('condition/lr_fender'),
  'condition/lr_light': lazyConditionIcons('condition/lr_light'),
  'condition/passenger_seat': lazyConditionIcons('condition/passenger_seat'),
  'condition/r_mirror': lazyConditionIcons('condition/r_mirror'),
  'condition/rear_bumper': lazyConditionIcons('condition/rear_bumper'),
  'condition/rear_window': lazyConditionIcons('condition/rear_window'),
  'condition/rf_door_trim': lazyConditionIcons('condition/rf_door_trim'),
  'condition/rf_door': lazyConditionIcons('condition/rf_door'),
  'condition/rf_fender': lazyConditionIcons('condition/rf_fender'),
  'condition/rf_light': lazyConditionIcons('condition/rf_light'),
  'condition/roof': lazyConditionIcons('condition/roof'),
  'condition/rr_door_trim': lazyConditionIcons('condition/rr_door_trim'),
  'condition/rr_door': lazyConditionIcons('condition/rr_door'),
  'condition/rr_fender': lazyConditionIcons('condition/rr_fender'),
  'condition/rr_light': lazyConditionIcons('condition/rr_light'),
  'condition/windshield': lazyConditionIcons('condition/windshield'),
  /**
   * Do not remove condition/* icons
   * BE uses them and it's not typed
   */
  'content/add_box': lazyContentIcons('content/add_box'),
  'content/add_circle_outline': lazyContentIcons('content/add_circle_outline'),
  'content/add_circle': lazyContentIcons('content/add_circle'),
  'content/add': lazyContentIcons('content/add'),
  'content/archive': lazyContentIcons('content/archive'),
  'content/attribution': lazyContentIcons('content/attribution'),
  'content/backspace': lazyContentIcons('content/backspace'),
  'content/ballot': lazyContentIcons('content/ballot'),
  'content/block': lazyContentIcons('content/block'),
  'content/clear': lazyContentIcons('content/clear'),
  'content/copy': lazyContentIcons('content/copy'),
  'content/create': lazyContentIcons('content/create'),
  'content/cut': lazyContentIcons('content/cut'),
  'content/delete_sweep': lazyContentIcons('content/delete_sweep'),
  'content/drafts': lazyContentIcons('content/drafts'),
  'content/file_copy': lazyContentIcons('content/file_copy'),
  'content/filter_list': lazyContentIcons('content/filter_list'),
  'content/flag': lazyContentIcons('content/flag'),
  'content/font_download': lazyContentIcons('content/font_download'),
  'content/forward': lazyContentIcons('content/forward'),
  'content/gesture': lazyContentIcons('content/gesture'),
  'content/how_to_reg': lazyContentIcons('content/how_to_reg'),
  'content/how_to_vote': lazyContentIcons('content/how_to_vote'),
  'content/inbox': lazyContentIcons('content/inbox'),
  'content/link_off': lazyContentIcons('content/link_off'),
  'content/link': lazyContentIcons('content/link'),
  'content/low_priority': lazyContentIcons('content/low_priority'),
  'content/mail': lazyContentIcons('content/mail'),
  'content/markunread': lazyContentIcons('content/markunread'),
  'content/move_to_inbox': lazyContentIcons('content/move_to_inbox'),
  'content/next_week': lazyContentIcons('content/next_week'),
  'content/outlined_flag': lazyContentIcons('content/outlined_flag'),
  'content/paste': lazyContentIcons('content/paste'),
  'content/redo': lazyContentIcons('content/redo'),
  'content/remove_circle_outline': lazyContentIcons('content/remove_circle_outline'),
  'content/remove_circle': lazyContentIcons('content/remove_circle'),
  'content/remove': lazyContentIcons('content/remove'),
  'content/reply_all': lazyContentIcons('content/reply_all'),
  'content/reply': lazyContentIcons('content/reply'),
  'content/report_gmailerrorred': lazyContentIcons('content/report_gmailerrorred'),
  'content/report_off': lazyContentIcons('content/report_off'),
  'content/report': lazyContentIcons('content/report'),
  'content/save_alt': lazyContentIcons('content/save_alt'),
  'content/save': lazyContentIcons('content/save'),
  'content/select_all': lazyContentIcons('content/select_all'),
  'content/send': lazyContentIcons('content/send'),
  'content/sort': lazyContentIcons('content/sort'),
  'content/text_format': lazyContentIcons('content/text_format'),
  'content/unarchive': lazyContentIcons('content/unarchive'),
  'content/undo': lazyContentIcons('content/undo'),
  'content/waves': lazyContentIcons('content/waves'),
  'content/weekend': lazyContentIcons('content/weekend'),
  'content/where_to_vote': lazyContentIcons('content/where_to_vote'),
  'content/rss': lazyContentIcons('content/rss'),
  'content/run_mirror': lazyContentIcons('content/run_mirror'),
  'content/customer_service': lazyContentIcons('content/customer_service'),
  'content/notebook': lazyContentIcons('content/notebook'),
  'custom/employee': lazyCustomIcons('custom/employee'),
  'custom/invoice': lazyCustomIcons('custom/invoice'),
  'custom/no_data': lazyCustomIcons('custom/no_data'),
  'custom/no_images': lazyCustomIcons('custom/no_images'),
  'custom/no_messages': lazyCustomIcons('custom/no_messages'),
  'custom/no_person': lazyCustomIcons('custom/no_person'),
  'custom/no_table_data': lazyCustomIcons('custom/no_table_data'),
  'custom/push_pin_outlined': lazyCustomIcons('custom/push_pin_outlined'),
  'custom/push_pin': lazyCustomIcons('custom/push_pin'),
  'custom/tractor': lazyCustomIcons('custom/tractor'),
  'custom/warehouse': lazyCustomIcons('custom/warehouse'),
  'pictogram/animal': lazyCustomIcons('pictogram/animal'),
  'pictogram/assistance': lazyCustomIcons('pictogram/assistance'),
  'pictogram/car_crash': lazyCustomIcons('pictogram/car_crash'),
  'pictogram/natural_element': lazyCustomIcons('pictogram/natural_element'),
  'pictogram/steal': lazyCustomIcons('pictogram/steal'),
  'pictogram/vandalism': lazyCustomIcons('pictogram/vandalism'),
  'devices/access_alarm': lazyOtherIcons('devices/access_alarm'),
  'devices/access_time': lazyOtherIcons('devices/access_time'),
  'editor/attach_money': lazyEditorIcons('editor/attach_money'),
  'editor/border_color': lazyEditorIcons('editor/border_color'),
  'editor/drag_handle': lazyEditorIcons('editor/drag_handle'),
  'editor/insert_drive_file': lazyEditorIcons('editor/insert_drive_file'),
  'editor/mode': lazyEditorIcons('editor/mode'),
  'editor/monetization_on': lazyEditorIcons('editor/monetization_on'),
  'editor/show_chart': lazyEditorIcons('editor/show_chart'),
  'editor/table_chart': lazyEditorIcons('editor/table_chart'),
  'file/cloud_done': lazyFileIcons('file/cloud_done'),
  'file/download': lazyFileIcons('file/download'),
  'file/folder_open': lazyFileIcons('file/folder_open'),
  'file/folder': lazyFileIcons('file/folder'),
  'file/upload': lazyFileIcons('file/upload'),
  'files_compact/property_DOC': lazyFileIcons('files_compact/property_DOC'),
  'files_compact/property_JPG': lazyFileIcons('files_compact/property_JPG'),
  'files_compact/property_JSON': lazyFileIcons('files_compact/property_JSON'),
  'files_compact/property_PDF': lazyFileIcons('files_compact/property_PDF'),
  'files_compact/property_PNG': lazyFileIcons('files_compact/property_PNG'),
  'files_compact/property_TXT': lazyFileIcons('files_compact/property_TXT'),
  'files_compact/property_XLS_CSV': lazyFileIcons('files_compact/property_XLS_CSV'),
  'files_compact/property_ZIP': lazyFileIcons('files_compact/property_ZIP'),
  'files_default/file_type_CSV': lazyFileIcons('files_default/file_type_CSV'),
  'files_default/file_type_DOC': lazyFileIcons('files_default/file_type_DOC'),
  'files_default/file_type_JPG': lazyFileIcons('files_default/file_type_JPG'),
  'files_default/file_type_JSON': lazyFileIcons('files_default/file_type_JSON'),
  'files_default/file_type_PDF': lazyFileIcons('files_default/file_type_PDF'),
  'files_default/file_type_PNG': lazyFileIcons('files_default/file_type_PNG'),
  'files_default/file_type_TXT': lazyFileIcons('files_default/file_type_TXT'),
  'files_default/file_type_XLS': lazyFileIcons('files_default/file_type_XLS'),
  'files_default/file_type_ZIP': lazyFileIcons('files_default/file_type_ZIP'),
  'flags/ABW': lazyFlagsIcons('flags/ABW'),
  'flags/AFG': lazyFlagsIcons('flags/AFG'),
  'flags/AGO': lazyFlagsIcons('flags/AGO'),
  'flags/AIA': lazyFlagsIcons('flags/AIA'),
  'flags/ALA': lazyFlagsIcons('flags/ALA'),
  'flags/ALB': lazyFlagsIcons('flags/ALB'),
  'flags/AND': lazyFlagsIcons('flags/AND'),
  'flags/ANT': lazyFlagsIcons('flags/ANT'),
  'flags/ARE': lazyFlagsIcons('flags/ARE'),
  'flags/ARG': lazyFlagsIcons('flags/ARG'),
  'flags/ARM': lazyFlagsIcons('flags/ARM'),
  'flags/ATG': lazyFlagsIcons('flags/ATG'),
  'flags/AUS': lazyFlagsLocaleIcons('flags/AUS'),
  'flags/austria': lazyFlagsLocaleIcons('flags/austria'),
  'flags/AUT': lazyFlagsIcons('flags/AUT'),
  'flags/AZE': lazyFlagsIcons('flags/AZE'),
  'flags/BDI': lazyFlagsIcons('flags/BDI'),
  'flags/BEL': lazyFlagsIcons('flags/BEL'),
  'flags/BEN': lazyFlagsIcons('flags/BEN'),
  'flags/BFA': lazyFlagsIcons('flags/BFA'),
  'flags/BGD': lazyFlagsIcons('flags/BGD'),
  'flags/BGR': lazyFlagsIcons('flags/BGR'),
  'flags/BHR': lazyFlagsIcons('flags/BHR'),
  'flags/BHS': lazyFlagsIcons('flags/BHS'),
  'flags/BIH': lazyFlagsIcons('flags/BIH'),
  'flags/BLR': lazyFlagsIcons('flags/BLR'),
  'flags/BLZ': lazyFlagsIcons('flags/BLZ'),
  'flags/BMU': lazyFlagsIcons('flags/BMU'),
  'flags/BOL': lazyFlagsIcons('flags/BOL'),
  'flags/BRA': lazyFlagsIcons('flags/BRA'),
  'flags/BRB': lazyFlagsIcons('flags/BRB'),
  'flags/BRN': lazyFlagsIcons('flags/BRN'),
  'flags/BTN': lazyFlagsIcons('flags/BTN'),
  'flags/BWA': lazyFlagsIcons('flags/BWA'),
  'flags/CAN': lazyFlagsIcons('flags/CAN'),
  'flags/CHE': lazyFlagsIcons('flags/CHE'),
  'flags/CHL': lazyFlagsIcons('flags/CHL'),
  'flags/CHN': lazyFlagsIcons('flags/CHN'),
  'flags/CIV': lazyFlagsIcons('flags/CIV'),
  'flags/CMR': lazyFlagsIcons('flags/CMR'),
  'flags/COD': lazyFlagsIcons('flags/COD'),
  'flags/COG': lazyFlagsIcons('flags/COG'),
  'flags/COL': lazyFlagsIcons('flags/COL'),
  'flags/COM': lazyFlagsIcons('flags/COM'),
  'flags/CRI': lazyFlagsIcons('flags/CRI'),
  'flags/CUB': lazyFlagsIcons('flags/CUB'),
  'flags/CYM': lazyFlagsIcons('flags/CYM'),
  'flags/CYP': lazyFlagsIcons('flags/CYP'),
  'flags/CZE': lazyFlagsLocaleIcons('flags/CZE'),
  'flags/czech_republic': lazyFlagsLocaleIcons('flags/czech_republic'),
  'flags/DEU': lazyFlagsLocaleIcons('flags/DEU'),
  'flags/DJI': lazyFlagsIcons('flags/DJI'),
  'flags/DMA': lazyFlagsIcons('flags/DMA'),
  'flags/DNK': lazyFlagsIcons('flags/DNK'),
  'flags/DOM': lazyFlagsIcons('flags/DOM'),
  'flags/DZA': lazyFlagsIcons('flags/DZA'),
  'flags/ECU': lazyFlagsIcons('flags/ECU'),
  'flags/EGY': lazyFlagsIcons('flags/EGY'),
  'flags/ERI': lazyFlagsIcons('flags/ERI'),
  'flags/ESP': lazyFlagsLocaleIcons('flags/ESP'),
  'flags/EST': lazyFlagsIcons('flags/EST'),
  'flags/ETH': lazyFlagsIcons('flags/ETH'),
  'flags/EUE': lazyFlagsIcons('flags/EUE'),
  'flags/FIN': lazyFlagsIcons('flags/FIN'),
  'flags/FJI': lazyFlagsIcons('flags/FJI'),
  'flags/FLK': lazyFlagsIcons('flags/FLK'),
  'flags/FRA': lazyFlagsLocaleIcons('flags/FRA'),
  'flags/FSM': lazyFlagsIcons('flags/FSM'),
  'flags/GAB': lazyFlagsIcons('flags/GAB'),
  'flags/GBR': lazyFlagsLocaleIcons('flags/GBR'),
  'flags/GEO': lazyFlagsIcons('flags/GEO'),
  'flags/germany': lazyFlagsLocaleIcons('flags/germany'),
  'flags/GGY': lazyFlagsIcons('flags/GGY'),
  'flags/GHA': lazyFlagsIcons('flags/GHA'),
  'flags/GIB': lazyFlagsIcons('flags/GIB'),
  'flags/GIN': lazyFlagsIcons('flags/GIN'),
  'flags/GMB': lazyFlagsIcons('flags/GMB'),
  'flags/GNB': lazyFlagsIcons('flags/GNB'),
  'flags/GNQ': lazyFlagsIcons('flags/GNQ'),
  'flags/GRC': lazyFlagsIcons('flags/GRC'),
  'flags/GRD': lazyFlagsIcons('flags/GRD'),
  'flags/GTM': lazyFlagsIcons('flags/GTM'),
  'flags/GUY': lazyFlagsIcons('flags/GUY'),
  'flags/HKG': lazyFlagsIcons('flags/HKG'),
  'flags/HND': lazyFlagsIcons('flags/HND'),
  'flags/HRV': lazyFlagsIcons('flags/HRV'),
  'flags/HTI': lazyFlagsIcons('flags/HTI'),
  'flags/HUN': lazyFlagsIcons('flags/HUN'),
  'flags/IDN': lazyFlagsIcons('flags/IDN'),
  'flags/IMN': lazyFlagsIcons('flags/IMN'),
  'flags/IND': lazyFlagsIcons('flags/IND'),
  'flags/IRL': lazyFlagsIcons('flags/IRL'),
  'flags/IRN': lazyFlagsIcons('flags/IRN'),
  'flags/IRQ': lazyFlagsIcons('flags/IRQ'),
  'flags/ISL': lazyFlagsIcons('flags/ISL'),
  'flags/ISR': lazyFlagsIcons('flags/ISR'),
  'flags/ITA': lazyFlagsLocaleIcons('flags/ITA'),
  'flags/JAM': lazyFlagsIcons('flags/JAM'),
  'flags/JEY': lazyFlagsIcons('flags/JEY'),
  'flags/JOR': lazyFlagsIcons('flags/JOR'),
  'flags/JPN': lazyFlagsIcons('flags/JPN'),
  'flags/KAZ': lazyFlagsIcons('flags/KAZ'),
  'flags/KEN': lazyFlagsIcons('flags/KEN'),
  'flags/KGZ': lazyFlagsIcons('flags/KGZ'),
  'flags/KHM': lazyFlagsIcons('flags/KHM'),
  'flags/KNA': lazyFlagsIcons('flags/KNA'),
  'flags/KOR': lazyFlagsIcons('flags/KOR'),
  'flags/KWT': lazyFlagsIcons('flags/KWT'),
  'flags/LAO': lazyFlagsIcons('flags/LAO'),
  'flags/LBN': lazyFlagsIcons('flags/LBN'),
  'flags/LBR': lazyFlagsIcons('flags/LBR'),
  'flags/LBY': lazyFlagsIcons('flags/LBY'),
  'flags/LCA': lazyFlagsIcons('flags/LCA'),
  'flags/LIE': lazyFlagsIcons('flags/LIE'),
  'flags/LKA': lazyFlagsIcons('flags/LKA'),
  'flags/LSO': lazyFlagsIcons('flags/LSO'),
  'flags/LTU': lazyFlagsIcons('flags/LTU'),
  'flags/LUX': lazyFlagsIcons('flags/LUX'),
  'flags/LVA': lazyFlagsIcons('flags/LVA'),
  'flags/MAC': lazyFlagsIcons('flags/MAC'),
  'flags/MAR': lazyFlagsIcons('flags/MAR'),
  'flags/MCO': lazyFlagsIcons('flags/MCO'),
  'flags/MDA': lazyFlagsIcons('flags/MDA'),
  'flags/MDG': lazyFlagsIcons('flags/MDG'),
  'flags/MDV': lazyFlagsIcons('flags/MDV'),
  'flags/MEX': lazyFlagsIcons('flags/MEX'),
  'flags/MKD': lazyFlagsIcons('flags/MKD'),
  'flags/MLI': lazyFlagsIcons('flags/MLI'),
  'flags/MLT': lazyFlagsIcons('flags/MLT'),
  'flags/MMR': lazyFlagsIcons('flags/MMR'),
  'flags/MNE': lazyFlagsIcons('flags/MNE'),
  'flags/MNG': lazyFlagsIcons('flags/MNG'),
  'flags/MOZ': lazyFlagsIcons('flags/MOZ'),
  'flags/MRT': lazyFlagsIcons('flags/MRT'),
  'flags/MSR': lazyFlagsIcons('flags/MSR'),
  'flags/MUS': lazyFlagsIcons('flags/MUS'),
  'flags/MWI': lazyFlagsIcons('flags/MWI'),
  'flags/MYS': lazyFlagsIcons('flags/MYS'),
  'flags/NAM': lazyFlagsIcons('flags/NAM'),
  'flags/NER': lazyFlagsIcons('flags/NER'),
  'flags/NGA': lazyFlagsIcons('flags/NGA'),
  'flags/NIC': lazyFlagsIcons('flags/NIC'),
  'flags/NLD': lazyFlagsIcons('flags/NLD'),
  'flags/NOR': lazyFlagsIcons('flags/NOR'),
  'flags/NPL': lazyFlagsIcons('flags/NPL'),
  'flags/NZL': lazyFlagsIcons('flags/NZL'),
  'flags/OMN': lazyFlagsIcons('flags/OMN'),
  'flags/PAK': lazyFlagsIcons('flags/PAK'),
  'flags/PAN': lazyFlagsIcons('flags/PAN'),
  'flags/PER': lazyFlagsIcons('flags/PER'),
  'flags/PHL': lazyFlagsIcons('flags/PHL'),
  'flags/PLW': lazyFlagsIcons('flags/PLW'),
  'flags/PNG': lazyFlagsIcons('flags/PNG'),
  'flags/POL': lazyFlagsIcons('flags/POL'),
  'flags/PRI': lazyFlagsIcons('flags/PRI'),
  'flags/PRK': lazyFlagsIcons('flags/PRK'),
  'flags/PRT': lazyFlagsIcons('flags/PRT'),
  'flags/PRY': lazyFlagsIcons('flags/PRY'),
  'flags/PYF': lazyFlagsIcons('flags/PYF'),
  'flags/QAT': lazyFlagsIcons('flags/QAT'),
  'flags/RCA': lazyFlagsIcons('flags/RCA'),
  'flags/ROU': lazyFlagsIcons('flags/ROU'),
  'flags/RSM': lazyFlagsIcons('flags/RSM'),
  'flags/RUS': lazyFlagsIcons('flags/RUS'),
  'flags/RWA': lazyFlagsIcons('flags/RWA'),
  'flags/SAU': lazyFlagsIcons('flags/SAU'),
  'flags/SDN': lazyFlagsIcons('flags/SDN'),
  'flags/SEN': lazyFlagsIcons('flags/SEN'),
  'flags/SGP': lazyFlagsIcons('flags/SGP'),
  'flags/SHN': lazyFlagsIcons('flags/SHN'),
  'flags/SLB': lazyFlagsIcons('flags/SLB'),
  'flags/SLE': lazyFlagsIcons('flags/SLE'),
  'flags/SLV': lazyFlagsIcons('flags/SLV'),
  'flags/SOM': lazyFlagsIcons('flags/SOM'),
  'flags/SRB': lazyFlagsIcons('flags/SRB'),
  'flags/SSD': lazyFlagsIcons('flags/SSD'),
  'flags/STP': lazyFlagsIcons('flags/STP'),
  'flags/SUR': lazyFlagsIcons('flags/SUR'),
  'flags/SVK': lazyFlagsLocaleIcons('flags/SVK'),
  'flags/SVN': lazyFlagsIcons('flags/SVN'),
  'flags/SWE': lazyFlagsIcons('flags/SWE'),
  'flags/SWZ': lazyFlagsIcons('flags/SWZ'),
  'flags/SYC': lazyFlagsIcons('flags/SYC'),
  'flags/SYR': lazyFlagsIcons('flags/SYR'),
  'flags/TCA': lazyFlagsIcons('flags/TCA'),
  'flags/TCD': lazyFlagsIcons('flags/TCD'),
  'flags/TGO': lazyFlagsIcons('flags/TGO'),
  'flags/THA': lazyFlagsIcons('flags/THA'),
  'flags/TJK': lazyFlagsIcons('flags/TJK'),
  'flags/TKM': lazyFlagsIcons('flags/TKM'),
  'flags/TMP': lazyFlagsIcons('flags/TMP'),
  'flags/TON': lazyFlagsIcons('flags/TON'),
  'flags/TTO': lazyFlagsIcons('flags/TTO'),
  'flags/TUN': lazyFlagsIcons('flags/TUN'),
  'flags/TUR': lazyFlagsIcons('flags/TUR'),
  'flags/TWN': lazyFlagsIcons('flags/TWN'),
  'flags/TZA': lazyFlagsIcons('flags/TZA'),
  'flags/UGA': lazyFlagsIcons('flags/UGA'),
  'flags/UKR': lazyFlagsIcons('flags/UKR'),
  'flags/URY': lazyFlagsIcons('flags/URY'),
  'flags/USA': lazyFlagsLocaleIcons('flags/USA'),
  'flags/UZB': lazyFlagsIcons('flags/UZB'),
  'flags/VCT': lazyFlagsIcons('flags/VCT'),
  'flags/VEN': lazyFlagsIcons('flags/VEN'),
  'flags/VGB': lazyFlagsIcons('flags/VGB'),
  'flags/VNM': lazyFlagsIcons('flags/VNM'),
  'flags/VUT': lazyFlagsIcons('flags/VUT'),
  'flags/WSM': lazyFlagsIcons('flags/WSM'),
  'flags/YEM': lazyFlagsIcons('flags/YEM'),
  'flags/ZAF': lazyFlagsIcons('flags/ZAF'),
  'flags/ZMB': lazyFlagsIcons('flags/ZMB'),
  'flags/ZWE': lazyFlagsIcons('flags/ZWE'),
  germany: lazyFlagsIcons('germany'),
  'hardware/desktop_mac': lazyHardwareIcons('hardware/desktop_mac'),
  'hardware/keyboard_arrow_down': lazyHardwareIcons('hardware/keyboard_arrow_down'),
  'hardware/keyboard_arrow_left': lazyHardwareIcons('hardware/keyboard_arrow_left'),
  'hardware/keyboard_arrow_right': lazyHardwareIcons('hardware/keyboard_arrow_right'),
  'hardware/keyboard_arrow_up': lazyHardwareIcons('hardware/keyboard_arrow_up'),
  'hardware/security': lazyHardwareIcons('hardware/security'),
  icon_placeholder: lazyOtherIcons('icon_placeholder'),
  'image/add_a_photo': lazyImageIcons('image/add_a_photo'),
  'image/add_photo_alternate': lazyImageIcons('image/add_photo_alternate'),
  'image/blur_off': lazyImageIcons('image/blur_off'),
  'image/camera_alt': lazyImageIcons('image/camera_alt'),
  'image/edit': lazyImageIcons('image/edit'),
  'image/filter_2': lazyImageIcons('image/filter_2'),
  'image/filter_none': lazyImageIcons('image/filter_none'),
  'image/image': lazyImageIcons('image/image'),
  'image/lens': lazyImageIcons('image/lens'),
  'image/photo_camera': lazyImageIcons('image/photo_camera'),
  'image/photo': lazyImageIcons('image/photo'),
  'image/remove_red_eye': lazyImageIcons('image/remove_red_eye'),
  'image/timelapse': lazyImageIcons('image/timelapse'),
  'image/video_call': lazyImageIcons('image/video_call'),
  'image/photo_album': lazyImageIcons('image/photo_album'),
  'maps/directions_car': lazyMapsIcons('maps/directions_car'),
  'maps/fastfood': lazyMapsIcons('maps/fastfood'),
  'maps/local_mall': lazyMapsIcons('maps/local_mall'),
  'maps/local_offer': lazyMapsIcons('maps/local_offer'),
  'maps/local_parking': lazyMapsIcons('maps/local_parking'),
  'maps/person_pin': lazyMapsIcons('maps/person_pin'),
  'maps/place': lazyMapsIcons('maps/place'),
  'maps/zoom_out_map': lazyMapsIcons('maps/zoom_out_map'),
  'modules/pneu_hotel': lazyOtherIcons('modules/pneu_hotel'),
  'navigation/apps': lazyNavigationIcons('navigation/apps'),
  'navigation/arrow_back_ios': lazyNavigationIcons('navigation/arrow_back_ios'),
  'navigation/arrow_back': lazyNavigationIcons('navigation/arrow_back'),
  'navigation/arrow_downward': lazyNavigationIcons('navigation/arrow_downward'),
  'navigation/arrow_drop_down_circle': lazyNavigationIcons('navigation/arrow_drop_down_circle'),
  'navigation/arrow_drop_down': lazyNavigationIcons('navigation/arrow_drop_down'),
  'navigation/arrow_drop_up': lazyNavigationIcons('navigation/arrow_drop_up'),
  'navigation/arrow_forward_ios': lazyNavigationIcons('navigation/arrow_forward_ios'),
  'navigation/arrow_forward': lazyNavigationIcons('navigation/arrow_forward'),
  'navigation/arrow_left': lazyNavigationIcons('navigation/arrow_left'),
  'navigation/arrow_right': lazyNavigationIcons('navigation/arrow_right'),
  'navigation/arrow_upward': lazyNavigationIcons('navigation/arrow_upward'),
  'navigation/cancel': lazyNavigationIcons('navigation/cancel'),
  'navigation/cancel_outline': lazyNavigationIcons('navigation/cancel_outline'),
  'navigation/check': lazyNavigationIcons('navigation/check'),
  'navigation/chevron_left': lazyNavigationIcons('navigation/chevron_left'),
  'navigation/chevron_right': lazyNavigationIcons('navigation/chevron_right'),
  'navigation/close': lazyNavigationIcons('navigation/close'),
  'navigation/expand_less': lazyNavigationIcons('navigation/expand_less'),
  'navigation/expand_more': lazyNavigationIcons('navigation/expand_more'),
  'navigation/first_page': lazyNavigationIcons('navigation/first_page'),
  'navigation/fullscreen_exit': lazyNavigationIcons('navigation/fullscreen_exit'),
  'navigation/fullscreen': lazyNavigationIcons('navigation/fullscreen'),
  'navigation/last_page': lazyNavigationIcons('navigation/last_page'),
  'navigation/menu': lazyNavigationIcons('navigation/menu'),
  'navigation/more_horiz': lazyNavigationIcons('navigation/more_horiz'),
  'navigation/more_vert': lazyNavigationIcons('navigation/more_vert'),
  'navigation/refresh': lazyNavigationIcons('navigation/refresh'),
  'navigation/subdirectory_arrow_left': lazyNavigationIcons('navigation/subdirectory_arrow_left'),
  'navigation/subdirectory_arrow_right': lazyNavigationIcons('navigation/subdirectory_arrow_right'),
  'navigation/unfold_less': lazyNavigationIcons('navigation/unfold_less'),
  'navigation/unfold_more': lazyNavigationIcons('navigation/unfold_more'),
  'notification/do_disturb': lazyOtherIcons('notification/do_disturb'),
  'notification/terminal': lazyOtherIcons('notification/terminal'),
  'places/business_center': lazyOtherIcons('places/business_center'),
  'social/cake': lazySocialIcons('social/cake'),
  'social/domain': lazySocialIcons('social/domain'),
  'social/group': lazySocialIcons('social/group'),
  'social/notifications': lazySocialIcons('social/notifications'),
  'social/people': lazySocialIcons('social/people'),
  'social/person_add': lazySocialIcons('social/person_add'),
  'social/person': lazySocialIcons('social/person'),
  'social/poll': lazySocialIcons('social/poll'),
  'social/publicon': lazySocialIcons('social/publicon'),
  'social/share': lazySocialIcons('social/share'),
  'toggle/radio_button_checked': lazyOtherIcons('toggle/radio_button_checked'),
  'toggle/radio_button_unchecked': lazyOtherIcons('toggle/radio_button_unchecked'),
  'toggle/star': lazyOtherIcons('toggle/star'),
  'toggle/tick': lazyOtherIcons('toggle/tick'),
  'toggle/minus': lazyOtherIcons('toggle/minus'),
  paste: lazyOtherIcons('paste'),
  upload: lazyOtherIcons('upload'),
  view: lazyOtherIcons('view'),
  viewOff: lazyOtherIcons('viewOff'),
  'wizard/danger': lazyOtherIcons('wizard/danger'),
  'wizard/success': lazyOtherIcons('wizard/success'),
  'wizard/informational': lazyOtherIcons('wizard/informational'),
  'wizard/warning': lazyOtherIcons('wizard/warning'),
  'lightbox/minus': lazyOtherIcons('lightbox/minus'),
  'lightbox/plus': lazyOtherIcons('lightbox/plus'),
  'action/ai': lazyActionIcons('action/ai'),
  'action/rotate': lazyActionIcons('action/rotate'),
  'specification/co2': lazySpecificationIcons('specification/co2'),
  'specification/drive_type': lazySpecificationIcons('specification/drive_type'),
  'specification/fuel_type': lazySpecificationIcons('specification/fuel_type'),
  'specification/mileage': lazySpecificationIcons('specification/mileage'),
  'specification/power': lazySpecificationIcons('specification/power'),
  'specification/transmission': lazySpecificationIcons('specification/transmission'),
  'specification/vehicle': lazySpecificationIcons('specification/vehicle'),
  'specification/year': lazySpecificationIcons('specification/year'),
  'technicalParams/trunk': lazySpecificationIcons('technicalParams/trunk'),
  'technicalParams/weight': lazySpecificationIcons('technicalParams/weight'),
  'technicalParams/towing': lazySpecificationIcons('technicalParams/towing'),
  'sidebar/add': lazySidebarIcons('sidebar/add'),
  'sidebar/build': lazySidebarIcons('sidebar/build'),
  'sidebar/business_center': lazySidebarIcons('sidebar/business_center'),
  'sidebar/contract': lazySidebarIcons('sidebar/contract'),
  'sidebar/dashboard': lazySidebarIcons('sidebar/dashboard'),
  'sidebar/deployed_code_account': lazySidebarIcons('sidebar/deployed_code_account'),
  'sidebar/deployed_code_alert': lazySidebarIcons('sidebar/deployed_code_alert'),
  'sidebar/deployed_code': lazySidebarIcons('sidebar/deployed_code'),
  'sidebar/directions_car': lazySidebarIcons('sidebar/directions_car'),
  'sidebar/employee': lazySidebarIcons('sidebar/employee'),
  'sidebar/engineering': lazySidebarIcons('sidebar/engineering'),
  'sidebar/fiber_manual_record_add': lazySidebarIcons('sidebar/fiber_manual_record_add'),
  'sidebar/fiber_manual_record_favorite': lazySidebarIcons('sidebar/fiber_manual_record_favorite'),
  'sidebar/fiber_manual_record_hidden': lazySidebarIcons('sidebar/fiber_manual_record_hidden'),
  'sidebar/fiber_manual_record_swap': lazySidebarIcons('sidebar/fiber_manual_record_swap'),
  'sidebar/fiber_manual_record': lazySidebarIcons('sidebar/fiber_manual_record'),
  'sidebar/frame_inspect': lazySidebarIcons('sidebar/frame_inspect'),
  'sidebar/invoice': lazySidebarIcons('sidebar/invoice'),
  'sidebar/list_alt': lazySidebarIcons('sidebar/list_alt'),
  'sidebar/local_shipping': lazySidebarIcons('sidebar/local_shipping'),
  'sidebar/loyalty': lazySidebarIcons('sidebar/loyalty'),
  'sidebar/news': lazySidebarIcons('sidebar/news'),
  'sidebar/next_week': lazySidebarIcons('sidebar/next_week'),
  'sidebar/order_approve': lazySidebarIcons('sidebar/order_approve'),
  'sidebar/paid': lazySidebarIcons('sidebar/paid'),
  'sidebar/pending_actions': lazySidebarIcons('sidebar/pending_actions'),
  'sidebar/person': lazySidebarIcons('sidebar/person'),
  'sidebar/pneuhotel': lazySidebarIcons('sidebar/pneuhotel'),
  'sidebar/point_of_sale': lazySidebarIcons('sidebar/point_of_sale'),
  'sidebar/settings': lazySidebarIcons('sidebar/settings'),
  'sidebar/shopping_cart': lazySidebarIcons('sidebar/shopping_cart'),
  'sidebar/stroke_full': lazySidebarIcons('sidebar/stroke_full'),
  'sidebar/trending_up': lazySidebarIcons('sidebar/trending_up'),
  'sidebar/warehouse': lazySidebarIcons('sidebar/warehouse'),
  'sidebar/wheel': lazySidebarIcons('sidebar/wheel'),
  'sidebar/wheel_list': lazySidebarIcons('sidebar/wheel_list'),
  fallback: fallbackIcon,
} as const;
