import {Action, ButtonVariant} from 'platform/components';

import {isEmpty, isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {buildArray, Nullish} from 'shared';

type Option = {
  key: string;
  style: string;
  position: number;
  // upcoming props
  title?: string;
  variant?: ButtonVariant;
};

type GetActionsResponse = {
  optionClick: string;
  options: Option[];
};

type HeaderActionCallback = (key: string) => void;

type CreateHeaderActionsConfig = {
  actions: GetActionsResponse | Nullish;
  callback: HeaderActionCallback;
  isLoading?: boolean;
};

/**
 * Generates a list of header actions based on the provided configuration.
 *
 * @param {CreateHeaderActionsConfig} props - The configuration object containing actions, callback, and optional loading state.
 * @returns {Action[]} An array of actions to be rendered in the header.
 *
 * @example
 * const actions = createHeaderActions({
 *   actions: {
 *     optionClick: 'save',
 *     options: [
 *       { key: 'save', style: 'button', position: 1 },
 *       { key: 'edit', style: 'button', position: 2 },
 *       { key: 'delete', style: 'hidden', position: 3 }
 *     ]
 *   },
 *   callback: (key) => console.log(key),
 *   isLoading: false
 * });
 */
export const createHeaderActions = (props: CreateHeaderActionsConfig): Action[] => {
  if (isNil(props.actions) || isEmpty(props.actions.options)) {
    return [];
  }

  const optionClick = props.actions.options.find(
    (option) => option.key === props.actions?.optionClick
  );

  const contextOptions = props.actions.options
    .filter((option) => option.key !== props.actions?.optionClick && option.style !== 'hidden')
    .sort((a, b) => a.position - b.position);

  return buildArray<Action>()
    .when(isNotNilOrEmpty(contextOptions), {
      type: 'dropdown-iconButton',
      icon: 'navigation/more_vert',
      priority: 'secondary',
      menuItems: contextOptions.map((contextOption) => ({
        variant: 'primary',
        isLoading: props.isLoading ?? false,
        label: contextOption.title ?? contextOption.key,
        onClick: (event) => {
          event.stopPropagation();
          props.callback(contextOption.key);
        },
      })),
    })
    .when(props.actions?.optionClick, {
      type: 'button',
      title: optionClick?.title ?? optionClick?.key ?? '',
      variant: optionClick?.variant ?? 'primary',
      isLoading: props.isLoading ?? false,
      onClick: (event) => {
        event.stopPropagation();
        props.callback(props.actions?.optionClick as string);
      },
    });
};
