import {match, Pattern} from 'ts-pattern';

import {always, isNotEmpty} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {EMPTY_PLACEHOLDER, MakeFieldDefinitionResponseBody} from '@omnetic-dms/shared';

export type GetValueTypeLabelArgs = MakeFieldDefinitionResponseBody & {
  currencySymbol?: string;
};

export const getValueTypeLabel = (definition: GetValueTypeLabelArgs) => {
  const stringWithEnum = `${i18n.t(`page.generalSettings.labels.string`)} (${i18n.t(`page.generalSettings.labels.enum`)})`;
  const moneyWithCurrency = `${i18n.t(`page.generalSettings.labels.money`)} (${definition.currencySymbol || EMPTY_PLACEHOLDER})`;

  const valueTypeLabel = match([definition.valueType, isNotEmpty(definition.allowedValues)])
    .with(['string', true], always(stringWithEnum))
    .with(['money', Pattern.any], always(moneyWithCurrency))
    .otherwise(([valueType]) => i18n.t(`page.generalSettings.labels.${valueType}`));

  return valueTypeLabel;
};
