import {moduleKeys} from '@omnetic-dms/config';
import {inspectionRoutes} from '@omnetic-dms/routes';
import {Module, featureFlags, permissions} from '@omnetic-dms/shared';

import {Inspection} from './pages/Inspection/Inspection';

export const InspectionModule: Module = {
  id: moduleKeys.inspection,
  layoutProps: {
    title: 'module.inspection.title',
    icon: 'action/youtube_searched_for',
    iconV2: 'sidebar/frame_inspect',
  },
  routerProps: {
    path: inspectionRoutes.inspection,
  },
  requiredPermission: permissions.readInspection,
  requiredFeatureFlag: featureFlags.INSPECTION_MODULE,
  content: [
    {
      id: 'inspection-module-page',
      routerProps: {
        path: inspectionRoutes.inspection,
        element: <Inspection />,
      },
      layoutProps: {
        title: 'module.inspection.title',
        icon: 'sidebar/frame_inspect',
      },
    },
  ],
};
