import {fromUint8Array} from 'js-base64';

interface FileChunkReadParams {
  uri: string;
  index: number;
  fileSize: number;
  chunkSize: number;
}

export function getWebBase64(params: FileChunkReadParams) {
  return fetch(params.uri)
    .then((response) => response.blob())
    .then((blob) => {
      const start = params.index * params.chunkSize;
      const end = Math.min((params.index + 1) * params.chunkSize, params.fileSize);

      return blob.slice(start, end);
    })
    .then((blob) => blob.arrayBuffer())
    .then((buffer) => fromUint8Array(new Uint8Array(buffer)));
}
