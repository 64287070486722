import {Clickable, Icon} from 'platform/foundation';

import {
  handleApiError,
  MenuItemPin as MenuItemPinObject,
  MenuItemProps,
  usePinUserMenuItemMutation,
  useUnpinUserMenuItemMutation,
} from '@omnetic-dms/shared';

import {suffixTestId} from 'shared';

interface PinProps extends Pick<MenuItemProps, 'path' | 'id'> {
  isPinned?: boolean;
}

export function MenuItemPin(props: PinProps) {
  const [unpinUserMenuItem] = useUnpinUserMenuItemMutation();
  const [pinUserMenuItem] = usePinUserMenuItemMutation();

  const testIdBase = `routePath=${props.path};id=${props.id}-menuItem`;

  const handlePin = () => {
    const arg: MenuItemPinObject = {item: props.id};
    const pinningAction = props.isPinned ? unpinUserMenuItem(arg) : pinUserMenuItem(arg);

    pinningAction.unwrap().catch(handleApiError);
  };

  return (
    <Clickable
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        handlePin();
      }}
    >
      <Icon
        size={4}
        color="text.white"
        value={props.isPinned ? 'custom/push_pin' : 'custom/push_pin_outlined'}
        data-testid={suffixTestId('pin', {'data-testid': testIdBase})}
      />
    </Clickable>
  );
}
