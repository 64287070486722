import {ButtonGroup, Card, DataStatus, IconButton} from 'platform/components';
import {Box, Right} from 'platform/foundation';

import {FC, useMemo} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {
  CustomerSeriesDefinitionResponseBody,
  selectBranchList,
  useGetCustomerSeriesDefinitionListQuery,
} from '@omnetic-dms/shared';
import {SimpleTable, ColumnType, useRouter, selectSeries} from '@omnetic-dms/teas';

import {composePath} from 'shared';

export const CustomerSeriesDefinitionList: FC = () => {
  const router = useRouter();
  const {
    data: definitions,
    isLoading: isDefinitionsLoading,
    isError: isDefinitionsErrored,
  } = useGetCustomerSeriesDefinitionListQuery();
  const {
    data: {branchListItems: branchList} = {},
    isLoading: isBranchListLoading,
    isError: isBranchListErrored,
  } = useSelector(selectBranchList);
  const series = useSelector(selectSeries);

  const columns = useMemo<ColumnType<CustomerSeriesDefinitionResponseBody>[]>(
    () => [
      {
        Header: i18n.t('entity.seriesDefinition.labels.definitionName'),
        id: 'name',
      },
      {
        Header: i18n.t('entity.branch.labels.branch'),
        id: 'branch',
        Cell: ({row}) => {
          if (!row.branchId) {
            return i18n.t('general.labels.all');
          }

          return branchList
            ? (branchList.find(({id}: {id: string}) => id === row.branchId)?.marketingName ?? '')
            : [];
        },
      },
      {
        Header: i18n.t('entity.seriesDefinition.labels.customerDefinitionSeries'),
        id: 'definitionSeries',
        Cell: ({row}) => series.find(({id}) => id === row.seriesId)?.name ?? row.seriesId,
      },
      {
        Header: '',
        id: 'actions',
        width: 100,
        Cell: ({row}) => (
          <Box paddingVertical={3}>
            <Right>
              <ButtonGroup>
                <IconButton
                  key="edit"
                  size="small"
                  icon="image/edit"
                  onClick={() =>
                    router.push(
                      composePath(settingsRoutes.customerCodeDefinitionDetail, {
                        params: {id: row.id},
                      })
                    )
                  }
                />
              </ButtonGroup>
            </Right>
          </Box>
        ),
      },
    ],
    [router, branchList, series]
  );

  return (
    <DataStatus
      isLoading={isDefinitionsLoading || isBranchListLoading}
      isError={isDefinitionsErrored || isBranchListErrored}
      minHeight={25}
    >
      {definitions && definitions.length === 0 && (
        <Card title={i18n.t('entity.seriesDefinition.labels.definitions')}>
          <SimpleTable
            noZebra
            showRowDivider
            tdPadding="default"
            highlightRowOnHover
            rows={[
              {
                data: definitions!,
              },
            ]}
            getRowId={({id}) => id}
            columns={columns}
          />
        </Card>
      )}
    </DataStatus>
  );
};
