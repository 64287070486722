import {Button, FormControl, FormField} from 'platform/components';
import {Grid, GridItem, HStack, Heading, VStack} from 'platform/foundation';

import {not} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {GetWarehouseHandlingUnitsResponse} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ReceiveNoteItemFormType} from '../../../../../types/ReceiveNote';
import {getOptionsFromHandlingUnits} from '../../../../../utils/getOptionsFromHandlingUnits';

export interface SupplierToWarehouseMappingFormProps extends RequiredTestIdProps {
  control: FormControl<ReceiveNoteItemFormType>;
  handlingUnits: GetWarehouseHandlingUnitsResponse | Nullish;
  areHandlingUnitsLoading: boolean;
  isChecked: boolean;
  isEditingDisabled: boolean;
  onSaveMapping: () => Promise<void>;
  isSaveMappingLoading: boolean;
}

export function SupplierToWarehouseMappingForm(props: SupplierToWarehouseMappingFormProps) {
  return (
    <VStack spacing={4} data-testid={props['data-testid']}>
      <HStack justify="space-between">
        <Heading size={5}>{i18n.t('entity.warehouse.labels.supplierToWarehouseMapping')}</Heading>
        <Button
          variant="link"
          size="small"
          title={i18n.t('entity.warehouse.actions.saveMapping')}
          leftIcon="content/save"
          onClick={props.onSaveMapping}
          isDisabled={not(props.isChecked) || props.isEditingDisabled}
          isLoading={props.isSaveMappingLoading}
          data-testid={suffixTestId('actions.saveMapping', props)}
        />
      </HStack>

      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            type="number"
            name="supplierToWarehouseMapping.deliveryQuantity"
            label={i18n.t('entity.warehouse.labels.deliveryQuantity')}
            control={props.control}
            isDisabled={not(props.isChecked) || props.isEditingDisabled}
            data-testid={suffixTestId('inputs.deliveryQuantity', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            isRequired
            type="choice"
            name="supplierToWarehouseMapping.deliveryUnit"
            label={i18n.t('entity.warehouse.labels.deliveryUnit')}
            options={getOptionsFromHandlingUnits(props.handlingUnits)}
            control={props.control}
            isLoading={props.areHandlingUnitsLoading}
            isDisabled={not(props.isChecked) || props.isEditingDisabled}
            data-testid={suffixTestId('inputs.deliveryUnit', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            type="currency"
            name="supplierToWarehouseMapping.receiveQuantity"
            label={i18n.t('entity.warehouse.labels.receiveQuantity')}
            control={props.control}
            isDisabled={not(props.isChecked) || props.isEditingDisabled}
            data-testid={suffixTestId('inputs.receiveQuantity', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            type="choice"
            name="supplierToWarehouseMapping.receiveUnit"
            label={i18n.t('entity.warehouse.labels.receiveUnit')}
            options={getOptionsFromHandlingUnits(props.handlingUnits)}
            control={props.control}
            isLoading={props.areHandlingUnitsLoading}
            isDisabled={not(props.isChecked) || props.isEditingDisabled}
            isNotClearable
            menuInPortal
            data-testid={suffixTestId('inputs.receiveUnit', props)}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
}
