import {Card, DataStatus} from 'platform/components';
import {Hide, Show, Space, Text, Image} from 'platform/foundation';
import styled from 'styled-components';

import {ReactElement, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  featureFlags,
  queryParams,
  useIsPublishingMultiBranchEnabledQuery,
  platformImageURLs,
  fakePlatforms,
} from '@omnetic-dms/shared';
import {
  Switcher,
  fetchAdSettingsRequest,
  updatePlatformRequest,
  getAdPlatformsSettingsSelector,
  loadingSelector,
  AdvertisingPlatformCodeEnum,
  EPlatformStatusAction,
} from '@omnetic-dms/teas';

import {composePath, useQueryState} from 'shared';

import {StyledLink} from '../../Tenant/styles';
import {SwitchWrapper} from '../../VehicleServices/components/styles';
import {AutomaticUpdateControl} from './AutomaticUpdateControl';
import {LinkIcon} from './Link';
import {MultiBranchControl} from './MultiBranchControl';

const LeftBar = styled.div`
  display: flex;
  align-items: center;
`;

const ItemWrapper = styled.section`
  border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  padding: 0 16px;

  &:last-child {
    border-bottom: none;
  }
`;

export function GeneralAdvertisementPlatforms() {
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingSelector);
  const platforms = useSelector(getAdPlatformsSettingsSelector);

  const [branchId] = useQueryState(queryParams.BRANCH_ID);
  const {data: isMultiBranch} = useIsPublishingMultiBranchEnabledQuery();
  const isSettingsSynchronised = isMultiBranch === isNotNil(branchId);

  useEffect(() => {
    if (isSettingsSynchronised) {
      dispatch(fetchAdSettingsRequest({branchId: branchId ?? undefined}));
    }
  }, [dispatch, isSettingsSynchronised, branchId]);

  const updateStatus = useCallback(
    (
      platformCode: AdvertisingPlatformCodeEnum,
      action: EPlatformStatusAction,
      branchId?: string
    ) => {
      dispatch(updatePlatformRequest({platformCode, branchId, action}));
    },
    [dispatch]
  );

  const platformLink = (code: string) =>
    composePath(settingsRoutes.advertisingPlatformsDetail, {
      params: {code},
    });

  const items = platforms.map(
    (item): ReactElement => (
      <ItemWrapper key={item.code}>
        <LeftBar>
          <SwitchWrapper
            data-testid={testIds.settings.advertisingPlatforms(`switchWrapper-${item.code}`)}
          >
            <Switcher
              name={`platform-${item.code}`}
              value={item.enabled}
              disabled={!item?.hasCredentials}
              onChange={() =>
                updateStatus(
                  item.code as AdvertisingPlatformCodeEnum,
                  item.enabled ? EPlatformStatusAction.DISABLE : EPlatformStatusAction.ENABLE,
                  branchId ?? undefined
                )
              }
            />
          </SwitchWrapper>
          <Hide when={isNil(platformImageURLs[item.code])}>
            <Image
              src={platformImageURLs[item.code]}
              alt={item.title}
              height={4}
              data-testid={testIds.settings.advertisingPlatforms(`logo-${item.code}`)}
            />
          </Hide>
          <Show when={isNil(platformImageURLs[item.code])}>
            <Text data-testid={testIds.settings.advertisingPlatforms(`logo-${item.code}`)}>
              {item.title}
            </Text>
          </Show>
        </LeftBar>
        <span data-testid={testIds.settings.advertisingPlatforms(`open-${item.code}`)}>
          <Link
            to={platformLink(item.code)}
            data-testid={testIds.settings.advertisingPlatforms(`link-platform-${item.code}`)}
          >
            <StyledLink>
              <LinkIcon />
            </StyledLink>
          </Link>
        </span>
      </ItemWrapper>
    )
  );

  // TODO T20-40939 remove
  const fakeItems = fakePlatforms.map(({code, title}) => (
    <ItemWrapper key={code}>
      <LeftBar>
        <SwitchWrapper data-testid={testIds.settings.advertisingPlatforms(`switchWrapper-${code}`)}>
          <Switcher name={`platform-${code}`} value={false} disabled={true} onChange={() => {}} />
        </SwitchWrapper>
        <Hide when={isNil(platformImageURLs[code])}>
          <Image
            src={platformImageURLs[code]}
            alt={title}
            height={4}
            data-testid={testIds.settings.advertisingPlatforms(`logo-${code}`)}
          />
        </Hide>
        <Show when={isNil(platformImageURLs[code])}>
          <Text data-testid={testIds.settings.advertisingPlatforms(`logo-${code}`)}>{title}</Text>
        </Show>
      </LeftBar>
      <span data-testid={testIds.settings.advertisingPlatforms(`open-${code}`)}>
        <Link
          to={platformLink(code)}
          data-testid={testIds.settings.advertisingPlatforms(`link-platform-${code}`)}
        >
          <StyledLink>
            <LinkIcon />
          </StyledLink>
        </Link>
      </span>
    </ItemWrapper>
  ));

  return (
    <>
      <Show whenFeatureEnabled={featureFlags.SETTINGS_ADVERTISEMENT_AUTOMATIC_UPDATE}>
        <AutomaticUpdateControl
          data-testid={testIds.settings.advertisingPlatforms('automaticUpdateControl')}
        />
        <Space vertical={4} />
      </Show>
      <Show whenFeatureEnabled={featureFlags.SETTINGS_ADVERTISEMENT_MULTIBRANCH}>
        <MultiBranchControl />
        <Space vertical={4} />
      </Show>
      <Card>
        <DataStatus isLoading={isLoading}>
          {items}
          <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_FAKE_PLATFORMS}>{fakeItems}</Show>
        </DataStatus>
      </Card>
    </>
  );
}
