import {
  Action,
  DataStatus,
  DropdownActionMenuItem,
  FlagProps,
  openDeleteDialog,
  openDialog,
  Parameter,
  showNotification,
} from 'platform/components';

import {Helmet} from 'react-helmet-async';

import {any, head, isNil} from 'ramda';
import {isNotNil, isTrue} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, customerRoutes, testIds} from '@omnetic-dms/routes';
import {
  ActivityLogs,
  BusinessCaseState,
  DetailTemplateHeader,
  EMPTY_PLACEHOLDER,
  EntityHeader,
  EntityResourceIds,
  featureFlags,
  GdprConsentsList,
  getBusinessCaseStateFlag,
  getBusinessCaseTypeFlag,
  getCustomerEmail,
  getCustomerName,
  getCustomerPhoneNumber,
  handleApiError,
  isCustomerNaturalPerson,
  NavigationItem,
  Page,
  Section,
  SendEmail,
  SendSms,
  SnippetProps,
  useCreateBusinessCaseMutation,
  useCustomerUrl,
  useDeleteCustomerMutation,
  useDocumentContextCount,
  useGetCustomerContractsByCustomerIdQuery,
  useGetCustomerV2Query,
  useGetEmailConfigQuery,
  useGetKonzultaConfigQuery,
  useGetLastActiveBusinessCaseByCustomerQuery,
  useGetParticipationQuery,
  useGetTireVehicleCountQuery,
  usePermissions,
} from '@omnetic-dms/shared';

import {buildArray, buildObject, composePath, isFeatureEnabled, useNavigate} from 'shared';

import {customerSearchParams} from '../../customerSearchParams';
import {BusinessCases} from './(sections)/BusinessCases/BusinessCases';
import {CustomerContracts} from './(sections)/CustomerContracts/CustomerContracts';
import {CustomerInformation} from './(sections)/CustomerInformation/CustomerInformation';
import {Documents} from './(sections)/Documents/Documents';
import {Interests} from './(sections)/Interests/Interests';
import {ServiceCases} from './(sections)/ServiceCases/ServiceCases';
import {Tires} from './(sections)/Tires/Tires';
import {Vehicles} from './(sections)/Vehicles/Vehicles';

export function CustomerDetail() {
  const navigate = useNavigate();
  const {customerId} = useCustomerUrl();
  const documentsCountBadge = useDocumentContextCount('customer', customerId);

  const {
    data,
    isLoading: isCustomerLoading,
    isError: isCustomerErrored,
  } = useGetCustomerV2Query({customerId});

  const {
    data: konzultaConfig,
    isLoading: isKonzultaConfigLoading,
    isError: isKonzultaConfigErrored,
  } = useGetKonzultaConfigQuery();

  const {
    data: emailConfig,
    isLoading: isEmailConfigLoading,
    isError: isEmailConfigErrored,
  } = useGetEmailConfigQuery();

  const {
    data: activeBusinessCase,
    isLoading: isActiveBCLoading,
    isError: isActiveBCErrored,
  } = useGetLastActiveBusinessCaseByCustomerQuery(
    {customerId},
    {
      selectFromResult: (res) => ({
        ...res,
        data: isNotNil(res?.data) ? head(res.data) : null,
      }),
    }
  );

  const {data: vehicleTires} = useGetTireVehicleCountQuery(
    {customerId},
    {
      skip:
        !isFeatureEnabled(featureFlags.AFTS_TIRE_ORDERS_CUSTOMER_CARD) ||
        !isFeatureEnabled(featureFlags.AFTS_LIST_TIRES_CUSTOMER_CARD),
    }
  );

  const {data: customerParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.customer,
    recordId: customerId,
  });

  const [
    hasDeleteCustomerPermission,
    canPurchaseVehicle,
    canSellVehicle,
    hasCreateBusinessCasePurchasePermission,
    hasCreateBusinessCaseSellingPermission,
    hasAddVehicleToBuyPermission,
    hasAddVehicleToSellPermission,
    canViewBusinessCaseSelling,
    canViewBusinessCasePurchase,
    canReadTag,
    hasAddCustomerTagPermission,
    hasUpdateCustomerTagPermission,
    hasAddCustomerTagToRecordPermission,
    hasRemoveCustomerTagFromRecordPermission,
    hasRemoveCustomerTagPermission,
    hasClearCustomerTagExpirationPermission,
    hasSetCustomerTagExpirationPermission,
    canReadBusinessCase,
    canReadServiceCase,
  ] = usePermissions({
    permissionKeys: [
      'deleteCustomer',
      'viewBusinessCasePurchase',
      'addVehicleToSell',
      'createBusinessCasePurchase',
      'createBusinessCaseSelling',
      'addVehicleToBuy',
      'addVehicleToSell',
      'viewBusinessCaseSelling',
      'viewBusinessCasePurchase',
      'customerTagRead',
      'customerTagAdd',
      'customerTagUpdate',
      'customerTagAddToRecord',
      'customerTagRemoveFromRecord',
      'customerTagRemove',
      'customerTagClearExpiration',
      'customerTagSetExpiration',
      'businessCaseRead',
      'serviceCaseRead',
    ],
    scopes: {
      customerTagAddToRecord: customerParticipation,
      customerTagRead: customerParticipation,
      customerTagRemoveFromRecord: customerParticipation,
    },
  });

  const canCreateBusinessCase =
    hasCreateBusinessCasePurchasePermission && hasAddVehicleToBuyPermission;
  const canCreateSellBusinessCase =
    hasCreateBusinessCaseSellingPermission && hasAddVehicleToSellPermission;

  const canSeePurchase = canPurchaseVehicle && canCreateBusinessCase;
  const canSeeSell = canSellVehicle && canCreateSellBusinessCase;

  const canCreateTag = hasAddCustomerTagPermission && hasUpdateCustomerTagPermission;

  const canAssignOrUnassignTag =
    hasAddCustomerTagToRecordPermission && hasRemoveCustomerTagFromRecordPermission;

  const canDeleteTag =
    hasRemoveCustomerTagPermission &&
    hasClearCustomerTagExpirationPermission &&
    hasSetCustomerTagExpirationPermission;

  const isWorkShopEnabled = isFeatureEnabled(featureFlags.ACL_WORKSHOP) && canReadServiceCase;
  const {data: customerContracts} = useGetCustomerContractsByCustomerIdQuery(
    {customerId},
    {skip: !isWorkShopEnabled}
  );

  const [createBusinessCaseMutation, {isLoading: isCreateBusinessCaseLoading}] =
    useCreateBusinessCaseMutation();
  const [deleteCustomer, {isLoading: isDeleteCustomerLoading}] = useDeleteCustomerMutation();

  const isLoading = any(isTrue, [
    isCustomerLoading,
    isActiveBCLoading,
    isKonzultaConfigLoading,
    isEmailConfigLoading,
  ]);

  const isError = any(isTrue, [
    isCustomerErrored,
    isActiveBCErrored,
    isKonzultaConfigErrored,
    isEmailConfigErrored,
  ]);

  const handleDelete = () =>
    openDeleteDialog({
      'data-testid': testIds.customer.detail('delete'),
      onConfirm: () =>
        deleteCustomer({
          customerId,
        })
          .unwrap()
          .then(() => {
            showNotification.success(i18n.t('entity.customer.notifications.deleteSuccess'));
            navigate(customerRoutes.overview);
          })
          .catch(handleApiError),
    });

  const createBusinessCase = () =>
    createBusinessCaseMutation({
      createBusinessCaseRequestBody: {
        customerId,
      },
    })
      .unwrap()
      .then((res) => navigate(composePath(businessCaseRoutes.customer, {params: {id: res.id}})))
      .catch(handleApiError);

  const navigationItems = buildArray<NavigationItem>()
    .add({
      id: customerSearchParams.customerDetail.customerInformation,
      href: composePath(customerRoutes.customerInformation, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customer.labels.customerInformation'),
      content: <CustomerInformation />,
      'data-testid': testIds.customer.detail('navigation-customerInformation'),
    })
    .when(data && isCustomerNaturalPerson(data), {
      id: customerSearchParams.customerDetail.GDPRConsents,
      href: composePath(customerRoutes.GDPRConsents, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customer.labels.GDPRConsents'),
      content: (
        <Section>
          <GdprConsentsList businessCaseId={activeBusinessCase?.id} customerId={customerId} />
        </Section>
      ),
      'data-testid': testIds.customer.detail('navigation-GDPRConsents'),
    })
    .when(canPurchaseVehicle || canSellVehicle, {
      id: customerSearchParams.customerDetail.businessCases,
      href: composePath(customerRoutes.businessCases, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customer.labels.businessCases'),
      content: <BusinessCases />,
      'data-testid': testIds.customer.detail('navigation-businessCases'),
    })
    .add({
      id: customerSearchParams.customerDetail.interests,
      href: composePath(customerRoutes.interests, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customer.labels.interests'),
      content: <Interests />,
      'data-testid': testIds.customer.detail('navigation-interests'),
    })
    .add({
      id: customerSearchParams.customerDetail.vehicles,
      href: composePath(customerRoutes.vehicles, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customer.labels.vehicles'),
      content: <Vehicles />,
      'data-testid': testIds.customer.detail('navigation-vehicles'),
    })
    .add({
      id: customerSearchParams.customerDetail.documents,
      href: composePath(customerRoutes.documents, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customer.labels.documents'),
      content: <Documents />,
      'data-testid': testIds.customer.detail('navigation-documents'),
      badge: documentsCountBadge,
    })
    .when(isWorkShopEnabled, {
      id: customerSearchParams.customerDetail.serviceCases,
      href: composePath(customerRoutes.serviceCases, {
        params: {id: customerId},
      }),
      label: i18n.t('page.workshop.labels.serviceCases'),
      content: <ServiceCases />,
      'data-testid': testIds.customer.detail('navigation-serviceCases'),
    })
    .when(isWorkShopEnabled, {
      id: customerSearchParams.customerDetail.customerContracts,
      href: composePath(customerRoutes.customerContracts, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customerContract.labels.customerContracts'),
      content: <CustomerContracts />,
      hasSeparator: true,
      badge: {
        colorScheme: 'neutral',
        value: customerContracts?.length,
      },
      'data-testid': testIds.customer.detail('navigation-customerContracts'),
    })
    .whenFeatureEnabled(featureFlags.CORE_ACTIVITY_LOGS, {
      id: 'activityLogs',
      href: composePath(customerRoutes.activityLogs, {
        params: {id: customerId},
      }),
      label: i18n.t('general.labels.activityLogs'),
      content: <ActivityLogs code="customer-action-log" />,
      'data-testid': testIds.vehicles.detail('navigation-item-activityLogs'),
    })
    .when(
      isFeatureEnabled(featureFlags.AFTS_TIRE_ORDERS_CUSTOMER_CARD) ||
        isFeatureEnabled(featureFlags.AFTS_LIST_TIRES_CUSTOMER_CARD),
      {
        id: 'tires',
        href: composePath(customerRoutes.tires, {
          params: {id: customerId},
        }),
        label: i18n.t('page.tiresInventory.labels.tires'),
        content: <Tires />,
        isDisabled: !Boolean(vehicleTires?.count),
        'data-testid': testIds.vehicles.detail('navigation-item-tires'),
      }
    );

  const entityHeaderProps = buildObject<DetailTemplateHeader>()
    .title(getCustomerName(data) ?? EMPTY_PLACEHOLDER)
    .icon('social/person')
    .height(61)
    .parameters([data?.publicId])
    .recordId(data?.id)
    .resourceId(EntityResourceIds.customer)
    .controls(['ASSIGNEE'])
    .flags(
      buildArray<FlagProps>()
        .when(data && isCustomerNaturalPerson(data), {
          colorScheme: 'teal',
          label: i18n.t('general.labels.person'),
          'data-testid': testIds.customer.detail('person'),
        })
        .when(data && !isCustomerNaturalPerson(data), {
          colorScheme: 'purple',
          label: i18n.t('general.labels.company'),
          'data-testid': testIds.customer.detail('company'),
        })
    )
    .primaryParameter(
      getCustomerPhoneNumber(data) ?? EMPTY_PLACEHOLDER,
      data && isCustomerNaturalPerson(data)
    )
    .secondaryParameter(
      getCustomerEmail(data) ?? EMPTY_PLACEHOLDER,
      data && isCustomerNaturalPerson(data)
    )
    .snippets(
      buildArray<SnippetProps>()
        .when(
          isNotNil(activeBusinessCase?.id) &&
            (activeBusinessCase?.vehicleDataPartialInfo?.purchaseVehiclesPartialInfo?.length ||
              activeBusinessCase?.vehicleDataPartialInfo?.purchaseBrokerageVehiclesPartialInfo
                ?.length)
            ? canPurchaseVehicle
            : canSellVehicle,
          buildObject<SnippetProps>()
            .icon('places/business_center')
            .label(i18n.t('page.businessCase.labels.activeBusinessCase'))
            .title(
              head(
                activeBusinessCase?.vehicleDataPartialInfo?.purchaseBrokerageVehiclesPartialInfo ??
                  []
              )?.title ||
                head(activeBusinessCase?.vehicleDataPartialInfo?.purchaseVehiclesPartialInfo ?? [])
                  ?.title ||
                head(activeBusinessCase?.vehicleDataPartialInfo?.saleVehiclesPartialInfo ?? [])
                  ?.title ||
                i18n.t(`page.workshop.placeholders.noVehicle`)
            )
            .flags(
              buildArray<FlagProps>()
                .when(
                  activeBusinessCase?.businessCaseState,
                  getBusinessCaseStateFlag(
                    activeBusinessCase?.businessCaseState as BusinessCaseState
                  ) as FlagProps
                )
                .when(
                  activeBusinessCase?.businessCaseType,
                  getBusinessCaseTypeFlag(activeBusinessCase?.businessCaseType) as FlagProps
                )
            )
            .parameters(
              buildArray<Parameter>()
                .when(activeBusinessCase?.code, activeBusinessCase?.code)
                .when(
                  activeBusinessCase?.ownerFirstName && activeBusinessCase?.ownerLastName,
                  `${activeBusinessCase?.ownerFirstName} ${activeBusinessCase?.ownerLastName}`
                )
            )
            .href(
              activeBusinessCase?.id
                ? composePath(businessCaseRoutes.overview, {
                    params: {id: activeBusinessCase.id},
                  })
                : undefined,
              isNotNil(activeBusinessCase?.id)
            )
            .selectedContactId(
              isNotNil(activeBusinessCase?.ownerId) ? activeBusinessCase?.ownerId : undefined
            )
            .build()
        )
        .when(
          isNil(activeBusinessCase?.id) && (canCreateBusinessCase || canCreateSellBusinessCase),
          {
            icon: 'places/business_center',
            label: i18n.t('page.businessCase.labels.activeBusinessCase'),
            placeholder: {
              title: i18n.t('page.businessCase.actions.create'),
              onClick: createBusinessCase,
            },
          }
        )
    )
    .actions(
      buildArray<Action>()
        .when(hasDeleteCustomerPermission, {
          type: 'dropdown-iconButton',
          priority: 'secondary',
          menuItems: buildArray<DropdownActionMenuItem>().add({
            label: i18n.t('entity.customer.actions.delete'),
            onClick: handleDelete,
            isLoading: isDeleteCustomerLoading,
            'data-testid': testIds.customer.detail('delete'),
          }),
          'data-testid': testIds.customer.detail('vehicleHeader-more'),
        })
        .when(konzultaConfig?.enabled || emailConfig?.enabled, {
          type: 'dropdown-button',
          variant: 'secondary',
          rightIcon: 'hardware/keyboard_arrow_down',
          title: i18n.t('general.actions.sendMessage'),
          menuItems: buildArray<DropdownActionMenuItem>()
            .when(emailConfig?.enabled, {
              label: i18n.t('general.labels.email'),
              leftIcon: 'communication/email',
              onClick: () =>
                openDialog(
                  <SendEmail
                    data-testid={testIds.customer.detail('sendEmail')}
                    customerId={customerId}
                  />,
                  {
                    scrollBehavior: 'outside',
                    title: i18n.t('general.labels.email'),
                  }
                ),
              'data-testid': testIds.customer.detail('sendEmail'),
            })
            .when(konzultaConfig?.enabled, {
              label: i18n.t('general.labels.sms'),
              leftIcon: 'communication/textsms',
              onClick: () =>
                openDialog(
                  <SendSms
                    data-testid={testIds.customer.detail('sendSms')}
                    customerId={customerId}
                    selectedContactId={data?.id}
                  />,
                  {
                    scrollBehavior: 'outside',
                    title: i18n.t('general.labels.smsMessage'),
                  }
                ),
              'data-testid': testIds.customer.detail('sendSms'),
            }),
          'data-testid': testIds.customer.detail('sendMessage'),
        })
        .when(
          isFeatureEnabled(featureFlags.ACL_SALES) &&
            canReadBusinessCase &&
            ((canSeePurchase && canViewBusinessCasePurchase) ||
              (canSeeSell && canViewBusinessCaseSelling)),
          {
            type: 'button',
            variant: 'primary',
            title: i18n.t('page.businessCase.actions.create'),
            onClick: createBusinessCase,
            isLoading: isCreateBusinessCaseLoading,
            'data-testid': testIds.customer.detail('createBusinessCase'),
          }
        )
    )
    .build();

  return (
    <>
      {data && <Helmet title={i18n.t('page.customer.title', {name: getCustomerName(data)})} />}
      <DataStatus isLoading={isLoading} isError={isError} minHeight={100}>
        <Page
          key={customerId}
          navigation={navigationItems}
          header={
            <EntityHeader
              {...entityHeaderProps}
              isTagDeletable={canDeleteTag}
              isTagUpdatable={canCreateTag}
              isTagReadable={canReadTag}
              isTagCreatable={canCreateTag}
              isTagAssignable={canAssignOrUnassignTag}
              isTagUnassignable={canAssignOrUnassignTag}
              data-testid={testIds.customer.detail('customer-detail')}
            />
          }
          data-testid={testIds.customer.detail('customer-page')}
        />
      </DataStatus>
    </>
  );
}
