import {Attributes} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';
import {Nullish} from 'utility-types';

import {RequiredTestIdProps, parseDate, suffixTestId} from 'shared';

import i18n from '../../../i18n/i18n';

interface VehicleServiceIntervalFluidAttributesProps extends RequiredTestIdProps {
  part:
    | {
        lastReplacementDate: string | Nullish;
        lastReplacementMileage: number | Nullish;
        nextReplacementMileage: number | Nullish;
        nextReplacementDate: string | Nullish;
      }
    | Nullish;
}

export function VehicleServiceIntervalFluidAttributes(
  props: VehicleServiceIntervalFluidAttributesProps
) {
  const formatDateTime = useDateTimeFormatter();

  return (
    <Attributes
      rows={[
        {
          label: i18n.t('entity.vehicle.labels.dateOfLastReplacement'),
          value: props.part?.lastReplacementDate
            ? formatDateTime('dateShort', parseDate(props.part?.lastReplacementDate))
            : null,
        },
        {
          label: `${i18n.t('entity.vehicle.labels.mileageAtLastReplacement')} (${i18n.t('general.metric.km')})`,
          value: props.part?.lastReplacementMileage,
        },
        {
          label: i18n.t('entity.vehicle.labels.replacementAfterMonths'),
          value: props.part?.nextReplacementMileage,
        },
        {
          label: i18n.t('entity.vehicle.labels.nextReplacementDate'),
          value: props.part?.nextReplacementDate
            ? formatDateTime('dateShort', parseDate(props.part?.nextReplacementDate))
            : null,
        },
      ]}
      data-testid={suffixTestId('part', props)}
    />
  );
}
