import {useReducer} from 'react';

import {isNil} from 'ramda';

import {isDefined} from 'shared';

import {GetBase64Fn} from '../types/GetBase64Fn';
import {HandleMultiMultiPartUploadType} from '../types/HandleMultiMultiPartUploadType';
import {QueueVideoTask} from '../types/QueueVideoTask';
import {QueueVideoTaskJob} from '../types/QueueVideoTaskJob';
import {UploadApi} from '../types/UploadApi';
import {VideoUploadError} from '../types/VideoUploadError';
import {convertFileToQueueTask} from '../utils/convertFileToQueueTask';
import {enhanceWithCountOfParts} from '../utils/enhanceWithCountOfParts';
import {getUploadProgress} from '../utils/getUploadProgress';
import {handleUploadChunk} from '../utils/handleUploadChunk';
import {TaskActionKind as ActionKind, taskReducer} from '../utils/taskReducer';
import {usePreSignLinksManager} from './usePreSignLinksManager';
import {useVideoCompleteManager} from './useVideoCompleteManager';

export function useVideoUploadManager(
  api: UploadApi,
  errorCallback: (error: VideoUploadError) => void
) {
  const [{tasks}, dispatch] = useReducer(taskReducer, {tasks: []});
  const {getPreSignLinks} = usePreSignLinksManager(api.sendMultiPartUploadRequest);
  const {handleTaskCompleted} = useVideoCompleteManager(api.sendMultipartUploadCompleted);

  const addTask = (file: QueueVideoTask) =>
    dispatch({type: ActionKind.ADD_TASK, payload: {task: file}});

  const removeTask = (id: string) => dispatch({type: ActionKind.REMOVE_TASK, payload: {id}});

  const handleError = (error: VideoUploadError, taskConnectionFileId?: string) => {
    errorCallback(error);
    if (isDefined(taskConnectionFileId)) {
      updateTaskStatus(taskConnectionFileId, 'ERROR');
    }
  };

  /**
   * Retrieves the progress of each task.
   */
  const uploadProgress = tasks
    .map((task) => {
      const doneJobsLength = task.jobs.filter((j) => j.status === 'DONE').length;
      const allJobsLength = task.jobs.length;

      return allJobsLength > 0
        ? {
            id: task.id,
            progress: getUploadProgress(doneJobsLength / allJobsLength),
            connectionFileId: task.connectionFileId,
          }
        : null;
    })
    .filter(isDefined);

  /**
   * Initiates a multipart upload process for a file.
   */
  const handleMultiMultiPartUpload: HandleMultiMultiPartUploadType = (rawFile, callback) => {
    const file = enhanceWithCountOfParts(rawFile);
    const chunkSize = file.chunkSize;

    if (isNil(chunkSize)) {
      return Promise.resolve(errorCallback(VideoUploadError.MAX_VIDEO_SIZE));
    }

    return getPreSignLinks(file)
      .then((uploadInfo) => {
        const task = convertFileToQueueTask({...file, chunkSize}, uploadInfo, callback);
        addTask(task);
        return task;
      })
      .catch(() => errorCallback(VideoUploadError.GET_PRE_SIGN_LINKS));
  };

  const handleExpiredTask = (task: QueueVideoTask) => {
    removeTask(task.id);
    return handleMultiMultiPartUpload(task, task.callback);
  };

  /**
   * Updates an existing task status in the upload queue with new information.
   */
  const updateTaskStatus = (taskConnectionFileId: string, status: QueueVideoTask['status']) =>
    dispatch({type: ActionKind.UPDATE_TASK_STATUS, payload: {status, taskConnectionFileId}});

  /**
   * Updates a specific job within an upload task.
   */
  const updateTaskJob = (
    taskConnectionFileId: string,
    updatedJob: Partial<QueueVideoTaskJob> & {id: string}
  ) =>
    dispatch({type: ActionKind.UPDATE_TASK_JOB, payload: {job: updatedJob, taskConnectionFileId}});

  /**
   * Processes a single job within an upload task by uploading the corresponding chunk of the file.
   * It updates the job's status to 'ONGOING' at the start and to 'DONE' upon completion. This function is
   * designed to be called for each job within a task, handling the asynchronous upload logic and state updates.
   *
   * @param job - The `QueueVideoTaskJob` object representing the job to be processed.
   * @param task - The `QueueVideoTask` object representing the parent task of the job.
   * @param getBase64 - A function that reads a file segment and returns it as a base64 string.
   * @returns A promise that resolves once the job has been processed, indicating that the chunk upload is complete.
   *
   * @sideEffects
   * - Performs asynchronous operations, including reading a file segment and uploading it.
   * - Updates the state of the job and potentially the task within the React component state.
   */
  const processJob = (job: QueueVideoTaskJob, task: QueueVideoTask, getBase64: GetBase64Fn) => {
    updateTaskJob(task.connectionFileId, {id: job.id, status: 'ONGOING'});

    return handleUploadChunk(task, job, getBase64)
      .then(({etag}) => updateTaskJob(task.connectionFileId, {id: job.id, status: 'DONE', etag}))
      .catch(() => handleError(VideoUploadError.UNABLE_TO_UPLOAD_CHUNK, task.connectionFileId));
  };

  /**
   * Initiates the processing of an upload task. It updates the task's status and sequentially processes
   * each 'PENDING' job within the task. If a job is found, it calls `processJob` to handle the upload of the chunk.
   * Once all jobs are processed or if no pending jobs are found, it updates the task's status accordingly.
   *
   * @param task - The `QueueVideoTask` to be processed. This includes updating its status and processing its jobs.
   * @param getBase64 - A function that reads a file segment and returns it as a base64 string.
   * @param onCompleteCallback - A function that handles completing task in upper scope.
   * @returns A promise that resolves once the task processing is complete. This may involve updating the task's status
   * to either 'PENDING' or 'DONE', depending on whether there are more jobs to process.
   *
   * @sideEffects
   * - Initiates the processing of jobs within the task, which includes asynchronous file reading and uploading.
   * - Updates the component state to reflect changes in task and job statuses, potentially triggering re-renders.
   */
  const processTask = (
    task: QueueVideoTask,
    getBase64: GetBase64Fn,
    onCompleteCallback: (id: string) => void
  ) => {
    if (task.status === 'PENDING') {
      updateTaskStatus(task.connectionFileId, 'ONGOING');
    }

    const pendingJob = task.jobs.find((job) => job.status === 'PENDING');
    const onGoingJob = task.jobs.find((job) => job.status === 'ONGOING');

    if (isDefined(pendingJob)) {
      return processJob(pendingJob, task, getBase64).finally(() =>
        updateTaskStatus(task.connectionFileId, 'PENDING')
      );
    }
    if (isDefined(onGoingJob)) {
      return;
    }

    updateTaskStatus(task.connectionFileId, 'DONE');
    onCompleteCallback(task.id);

    return handleTaskCompleted(task.connectionFileId, task.jobs)
      .then(() => task.callback(task))
      .then(() => removeTask(task.id))
      .catch(() => handleError(VideoUploadError.UNABLE_TO_COMPLETE_TASK, task.connectionFileId));
  };

  return {
    tasks,
    removeTask,
    processTask,
    uploadProgress,
    handleExpiredTask,
    handleMultiMultiPartUpload,
  };
}
