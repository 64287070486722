import {captureException} from '@sentry/browser';
import {DataStatus, useTranslationContext} from 'platform/components';
import {Box, Icon, Image, Show, Video} from 'platform/foundation';
import {css} from 'styled-components';
import {match} from 'ts-pattern';

import {MouseEventHandler, useReducer, useRef} from 'react';

import {isNil, isNotNil} from 'ramda';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {LightboxVideo} from '../types/LightboxVideo';

interface VideoItemProps extends RequiredTestIdProps {
  video: LightboxVideo;
}

export function VideoItem(props: VideoItemProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [state, dispatch] = useReducer(fetchVideoReducer, fetchVideoInitialState);
  const t = useTranslationContext();

  const isVideoPaused = () => videoRef.current?.paused;

  const pauseVideo = () => {
    videoRef.current?.pause();
  };

  const playVideo = (videoType?: string) => {
    videoRef.current?.play().catch((error) => {
      if (videoType === 'original') {
        dispatch({type: 'PENDING_VARIANTS'});
      } else {
        dispatch({type: 'ERROR', payload: error.message});
        captureException(error, {extra: {url: state.url, variant: videoType}});
      }
    });
  };

  /**
   * @function loadAndPlayVideo
   * @see {@link ../../docs/videoLoad.svg|Video load rules chart}
   */
  const loadAndPlayVideo = () => {
    dispatch({type: 'LOADING'});

    const variant720p = props.video.variants?.find((variant) => variant.type === '720p');
    const variant480p = props.video.variants?.find((variant) => variant.type === '480p');
    const variant360p = props.video.variants?.find((variant) => variant.type === '360p');
    const originalVideo = {type: 'original', url: props.video.originUrl};
    const video = variant720p ?? variant480p ?? variant360p ?? originalVideo;
    if (isNotNil(video?.url)) {
      dispatch({type: 'SUCCESS', payload: video.url});
      /**
       * short wait before component flips state
       * which turned out to be a lot less logical than checking whether the video loaded or not
       */
      setTimeout(() => playVideo(video.type), FLIP_STATE_TIMEOUT);
    } else {
      dispatch({type: 'PENDING_VIDEO'});
    }
  };

  const handleVideoClick: MouseEventHandler<HTMLDivElement> | undefined = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isNil(state.url) || state.isErrored) {
      loadAndPlayVideo();
    } else {
      if (isVideoPaused()) {
        playVideo();
      } else {
        pauseVideo();
      }
    }
  };

  const errorMessage = match(state.error)
    .with('PENDING_VIDEO', () => t('platform.lightbox.pendingVideo'))
    .with('PENDING_VARIANTS', () => t('platform.lightbox.pendingVariants'))
    .otherwise(() => undefined); // Default message will be applied

  return (
    <div
      css={css`
        cursor: pointer;
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({theme}) => theme.colors.palettes.neutral['900']['100']};
        overflow: hidden;
        position: relative;
      `}
      onClick={handleVideoClick}
    >
      <DataStatus isError={state.isErrored} errorMessage={errorMessage}>
        {isNotNil(state.url) && state.isSucceeded ? (
          <Video ref={videoRef} data-testid={suffixTestId('videoItem', props)} src={state.url} />
        ) : (
          <>
            <Show when={isNotNil(props.video.coverImageUrl)}>
              <Box width="100%" height="100%" position="absolute">
                <Image
                  src={props.video.coverImageUrl}
                  width="100%"
                  height="100%"
                  fit="contain"
                  data-testid={suffixTestId('videoItem-coverImage', props)}
                />
              </Box>
            </Show>
            <Show when={isNil(props.video.coverImageUrl)}>
              <Box
                width="100%"
                height="100%"
                position="absolute"
                backgroundColor="palettes.neutral.10.40"
              />
            </Show>
            <Box position="relative">
              <Icon
                data-testid={suffixTestId('videoItem-empty', props)}
                value="AV/play_arrow"
                size={15}
              />
            </Box>
          </>
        )}
      </DataStatus>
    </div>
  );
}

type FetchVideoAction =
  | {type: 'LOADING'}
  | {type: 'SUCCESS'; payload: string}
  | {type: 'PENDING_VIDEO'} // Even original is not available yet
  | {type: 'PENDING_VARIANTS'} // Original is ready, but no variants yet
  | {type: 'ERROR'; payload?: string};

type FetchVideoState = {
  isLoading: boolean;
  isErrored: boolean;
  isSucceeded: boolean;
  error: string | null;
  url: string | null;
};

const fetchVideoInitialState: FetchVideoState = {
  isLoading: false,
  isErrored: false,
  isSucceeded: false,
  error: null,
  url: null,
};

const fetchVideoReducer = (state: FetchVideoState, action: FetchVideoAction): FetchVideoState =>
  match(action)
    .with({type: 'LOADING'}, () => ({
      ...state,
      isLoading: true,
      isErrored: false,
      isSucceeded: false,
      error: null,
    }))
    .with({type: 'SUCCESS'}, ({payload}) => ({
      ...state,
      isLoading: false,
      isErrored: false,
      isSucceeded: true,
      error: null,
      url: payload,
    }))
    .with({type: 'PENDING_VIDEO'}, () => ({
      ...state,
      isLoading: false,
      isSucceeded: false,
      isErrored: true,
      error: 'PENDING_VIDEO',
    }))
    .with({type: 'PENDING_VARIANTS'}, () => ({
      ...state,
      isLoading: false,
      isSucceeded: false,
      isErrored: true,
      error: 'PENDING_VARIANTS',
    }))
    .with({type: 'ERROR'}, ({payload}) => ({
      ...state,
      isLoading: false,
      isSucceeded: false,
      isErrored: true,
      error: payload ?? 'OTHER',
    }))
    .otherwise(() => state);

const FLIP_STATE_TIMEOUT = 500;
