import {head} from 'ramda';

import {isDefined} from 'shared';

import {
  CreateAuditApiArg,
  CreateAuditApiResponse,
  CreateAutoFillTemplateApiArg,
  CreateAutoFillTemplateApiResponse,
  CreateInspectionApiArg,
  CreateInspectionApiResponse,
  CreateTypeOfControlSettingsApiArg,
  CreateTypeOfControlSettingsApiResponse,
  DeleteAutoFillTemplateApiArg,
  DeleteAutoFillTemplateApiResponse,
  DeleteInspectionApiArg,
  DeleteInspectionApiResponse,
  DeleteInspectionTemplateApiArg,
  DeleteInspectionTemplateApiResponse,
  DeleteVideoAssetApiArg,
  FileOperationListApiArg,
  FileOperationListApiResponse,
  GetAuditDataApiArg,
  GetAuditDataApiResponse,
  GetAuditStructureApiArg,
  GetAuditStructureApiResponse,
  GetAutoFillTemplateApiArg,
  GetAutoFillTemplatesApiArg,
  GetAutoFillTemplatesApiResponse,
  GetBasicStructureForSettingsApiArg,
  GetBasicStructureForSettingsApiResponse,
  GetNewestStructureByTypeApiArg,
  GetNewestStructureByTypeApiResponse,
  GetPublicizeInspectionApiArg,
  GetPublicizeInspectionApiResponse,
  GetSpecificallyAuditDataApiArg,
  GetSpecificallyAuditDataApiResponse,
  GetStructureSettingsApiArg,
  GetStructureSettingsApiResponse,
  GetVehicleAuditApiArg,
  GetVideoAssetApiArg,
  GetVideoAssetApiResponse,
  InspectionListApiArg,
  InspectionListApiResponse,
  InspectionTemplatesApiResponse,
  InspectionToInProgressApiArg,
  InspectionType,
  LoadAuditDataByVehicleIdApiArg,
  LoadAuditDataByVehicleIdApiResponse,
  RotateImageAssetApiArg,
  RotateImageAssetApiResponse,
  ToFinishingInspectionApiArg,
  ToFinishingInspectionApiResponse,
  ToggleInspectionTemplateActiveStateApiArg,
  ToggleInspectionTemplateActiveStateApiResponse,
  ToInRevisionInspectionApiArg,
  ToInRevisionInspectionApiResponse,
  ToRevisionInspectionApiArg,
  ToRevisionInspectionApiResponse,
  UpdateAuditDataApiArg,
  UpdateAuditDataApiResponse,
  UploadVideoAssetApiArg,
  UploadVideoAssetApiResponse,
  VehicleAuditStatusLogs,
} from '../types/api/carAudit';
import {omneticApi} from './baseApi/omneticApi';

export const carAuditApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    createAutoFillTemplate: build.mutation<
      CreateAutoFillTemplateApiResponse,
      CreateAutoFillTemplateApiArg
    >({
      query(queryArg) {
        return {
          url: `/car-audit/v1/inspection/${queryArg.vehicleId}`,
          method: 'POST',
          body: {
            inspectionType: InspectionType.AUTOFILL_TEMPLATE,
            auditStructureId: queryArg.auditStructureId,
            inspectionName: queryArg.inspectionName,
          },
        };
      },
      invalidatesTags: ['AutoFillTemplates'],
    }),
    getAutoFillTemplates: build.query<GetAutoFillTemplatesApiResponse, GetAutoFillTemplatesApiArg>({
      query: () => ({
        url: `/car-audit/v1/inspections`,
        method: 'POST',
        body: {
          vehicleId: null,
          inspectionType: InspectionType.AUTOFILL_TEMPLATE,
        },
      }),
      transformResponse: (response: GetAutoFillTemplatesApiResponse) =>
        response.filter((item) => item.inspectionName !== null),
      providesTags: ['AutoFillTemplates'],
    }),
    deleteAutoFillTemplate: build.mutation<
      DeleteAutoFillTemplateApiResponse,
      DeleteAutoFillTemplateApiArg
    >({
      query(queryArg) {
        return {
          url: `/car-audit/v1/inspection/${queryArg.inspectionId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['AutoFillTemplates'],
    }),
    getAuditStructure: build.query<GetAuditStructureApiResponse, GetAuditStructureApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/audit-structure/${queryArg.auditStructureId}`,
        method: 'GET',
      }),
    }),
    getNewestStructureByType: build.query<
      GetNewestStructureByTypeApiResponse,
      GetNewestStructureByTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/car-audit/v1/audit-structure/${queryArg.inspectionType}/newest`,
        method: 'GET',
      }),
    }),
    updateAuditData: build.mutation<UpdateAuditDataApiResponse, UpdateAuditDataApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/autofill-template/${queryArg.inspectionId}/${queryArg.autofillTemplateId}`,
        method: 'PUT',
        body: queryArg.requestBody,
      }),
    }),
    getAuditData: build.query<GetAuditDataApiResponse, GetAuditDataApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/audits/${queryArg.auditId}`,
        method: 'GET',
      }),
    }),
    getAutoFillTemplate: build.query<GetAuditDataApiResponse, GetAutoFillTemplateApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/autofill-template/${queryArg.inspectionId}/${queryArg.autoFillTemplateId}`,
        method: 'GET',
      }),
    }),
    createAudit: build.mutation<CreateAuditApiResponse, CreateAuditApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v2/audits/${queryArg.vehicleId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    toFinishingInspection: build.mutation<
      ToFinishingInspectionApiResponse,
      ToFinishingInspectionApiArg
    >({
      query(queryArg) {
        return {
          url: `/car-audit/v2/audits/${queryArg.inspectionId}/to_finishing`,
          method: 'PUT',
          body: {
            note: queryArg.note,
            updatedAt: null,
          },
        };
      },
    }),
    toRevisionInspection: build.mutation<
      ToRevisionInspectionApiResponse,
      ToRevisionInspectionApiArg
    >({
      query(queryArg) {
        return {
          url: `/car-audit/v2/audits/${queryArg.inspectionId}/to_revision`,
          method: 'PUT',
          body: {
            updatedAt: null,
          },
        };
      },
    }),
    toInRevisionInspection: build.mutation<
      ToInRevisionInspectionApiResponse,
      ToInRevisionInspectionApiArg
    >({
      query: (queryArg) => ({
        url: `/car-audit/v2/audits/${queryArg.inspectionId}/in_revision`,
        method: 'PUT',
        body: queryArg.toInRevisionInspectionRequestBody,
      }),
    }),
    inspectionToInProgress: build.mutation<void, InspectionToInProgressApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/audits/${queryArg.inspectionId}/in_progress`,
        method: 'PUT',
      }),
    }),
    getInspectionTemplates: build.query<InspectionTemplatesApiResponse, {isActive?: boolean}>({
      query: (queryArg) => ({
        url: `/car-audit/v1/audit-structure-templates${isDefined(queryArg.isActive) ? `?isActive=${queryArg.isActive}` : ''}`,
        method: 'GET',
      }),
      transformResponse: (response: InspectionTemplatesApiResponse) =>
        response.filter((template) => template.key !== null),
      providesTags: ['InspectionTemplates'],
    }),
    deleteInspectionTemplate: build.mutation<
      DeleteInspectionTemplateApiResponse,
      DeleteInspectionTemplateApiArg
    >({
      query(queryArg) {
        return {
          url: `/car-audit/v1/inspection-structure/${queryArg.templateId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['InspectionTemplates'],
    }),
    toggleInspectionTemplateActiveState: build.mutation<
      ToggleInspectionTemplateActiveStateApiResponse,
      ToggleInspectionTemplateActiveStateApiArg
    >({
      query(queryArg) {
        return {
          url: `/car-audit/v1/inspection-structure/${queryArg.templateId}/toggle-active-state`,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error, arg) => (!isDefined(error) ? ['InspectionTemplates'] : []),
    }),
    getBasicStructureForSettings: build.query<
      GetBasicStructureForSettingsApiResponse,
      GetBasicStructureForSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/car-audit/v1/audit-structure-settings/structure`,
        params: {templateId: queryArg.templateId, inspectionType: queryArg.inspectionType},
      }),
    }),
    createTypeOfControlSettings: build.mutation<
      CreateTypeOfControlSettingsApiResponse,
      CreateTypeOfControlSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/car-audit/v1/audit-structure-settings`,
        method: 'POST',
        body: queryArg.createTypeOfControlSettingsRequestBody,
      }),
      invalidatesTags: ['InspectionTemplates'],
    }),
    getStructureSettings: build.query<GetStructureSettingsApiResponse, GetStructureSettingsApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/audit-structure-settings/${queryArg.typeOfControlId}`,
      }),
    }),
    getPublicizeInspection: build.query<
      GetPublicizeInspectionApiResponse,
      GetPublicizeInspectionApiArg
    >({
      query({inspectionId}) {
        return {
          url: `/car-audit/v1/publicize-inspection/${inspectionId}`,
        };
      },
    }),
    deleteInspection: build.mutation<DeleteInspectionApiResponse, DeleteInspectionApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/inspection/${queryArg.inspectionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'InspectionList', vehicleId: queryArg.force},
      ],
    }),
    inspectionList: build.query<InspectionListApiResponse, InspectionListApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/inspections`,
        method: 'POST',
        body: queryArg.inspectionListRequestBody,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'InspectionList', vehicleId: queryArg.inspectionListRequestBody.vehicleId},
      ],
    }),
    createInspection: build.mutation<CreateInspectionApiResponse, CreateInspectionApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/inspection/${queryArg.vehicleId}`,
        method: 'POST',
        body: queryArg.createAuditRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'InspectionList', vehicleId: queryArg.vehicleId},
      ],
    }),
    loadAuditDataByVehicleId: build.query<
      LoadAuditDataByVehicleIdApiResponse,
      LoadAuditDataByVehicleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/car-audit/v1/search/audits/${queryArg.inspectionType}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getSpecificallyAuditData: build.query<
      GetSpecificallyAuditDataApiResponse,
      GetSpecificallyAuditDataApiArg
    >({
      query: (queryArg) => ({
        url: `/car-audit/v1/search/audit-data/${queryArg.inspectionType}`,
        method: 'POST',
        body: queryArg.specificallyAuditDataRequestBody,
      }),
      providesTags: (result, error, queryArg) => [
        {
          type: 'AuditData',
          id: head(queryArg.specificallyAuditDataRequestBody.vehicleIds ?? []),
        },
      ],
    }),
    rotateImageAsset: build.mutation<RotateImageAssetApiResponse, RotateImageAssetApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/audits/images/rotate`,
        method: 'POST',
        body: queryArg.rotateImageAssetRequestBody,
      }),
    }),
    fileOperationList: build.query<FileOperationListApiResponse, FileOperationListApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/file-storage/file-operation`,
        params: {
          sourceFileId: queryArg.sourceFileId,
          sourceRemoteFileId: queryArg.sourceRemoteFileId,
          state: queryArg.state,
          batchId: queryArg.batchId,
        },
      }),
    }),
    getVehicleAuditApiCatalogs: build.query<VehicleAuditStatusLogs | null, GetVehicleAuditApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/vehicle-audit/${queryArg.auditId}/status-log`,
        headers: {
          'X-Workspace': queryArg.workspace, // Added missing comma here
        },
      }),
    }),
    getVideoAsset: build.query<GetVideoAssetApiResponse, GetVideoAssetApiArg>({
      query: ({auditId, videoAssetId}) => ({
        url: `/car-audit/v1/audits/${auditId}/video-asset/${videoAssetId}`,
        method: 'GET',
      }),
    }),
    uploadVideoAsset: build.mutation<UploadVideoAssetApiResponse, UploadVideoAssetApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/audits/${queryArg.auditId}/video-asset`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteVideoAsset: build.mutation<void, DeleteVideoAssetApiArg>({
      query: ({auditId, videoAssetId}) => ({
        url: `/car-audit/v1/audits/${auditId}/video-asset/${videoAssetId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const selectAutoFillTemplates = carAuditApi.endpoints.getAutoFillTemplates.select();

export const {
  useCreateAutoFillTemplateMutation,
  useGetAutoFillTemplatesQuery,
  useCreateAuditMutation,
  useDeleteAutoFillTemplateMutation,
  useGetNewestStructureByTypeQuery,
  useLazyGetNewestStructureByTypeQuery,
  useUpdateAuditDataMutation,
  useGetAuditStructureQuery,
  useLazyGetAuditStructureQuery,
  useGetAuditDataQuery,
  useLazyGetAuditDataQuery,
  useLazyGetAutoFillTemplateQuery,
  useToFinishingInspectionMutation,
  useToRevisionInspectionMutation,
  useDeleteInspectionTemplateMutation,
  useGetInspectionTemplatesQuery,
  useLazyGetInspectionTemplatesQuery,
  useToggleInspectionTemplateActiveStateMutation,
  useGetBasicStructureForSettingsQuery,
  useCreateTypeOfControlSettingsMutation,
  useGetStructureSettingsQuery,
  useLazyGetPublicizeInspectionQuery,
  useDeleteInspectionMutation,
  useInspectionListQuery,
  useCreateInspectionMutation,
  useLoadAuditDataByVehicleIdQuery,
  useGetSpecificallyAuditDataQuery,
  useRotateImageAssetMutation,
  useFileOperationListQuery,
  useGetVehicleAuditApiCatalogsQuery,
  useToInRevisionInspectionMutation,
  useInspectionToInProgressMutation,
  useLazyGetVideoAssetQuery,
  useUploadVideoAssetMutation,
  useDeleteVideoAssetMutation,
} = carAuditApi;
