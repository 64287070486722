import {
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Text, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {useParams} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {
  featureFlags,
  handleApiError,
  useCreateBackgroundRemovalMutation,
  useGetBackgroundRemovalV2Query,
  useUpdateBackgroundRemovalV2Mutation,
} from '@omnetic-dms/shared';

import {buildArray, useNavigate, yupString} from 'shared';

import {BackgroundRemovalFormValues} from '../types/BackgroundRemovalFormValues';
import {BackgroundSelector} from './BackgroundSelector';

const originalBgIds = ['49716', '61155', '79610', '12560'];
const mercedesBgIds = ['64169', '13206'];

export function BackgroundRemovalForm() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {
    data: settings,
    isLoading,
    isError,
  } = useGetBackgroundRemovalV2Query({settingId: id ?? ''}, {skip: isNilOrEmpty(id)});

  const backgroundIds = buildArray<string>(originalBgIds)
    .whenFeatureEnabled(featureFlags.MERCEDES_BACKGROUND_PHOTOS, mercedesBgIds[0])
    .whenFeatureEnabled(featureFlags.MERCEDES_BACKGROUND_PHOTOS, mercedesBgIds[1]);

  const [createConfiguration] = useCreateBackgroundRemovalMutation();
  const [updateConfiguration] = useUpdateBackgroundRemovalV2Mutation();

  const defaultValues = {
    name: settings?.name ?? '',
    backgroundId: settings?.backgroundId ?? '49716',
    backgroundReplacementEnabled: settings?.backgroundReplacementEnabled ?? true,
    registrationPlateRemovalEnabled: settings?.registrationPlateRemovalEnabled ?? false,
    windowTintingEnabled: settings?.windowTintingEnabled ?? false,
  };
  const onSubmit: FormSubmitHandler<BackgroundRemovalFormValues> = async (values) => {
    const args = {
      name: values.name,
      backgroundId: values.backgroundId,
      registrationPlateRemovalEnabled: values.registrationPlateRemovalEnabled,
      windowTintingEnabled: values.windowTintingEnabled,
    };
    if (id) {
      await updateConfiguration({settingId: id, updateBackgroundRemovalRequestBody: args})
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
          navigate(settingsRoutes.backgroundRemoval);
        })
        .catch(handleApiError);
    } else {
      await createConfiguration({createBackgroundRemovalRequestBody: args})
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
          navigate(settingsRoutes.backgroundRemoval);
        })
        .catch(handleApiError);
    }
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<BackgroundRemovalFormValues>
        onSubmit={onSubmit}
        schema={schema}
        defaultValues={defaultValues}
      >
        {(control, formApi) => {
          const currentBgId = formApi.watch('backgroundId');
          const isMercedesBg = mercedesBgIds.includes(currentBgId);
          return (
            <VStack spacing={4}>
              <FormField
                label={i18n.t('page.salesSettings.backgroundRemoval.configurationName')}
                control={control}
                name="name"
                type="text"
                isRequired
                data-testid="backgroundRemoval-configurationName"
              />

              <Separator spacing={2} />

              <FormField
                label={i18n.t('page.salesSettings.backgroundRemoval.labels.replaceBackground')}
                control={control}
                name="backgroundReplacementEnabled"
                type="switch"
                isDisabled
                data-testid="backgroundRemoval-backgroundReplacementEnabled"
              />
              <Box paddingLeft={12}>
                <VStack spacing={4}>
                  <Text size="small" color="tertiary">
                    {i18n.t('page.salesSettings.backgroundRemoval.description.replaceBackground')}
                  </Text>
                  <BackgroundSelector
                    name="backgroundId"
                    options={backgroundIds}
                    control={control}
                    formApi={formApi}
                    externalOnClick={(selectedBgId) => {
                      if (mercedesBgIds.includes(selectedBgId)) {
                        formApi.setValue('registrationPlateRemovalEnabled', false);
                      }
                    }}
                  />
                </VStack>
              </Box>

              <Separator spacing={2} />

              <FormField
                label={i18n.t('page.salesSettings.backgroundRemoval.labels.replacePlate')}
                control={control}
                name="registrationPlateRemovalEnabled"
                type="switch"
                data-testid="backgroundRemoval-registrationPlateRemovalEnabled"
                isDisabled={isMercedesBg}
              />
              <Box paddingLeft={12}>
                <VStack spacing={4}>
                  <Text size="small" color="tertiary">
                    {i18n.t('page.salesSettings.backgroundRemoval.description.replacePlate')}
                  </Text>
                </VStack>
              </Box>

              <Separator spacing={2} />

              <FormField
                label={i18n.t('page.salesSettings.backgroundRemoval.labels.tintWindows')}
                control={control}
                name="windowTintingEnabled"
                type="switch"
                isDisabled={!settings?.windowTintingEnabled}
                data-testid="backgroundRemoval-windowTintingEnabled"
              />
              <Box paddingLeft={12}>
                <Text size="small" color="tertiary">
                  {i18n.t('page.salesSettings.backgroundRemoval.description.tintWindows')}
                </Text>
              </Box>

              <Separator spacing={2} />

              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.discardChanges')}
                  variant="secondary"
                  onClick={() => navigate(settingsRoutes.backgroundRemoval)}
                  data-testid="backgroundRemoval-discardChangesBtn"
                />
                <FormButton
                  title={i18n.t('general.actions.saveAndClose')}
                  control={control}
                  type="submit"
                  data-testid="backgroundRemoval-submitBtn"
                />
              </ButtonGroup>
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const schema = object({
  backgroundReplacementEnabled: boolean().defined().isTrue(),
  backgroundId: yupString,
  registrationPlateRemovalEnabled: boolean().defined(),
  windowTintingEnabled: boolean().defined(),
  name: yupString.required(),
});
