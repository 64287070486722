import {
  AutoplacPlAuthenticateApiArg,
  AutoplacPlAuthenticateApiResponse,
  DasWeltAutoAuthenticateApiArg,
  DasWeltAutoAuthenticateApiResponse,
  FordGeronimoAuthenticateApiArg,
  FordGeronimoAuthenticateApiResponse,
  FordUzywaneAuthenticateApiArg,
  FordUzywaneAuthenticateApiResponse,
  GetAutoplacPlPlatformApiArg,
  GetAutoplacPlPlatformApiResponse,
  GetDasWeltAutoPlatformApiArg,
  GetDasWeltAutoPlatformApiResponse,
  GetFordGeronimoPlatformApiArg,
  GetFordGeronimoPlatformApiResponse,
  GetFordUzywanePlatformApiArg,
  GetFordUzywanePlatformApiResponse,
  GetHyundaiPromisePlatformApiArg,
  GetHyundaiPromisePlatformApiResponse,
  GetMamGoPlatformApiArg,
  GetMamGoPlatformApiResponse,
  GetMercedesNaSkladePlatformApiArg,
  GetMercedesNaSkladePlatformApiResponse,
  GetMyWebPlatformApiArg,
  GetMyWebPlatformApiResponse,
  GetOlxPlatformApiResponse,
  GetOtomotoPlatformApiArg,
  GetOtomotoPlatformApiResponse,
  GetSkodaPlusPlatformApiArg,
  GetSkodaPlusPlatformApiResponse,
  GetVehisPlatformApiArg,
  GetVehisPlatformApiResponse,
  HyundaiPromiseAuthenticateApiArg,
  HyundaiPromiseAuthenticateApiResponse,
  MamGoAuthenticateApiArg,
  MamGoAuthenticateApiResponse,
  MercedesNaSkladeAuthenticateApiArg,
  MercedesNaSkladeAuthenticateApiResponse,
  MyWebAuthenticateApiArg,
  MyWebAuthenticateApiResponse,
  OtomotoAuthenticateApiArg,
  OtomotoAuthenticateApiResponse,
  SkodaPlusAuthenticateApiArg,
  SkodaPlusAuthenticateApiResponse,
  VehisAuthenticateApiArg,
  VehisAuthenticateApiResponse,
} from '../types/api/salesPublishing';
import {omneticApi} from './baseApi/omneticApi';

const salesPublishingApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getOlxPlatform: build.query<GetOlxPlatformApiResponse, void>({
      query: () => ({
        url: `/dms/v2/sales/publishing/platform/olx`,
      }),
    }),
    getDasWeltAutoPlatform: build.query<
      GetDasWeltAutoPlatformApiResponse,
      GetDasWeltAutoPlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/das_weltauto`,
        params: queryArg.params,
      }),
      providesTags: () => [{type: 'PublishingPlatformDasWeltAuto'}],
    }),
    getSkodaPlusPlatform: build.query<GetSkodaPlusPlatformApiResponse, GetSkodaPlusPlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/skoda_plus`,
        params: queryArg.params,
      }),
      providesTags: () => [{type: 'PublishingPlatformSkodaPlus'}],
    }),
    getMyWebPlatform: build.query<GetMyWebPlatformApiResponse, GetMyWebPlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/myweb`,
        params: queryArg?.params,
      }),
      providesTags: [{type: 'PublishingPlatformMyWeb'}],
    }),
    getMercedesNaSkladePlatform: build.query<
      GetMercedesNaSkladePlatformApiResponse,
      GetMercedesNaSkladePlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/mercedes_na_sklade`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformMercedesNaSklade'}],
    }),
    getHyundaiPromisePlatform: build.query<
      GetHyundaiPromisePlatformApiResponse,
      GetHyundaiPromisePlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/hyundai_promise`,
        params: queryArg.params,
      }),
      providesTags: () => [{type: 'PublishingPlatformHyundaiPromise'}],
    }),
    skodaPlusAuthenticate: build.mutation<
      SkodaPlusAuthenticateApiResponse,
      SkodaPlusAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/skoda_plus/authenticate`,
        params: queryArg.params,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: () => [
        // SkodaPlus and DasWeltAuto have same credentials
        {type: 'PublishingPlatformSkodaPlus'},
        {type: 'PublishingPlatformDasWeltAuto'},
      ],
    }),
    dasWeltAutoAuthenticate: build.mutation<
      DasWeltAutoAuthenticateApiResponse,
      DasWeltAutoAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/das_weltauto/authenticate`,
        params: queryArg.params,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: () => [
        // SkodaPlus and DasWeltAuto have same credentials
        {type: 'PublishingPlatformDasWeltAuto'},
        {type: 'PublishingPlatformSkodaPlus'},
      ],
    }),
    myWebAuthenticate: build.mutation<MyWebAuthenticateApiResponse, MyWebAuthenticateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/myweb/authenticate`,
        params: queryArg.params,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: [{type: 'PublishingPlatformMyWeb'}],
    }),
    mercedesNaSkladeAuthenticate: build.mutation<
      MercedesNaSkladeAuthenticateApiResponse,
      MercedesNaSkladeAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/mercedes_na_sklade/authenticate`,
        params: queryArg.params,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: [{type: 'PublishingPlatformMercedesNaSklade'}],
    }),
    hyundaiPromiseAuthenticate: build.mutation<
      HyundaiPromiseAuthenticateApiResponse,
      HyundaiPromiseAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/hyundai_promise/authenticate`,
        params: queryArg.params,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: [{type: 'PublishingPlatformHyundaiPromise'}],
    }),
    getOtomotoPlatform: build.query<GetOtomotoPlatformApiResponse, GetOtomotoPlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/otomoto`,
        params: queryArg.params,
      }),
    }),
    otomotoAuthenticate: build.mutation<OtomotoAuthenticateApiResponse, OtomotoAuthenticateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/otomoto/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
    }),
    getFordUzywanePlatform: build.query<
      GetFordUzywanePlatformApiResponse,
      GetFordUzywanePlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/ford_uzywane`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformFordUzywane'}],
    }),
    fordUzywaneAuthenticate: build.mutation<
      FordUzywaneAuthenticateApiResponse,
      FordUzywaneAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/ford_uzywane/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformFordUzywane'}],
    }),
    getAutoplacPlPlatform: build.query<
      GetAutoplacPlPlatformApiResponse,
      GetAutoplacPlPlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/autoplac_pl`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformAutoplacPl'}],
    }),
    AutoplacPlAuthenticate: build.mutation<
      AutoplacPlAuthenticateApiResponse,
      AutoplacPlAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/autoplac_pl/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformAutoplacPl'}],
    }),
    getAutoplacPlExtraLink: build.query<{link: string}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform/autoplac_pl/link',
        params: {branchId},
        method: 'GET',
      }),
    }),
    getVehisPlatform: build.query<GetVehisPlatformApiResponse, GetVehisPlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/vehis`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformVehis'}],
    }),
    VehisAuthenticate: build.mutation<VehisAuthenticateApiResponse, VehisAuthenticateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/vehis/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformVehis'}],
    }),
    getVehisExtraLink: build.query<{link: string}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform/vehis/link',
        params: {branchId},
        method: 'GET',
      }),
    }),
    getMamGoPlatform: build.query<GetMamGoPlatformApiResponse, GetMamGoPlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/mamgo`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformMamGo'}],
    }),
    MamGoAuthenticate: build.mutation<MamGoAuthenticateApiResponse, MamGoAuthenticateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/mamgo/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformMamGo'}],
    }),
    getMamGoExtraLink: build.query<{link: string}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform/mamgo/link',
        params: {branchId},
        method: 'GET',
      }),
    }),
    getFordGeronimoPlatform: build.query<
      GetFordGeronimoPlatformApiResponse,
      GetFordGeronimoPlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/ford_geronimo`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformFordGeronimo'}],
    }),
    FordGeronimoAuthenticate: build.mutation<
      FordGeronimoAuthenticateApiResponse,
      FordGeronimoAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/ford_geronimo/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformFordGeronimo'}],
    }),
    getFordGeronimoExtraLink: build.query<{link: string}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform/ford_geronimo/link',
        params: {branchId},
        method: 'GET',
      }),
    }),
    getMyWebExtraLink: build.query<{link: string}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform/myweb/link',
        params: {branchId},
        method: 'GET',
      }),
    }),
    myWebPublishingOnTenant: build.mutation<void, {branchId: string; isEnabled: boolean}>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/myweb/publish-on-tenant/${
          queryArg.isEnabled ? 'enable' : 'disable'
        }`,
        params: {branchId: queryArg.branchId},
        method: 'PUT',
      }),
      invalidatesTags: [{type: 'PublishingPlatformMyWeb'}],
    }),
    getFordUzywaneExtraLink: build.query<{link: string}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform/ford_uzywane/link',
        params: {branchId},
        method: 'GET',
      }),
    }),
    getTipCarsDefaultSettings: build.query<{vinHidden: boolean}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v2/sales/publishing/platform/tipcars/settings/defaults',
        params: {branchId},
        method: 'GET',
      }),
      providesTags: [{type: 'TipCarsDefaultSettings'}],
    }),
    setTipCarsDefaultSettings: build.mutation<void, {vinHidden: boolean; branchId?: string}>({
      query: ({vinHidden, branchId}) => ({
        url: `/dms/v2/sales/publishing/platform/tipcars/settings/defaults`,
        params: {branchId},
        body: {vinHidden},
        method: 'PUT',
      }),
      invalidatesTags: [{type: 'TipCarsDefaultSettings'}],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOlxPlatformQuery,
  useGetSkodaPlusPlatformQuery,
  useSkodaPlusAuthenticateMutation,
  useGetDasWeltAutoPlatformQuery,
  useDasWeltAutoAuthenticateMutation,
  useGetMyWebPlatformQuery,
  useMyWebAuthenticateMutation,
  useGetMyWebExtraLinkQuery,
  useMyWebPublishingOnTenantMutation,
  useGetMercedesNaSkladePlatformQuery,
  useMercedesNaSkladeAuthenticateMutation,
  useGetHyundaiPromisePlatformQuery,
  useHyundaiPromiseAuthenticateMutation,
  useGetOtomotoPlatformQuery,
  useOtomotoAuthenticateMutation,
  useGetTipCarsDefaultSettingsQuery,
  useSetTipCarsDefaultSettingsMutation,
  useGetFordUzywanePlatformQuery,
  useFordUzywaneAuthenticateMutation,
  useGetFordUzywaneExtraLinkQuery,
  useGetAutoplacPlPlatformQuery,
  useAutoplacPlAuthenticateMutation,
  useGetAutoplacPlExtraLinkQuery,
  useGetVehisPlatformQuery,
  useVehisAuthenticateMutation,
  useGetVehisExtraLinkQuery,
  useGetMamGoPlatformQuery,
  useMamGoAuthenticateMutation,
  useGetMamGoExtraLinkQuery,
  useGetFordGeronimoPlatformQuery,
  useFordGeronimoAuthenticateMutation,
  useGetFordGeronimoExtraLinkQuery,
} = salesPublishingApi;
