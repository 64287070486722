import {DataStatus, showNotification} from 'platform/components';
import {Show} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {testIds} from '@omnetic-dms/routes';
import {
  featureFlags,
  handleApiError,
  queryParams,
  useFordGeronimoAuthenticateMutation,
  useGetFordGeronimoExtraLinkQuery,
  useGetFordGeronimoPlatformQuery,
} from '@omnetic-dms/shared';
import {AdvertisingPlatformCodeEnum} from '@omnetic-dms/teas';

import {useQueryState} from 'shared';

import {
  PlatformAuthenticationFormUrlExport,
  PlatformAuthenticationFormUrlExportProps,
} from './components/PlatformAuthenticationFormUrlExport';
import {PlatformPhotoSettings} from './components/PlatformPhotoSettings';

export function PlatformSettingsFordGeronimo() {
  const [branchId] = useQueryState(queryParams.BRANCH_ID);
  const params = {
    branchId: isNotNil(branchId) ? branchId : undefined,
  };
  const {data, isLoading, isError} = useGetFordGeronimoPlatformQuery({params});
  const {data: extraLinkData} = useGetFordGeronimoExtraLinkQuery(params);
  const [saveAuthentication] = useFordGeronimoAuthenticateMutation();

  const onSubmitCredentials: PlatformAuthenticationFormUrlExportProps['onSubmit'] = (values) =>
    saveAuthentication({
      body: values,
      params,
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  return (
    <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_GENERAL_SETTINGS}>
      <DataStatus isLoading={isLoading} isError={isError}>
        {isNotNil(data) && isNotNil(extraLinkData) && (
          <PlatformAuthenticationFormUrlExport
            defaultValues={data.credentials}
            extraLink={extraLinkData.link}
            onSubmit={onSubmitCredentials}
            data-testid={testIds.settings.advertisingPlatforms(
              'platformAuthenticationFormUrlExport'
            )}
          />
        )}
        <PlatformPhotoSettings platformCode={AdvertisingPlatformCodeEnum.FORD_GERONIMO} />
      </DataStatus>
    </Show>
  );
}
