import {GetRowIdParams, IServerSideGetRowsParams, ModuleRegistry} from '@ag-grid-community/core';
import {AgGridReact} from '@ag-grid-community/react';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import '@ag-grid-enterprise/core';
import {FiltersToolPanelModule} from '@ag-grid-enterprise/filter-tool-panel';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {MultiFilterModule} from '@ag-grid-enterprise/multi-filter';
import {RangeSelectionModule} from '@ag-grid-enterprise/range-selection';
import {RichSelectModule} from '@ag-grid-enterprise/rich-select';
import {ServerSideRowModelModule} from '@ag-grid-enterprise/server-side-row-model';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {SideBarModule} from '@ag-grid-enterprise/side-bar';
import {StatusBarModule} from '@ag-grid-enterprise/status-bar';
import {captureMessage} from '@sentry/browser';
import {diff} from 'deep-object-diff';
import {nanoid} from 'nanoid';
import {useDialog, useTranslationContext} from 'platform/components';
import {useLocale} from 'platform/locale';

import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import {dissoc, isNil} from 'ramda';
import {isNilOrEmpty, isNotNil, isNumber, isPlainObj, isPositive} from 'ramda-adjunct';

import {Box} from '@chakra-ui/react';

import {noop, Nullish, suffixTestId} from 'shared';

import {BulkActionsPanelWrapper} from './components/BulkActionsPanelWrapper';
import {DataGridAside} from './components/DataGridAside';
import {DataGridHeader} from './components/DataGridHeader';
import {DefaultLoadingCellRenderer} from './components/DefaultLoadingCellRenderer';
import {DetailRendererWrapper} from './components/DetailRendererWrapper';
import {EmptyStateRendererWrapper} from './components/EmptyStateRendererWrapper';
import {ExportOptionsRendererWrapper} from './components/ExportOptionsRendererWrapper';
import {LoadingCellRendererWrapper} from './components/LoadingCellRendererWrapper';
import {PaginationWrapper} from './components/PaginationWrapper';
import {ToolPanelHeader} from './components/ToolPanelHeader';
import {sideBarOptions} from './constants/sideBarOptions';
import {SMART_SEARCH_KEY} from './constants/smartSearchKey';
import {useDataGridContext} from './context/useDataGridContext';
import {useDataGridFiltersModel} from './context/useDataGridFiltersModel';
import {useColumnDefs} from './hooks/useColDefs';
import {useContextMenu} from './hooks/useContextMenu';
import {useDefaultSettings} from './hooks/useDefaultSettings';
import {useGridApiEventListener} from './hooks/useGridApiEventListener';
import {useGridTheme} from './hooks/useGridTheme';
import {useHeaderHeight} from './hooks/useHeaderHeight';
import {useHttpCalls} from './hooks/useHttpCalls';
import {useRefreshServerSideStore} from './hooks/useRefreshServerSideStore';
import {useRowClickHandler} from './hooks/useRowClickHandler';
import {
  AgGridEvent,
  ColumnApi,
  ColumnMovedEvent,
  ColumnVisibleEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  SelectionChangedEvent,
} from './types/AgGridTypes';
import {AgGridWrapperProps} from './types/AgGridWrapperProps';
import {ColumnsSetting, OrderByRequestBody, VirtualPreset} from './types/Api';
import {DataGridRef} from './types/DataGridRef';
import {Filters} from './types/Filters';
import {ToolpanelType} from './types/ToolpanelType';
import {addTestIdsToAgGridElements} from './utils/addTestIdsToAgGridElements';
import {getColDefsVisibleInToolPanel} from './utils/getColDefsVisibleInToolPanel';
import {getGroupedColDefs} from './utils/getGroupedColDefs';
import {getMainMenuItems} from './utils/getMainMenuItems';
import {getPresetStates} from './utils/getPresetStates';
import {getRowSelection} from './utils/getRowSelection';
import {getStatusBar} from './utils/getStatusBar';
import {isPresetColumnsSettingsInvalid} from './utils/isPresetColumnsSettingsInvalid';
import {isPresetCustom} from './utils/isPresetCustom';
import {isTestEnvironment} from './utils/isTestEnvironment';
import {LineHeightMappingToPx} from './utils/lineHeightToPx';
import {objectHasEveryEqualPropOfAnother} from './utils/objectHasEveryEqualPropOfAnother';
import {transformActions} from './utils/transformActions';
import {translationMap} from './utils/translationMap';
import {getSearchParamsByGridCode} from './utils/url/getSearchParamsByGridCode';
import {setSearchParamsByGridCode} from './utils/url/setSearchParamsByGridCode';

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  MenuModule,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  SetFilterModule,
  SideBarModule,
  StatusBarModule,
]);

type Comparison = {
  filters: Filters;
  sortBy: OrderByRequestBody[];
};

export const AgGridWrapper = forwardRef(
  (props: AgGridWrapperProps, ref: ForwardedRef<DataGridRef>) => {
    const {
      loadingCellRenderer = DefaultLoadingCellRenderer,
      actionCallback,
      autoGroupColumnDef,
      onRowSelectionChange = noop,
      queryModifier,
      definition,
      setVirtualPreset,
    } = props;
    const http = useHttpCalls();
    const translate = useTranslationContext();
    const locale = useLocale();
    const translations = translationMap(translate);
    const internalId = useMemo(() => `dataGrid-${nanoid()}`, []);

    const [gridApi, setGridApi] = useState<GridApi>();
    const [columnApi, setColumnApi] = useState<ColumnApi>();
    const [count, setCount] = useState<number>();

    const [isOpen, onOpen, onClose] = useDialog();

    const {activePreset} = useDataGridContext();
    const {onFiltersChange} = useDataGridFiltersModel();

    const [settings, setSettings] = useDefaultSettings(gridApi, columnApi);

    const getContextMenuItems = useContextMenu(gridApi, props, settings, definition);
    const lastUsedComparison = useRef<Comparison | Nullish>(null);
    const lastGroupedColDefs = useRef<ReturnType<typeof getGroupedColDefs>>();
    const refreshData = useRefreshServerSideStore(gridApi);

    // Some functionality cannot be enabled when it was initially disabled
    // So we enable them on first render and then pass correct value (disabled/enabled)
    const isFirstRender = useRef(true);
    // eslint-disable-next-line eag/no-effect-without-cleanup
    useEffect(() => {
      isFirstRender.current = false;
    }, []);

    useImperativeHandle(ref, () => ({
      refreshData,
    }));

    const onRowClicked = useRowClickHandler(
      gridApi,
      settings,
      actionCallback,
      transformActions(props.definition.actions),
      props.gridCode,
      definition
    );
    const searchParams2 = getSearchParamsByGridCode(props.gridCode);
    const initialPage = Math.ceil((Number(searchParams2?.rowIndex) + 1) / settings.itemsPerPage);

    const {gridTheme, footerTheme, wrapperTheme} = useGridTheme(settings, definition);
    const {colDefs, defaultColDef} = useColumnDefs(props, settings, props.columnConfig, definition);

    useHeaderHeight(settings, gridApi);

    /*
     * Grid calls getRows when it requires more rows as specified in the params.
     * Params object contains callbacks for responding to the request.
     * */
    const handleGetRows = (rowsParams: IServerSideGetRowsParams) => {
      let searchParams = getSearchParamsByGridCode(props.gridCode);
      if (isNilOrEmpty(searchParams)) {
        setSearchParamsByGridCode(props.gridCode, activePreset.dataQueryId);
        searchParams = getSearchParamsByGridCode(props.gridCode);
      }
      const {success, request, api} = rowsParams;

      const sortBy: OrderByRequestBody[] = request.sortModel.map((sort) => ({
        columnKey: sort.colId,
        order: sort.sort.toUpperCase() as OrderByRequestBody['order'],
      }));

      const filterModel = api.getFilterModel();

      const staticFilters = queryModifier ? queryModifier({}) : {};

      const modifiedFilters = queryModifier ? queryModifier(filterModel) : filterModel;

      const limit = settings.itemsPerPage;
      const offset = request.startRow ?? 0;

      const filters = {
        limit,
        offset,
        filters: dissoc(SMART_SEARCH_KEY, modifiedFilters),
        order: sortBy,
        smartSearch: modifiedFilters[SMART_SEARCH_KEY] ?? null,
      };

      const getDataByQueryId = (queryId: string) => {
        http.getDataByQuery(queryId, offset, limit, !!filters.smartSearch).then((dataResponse) => {
          http.getCountByQuery(queryId, !!filters.smartSearch).then((rowCountResponse) => {
            if (rowCountResponse === 0) {
              api?.showNoRowsOverlay();
              success({
                rowData: [],
                rowCount: 0,
              });
              return;
            }

            if (dataResponse && isNumber(rowCountResponse)) {
              const x = dataResponse.map((response) => ({
                ...response.cells,
                actions: response.actions,
                id: response.id,
                rowNumber: response.rowNumber,
              }));

              try {
                const successData = {
                  rowData: x,
                  rowCount: rowCountResponse,
                };
                api.hideOverlay();
                setCount(rowCountResponse);
                success(successData);
              } catch (error) {
                if (isPlainObj(error) && 'message' in error) {
                  // eslint-disable-next-line no-console
                  console.error(error?.message);
                  api.hideOverlay();
                }
              }
            }
          });
        });
      };

      const comparison = {
        smartSearch: filters.smartSearch,
        filters,
        sortBy,
      };
      const filterTechnicalArray = (columnSettings: ColumnsSetting[]): ColumnsSetting[] =>
        columnSettings?.filter(
          (columnSetting) =>
            columnSetting.columnKey !== SMART_SEARCH_KEY &&
            columnSetting.columnKey !== 'eag-col-actions'
        );

      const isFilterModified = isNilOrEmpty(lastUsedComparison.current)
        ? null
        : diff(lastUsedComparison.current ?? {}, comparison);

      const isAllStaticFilterPresent = objectHasEveryEqualPropOfAnother(
        staticFilters,
        props.dataQuery.filters
      );

      // Use same query id only if filters are not modified and all static filters are present, otherwise create new query id
      if (isNilOrEmpty(isFilterModified) && isAllStaticFilterPresent) {
        getDataByQueryId(searchParams.queryId);
        lastUsedComparison.current = comparison as Comparison;
        return;
      }

      //   New data query id
      http
        .createDataQuery({
          filters: filters.filters ?? {},
          order: filters.order ?? {},
          smartSearch: filters.smartSearch,
        })
        .then((queryIdResponseBody) => {
          if (!queryIdResponseBody) {
            return;
          }

          const newDataQueryId = queryIdResponseBody.dataQueryId;

          setSearchParamsByGridCode(props.gridCode, newDataQueryId, searchParams2?.rowIndex);

          if (!isPresetCustom(activePreset) && isNotNil(columnApi)) {
            const modifiedPreset: VirtualPreset = {
              columnsSettings: filterTechnicalArray(getPresetStates(columnApi)),
              gridSettings: settings,
              dataQueryId: newDataQueryId,
            };

            if (isPresetColumnsSettingsInvalid(modifiedPreset)) {
              captureMessage('updatePreset', (scope) =>
                scope.setLevel('error').setExtras({
                  userDescription: 'update virtual preset from agGridWrapper',
                  state: modifiedPreset,
                })
              );
              return;
            }

            http.updateVirtualPreset(modifiedPreset);
          }

          getDataByQueryId(newDataQueryId);

          lastUsedComparison.current = comparison as Comparison;
        });
    };

    const disableActionColumnMove = useCallback((params: ColumnMovedEvent) => {
      if (params.column?.isPinnedRight()) {
        const columnCount = params.api.getAllGridColumns()?.filter((c) => c.isVisible()).length;

        // WTF, why 2?
        const maxIndex = isNotNil(columnCount) ? columnCount - 2 : 0;
        if (isNotNil(params.toIndex)) {
          if (params.toIndex > maxIndex) {
            params.columnApi.moveColumnByIndex(params.toIndex, maxIndex);
          }
        }
      }
    }, []);

    const handleFiltersChanged = (event: AgGridEvent) => {
      setTimeout(() => {
        const filterModel = event.api?.getFilterModel() || {};
        props.onFilterChanged?.(filterModel);
        onFiltersChange(filterModel);
      }, 10);
    };

    const {addListeners} = useGridApiEventListener(
      gridApi,
      ['filterChanged'],
      handleFiltersChanged
    );

    useEffect(() => {
      if (isNil(gridApi) || isNilOrEmpty(colDefs)) {
        return;
      }

      const groupedDefs = getGroupedColDefs(colDefs);

      gridApi?.getToolPanelInstance('filters')?.setFilterLayout(groupedDefs);
      gridApi?.getToolPanelInstance('columns')?.setColumnLayout(groupedDefs);
      lastGroupedColDefs.current = groupedDefs;
    }, [gridApi, colDefs]);

    addListeners();

    /*
     * The grid has initialised and is ready for most api calls, but may not be fully rendered yet
     * */
    const onGridReady = ({api, columnApi: colApi}: GridReadyEvent) => {
      api.setFilterModel({...props.dataQuery.filters, smartSearch: props.dataQuery.smartSearch});

      // api.paginationGoToPage(initialPage === 0 ? 1 : initialPage);

      if (props.onFilterChanged) {
        props.onFilterChanged(props.dataQuery.filters || {});
      }

      setGridApi(api);
      setColumnApi(colApi);

      addListeners();
    };

    /*
     * Row selection is changed. Use the grid API getSelectedNodes() to get the new list of selected nodes.
     * */
    const onSelectionChanged = useCallback(
      ({api}: SelectionChangedEvent) => {
        const selectedNodes = api.getSelectedNodes();
        onRowSelectionChange(selectedNodes.map((node) => node?.data));
      },
      [onRowSelectionChange]
    );

    const getRowId = (row: GetRowIdParams) => row?.data?.id;

    /*
     * Specifies the params to be used by the Detail Cell Renderer.
     * Can also be a function that provides the params to enable dynamic definitions of the params
     * */
    const detailCellRendererParams = useMemo<Record<string, unknown>>(
      () => ({
        gridProps: props,
      }),
      [props]
    );

    const disableKeyboardCallback = useMemo(() => {
      if (!settings.keyboardNavigationEnabled) {
        return () => null;
      }
      return undefined;
    }, [settings.keyboardNavigationEnabled]);

    const gridOptions: GridOptions = {
      rowSelection: getRowSelection(definition),
      statusBar: getStatusBar(definition),
      embedFullWidthRows: true,
      suppressRowTransform: true,
      chartThemes: ['alpine'],
      rowHeight: LineHeightMappingToPx[settings.rowHeight],
      rowModelType: 'serverSide',
      pagination: true,
      paginationPageSize: settings.itemsPerPage ?? 25,
      cacheBlockSize: settings.itemsPerPage ?? 25,
      serverSideDatasource: {
        getRows: handleGetRows,
      },
      onSelectionChanged,
      defaultColDef,
      getContextMenuItems,
      suppressRowClickSelection: true,
      getRowId,
      detailCellRenderer: DetailRendererWrapper,
      detailCellRendererParams,
      noRowsOverlayComponent: EmptyStateRendererWrapper,
      noRowsOverlayComponentParams: {
        gridProps: props,
        settings,
      },
      loadingCellRenderer: LoadingCellRendererWrapper,
      loadingCellRendererParams: {
        gridProps: props,
        component: loadingCellRenderer,
      },
      context: {
        gridProps: props,
        settings,
        behavior: definition.behavior,
        getContextMenuItems,
      },
      suppressPaginationPanel: true,
      /*
       * For customising the main 'column header' menu
       * */
      getMainMenuItems: getMainMenuItems({
        gridApi,
        translationMap: translations,
        onResetColumns: () =>
          http.deleteVirtualPreset().then((response) => response && setVirtualPreset(response)),
        definition,
      }),
      suppressMultiSort: definition.behavior.columnSortMode !== 'MULTI_SORT',
      navigateToNextCell: disableKeyboardCallback,
      tabToNextCell: disableKeyboardCallback,
      navigateToNextHeader: disableKeyboardCallback,
      sideBar: sideBarOptions,
      onRowClicked,
      autoGroupColumnDef,
      allowDragFromColumnsToolPanel: true,
      localeText: {
        ...translations,
        thousandSeparator: locale.localeConfig.number.thousandsSeparator,
        decimalSeparator: locale.localeConfig.number.decimalSeparator,
      },
      onColumnVisible: (params: ColumnVisibleEvent) => params.api.resetRowHeights(),
      onColumnMoved: disableActionColumnMove,
      onPaginationChanged: (params: PaginationChangedEvent) => {
        if (params.newPage) {
          const page = params.api.paginationGetCurrentPage() + 1;
          (props.onPageChangeCallback ?? noop)(page);
        }
      },
      suppressContextMenu: !definition.behavior.contextMenuEnabled,
      enableRangeSelection: isFirstRender ? true : definition.behavior.rangeSelectAllowed,
      enableRangeHandle: isFirstRender ? true : definition.behavior.rangeSelectAllowed,
      detailRowAutoHeight: true,
      domLayout: props.autoHeight ? 'autoHeight' : 'normal',
      columnDefs: colDefs,
      suppressColumnVirtualisation: true,
      ...(isTestEnvironment
        ? {
            onToolPanelVisibleChanged: () => {
              if (!lastGroupedColDefs.current) {
                return;
              }

              addTestIdsToAgGridElements(getColDefsVisibleInToolPanel(lastGroupedColDefs.current));
            },
          }
        : {}),
    };

    return (
      <Box
        id={internalId}
        __css={wrapperTheme}
        data-testid={suffixTestId('datagridWrapper', props)}
      >
        {gridApi && (
          <>
            <ToolPanelHeader
              gridApi={gridApi}
              toolPanelType={ToolpanelType.column}
              mountElement={document.querySelector(`#${internalId} .ag-column-panel`)}
            />
            <ToolPanelHeader
              gridApi={gridApi}
              toolPanelType={ToolpanelType.filter}
              mountElement={document.querySelector(`#${internalId} .ag-filter-toolpanel`)}
            />
            <ToolPanelHeader
              gridApi={gridApi}
              toolPanelType={ToolpanelType.settings}
              mountElement={document.querySelector(`#${internalId} .ag-custom-settings-toolpanel`)}
            />
          </>
        )}

        {gridApi && columnApi && (
          <DataGridHeader
            dataGridSettings={settings}
            dataGridProps={props}
            gridApi={gridApi}
            columnApi={columnApi}
            data-testid={props['data-testid']}
            openExportMenu={onOpen}
            definition={definition}
            dataQuery={props.dataQuery}
            setSettings={setSettings}
            refreshData={refreshData}
          />
        )}

        {gridApi && settings && definition.behavior.actionColumnEnabled && (
          <BulkActionsPanelWrapper
            settings={settings}
            gridProps={props}
            api={gridApi}
            definition={definition}
          />
        )}

        <ExportOptionsRendererWrapper
          isOpen={isOpen}
          onClose={onClose}
          gridOptions={gridOptions}
          gridApi={gridApi}
          colApi={columnApi}
          dataQuery={props.dataQuery}
          gridProps={props}
          dataGridSettings={settings}
          columns={props.definition?.columns}
          count={count as number}
          data-testid={props['data-testid']}
        />

        <div
          style={{
            overflowY: 'hidden',
            height: '100%',
            display: 'flex',
          }}
          data-testid={suffixTestId('datagridContentWrapper', props)}
        >
          <Box
            {...gridOptions}
            as={AgGridReact}
            onGridReady={onGridReady}
            data-testid={suffixTestId('datagridAgGridComponent', props)}
            __css={{
              ...gridTheme,
              flexGrow: '999999999',
            }}
          />
          <DataGridAside
            dataGridSettings={settings}
            dataGridProps={props}
            gridApi={gridApi}
            internalGridId={internalId}
            data-testid={suffixTestId('datagridAside', props)}
            definition={definition}
            setSettings={setSettings}
          />
        </div>

        {/** Footer */}
        {isNotNil(settings.itemsPerPage) &&
          definition.behavior.paginationEnabled &&
          gridApi &&
          isPositive(count) && (
            <PaginationWrapper
              initialPage={initialPage}
              data-testid={props['data-testid']}
              gridApi={gridApi}
              gridProps={props}
              footerTheme={footerTheme}
              count={Math.floor(count / settings.itemsPerPage) + 1}
            />
          )}
      </Box>
    );
  }
);
