import {Box, Grid, Hide, Show, VStack} from 'platform/foundation';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  CommentsWidget,
  EntityResourceIds,
  useGetBusinessCaseQuery,
  useGetParticipationQuery,
  usePermissions,
  VehicleCostsGridCard,
} from '@omnetic-dms/shared';

import {useRequiredParams} from 'shared';

interface BusinessCasePurchaseVehicleCostsProps {
  refreshBusinessCase: () => void;
}

export function BusinessCasePurchaseVehicleCosts(props: BusinessCasePurchaseVehicleCostsProps) {
  const {id} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: id ?? ''},
    {skip: isNilOrEmpty(id)}
  );
  const hasPurchaseVehicles = isNotNilOrEmpty(businessCase?.offers?.[0]?.purchaseVehicles);
  const vehicleId = hasPurchaseVehicles
    ? businessCase?.offers?.[0]?.purchaseVehicles?.[0]?.vehicleId
    : businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0]?.vehicleId;

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: vehicleId ?? '',
    },
    {skip: isNil(vehicleId)}
  );

  const [
    hasVehicleCommentReadPermission,
    hasVehicleCommentAddPermission,
    hasVehicleCommentDeletePermission,
  ] = usePermissions({
    permissionKeys: ['vehicleCommentRead', 'vehicleCommentAdd', 'vehicleCommentDelete'],
    scopes: {
      vehicleCommentAdd: vehicleParticipation,
      vehicleCommentRead: vehicleParticipation,
    },
  });

  const CostDataGrid = () => (
    <Show when={isNotNilOrEmpty(vehicleId)}>
      <VehicleCostsGridCard
        vehicleId={vehicleId ?? ''}
        data-testid={testIds.businessCase.buying('costs')}
        variant="inlineWhite"
        refreshBusinessCase={props.refreshBusinessCase}
        isSimplified
        businessCaseId={businessCase?.id}
      />
    </Show>
  );

  return (
    <Grid columns={2}>
      <VStack spacing={4}>
        <CostDataGrid />
      </VStack>
      <Hide when={isNilOrEmpty(vehicleId) || !hasVehicleCommentReadPermission}>
        <Box>
          <CommentsWidget
            cardVariant="inlineWhite"
            title={i18n.t('entity.vehicle.labels.vehicleComments')}
            resourceId={EntityResourceIds.vehicle}
            recordId={vehicleId!}
            isAddDisabled={!hasVehicleCommentAddPermission}
            isDeleteDisabled={!hasVehicleCommentDeletePermission}
          />
        </Box>
      </Hide>
    </Grid>
  );
}
