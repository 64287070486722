import {Icon, Box, Text, Show, HStack, Integer, Clickable} from 'platform/foundation';
import styled from 'styled-components';

import {Link} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {MenuItemProps as DefaultMenuItemProps} from '@omnetic-dms/shared';

import {composePath, useBoolean, useToggle} from 'shared';

import {MenuItemPin} from '../components/MenuItemPin';

const FULL_OPACITY = 1;
const PARTIAL_OPACITY = 0.6;

function getDepthPadding(depth: Integer): Integer {
  if (depth > 4) {
    return 16;
  }
  return (depth * 4) as Integer;
}

interface MenuItemProps extends DefaultMenuItemProps {
  depth: Integer;
  isPinned?: boolean;
  onClick: () => void;
}

export function MenuItem(props: MenuItemProps) {
  const [isOpen, toggle] = useToggle(false);
  const [isPinVisible, setPinVisible, hidePin] = useBoolean(false);

  const isGroup = props.content && props.content.length > 1;

  return (
    <>
      <StyledMenuLink
        to={composePath(props.path, {isSearchParamsPreserved: false})}
        onMouseEnter={setPinVisible}
        onMouseLeave={hidePin}
        onClick={props.onClick}
      >
        <Box paddingLeft={getDepthPadding(props.depth)}>
          <HStack justify="space-between" align="center">
            <HStack spacing={2} align="center">
              <Box opacity={isGroup ? FULL_OPACITY : PARTIAL_OPACITY}>
                <Icon
                  size={4}
                  color="text.white"
                  value={props.layoutProps.iconV2 ?? props.layoutProps.icon} // TODO: T20-59675 remove fallback once all items have icons
                />
              </Box>
              <Text size="small" color="white">
                {i18n.t(props.layoutProps.title)}
              </Text>
            </HStack>
            <HStack spacing={2}>
              <Show when={isPinVisible || props.isPinned}>
                <MenuItemPin id={props.id} isPinned={props.isPinned} path={props.path} />
              </Show>
              <Show when={isGroup}>
                <Clickable
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    toggle();
                  }}
                >
                  <StyledIconWrapper isOpen={isOpen}>
                    <Icon color="text.white" size={4} value="navigation/expand_more" />
                  </StyledIconWrapper>
                </Clickable>
              </Show>
            </HStack>
          </HStack>
        </Box>
      </StyledMenuLink>
      <Show when={isOpen && isGroup}>
        {props.content?.map((module) => (
          <MenuItem
            key={module.id}
            {...module}
            depth={(props.depth + 1) as Integer}
            onClick={props.onClick}
          />
        ))}
      </Show>
    </>
  );
}

const StyledMenuLink = styled(Link)`
  padding: ${({theme}) => theme.getSize(2)};
  border-radius: ${({theme}) => theme.getSize(1)};
  &:hover {
    background-color: ${(props) => props.theme.colors.palettes.neutral[500]['100']};
  }
`;

const StyledIconWrapper = styled.div<{isOpen: boolean}>`
  transition: transform 0.3s ease-in-out;
  transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
