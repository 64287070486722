import {
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, HStack, Show, VStack} from 'platform/foundation';
import {object} from 'yup';

import {isNotNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {warehouseRoutes} from '@omnetic-dms/routes';
import {
  BaseAuthorizationProfile,
  BaseSupplier,
  CurrencyResponseBody,
  GetReceiveNoteCorrectionResponse,
  getOptionsFromAuthorizationProfiles,
  getOptionsFromCurrencies,
  getOptionsFromSuppliers,
  getOptionsFromWarehouses,
  GetReceiveNoteResponse,
  GetWarehousesResponse,
  handleApiError,
  PatchReceiveNoteCorrectionRequest,
  PaymentType,
  PaymentTypeResponseBody,
  PostReceiveNoteCorrectionRequest,
  usePatchReceiveNoteCorrectionMutation,
  usePostReceiveNoteCorrectionMutation,
} from '@omnetic-dms/shared';

import {
  composePath,
  Nullish,
  RequiredTestIdProps,
  suffixTestId,
  useNavigate,
  yupString,
} from 'shared';

import {getOptionsFromPaymentTypes} from '../../../../../utils/getOptionsFromPaymentTypes';
import {ReceiveNoteCorrectionForm} from '../types/ReceiveNoteCorrectionForm';

interface BasicInformationFormProps extends RequiredTestIdProps {
  suppliers: BaseSupplier[] | Nullish;
  warehouses: GetWarehousesResponse | Nullish;
  authorizationProfiles: BaseAuthorizationProfile[] | Nullish;
  paymentTypes: PaymentTypeResponseBody[] | Nullish;
  currencies: CurrencyResponseBody[] | Nullish;
  onClose: VoidFunction;
  receiveNote?: GetReceiveNoteResponse | Nullish;
  receiveNoteCorrection?: GetReceiveNoteCorrectionResponse | Nullish;
  isCreatingReceiveNoteCorrection?: boolean;
}

export function BasicInformationForm(props: BasicInformationFormProps) {
  const navigate = useNavigate();

  const [postReceiveNoteCorrection] = usePostReceiveNoteCorrectionMutation();
  const [patchReceiveNoteCorrection] = usePatchReceiveNoteCorrectionMutation();

  const isReceiveNoteCorrectionCompleted =
    props.receiveNoteCorrection?.creditNote?.state === 'COMPLETED';

  const defaultValues: Partial<ReceiveNoteCorrectionForm> = props.isCreatingReceiveNoteCorrection
    ? {
        supplierId: props.receiveNote?.supplierId,
        warehouseId: props.receiveNote?.warehouseId,
        authorizationProfileId: props.receiveNote?.authorizationProfileId,
        paymentType: props.receiveNote?.paymentType,
        currency: props.receiveNote?.currency,
        exchangeRate: props.receiveNote?.exchangeRate,
        ncConversion: props.receiveNote?.ncConversion,
        note: props.receiveNote?.note,
      }
    : {
        supplierId: props.receiveNoteCorrection?.creditNote?.supplierId,
        warehouseId: props.receiveNoteCorrection?.creditNote?.warehouseId,
        authorizationProfileId: props.receiveNoteCorrection?.creditNote?.authorizationProfileId,
        number: props.receiveNoteCorrection?.creditNote?.creditNoteNumber,
        date: isReceiveNoteCorrectionCompleted
          ? props.receiveNoteCorrection?.creditNote?.updated
          : null,
        invoiceNumber: props.receiveNoteCorrection?.creditNote?.correctionInvoiceNumber,
        invoiceIssueDate: props.receiveNoteCorrection?.creditNote?.correctionInvoiceDate,
        paymentType: props.receiveNoteCorrection?.creditNote?.paymentType,
        currency: props.receiveNoteCorrection?.creditNote?.currency,
        exchangeRate: props.receiveNoteCorrection?.creditNote?.exchangeRate,
        ncConversion: props.receiveNoteCorrection?.creditNote?.ncConversion,
        note: props.receiveNoteCorrection?.creditNote?.note,
      };

  const handleSubmit: FormSubmitHandler<ReceiveNoteCorrectionForm> = async (formValues) => {
    if (props.isCreatingReceiveNoteCorrection) {
      const requestBody: PostReceiveNoteCorrectionRequest['body'] = {
        receiveNoteId: props.receiveNote?.receiveNoteId!,
        supplierId: formValues.supplierId,
        warehouseId: formValues.warehouseId,
        correctionInvoiceNumber: formValues.invoiceNumber,
        invoiceIssueDate: formValues.invoiceIssueDate,
        paymentType: formValues.paymentType.toLowerCase() as PaymentType,
        currency: formValues.currency,
        exchangeRate: formValues.exchangeRate,
        ncConversion: formValues.ncConversion,
        note: formValues.note,
      };

      return await postReceiveNoteCorrection({
        body: requestBody,
      })
        .unwrap()
        .then((receiveNoteCorrection) => {
          showNotification.success(
            i18n.t('entity.warehouse.notifications.receiveNoteCorrectionCreated')
          );
          props.onClose();
          navigate(
            composePath(warehouseRoutes.receiveNoteCorrectionDetailOverview, {
              params: {id: receiveNoteCorrection.id},
            })
          );
        })
        .catch(handleApiError);
    }

    const requestBody: PatchReceiveNoteCorrectionRequest['body'] = {
      supplierId: formValues.supplierId,
      warehouseId: formValues.warehouseId,
      correctionInvoiceNumber: formValues.invoiceNumber,
      invoiceIssueDate: formValues.invoiceIssueDate,
      paymentType: formValues.paymentType.toLowerCase() as PaymentType,
      currency: formValues.currency,
      exchangeRate: formValues.exchangeRate,
      ncConversion: formValues.ncConversion,
      note: formValues.note,
    };

    await patchReceiveNoteCorrection({
      creditNoteId: props.receiveNoteCorrection?.creditNote?.creditNoteId!,
      body: requestBody,
    })
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .then(props.onClose)
      .catch(handleApiError);
  };

  return (
    <Form<ReceiveNoteCorrectionForm>
      schema={formSchema}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    >
      {(control) => (
        <>
          <VStack spacing={4}>
            <Grid columns={4}>
              <FormField
                isRequired
                isNotClearable
                isDisabled
                control={control}
                type="choice"
                name="supplierId"
                label={i18n.t('entity.warehouse.labels.supplier')}
                options={getOptionsFromSuppliers(props.suppliers)}
                data-testid={suffixTestId('inputs.supplierId', props)}
              />
              <FormField
                isRequired
                isNotClearable
                isDisabled
                control={control}
                type="choice"
                name="warehouseId"
                label={i18n.t('entity.warehouse.labels.warehouse')}
                options={getOptionsFromWarehouses(props.warehouses)}
                data-testid={suffixTestId('inputs.warehouseId', props)}
              />
              <FormField
                isRequired
                isNotClearable
                isDisabled
                control={control}
                type="choice"
                name="authorizationProfileId"
                label={i18n.t('entity.warehouse.labels.authorizationProfile')}
                options={getOptionsFromAuthorizationProfiles(props.authorizationProfiles)}
                data-testid={suffixTestId('inputs.authorizationProfileId', props)}
              />
            </Grid>

            <Grid columns={4}>
              <FormField
                isDisabled
                control={control}
                type="text"
                name="number"
                label={i18n.t('entity.warehouse.labels.number')}
                data-testid={suffixTestId('inputs.number', props)}
              />
              <FormField
                isDisabled
                control={control}
                type="date"
                name="date"
                label={i18n.t('entity.warehouse.labels.date')}
                data-testid={suffixTestId('inputs.date', props)}
              />
              <FormField
                control={control}
                type="text"
                name="invoiceNumber"
                label={i18n.t('entity.warehouse.labels.invoiceNumber')}
                data-testid={suffixTestId('inputs.invoiceNumber', props)}
              />
              <FormField
                control={control}
                type="date"
                name="invoiceIssueDate"
                label={i18n.t('entity.warehouse.labels.invoiceIssueDate')}
                data-testid={suffixTestId('inputs.invoiceIssueDate', props)}
              />
            </Grid>

            <Grid columns={4}>
              <FormField
                isRequired
                isNotClearable
                control={control}
                type="choice"
                name="paymentType"
                label={i18n.t('entity.warehouse.labels.paymentType')}
                options={getOptionsFromPaymentTypes(props.paymentTypes)}
                data-testid={suffixTestId('inputs.paymentType', props)}
              />
              <FormField
                control={control}
                type="choice"
                name="currency"
                label={i18n.t('entity.warehouse.labels.currency')}
                options={getOptionsFromCurrencies(props.currencies)}
                data-testid={suffixTestId('inputs.currency', props)}
              />
              <FormField
                control={control}
                type="number"
                name="exchangeRate"
                label={i18n.t('entity.warehouse.labels.exchangeRate')}
                data-testid={suffixTestId('inputs.exchangeRate', props)}
              />
              <FormField
                control={control}
                type="choice"
                name="ncConversion"
                label={i18n.t('entity.warehouse.labels.ncConversion')}
                options={[]}
                data-testid={suffixTestId('inputs.ncConversion', props)}
              />
            </Grid>

            <Grid columns={4}>
              <GridItem span={4}>
                <FormField
                  control={control}
                  type="textarea"
                  name="note"
                  label={i18n.t('entity.warehouse.labels.note')}
                  data-testid={suffixTestId('inputs.note', props)}
                />
              </GridItem>
            </Grid>

            <HStack justify="flex-end">
              <ButtonGroup>
                <Show when={isNotNil(props.receiveNoteCorrection)}>
                  <FormButton
                    control={control}
                    type="reset"
                    variant="secondary"
                    title={i18n.t('general.labels.discard')}
                    onClick={props.onClose}
                    data-testid={suffixTestId('actions.discard', props)}
                  />
                </Show>
                <FormButton
                  control={control}
                  type="submit"
                  variant="primary"
                  title={i18n.t('general.labels.saveChanges')}
                  data-testid={suffixTestId('actions.submit', props)}
                />
              </ButtonGroup>
            </HStack>
          </VStack>
        </>
      )}
    </Form>
  );
}

const formSchema = object({
  paymentType: yupString.required(),
});
