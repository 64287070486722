import {IconButton, ButtonGroup, Card, DataStatus, openDialog} from 'platform/components';
import {Box} from 'platform/foundation';

import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {
  BusinessCaseSeriesDefinitionResponseBody,
  useGetBusinessCaseSeriesDefinitionListQuery,
} from '@omnetic-dms/shared';
import {SimpleTable, selectUserBranches} from '@omnetic-dms/teas';

import {BusinessCaseSeriesDefinitionForm} from './BusinessCaseSeriesDefinitionForm';

export function BusinessCaseSeriesDefinitionList() {
  const {
    data: definitions = [],
    isLoading: isLoadingDefinitions,
    isError: isErrorDefinitions,
  } = useGetBusinessCaseSeriesDefinitionListQuery();

  const branchList = useSelector(selectUserBranches) || [];

  const handleOpenEdit = (definitionId: BusinessCaseSeriesDefinitionResponseBody['id']) => {
    openDialog(<BusinessCaseSeriesDefinitionForm definitionId={definitionId} />, {
      title: i18n.t('page.businessCaseSeriesDefinition.actions.editDefinition'),
    });
  };

  const handleCreateNew = () => {
    openDialog(<BusinessCaseSeriesDefinitionForm />, {
      title: i18n.t('page.businessCaseSeriesDefinition.actions.newDefinition'),
    });
  };

  const columns = [
    {
      Header: i18n.t('entity.seriesDefinition.labels.definitionName'),
      id: 'name',
    },
    {
      Header: i18n.t('entity.branch.labels.branch'),
      id: 'branch',
      Cell: ({row}: {row: BusinessCaseSeriesDefinitionResponseBody}) => {
        if (!row.branchId) {
          return i18n.t('general.labels.all');
        }

        return branchList.find((branch) => branch.id === row.branchId)?.name ?? row.branchId;
      },
    },
    {
      Header: '',
      id: 'actions',
      width: 100,
      Cell: ({row}: {row: BusinessCaseSeriesDefinitionResponseBody}) => (
        <Box paddingHorizontal={3}>
          <ButtonGroup align="right">
            <IconButton
              key="edit"
              size="small"
              icon="image/edit"
              data-testid="businessCaseSeriesDefinitionListEdit"
              onClick={() => handleOpenEdit(row.id)}
            />
          </ButtonGroup>
        </Box>
      ),
    },
  ];

  return (
    <Card
      title={i18n.t('page.businessCaseSeriesDefinition.labels.definitions')}
      actions={[
        {
          type: 'button',
          variant: 'link',
          leftIcon: 'image/edit',
          onClick: handleCreateNew,
          title: i18n.t('page.businessCaseSeriesDefinition.actions.newDefinition'),
        },
      ]}
    >
      <DataStatus isLoading={isLoadingDefinitions} isError={isErrorDefinitions} spacing={5}>
        <SimpleTable
          noZebra
          showRowDivider
          tdPadding="default"
          highlightRowOnHover
          rows={[
            {
              data: definitions,
            },
          ]}
          getRowId={({id}) => id}
          columns={columns}
        />
      </DataStatus>
    </Card>
  );
}
