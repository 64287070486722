import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  DynamicUi,
  DynamicUiRefProps,
  FieldValues,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {createRef} from 'react';

import {isNotNil} from 'ramda';

import {RequiredTestIdProps} from 'shared';

import {
  useGetCustomFieldsSchemaQuery,
  useUpdateCustomFieldsPayloadMutation,
} from '../../../api/customFieldsApi';
import i18n from '../../../i18n/i18n';
import {EntityResourceIds} from '../../../types/api/api';
import {handleApiError} from '../../../utils/handleApiError';
import {useGetDefaultValues} from '../hooks/useGetDefaultValues';
import {useGetSubmitFieldValues} from '../hooks/useGetSubmitFieldValues';

interface EditCustomFieldsValuesFormProps extends RequiredTestIdProps {
  resourceId: EntityResourceIds;
  recordId: string;
}

export function EditCustomFieldsValuesForm(props: EditCustomFieldsValuesFormProps) {
  const dynamicUIRef = createRef<DynamicUiRefProps>();

  const [getFormattedFieldValues] = useGetSubmitFieldValues({resourceId: props.resourceId});

  const [updatePayload, {isLoading: isUpdatingPayload}] = useUpdateCustomFieldsPayloadMutation();
  const [getDefaultValues, {isLoading: isLoadingDefaultValues, isError: isDefaultValuesError}] =
    useGetDefaultValues(props);

  const {
    data: schema,
    isLoading: isLoadingSchema,
    isError: isSchemaError,
  } = useGetCustomFieldsSchemaQuery(props);

  const handleSubmit = (formValues: FieldValues) => {
    const fieldValues = getFormattedFieldValues(formValues);

    updatePayload({recordId: props.recordId, resourceId: props.resourceId, fieldValues})
      .unwrap()
      .then(() => showNotification.success(i18n.t('general.notifications.successfullyCompleted')))
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const defaultValues = getDefaultValues();
  const isLoading = isLoadingDefaultValues || isLoadingSchema;
  const isError = isDefaultValuesError || isSchemaError;

  return (
    <VStack spacing={4}>
      <DataStatus isLoading={isLoading} isError={isError}>
        {isNotNil(schema) && (
          <DynamicUi
            schema={schema.root}
            ref={dynamicUIRef}
            defaultValues={defaultValues}
            onSubmit={handleSubmit}
          />
        )}
      </DataStatus>
      <ButtonGroup align="right">
        <Button
          variant="secondary"
          title={i18n.t('general.actions.close')}
          onClick={closeCurrentDialog}
        />
        <Button
          type="submit"
          onClick={() => dynamicUIRef.current?.submit()}
          isLoading={isUpdatingPayload}
          title={i18n.t('general.actions.saveChanges')}
        />
      </ButtonGroup>
    </VStack>
  );
}
