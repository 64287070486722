import {Module, featureFlags} from '@omnetic-dms/shared';

import {DataGridDev} from './pages/DataGridDev/DataGridDev';
import {DataGridPlayground} from './pages/DataGridPlayground/DataGridPlayground';
import {DynamicUiBasicForm} from './pages/DemoDynamicUi/DynamicUiBasicForm';
import {OpenSearchDataGrid} from './pages/OpenSearchDataGrid/OpenSearchDataGrid';

export const DemoModule: Module = {
  id: 'demo-module',
  requiredFeatureFlag: featureFlags.DEMO_PAGE_DATAGRID_V4,
  layoutProps: {
    icon: 'custom/tractor',
    iconV2: 'sidebar/stroke_full',
    title: 'Demo Page',
  },
  routerProps: {
    path: '/demo/datagrid',
  },
  content: [
    {
      id: 'demo-module-dg',
      routerProps: {
        path: '/demo/datagrid',
        element: <DataGridPlayground />,
      },
      layoutProps: {
        title: 'Demo DataGrid',
        icon: 'sidebar/stroke_full',
      },
    },
    {
      id: 'demo-openSearch-dg',
      routerProps: {
        path: '/demo/openSearchDatagrid',
        element: <OpenSearchDataGrid />,
      },
      layoutProps: {
        title: 'Demo open search dataGrid',
        icon: 'sidebar/stroke_full',
      },
    },
    {
      id: 'demo-dev-dg',
      routerProps: {
        path: '/demo/devDatagrid',
        element: <DataGridDev />,
      },
      layoutProps: {
        title: 'Demo dev dataGrid',
        icon: 'sidebar/stroke_full',
      },
    },
    {
      id: 'demo-dynamic-UI',
      routerProps: {
        path: '/demo/demoDynamicUI',
        element: <DynamicUiBasicForm />,
      },
      layoutProps: {
        title: 'Demo DynamicUI',
        icon: 'sidebar/stroke_full',
      },
    },
  ],
};
