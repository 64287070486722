import {ButtonProps, openDialog, showNotification} from 'platform/components';
import {useRefreshDataGrid} from 'platform/datagrid';
import {Box, HStack} from 'platform/foundation';

import {equals, not} from 'ramda';
import {isArray, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {warehouseRoutes} from '@omnetic-dms/routes';
import {
  BulkPostReceiveNoteCorrectionItemsRequest,
  ReceiveNoteCorrectionBasketItem,
  EitherQuantityOrError,
  FullScreenModal,
  handleApiError,
  PatchReceiveNoteCorrectionItemsDeleteRequest,
  useBulkPostReceiveNoteCorrectionItemsMutation,
  useDeleteReceiveNoteCorrectionItemsMutation,
  useGetReceiveNoteCorrectionBasketQuery,
  useInvalidBasketItemsIds,
  usePatchReceiveNoteCorrectionBasketItemQuantityMutation,
  usePostReceiveNoteCorrectionBasketCheckoutMutation,
  AfterSalesCorrectionBasket,
  CorrectionBasketItemEditingDetails,
} from '@omnetic-dms/shared';

import {composePath, noop, RequiredTestIdProps, suffixTestId} from 'shared';

import {ReceiveNoteCorrectionAddMaterialList} from './ReceiveNoteCorrectionAddMaterialList';
import {ReceiveNoteCorrectionItemEdit} from './ReceiveNoteCorrectionItemEdit';

interface ReceiveNoteCorrectionAddMaterialModalProps extends RequiredTestIdProps {
  receiveNoteCorrectionId: string;
  receiveNoteId: string;
  onDiscard: VoidFunction;
}

export function ReceiveNoteCorrectionAddMaterialModal(
  props: ReceiveNoteCorrectionAddMaterialModalProps
) {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: hasBasketError,
  } = useGetReceiveNoteCorrectionBasketQuery({
    creditNoteId: props.receiveNoteCorrectionId,
  });

  const [checkoutBasket, {isLoading: isCheckingOutBasket}] =
    usePostReceiveNoteCorrectionBasketCheckoutMutation();

  const [deleteBasketItems, {isLoading: isDeletingBasketItems}] =
    useDeleteReceiveNoteCorrectionItemsMutation();

  const [bulkAddItemsToBasket] = useBulkPostReceiveNoteCorrectionItemsMutation();

  const [changeBasketItemQuantity] = usePatchReceiveNoteCorrectionBasketItemQuantityMutation();

  const hasEmptyBasket = isNilOrEmpty(basket?.creditNoteBasketItem);
  const basketItems = basket?.creditNoteBasketItem ?? [];
  const basketTotalPrice = basket?.creditNoteBasketTotalPrice;

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  const handleAdd = async () => {
    await checkoutBasket({
      creditNoteId: props.receiveNoteCorrectionId,
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.materialAdded')))
      .then(props.onDiscard)
      .catch(handleApiError);
  };

  const handleDiscard = async () => {
    await deleteBasketItems({
      creditNoteId: props.receiveNoteCorrectionId,
      body: {state: 'BASKET'},
    })
      .unwrap()
      .then(props.onDiscard)
      .catch(handleApiError);
  };

  const handleAddMaterialToBasket = async (itemsIds: string[]) => {
    const requestBody: BulkPostReceiveNoteCorrectionItemsRequest['body'] = {
      receiveNoteItemId: itemsIds,
    };

    await bulkAddItemsToBasket({
      creditNoteId: props.receiveNoteCorrectionId,
      body: requestBody,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleOpenMaterialDetailInNewTab = (warehouseId: string, articleId: string) => {
    window.open(
      composePath(warehouseRoutes.articleDetailOverview, {
        params: {warehouseId, id: articleId},
      }),
      '_blank'
    );
  };

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await changeBasketItemQuantity({
      creditNoteId: props.receiveNoteCorrectionId,
      creditNoteItemId: itemId,
      body: {correctionQuantity: quantity.newQuantity!},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleEdit = (editingDetails: CorrectionBasketItemEditingDetails) =>
    openDialog(
      <ReceiveNoteCorrectionItemEdit
        receiveNoteCorrectionId={props.receiveNoteCorrectionId}
        receiveNoteCorrectionItemId={editingDetails.itemId}
        onAfterSubmit={noop}
        data-testid={suffixTestId('itemEditDialog', props)}
      />,
      {
        id: 'itemEditDialog',
        title: i18n.t('entity.warehouse.labels.editReceiveNoteCorrectionItem'),
        size: 'large',
        withAdditionalFooter: true,
        'data-testid': suffixTestId('itemEditDialog', props),
      }
    );

  const handleDelete = async (itemsIds: string | string[]) => {
    const ids = isArray(itemsIds) ? itemsIds : [itemsIds];

    const requestBody: PatchReceiveNoteCorrectionItemsDeleteRequest['body'] = {
      state: 'BASKET',
      creditNoteItemId: ids,
    };

    await deleteBasketItems({
      creditNoteId: props.receiveNoteCorrectionId,
      body: requestBody,
    })
      .unwrap()
      .then(refreshDataGrid)
      .catch(handleApiError);
  };

  const actions: ButtonProps[] = [
    {
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: handleDiscard,
      isLoading: isDeletingBasketItems,
      'data-testid': suffixTestId('headerActions.discard', props),
    },
    {
      title: i18n.t('general.actions.add'),
      variant: 'primary',
      onClick: handleAdd,
      isLoading: isCheckingOutBasket,
      isDisabled: hasEmptyBasket || isNotNilOrEmpty(invalidBasketItemsIds),
      'data-testid': suffixTestId('headerActions.add', props),
    },
  ];

  return (
    <FullScreenModal headline={i18n.t('general.actions.addMaterial')} actions={actions}>
      <Box padding={4} height="100%">
        <HStack spacing={4} height="100%">
          <Box flex={5}>
            <ReceiveNoteCorrectionAddMaterialList
              receiveNoteId={props.receiveNoteId}
              dataGridRef={dataGridRef}
              onRefreshDataGrid={refreshDataGrid}
              onAddMaterialToBasket={handleAddMaterialToBasket}
              onOpenMaterialDetailInNewTab={handleOpenMaterialDetailInNewTab}
              data-testid={suffixTestId('materialList', props)}
            />
          </Box>
          <Box flex={1} minWidth={86}>
            <AfterSalesCorrectionBasket<ReceiveNoteCorrectionBasketItem>
              basket={{
                items: basketItems,
                totalPrice: basketTotalPrice,
                isLoading: isBasketLoading,
                hasError: hasBasketError,
                hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                isDeletingItems: isDeletingBasketItems,
              }}
              mechanic={{isMechanicHidden: true}}
              onQuantityChange={handleQuantityChange}
              onEdit={handleEdit}
              onDelete={handleDelete}
              data-testid={suffixTestId('basket', props)}
            />
          </Box>
        </HStack>
      </Box>
    </FullScreenModal>
  );
}
