import {ScatterProps} from 'recharts';
import styled from 'styled-components';

import {SourcingVehicleDetailResponseBody} from '@omnetic-dms/shared';
import {SourcingVehicleType} from '@omnetic-dms/teas';

export interface CircleProps extends ScatterProps {
  payload?: SourcingVehicleDetailResponseBody;
  isCurrentCar?: boolean;
  selectedVehicleAdId?: string;
  sourcingVehicle?: SourcingVehicleType;
}

export function Circle(props: CircleProps) {
  return (
    <StyledG
      $currentColor={props.fill}
      $isCurrentCar={props.isCurrentCar}
      $isSelected={props.selectedVehicleAdId === props.payload?.sourcingVehicle?.adId}
      data-testid={`price-map-point-${props.sourcingVehicle?.id}`}
    >
      <PointerCircle r={12} cx={props.cx} cy={props.cy} fill="transparent" className="stroke" />
      <PointerCircle r={8} cx={props.cx} cy={props.cy} fill={props.fill} />
    </StyledG>
  );
}

interface StyledGProps {
  $currentColor?: string;
  $isSelected?: boolean;
  $isCurrentCar?: boolean;
}

const StyledG = styled.g<StyledGProps>`
  cursor: pointer;

  ${({theme, $isCurrentCar, $currentColor}) =>
    $isCurrentCar
      ? `
				display: block;
				fill: transparent;
				stroke-width: 2px;
				stroke: ${$currentColor};
      `
      : `
      .stroke {
        display: none;
      }

      &:hover > .stroke {
        display: block;
        fill: ${theme.colors.palettes.blue[20][100]};
      }
  `}

  ${({$isSelected, theme}) =>
    $isSelected
      ? `
				> circle:not(.stroke) {
					stroke-width: 2px;
					stroke: ${theme.colors.palettes.blue[60][100]};
				}
			`
      : null}
`;

const PointerCircle = styled.circle`
  transition: none;
`;
