import {Dropdown, DropdownItem, Tooltip} from 'platform/components';
import {Hide, HStack, Link, Show, VStack} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

import {useGetSaleVehicleQuery} from '../../../api/saleVehicleApi';
import {useGetVehicleWarehouseManagementSettingsQuery} from '../../../api/vehicleWarehouseApi';
import {usePermissions} from '../../../hooks/usePermissions/usePermissions';
import {useVehicleWarehouse} from '../../../hooks/useVehicleWarehouse';
import i18n from '../../../i18n/i18n';
import {NoPermissionTooltip} from '../../NoPermissionTooltip/NoPermissionTooltip';

interface VehicleWarehouseProps extends TestIdProps {
  vehicleId: string;
}

export function VehicleWarehouse(props: VehicleWarehouseProps) {
  const {data: saleVehicle} = useGetSaleVehicleQuery({vehicleId: props.vehicleId});

  const {
    availableActions,
    openStockInDialog,
    openStockOutDialog,
    openCancelStockInDialog,
    openCancelStockOutDialog,
    openTransferDialog,
  } = useVehicleWarehouse({
    vehicleId: props.vehicleId,
    saleVehicleId: saleVehicle?.id,
    vehicleWarehouseId: saleVehicle?.vehicleWarehouse?.id,
  });

  const {data: warehouseManagmentData} = useGetVehicleWarehouseManagementSettingsQuery();

  const hasWarehouseManagementEnabled = warehouseManagmentData?.isEnabled;

  const [
    canIssueFromVehicleWarehouse,
    canReceiveToVehicleWarehouse,
    canCancelReceivalWarehouse,
    canCancelIssuingWarehouse,
  ] = usePermissions({
    permissionKeys: [
      'vehicleIssueFromVehicleWarehouse',
      'vehicleReceiveToVehicleWarehouse',
      'vehicleCancelReceivalWarehouse',
      'vehicleCancelIssuingWarehouse',
    ],
  });

  const shouldShowActions = [
    availableActions.issue && canIssueFromVehicleWarehouse,
    availableActions.receive && canReceiveToVehicleWarehouse,
    availableActions.cancelIssue && canCancelIssuingWarehouse,
    availableActions.cancelReceipt && canCancelReceivalWarehouse,
    availableActions.transfer && canReceiveToVehicleWarehouse && canIssueFromVehicleWarehouse,
  ].some(Boolean);

  const isBrokerage = saleVehicle?.type === 'BROKERAGE';

  return (
    <VStack spacing={1}>
      <Show when={shouldShowActions}>
        <Dropdown
          dropdownControl={
            <Link
              size="small"
              leftIcon="custom/warehouse"
              data-testid={suffixTestId('link', props)}
              title={
                saleVehicle?.vehicleWarehouse?.name ??
                i18n.t('entity.vehicleWarehouse.labels.noWarehouseAssigned')
              }
            />
          }
          data-testid={suffixTestId('vehicleWarehouse', props)}
        >
          <Show when={availableActions.cancelReceipt && canCancelReceivalWarehouse}>
            <DropdownItem
              label={i18n.t('entity.vehicleWarehouse.actions.warehouseCancelStockIn')}
              onClick={openCancelStockInDialog}
            />
          </Show>
          <Show when={availableActions.cancelIssue && canCancelIssuingWarehouse}>
            <DropdownItem
              label={i18n.t('entity.vehicleWarehouse.actions.warehouseCancelStockOut')}
              onClick={openCancelStockOutDialog}
            />
          </Show>
          <Show
            when={
              availableActions.receive &&
              canReceiveToVehicleWarehouse &&
              hasWarehouseManagementEnabled
            }
          >
            <DropdownItem
              label={i18n.t('entity.vehicleWarehouse.actions.warehouseStockIn')}
              onClick={openStockInDialog}
            />
          </Show>
          <Show when={availableActions.issue && canIssueFromVehicleWarehouse}>
            <DropdownItem
              label={i18n.t('entity.vehicleWarehouse.actions.warehouseStockOut')}
              onClick={openStockOutDialog}
            />
          </Show>
          <Show
            when={
              availableActions.transfer &&
              canIssueFromVehicleWarehouse &&
              canReceiveToVehicleWarehouse
            }
          >
            <DropdownItem
              label={i18n.t('entity.vehicleWarehouse.actions.warehouseTransfer')}
              onClick={() => openTransferDialog()}
            />
          </Show>
        </Dropdown>
      </Show>
      <Hide when={shouldShowActions}>
        <Show when={isBrokerage}>
          <Tooltip label={i18n.t('entity.vehicleWarehouse.labels.noAssign')}>
            <HStack>
              <Link
                isDisabled
                size="small"
                leftIcon="custom/warehouse"
                data-testid={suffixTestId('link', props)}
                title={
                  saleVehicle?.vehicleWarehouse?.name ??
                  i18n.t('entity.vehicleWarehouse.labels.noWarehouseAssigned')
                }
              />
            </HStack>
          </Tooltip>
        </Show>
        <Hide when={isBrokerage}>
          <NoPermissionTooltip shouldShowTooltip>
            <HStack>
              <Link
                isDisabled
                size="small"
                leftIcon="custom/warehouse"
                data-testid={suffixTestId('link', props)}
                title={
                  saleVehicle?.vehicleWarehouse?.name ??
                  i18n.t('entity.vehicleWarehouse.labels.noWarehouseAssigned')
                }
              />
            </HStack>
          </NoPermissionTooltip>
        </Hide>
      </Hide>
    </VStack>
  );
}
