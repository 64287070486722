import {
  AddVehicleReservationDepositApiArg,
  AddVehicleReservationDepositApiResponse,
  ApplyCostPresetApiArg,
  ApplyEarningPresetApiArg,
  CancelVehicleReservationApiArg,
  CancelVehicleReservationApiResponse,
  CancelVehicleReservationDepositApiArg,
  CancelVehicleReservationDepositApiResponse,
  ChangeBranchSaleVehicleApiArg,
  ChangeBranchSaleVehicleApiResponse,
  ChangeSaleVehicleStateToSellApiArg,
  ChangeSaleVehicleStateToSellApiResponse,
  CreateCostApiArg,
  CreateCostApiResponse,
  CreateCostPresetApiArg,
  CreateEarningApiArg,
  CreateEarningApiResponse,
  CreateEarningPresetApiArg,
  DeleteCostApiArg,
  DeleteCostApiResponse,
  DeleteCostPresetApiArg,
  DeleteEarningApiArg,
  DeleteEarningApiResponse,
  DeleteEarningPresetApiArg,
  DeleteLeasingAndCreditCompanyApiArg,
  DeleteLeasingAndCreditCompanyApiResponse,
  EditCostApiArg,
  EditCostApiResponse,
  EditEarningApiArg,
  EditEarningApiResponse,
  EnableLeasingAndCreditCompanyApiArg,
  EnableLeasingAndCreditCompanyApiResponse,
  GetActiveSaleVehicleApiArg,
  GetActiveSaleVehicleApiResponse,
  GetCostApiArg,
  GetCostApiResponse,
  GetCostPresetListApiResponse,
  GetCostPresetListResult,
  GetEarningApiArg,
  GetEarningApiResponse,
  GetEarningPresetListApiResponse,
  GetEarningPresetListResult,
  GetFinanceSummaryApiArg,
  GetFinanceSummaryApiResponse,
  GetSaleVehicleApiArg,
  GetSaleVehicleApiResponse,
  GetSaleVehicleByVehicleApiArg,
  GetSaleVehicleByVehicleApiResponse,
  GetVehicleSalesActionsApiArg,
  GetVehicleSalesActionsApiResponse,
  LeasingAndCreditCompanyApiArg,
  LeasingAndCreditCompanyApiResponse,
  MakeSaleVehicleAvailableForSaleApiArg,
  MakeSaleVehicleAvailableForSaleApiResponse,
  MakeSaleVehicleIsNotForSaleApiArg,
  MakeSaleVehicleIsNotForSaleApiResponse,
  MakeSaleVehicleIsNotSoldApiArg,
  MakeSaleVehicleIsNotSoldApiResponse,
  MakeSaleVehicleIsReturnedToCustomerApiArg,
  MakeSaleVehicleIsReturnedToCustomerApiResponse,
  PatchSaleVehicleWatermarkApiArg,
  PatchSaleVehicleWatermarkApiResponse,
  PatchVehiclePricesApiArg,
  PatchVehiclePricesApiResponse,
  RefundVehicleReservationDepositApiArg,
  RefundVehicleReservationDepositApiResponse,
  ReRegisterVehicleForSaleApiArg,
  ReRegisterVehicleForSaleApiResponse,
  ReserveVehicleApiArg,
  ReserveVehicleApiResponse,
  UpdateSaleVehicleApiArg,
  UpdateSaleVehicleApiResponse,
  UpdateVehicleReservationApiArg,
  UpdateVehicleReservationApiResponse,
  VehicleActiveReservationApiArg,
  VehicleActiveReservationApiResponse,
  VehicleReservationHistoryApiArg,
  VehicleReservationHistoryApiResponse,
} from '../types/api/saleVehicle';
import {omneticApi} from './baseApi/omneticApi';

export const saleVehicleApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getFinanceSummary: build.query<GetFinanceSummaryApiResponse, GetFinanceSummaryApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/finance-summary`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'VehicleFinanceSummary', id: queryArg.vehicleId},
      ],
    }),

    getCost: build.query<GetCostApiResponse, GetCostApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/cost/${queryArg.costId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'SaleVehicleCost', id: queryArg.costId}],
    }),
    createCost: build.mutation<CreateCostApiResponse, CreateCostApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/cost`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'VehicleFinanceSummary', id: queryArg.vehicleId},
        {type: 'SaleVehicleCost', id: 'LIST'},
        {type: 'Vehicle', id: queryArg.vehicleId},
        {type: 'documentsCount', id: queryArg.vehicleId},
      ],
    }),
    deleteCost: build.mutation<DeleteCostApiResponse, DeleteCostApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/cost/${queryArg.costId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'VehicleFinanceSummary', id: queryArg.vehicleId},
        {type: 'SaleVehicleCost', id: queryArg.costId},
        {type: 'SaleVehicleCost', id: 'LIST'},
        {type: 'Vehicle', id: queryArg.vehicleId},
        {type: 'documentsCount', id: queryArg.vehicleId},
      ],
    }),
    editCost: build.mutation<EditCostApiResponse, EditCostApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/cost/${queryArg.costId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'VehicleFinanceSummary', id: queryArg.vehicleId},
        {type: 'SaleVehicleCost', id: queryArg.costId},
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'documentsCount', id: queryArg.vehicleId},
        {type: 'Vehicle', id: queryArg.vehicleId},
      ],
    }),
    applyCostPreset: build.mutation<void, ApplyCostPresetApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/apply-cost-preset`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'VehicleFinanceSummary', id: queryArg.vehicleId},
        'BusinessCasePurchasePrices',
        {type: 'Vehicle', id: queryArg.vehicleId},
      ],
    }),
    createCostPreset: build.mutation<void, CreateCostPresetApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/cost-preset`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: () => [{type: 'SaleVehicleCostPreset', id: 'LIST'}],
    }),
    deleteCostPreset: build.mutation<void, DeleteCostPresetApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/cost-preset/${queryArg.costPresetId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{type: 'SaleVehicleCostPreset', id: 'LIST'}],
    }),
    getCostPresetList: build.query<GetCostPresetListResult, void>({
      query: () => ({
        url: `/dms/v1/sale-vehicle/cost-presets`,
      }),
      transformResponse: (response: GetCostPresetListApiResponse) =>
        response.map((preset) => ({
          value: preset.id,
          label: preset.name,
        })),
      providesTags: () => [{type: 'SaleVehicleCostPreset', id: 'LIST'}],
    }),

    getEarning: build.query<GetEarningApiResponse, GetEarningApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/earning/${queryArg.earningId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'SaleVehicleEarning', id: queryArg.earningId},
      ],
    }),
    createEarning: build.mutation<CreateEarningApiResponse, CreateEarningApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/earning`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'VehicleFinanceSummary', id: queryArg.vehicleId},
        {type: 'SaleVehicleEarning', id: 'LIST'},
        {type: 'documentsCount', id: queryArg.vehicleId},
        {type: 'Vehicle', id: queryArg.vehicleId},
      ],
    }),
    deleteEarning: build.mutation<DeleteEarningApiResponse, DeleteEarningApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/earning/${queryArg.earningId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'VehicleFinanceSummary', id: queryArg.vehicleId},
        {type: 'SaleVehicleEarning', id: queryArg.earningId},
        {type: 'documentsCount', id: queryArg.vehicleId},
        {type: 'SaleVehicleEarning', id: 'LIST'},
        {type: 'Vehicle', id: queryArg.vehicleId},
      ],
    }),
    editEarning: build.mutation<EditEarningApiResponse, EditEarningApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/earning/${queryArg.earningId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'VehicleFinanceSummary', id: queryArg.vehicleId},
        {type: 'SaleVehicleEarning', id: queryArg.earningId},
        {type: 'documentsCount', id: queryArg.vehicleId},
        {type: 'Vehicle', id: queryArg.vehicleId},
      ],
    }),
    applyEarningPreset: build.mutation<void, ApplyEarningPresetApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/apply-earning-preset`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'VehicleFinanceSummary', id: queryArg.vehicleId},
        {type: 'Vehicle', id: queryArg.vehicleId},
      ],
    }),
    createEarningPreset: build.mutation<void, CreateEarningPresetApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/earning-preset`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: () => [{type: 'SaleVehicleEarningPreset', id: 'LIST'}],
    }),
    deleteEarningPreset: build.mutation<void, DeleteEarningPresetApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/earning-preset/${queryArg.earningPresetId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{type: 'SaleVehicleEarningPreset', id: 'LIST'}],
    }),
    getEarningPresetList: build.query<GetEarningPresetListResult, void>({
      query: () => ({
        url: `/dms/v1/sale-vehicle/earning-presets`,
      }),
      transformResponse: (response: GetEarningPresetListApiResponse) =>
        response.map((preset) => ({
          value: preset.id,
          label: preset.name,
        })),
      providesTags: () => [{type: 'SaleVehicleEarningPreset', id: 'LIST'}],
    }),

    changeBranchSaleVehicle: build.mutation<
      ChangeBranchSaleVehicleApiResponse,
      ChangeBranchSaleVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/change-branch`,
        method: 'POST',
        body: queryArg.changeBranchSaleVehicleRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'Vehicle', id: queryArg.vehicleId},
        'vehicleWarehouseForMovement',
      ],
    }),
    changeSaleVehicleStateToSell: build.mutation<
      ChangeSaleVehicleStateToSellApiResponse,
      ChangeSaleVehicleStateToSellApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/change-state-to-sell`,
        method: 'PUT',
      }),
    }),
    getSaleVehicle: build.query<GetSaleVehicleApiResponse, GetSaleVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}`,
      }),
      extraOptions: {maxRetries: 1},
      providesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleFlags'},
      ],
    }),
    updateSaleVehicle: build.mutation<UpdateSaleVehicleApiResponse, UpdateSaleVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sale-vehicle/${queryArg.vehicleId}`,
        method: 'PUT',
        body: queryArg.updateSaleVehicleRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
        {type: 'Vehicle', id: queryArg.vehicleId},
        {type: 'vehicleWarehouseInformation', id: queryArg.vehicleId},
        'AdGeneralSettings',
        'Checkout',
        'offer',
        'BusinessCaseSalePrices',
        'BusinessCasePurchasePrices',
      ],
    }),
    getSaleVehicleByVehicle: build.query<
      GetSaleVehicleByVehicleApiResponse,
      GetSaleVehicleByVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/ids`,
        params: {type: queryArg.type},
      }),
      providesTags: (_result, _error, queryArg) => [{type: 'SaleVehicle', id: queryArg.vehicleId}],
    }),
    makeSaleVehicleAvailableForSale: build.mutation<
      MakeSaleVehicleAvailableForSaleApiResponse,
      MakeSaleVehicleAvailableForSaleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/make-available-for-sale`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'VehicleLastActiveBC', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    makeSaleVehicleIsNotForSale: build.mutation<
      MakeSaleVehicleIsNotForSaleApiResponse,
      MakeSaleVehicleIsNotForSaleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/make-not-for-sale`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    makeSaleVehicleIsNotSold: build.mutation<
      MakeSaleVehicleIsNotSoldApiResponse,
      MakeSaleVehicleIsNotSoldApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/make-not-sold`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    makeSaleVehicleIsReturnedToCustomer: build.mutation<
      MakeSaleVehicleIsReturnedToCustomerApiResponse,
      MakeSaleVehicleIsReturnedToCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/make-returned-to-customer`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    reRegisterVehicleForSale: build.mutation<
      ReRegisterVehicleForSaleApiResponse,
      ReRegisterVehicleForSaleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/re-register-for-sale`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    deleteLeasingAndCreditCompany: build.mutation<
      DeleteLeasingAndCreditCompanyApiResponse,
      DeleteLeasingAndCreditCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/leasing-credit-company`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    enableLeasingAndCreditCompany: build.mutation<
      EnableLeasingAndCreditCompanyApiResponse,
      EnableLeasingAndCreditCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/leasing-credit-company/enable`,
        method: 'PUT',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    leasingAndCreditCompany: build.mutation<
      LeasingAndCreditCompanyApiResponse,
      LeasingAndCreditCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/leasing-credit-company/${queryArg.codeId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    getVehicleSalesActions: build.query<
      GetVehicleSalesActionsApiResponse,
      GetVehicleSalesActionsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/actions/sales`,
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    patchVehiclePrices: build.mutation<PatchVehiclePricesApiResponse, PatchVehiclePricesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/price`,
        method: 'PATCH',
        body: queryArg.patchVehiclePricesRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        'BusinessCaseSalePrices',
        'BusinessCaseDetail',
        'offer',
        'Checkout',
      ],
    }),
    cancelVehicleReservation: build.mutation<
      CancelVehicleReservationApiResponse,
      CancelVehicleReservationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/reservation`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
        {type: 'VehicleActiveReservation', id: queryArg.vehicleId},
        {type: 'VehicleReservationHistory', id: queryArg.vehicleId},
      ],
    }),
    updateVehicleReservation: build.mutation<
      UpdateVehicleReservationApiResponse,
      UpdateVehicleReservationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/reservation`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'VehicleLastActiveBC', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
        {type: 'VehicleActiveReservation', id: queryArg.vehicleId},
        {type: 'VehicleReservationHistory', id: queryArg.vehicleId},
      ],
    }),
    vehicleActiveReservation: build.query<
      VehicleActiveReservationApiResponse,
      VehicleActiveReservationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/reservation/active`,
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'VehicleActiveReservation', id: queryArg.vehicleId},
      ],
    }),
    addVehicleReservationDeposit: build.mutation<
      AddVehicleReservationDepositApiResponse,
      AddVehicleReservationDepositApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/reservation/add-deposit`,
        method: 'POST',
        headers: {'X-Branch': queryArg['X-Branch']},
        body: queryArg.body,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
        {type: 'VehicleActiveReservation', id: queryArg.vehicleId},
        {type: 'VehicleReservationHistory', id: queryArg.vehicleId},
      ],
    }),
    cancelVehicleReservationDeposit: build.mutation<
      CancelVehicleReservationDepositApiResponse,
      CancelVehicleReservationDepositApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/reservation/cancel-deposit`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
        {type: 'VehicleActiveReservation', id: queryArg.vehicleId},
        {type: 'VehicleReservationHistory', id: queryArg.vehicleId},
      ],
    }),
    vehicleReservationHistory: build.query<
      VehicleReservationHistoryApiResponse,
      VehicleReservationHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/reservation/history`,
        params: {saleVehicleId: queryArg.saleVehicleId, businessCaseId: queryArg.businessCaseId},
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'VehicleReservationHistory', id: queryArg.vehicleId},
      ],
    }),
    refundVehicleReservationDeposit: build.mutation<
      RefundVehicleReservationDepositApiResponse,
      RefundVehicleReservationDepositApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/reservation/refund-deposit`,
        method: 'POST',
        headers: {'X-Branch': queryArg['X-Branch']},
        body: queryArg.body,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
        {type: 'VehicleActiveReservation', id: queryArg.vehicleId},
        {type: 'VehicleReservationHistory', id: queryArg.vehicleId},
      ],
    }),
    reserveVehicle: build.mutation<ReserveVehicleApiResponse, ReserveVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/reserve`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
        {type: 'VehicleActiveReservation', id: queryArg.vehicleId},
        {type: 'VehicleReservationHistory', id: queryArg.vehicleId},
      ],
    }),
    getSaleVehicleFromSaleVehicleId: build.query<GetSaleVehicleApiResponse, GetSaleVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/id/${queryArg.vehicleId}`,
      }),
      providesTags: ['SaleVehicle'],
    }),
    getActiveSaleVehicle: build.query<GetActiveSaleVehicleApiResponse, GetActiveSaleVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}`,
      }),
      providesTags: (_, __, queryArgs) => [{type: 'Vehicle', id: queryArgs.vehicleId}],
    }),
    patchSaleVehicleWatermark: build.mutation<
      PatchSaleVehicleWatermarkApiResponse,
      PatchSaleVehicleWatermarkApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sales/sale-vehicle/${queryArg.vehicleId}/watermark`,
        method: 'PATCH',
        body: queryArg.patchSaleVehicleWatermarkRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
      ],
    }),
  }),
});

export const selectSaleVehicle = (vehicleId: string) =>
  saleVehicleApi.endpoints.getSaleVehicle.select({vehicleId});

export const {
  useCreateCostMutation,
  useDeleteCostMutation,
  useEditCostMutation,
  useGetCostQuery,
  useApplyCostPresetMutation,
  useCreateCostPresetMutation,
  useDeleteCostPresetMutation,
  useGetCostPresetListQuery,
  useCreateEarningMutation,
  useDeleteEarningMutation,
  useEditEarningMutation,
  useGetEarningQuery,
  useApplyEarningPresetMutation,
  useCreateEarningPresetMutation,
  useDeleteEarningPresetMutation,
  useGetEarningPresetListQuery,
  useGetFinanceSummaryQuery,
  useChangeBranchSaleVehicleMutation,
  useChangeSaleVehicleStateToSellMutation,
  useGetSaleVehicleQuery,
  useLazyGetSaleVehicleQuery,
  useUpdateSaleVehicleMutation,
  useGetSaleVehicleByVehicleQuery,
  useMakeSaleVehicleAvailableForSaleMutation,
  useMakeSaleVehicleIsNotForSaleMutation,
  useMakeSaleVehicleIsNotSoldMutation,
  useMakeSaleVehicleIsReturnedToCustomerMutation,
  useReRegisterVehicleForSaleMutation,
  useDeleteLeasingAndCreditCompanyMutation,
  useEnableLeasingAndCreditCompanyMutation,
  useLeasingAndCreditCompanyMutation,
  useGetVehicleSalesActionsQuery,
  usePatchVehiclePricesMutation,
  useCancelVehicleReservationMutation,
  useUpdateVehicleReservationMutation,
  useCancelVehicleReservationDepositMutation,
  useRefundVehicleReservationDepositMutation,
  useAddVehicleReservationDepositMutation,
  useReserveVehicleMutation,
  useVehicleActiveReservationQuery,
  useVehicleReservationHistoryQuery,
  useGetSaleVehicleFromSaleVehicleIdQuery,
  useGetActiveSaleVehicleQuery,
  usePatchSaleVehicleWatermarkMutation,
} = saleVehicleApi;
