import {DataStatus} from 'platform/components';
import {Grid, Show, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useGetParticipationQuery,
  useGetVehicleQuery,
  usePermissions,
  featureFlags,
} from '@omnetic-dms/shared';

import {isFeatureEnabled, suffixTestId, TestIdProps, useRequiredParams} from 'shared';

import {ConditionsCard} from './ConditionsCard';
import {DetailsSideColumn} from './DetailsSideColumn';
import {ServiceBookCard} from './ServiceBookCard';
import {ServiceInspectionsCard} from './ServiceInspectionsCard';
import {TechnicalInspectionCard} from './TechnicalInspectionCard';
import {WarrantyCard} from './WarrantyCard';

interface VehicleDataProps extends TestIdProps {
  isSaleVehicle: boolean;
}

export function VehicleData(props: VehicleDataProps) {
  const {id: vehicleId} = useRequiredParams();
  const {data: vehicleDetail, isLoading, isError} = useGetVehicleQuery({vehicleId});
  const {data: vehicleParticipation} = useGetParticipationQuery({
    recordId: vehicleId,
    resourceId: EntityResourceIds.vehicle,
  });

  const [canUpdateVehicle, canSetCustomVehicleId] = usePermissions({
    permissionKeys: ['updateVehicle', 'setCustomIdVehicle'],
    scopes: {
      updateVehicle: vehicleParticipation,
      setCustomIdVehicle: vehicleParticipation,
    },
  });
  const canEditVehicle = canUpdateVehicle && canSetCustomVehicleId;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Show when={isNotNil(vehicleDetail)}>
        <Grid columns={2}>
          <VStack spacing={4}>
            <Show
              when={
                !props.isSaleVehicle &&
                isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)
              }
            >
              <TechnicalInspectionCard
                canEditVehicle={canEditVehicle}
                vehicleId={vehicleId}
                isSaleVehicle={props.isSaleVehicle}
              />
              <WarrantyCard
                canEditVehicle={canEditVehicle}
                vehicleId={vehicleId}
                isSaleVehicle={props.isSaleVehicle}
              />
              <ServiceBookCard
                canEditVehicle={canEditVehicle}
                vehicle={vehicleDetail}
                isSaleVehicle={props.isSaleVehicle}
                state={vehicleDetail?.state || {}}
              />
            </Show>
            <Show
              when={
                props.isSaleVehicle ||
                (!props.isSaleVehicle &&
                  !isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2))
              }
            >
              <ConditionsCard
                canEditVehicle={canEditVehicle}
                id={vehicleId}
                type={vehicleDetail?.type}
                state={vehicleDetail?.state || {}}
                modelSpecification={vehicleDetail?.modelSpecification || {}}
                vehicle={vehicleDetail?.vehicle || {}}
                vehicleSourceCodeId={vehicleDetail?.vehicleSourceCodeId}
              />
              <ServiceBookCard
                canEditVehicle={canEditVehicle}
                vehicle={vehicleDetail}
                state={vehicleDetail?.state || {}}
                isSaleVehicle={props.isSaleVehicle}
              />
              <ServiceInspectionsCard canEditVehicle={canEditVehicle} vehicleId={vehicleId} />
              <TechnicalInspectionCard
                canEditVehicle={canEditVehicle}
                vehicleId={vehicleId}
                isSaleVehicle={props.isSaleVehicle}
              />
              <WarrantyCard
                canEditVehicle={canEditVehicle}
                vehicleId={vehicleId}
                isSaleVehicle={props.isSaleVehicle}
              />
            </Show>
          </VStack>
          <VStack spacing={4}>
            <DetailsSideColumn
              vehicleId={vehicleId}
              isInsuranceShown
              data-testid={suffixTestId('detailsSideColumn', props)}
            />
          </VStack>
        </Grid>
      </Show>
    </DataStatus>
  );
}
