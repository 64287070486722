import {Attributes, Card} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {vehiclesRoutes} from '@omnetic-dms/routes';

import {composePath, Nullish, parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import i18n from '../../i18n/i18n';
import {GetVehicleApiResponseV2} from '../../types/api/api';
import {VehicleServiceIntervalFluidAttributes} from './components/VehicleServiceIntervalFluidAttributes';
import {VehicleServiceIntervalReplacementAttributes} from './components/VehicleServiceIntervalReplacementAttributes';

interface VehicleServiceIntervalsCardProps extends RequiredTestIdProps {
  vehicleData: GetVehicleApiResponseV2 | Nullish;
}

export function VehicleServiceIntervalsCard(props: VehicleServiceIntervalsCardProps) {
  const formatDateTime = useDateTimeFormatter();

  const serviceInspection = props.vehicleData?.serviceIntervals?.serviceInspection;
  const engineOil = props.vehicleData?.serviceIntervals?.engineOil;
  const gearboxOil = props.vehicleData?.serviceIntervals?.gearboxOil;
  const fourByFourOilDistributor = props.vehicleData?.serviceIntervals?.fourByFourOilDistributor;
  const timingBelt = props.vehicleData?.serviceIntervals?.timingBelt;
  const brakeFluid = props.vehicleData?.serviceIntervals?.brakeFluid;
  const coolantFluid = props.vehicleData?.serviceIntervals?.coolantFluid;
  const annualMileage = props.vehicleData?.serviceIntervals?.annualMileage;

  return (
    <Card
      title={i18n.t('entity.vehicle.labels.serviceIntervals')}
      actions={[
        {
          type: 'button',
          variant: 'link',
          size: 'small',
          leftIcon: 'image/edit',
          to: composePath(vehiclesRoutes.editServiceVehicle, {
            params: {id: props.vehicleData?.id},
            queryParams: {tab: 'service-intervals'},
            isSearchParamsPreserved: false,
          }),
          'data-testid': suffixTestId('edit', props),
          title: i18n.t('general.actions.edit'),
        },
      ]}
    >
      <VStack spacing={4}>
        <Heading size={4}>{i18n.t('entity.vehicle.labels.serviceInspection')}</Heading>
        <Attributes
          rows={[
            {
              label: i18n.t('entity.vehicle.labels.dateOfLastInspection'),
              value: serviceInspection?.lastServiceInspectionDate
                ? formatDateTime(
                    'dateShort',
                    parseDate(serviceInspection?.lastServiceInspectionDate)
                  )
                : null,
            },
            {
              label: i18n.t('entity.vehicle.labels.replacementAfterMonths'),
              value: serviceInspection?.replacementAfterMonths,
            },
            {
              label: i18n.t('entity.vehicle.labels.dateOfNextServiceInspection'),
              value: serviceInspection?.nextServiceInspectionDate
                ? formatDateTime(
                    'dateShort',
                    parseDate(serviceInspection?.nextServiceInspectionDate)
                  )
                : null,
            },
            {
              label: `${i18n.t('entity.vehicle.labels.lastServiceInspection')} (${i18n.t('general.metric.km')})`,
              value: serviceInspection?.lastServiceInspectionMileage,
            },
            {
              label: `${i18n.t('entity.vehicle.labels.replacementAfterMileage')} (${i18n.t('general.metric.km')})`,
              value: serviceInspection?.replacementAfterMileage,
            },
            {
              label: `${i18n.t('entity.vehicle.labels.nextServiceInspection')} (${i18n.t('general.metric.km')})`,
              value: serviceInspection?.nextServiceInspectionMileage,
            },
          ]}
          data-testid={suffixTestId('serviceInspection', props)}
        />
        <Heading size={4}>{i18n.t('entity.vehicle.labels.engineOil')}</Heading>
        <VehicleServiceIntervalReplacementAttributes
          part={engineOil}
          data-testid={suffixTestId('engineOil', props)}
        />
        <Heading size={4}>{i18n.t('entity.vehicle.labels.gearboxOil')}</Heading>
        <VehicleServiceIntervalReplacementAttributes
          part={gearboxOil}
          data-testid={suffixTestId('gerboxOil', props)}
        />
        <Heading size={4}>{i18n.t('entity.vehicle.labels.fourByFourOilDistributor')}</Heading>
        <VehicleServiceIntervalReplacementAttributes
          part={fourByFourOilDistributor}
          data-testid={suffixTestId('fourByFourOilDistributor', props)}
        />
        <Heading size={4}>{i18n.t('entity.vehicle.labels.timingBelt')}</Heading>
        <VehicleServiceIntervalReplacementAttributes
          part={timingBelt}
          data-testid={suffixTestId('timingBelt', props)}
        />
        <Heading size={4}>{i18n.t('entity.vehicle.labels.brakeFluid')}</Heading>
        <VehicleServiceIntervalFluidAttributes
          part={brakeFluid}
          data-testid={suffixTestId('brakeFluid', props)}
        />
        <Heading size={4}>{i18n.t('entity.vehicle.labels.coolantFluid')}</Heading>
        <VehicleServiceIntervalFluidAttributes
          part={coolantFluid}
          data-testid={suffixTestId('coolantFluid', props)}
        />
        <Heading size={4}>{i18n.t('entity.vehicle.labels.annualMileage')}</Heading>
        <Attributes
          rows={[
            {
              label: `${i18n.t('entity.vehicle.labels.serviceVisits')} (${i18n.t('general.metric.km')})`,
              value: annualMileage?.calculatedFromServiceVisits,
            },
          ]}
          data-testid={suffixTestId('annualMileage', props)}
        />
      </VStack>
    </Card>
  );
}
