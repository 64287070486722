import {
  DeletePartsRequestReturnBasketRequest,
  DeletePartsRequestReturnBasketRequestSchema,
  DeletePartsRequestReturnBasketResponse,
  DeletePartsRequestReturnBasketResponseSchema,
  GetPartsRequestReturnBasketRequest,
  GetPartsRequestReturnBasketRequestSchema,
  GetPartsRequestReturnBasketResponse,
  GetPartsRequestReturnBasketResponseSchema,
  PatchPartsRequestReturnBasketItemQuantityRequest,
  PatchPartsRequestReturnBasketItemQuantityRequestSchema,
  PatchPartsRequestReturnBasketItemQuantityResponse,
  PatchPartsRequestReturnBasketItemQuantityResponseSchema,
  PostPartsRequestReturnBasketCheckoutRequest,
  PostPartsRequestReturnBasketCheckoutRequestSchema,
  PostPartsRequestReturnBasketCheckoutResponse,
  PostPartsRequestReturnBasketCheckoutResponseSchema,
} from '../types/api/metadaWarehousePartsRequestReturnBasket';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehousePartsRequestReturnBasketApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getPartsRequestReturnBasket: build.query<
      GetPartsRequestReturnBasketResponse,
      GetPartsRequestReturnBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request-return/${queryArg.originEntityHeaderId}/basket`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'partsRequestReturnBasket', id: queryArg.originEntityHeaderId},
      ],
      extraOptions: {
        requestSchema: GetPartsRequestReturnBasketRequestSchema,
        responseSchema: GetPartsRequestReturnBasketResponseSchema,
      },
    }),
    postPartsRequestReturnBasketCheckout: build.mutation<
      PostPartsRequestReturnBasketCheckoutResponse,
      PostPartsRequestReturnBasketCheckoutRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request-return/${queryArg.originEntityHeaderId}/basket/checkout`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'partsRequestReturnBasket', id: queryArg.originEntityHeaderId},
        {type: 'directSales', id: queryArg.originEntityHeaderId},
      ],
      extraOptions: {
        requestSchema: PostPartsRequestReturnBasketCheckoutRequestSchema,
        responseSchema: PostPartsRequestReturnBasketCheckoutResponseSchema,
      },
    }),
    patchPartsRequestReturnBasketItemQuantity: build.mutation<
      PatchPartsRequestReturnBasketItemQuantityResponse,
      PatchPartsRequestReturnBasketItemQuantityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request-return/${queryArg.originEntityHeaderId}/basket-item/${queryArg.requestItemId}/quantity`,
        method: 'PATCH',
        queryArg,
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'partsRequestReturnBasket', id: queryArg.originEntityHeaderId},
      ],
      extraOptions: {
        requestSchema: PatchPartsRequestReturnBasketItemQuantityRequestSchema,
        responseSchema: PatchPartsRequestReturnBasketItemQuantityResponseSchema,
      },
    }),
    deletePartsRequestReturnBasket: build.mutation<
      DeletePartsRequestReturnBasketResponse,
      DeletePartsRequestReturnBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request-return/${queryArg.originEntityHeaderId}/basket/delete`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'partsRequestReturnBasket', id: queryArg.originEntityHeaderId},
        {type: 'directSales', id: queryArg.originEntityHeaderId},
      ],
      extraOptions: {
        requestSchema: DeletePartsRequestReturnBasketRequestSchema,
        responseSchema: DeletePartsRequestReturnBasketResponseSchema,
      },
    }),
  }),
});

export const {
  useGetPartsRequestReturnBasketQuery,
  usePostPartsRequestReturnBasketCheckoutMutation,
  usePatchPartsRequestReturnBasketItemQuantityMutation,
  useDeletePartsRequestReturnBasketMutation,
} = metadaWarehousePartsRequestReturnBasketApi;
