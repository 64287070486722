import {Clickable, Hide, HStack, Image, Scroll, Show, VStack} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {head, isEmpty, tail} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {testIds} from '@omnetic-dms/routes';
import {thumbnailUrl} from '@omnetic-dms/shared';

interface GalleryProps {
  images: string[];
}

export function Gallery(props: GalleryProps) {
  const hasNoImageUrls = isEmpty(props.images);

  const [lightboxControls, {onOpen}] = useLightbox('sourcingVehicleGallery');

  const handleOpen = (index?: number) => {
    onOpen(index ?? 0);
  };

  return (
    <>
      <VStack spacing={hasNoImageUrls ? 0 : 4}>
        <Clickable onClick={() => handleOpen()}>
          <Image
            data-testid={testIds.vehicles.publishing('advertisement')}
            src={head(props.images) ?? thumbnailUrl}
            height={hasNoImageUrls ? 95 : 80}
            fit="cover"
          />
        </Clickable>
        <Hide when={hasNoImageUrls}>
          <Scroll auto width="100%">
            <HStack spacing={1}>
              {tail(props.images).map((url, index) => (
                <Clickable key={url} onClick={() => handleOpen(index + 1)}>
                  <Image
                    data-testid={testIds.vehicles.publishing(`advertisement-[${index}]`)}
                    src={url}
                    height={18}
                    width={28}
                    fit="cover"
                  />
                </Clickable>
              ))}
            </HStack>
          </Scroll>
        </Hide>
      </VStack>
      <Show when={isNotNilOrEmpty(props.images)}>
        <Lightbox
          data-testid={testIds.vehicles.publishing('advertisement')}
          controls={lightboxControls}
          images={props.images.map((src) => ({id: src, url: src.replace('resize', 'get')}))}
        />
      </Show>
    </>
  );
}
