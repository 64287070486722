import {union} from 'ramda';

import {AuditDataVideoAssetsFilesBody, GetAuditDataApiResponse} from '@omnetic-dms/shared';

import {AuditDataAssetsFilesBody} from '../../types/AuditDataAssetsFilesBody';
import {AuditCategoriesWithError, TConditionFormValue} from '../../types/ConditionTypes';
import {LoadAuditDataCategories} from '../../types/LoadAuditDataCategories';
import {LoadAuditDataResponseItemBody} from '../../types/LoadAuditDataResponseItemBody';
import {getFormFieldName} from '../../utils/getFormFieldName';
import {isJsonString} from '../../utils/isJsonString';

export type AuditFields = Record<string, TConditionFormValue>;
export type AuditAsset = Record<string, AuditDataAssetsFilesBody>;
export type AuditAssets = Record<string, AuditAsset>;
export type AuditVideoAsset = Record<string, AuditDataVideoAssetsFilesBody>;
export type AuditVideoAssets = Record<string, AuditVideoAsset>;
export type AuditAssetsCategoryMap = Record<string, string[]>;

export type AuditData = Omit<LoadAuditDataResponseItemBody, 'categories' | 'vehicleId'> & {
  fields: AuditFields;
  assets: AuditAssets;
  videoAssets: AuditVideoAssets;
  assetsCategoryMap: AuditAssetsCategoryMap;
  isNew?: boolean;
  errorCategories: AuditCategoriesWithError;
};

const getParsedValue = (value: string): TConditionFormValue => {
  if (isJsonString(value)) {
    const parsedValue = JSON.parse(value);
    if (typeof parsedValue === 'number') {
      return value;
    }

    return parsedValue;
  }

  return value;
};

const getFieldsAndAssetsData = (
  auditCategoriesData: LoadAuditDataCategories[]
): {
  assets: AuditAssets;
  assetsCategoryMap: AuditAssetsCategoryMap;
  videoAssets: AuditVideoAssets;
  fields: AuditFields;
} => {
  const fields: AuditFields = {};
  const assets: AuditAssets = {};
  const videoAssets: AuditVideoAssets = {};
  const assetsCategoryMap: AuditAssetsCategoryMap = {};

  auditCategoriesData.forEach((category) => {
    category.fields.forEach(({paramDefinitionId, value}) => {
      fields[getFormFieldName(category.id, paramDefinitionId)] = getParsedValue(value);
    });

    category.assets.forEach(({paramDefinitionId, files}) => {
      files.forEach((file) => {
        const name = getFormFieldName(category.id, paramDefinitionId);

        if (!assets?.[name]) {
          assets[name] = {};
        }

        if (file.imageId) {
          assets[name][file.imageId] = file;
        }
        assetsCategoryMap[category.id] = union(assetsCategoryMap[category.id] ?? [], [name]);
      });
    });

    category.videoAssets.forEach(({paramDefinitionId, videos}) => {
      videos.forEach((video) => {
        const name = getFormFieldName(category.id, paramDefinitionId);

        if (!videoAssets?.[name]) {
          videoAssets[name] = {};
        }

        if (video.videoId) {
          videoAssets[name][video.videoId] = video;
        }
        assetsCategoryMap[category.id] = union(assetsCategoryMap[category.id] ?? [], [name]);
      });
    });
  });

  return {
    fields,
    assets,
    videoAssets,
    assetsCategoryMap,
  };
};

export const getInitialAuditData = (audit?: LoadAuditDataResponseItemBody): AuditData | null => {
  if (!audit) {
    return null;
  }

  const {
    id,
    state,
    user,
    finishedAt,
    controlStructureId,
    updateAt,
    inspectionType,
    inspectionNote,
  } = audit;
  const {fields, assets, videoAssets, assetsCategoryMap} = getFieldsAndAssetsData(audit.categories);

  return {
    id,
    state,
    user,
    finishedAt,
    controlStructureId,
    updateAt,
    inspectionType,
    inspectionNote,
    fields,
    assets,
    videoAssets,
    assetsCategoryMap,
    errorCategories: {},
  };
};

export const getAuditDataFromAutoFillTemplate = (
  actualAudit: AuditData,
  templates: GetAuditDataApiResponse
): AuditData | null => {
  const autoFillAuditData = templates[0];

  if (!autoFillAuditData) {
    return null;
  }

  const {fields} = getFieldsAndAssetsData(autoFillAuditData.categories);

  return {
    ...actualAudit,
    fields: {
      ...actualAudit.fields,
      ...fields,
    },
  };
};
