import {
  PostIntegrationsForXentryLinkApiResponse,
  PostIntegrationsForXentryLinkApiArg,
  PostIntegrationsForXentryUnlinkApiResponse,
  PostIntegrationsForXentryUnlinkApiArg,
  PostAvailableXentryOrdersApiResponse,
  PostAvailableXentryOrdersApiArg,
  GetServiceCaseIntegrationsApiResponse,
  GetServiceCaseIntegrationsApiArg,
  PostServiceOrderAudatexTaskCalculationsApiResponse,
  PostServiceOrderAudatexTaskCalculationsApiArg,
  PostServiceOrderMyClaimCalculationsApiResponse,
  PostServiceOrderMyClaimCalculationsApiArg,
  PostIntegrationsForXentryLinkApiResponseSchema,
  PostIntegrationsForXentryLinkApiArgSchema,
  PostIntegrationsForXentryUnlinkApiResponseSchema,
  PostIntegrationsForXentryUnlinkApiArgSchema,
  PostAvailableXentryOrdersApiResponseSchema,
  PostAvailableXentryOrdersApiArgSchema,
  GetServiceCaseIntegrationsApiResponseSchema,
  GetServiceCaseIntegrationsApiArgSchema,
  PostServiceOrderAudatexTaskCalculationsApiResponseSchema,
  PostServiceOrderAudatexTaskCalculationsApiArgSchema,
  PostServiceOrderMyClaimCalculationsApiResponseSchema,
  PostServiceOrderMyClaimCalculationsApiArgSchema,
  PostServiceCaseIntegrationsXentryOrderManualCreationApiResponse,
  PostServiceCaseIntegrationsXentryOrderManualCreationApiArg,
  PostServiceCaseIntegrationsXentryOrderManualCreationApiResponseSchema,
  PostServiceCaseIntegrationsXentryOrderManualCreationApiArgSchema,
} from '../types/api/metadaWorkshopIntegration';
import {metadaApi} from './baseApi/metadaApi';

const metadaWorkshopIntegrationApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postIntegrationsForXentryLink: build.mutation<
      PostIntegrationsForXentryLinkApiResponse,
      PostIntegrationsForXentryLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/integration/xentry-order-link`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        'serviceOrderJobs',
      ],
      extraOptions: {
        responseSchema: PostIntegrationsForXentryLinkApiResponseSchema,
        requestSchema: PostIntegrationsForXentryLinkApiArgSchema,
      },
    }),
    postIntegrationsForXentryUnlink: build.mutation<
      PostIntegrationsForXentryUnlinkApiResponse,
      PostIntegrationsForXentryUnlinkApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/integration/xentry-order-unlink`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        'serviceOrderJobs',
      ],
      extraOptions: {
        responseSchema: PostIntegrationsForXentryUnlinkApiResponseSchema,
        requestSchema: PostIntegrationsForXentryUnlinkApiArgSchema,
      },
    }),
    postAvailableXentryOrders: build.mutation<
      PostAvailableXentryOrdersApiResponse,
      PostAvailableXentryOrdersApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/integration/xentry-orders`,
        method: 'POST',
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostAvailableXentryOrdersApiResponseSchema,
        requestSchema: PostAvailableXentryOrdersApiArgSchema,
      },
    }),
    getServiceCaseIntegrations: build.query<
      GetServiceCaseIntegrationsApiResponse,
      GetServiceCaseIntegrationsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/integrations`,
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetServiceCaseIntegrationsApiResponseSchema,
        requestSchema: GetServiceCaseIntegrationsApiArgSchema,
      },
    }),
    postServiceOrderAudatexTaskCalculations: build.mutation<
      PostServiceOrderAudatexTaskCalculationsApiResponse,
      PostServiceOrderAudatexTaskCalculationsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/integration/audatex-task-calculations`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'orderIntegration', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PostServiceOrderAudatexTaskCalculationsApiResponseSchema,
        requestSchema: PostServiceOrderAudatexTaskCalculationsApiArgSchema,
      },
    }),
    postServiceOrderMyClaimCalculations: build.mutation<
      PostServiceOrderMyClaimCalculationsApiResponse,
      PostServiceOrderMyClaimCalculationsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/integration/myclaim-calculations`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'orderIntegration', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PostServiceOrderMyClaimCalculationsApiResponseSchema,
        requestSchema: PostServiceOrderMyClaimCalculationsApiArgSchema,
      },
    }),
    postServiceCaseIntegrationsXentryOrderManualCreation: build.mutation<
      PostServiceCaseIntegrationsXentryOrderManualCreationApiResponse,
      PostServiceCaseIntegrationsXentryOrderManualCreationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/integration/xentry-order-manual-creation`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        'serviceOrderJobs',
      ],
      extraOptions: {
        responseSchema: PostServiceCaseIntegrationsXentryOrderManualCreationApiResponseSchema,
        requestSchema: PostServiceCaseIntegrationsXentryOrderManualCreationApiArgSchema,
      },
    }),
  }),
});
export const {
  usePostIntegrationsForXentryLinkMutation,
  usePostIntegrationsForXentryUnlinkMutation,
  usePostAvailableXentryOrdersMutation,
  useGetServiceCaseIntegrationsQuery,
  usePostServiceOrderAudatexTaskCalculationsMutation,
  usePostServiceOrderMyClaimCalculationsMutation,
  usePostServiceCaseIntegrationsXentryOrderManualCreationMutation,
} = metadaWorkshopIntegrationApi;
