import {
  GetArticleRequest,
  GetArticleResponse,
  DeleteArticleResponse,
  DeleteArticleRequest,
  PatchArticleDefaultSupplierResponse,
  PatchArticleDefaultSupplierRequest,
  PatchArticleInfoResponse,
  PatchArticleInfoRequest,
  PatchArticleInfoRequestSchema,
  PatchArticleDefaultSupplierRequestSchema,
  DeleteArticleRequestSchema,
  GetArticleRequestSchema,
  GetArticleResponseSchema,
  GetSupplierArticleResponse,
  GetSupplierArticleRequest,
  GetSupplierArticleRequestSchema,
  GetSupplierArticleResponseSchema,
  GetArticleIdByManufacturerNumberAndManufacturerIdRequest,
  GetArticleIdByManufacturerNumberAndManufacturerIdResponse,
  GetArticleIdByManufacturerNumberAndManufacturerIdRequestSchema,
  GetArticleIdByManufacturerNumberAndManufacturerIdResponseSchema,
  DeleteArticleResponseSchema,
  PatchArticleDefatilSupplierResponseSchema,
  PatchArticleInfoResponseSchema,
  PostArticleResponse,
  PostArticleRequest,
  PostArticleRequestSchema,
  PostArticleResponseSchema,
  PatchArticleResponse,
  PatchArticleRequest,
  PatchArticleRequestSchema,
  PatchArticleResponseSchema,
  PutArticleCalculationResponse,
  PutArticleCalculationRequest,
  PutArticleCalculationRequestSchema,
  PutArticleCalculationResponseSchema,
} from '../types/api/metadaWarehouseArticle';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseArticleApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getArticle: build.query<GetArticleResponse, GetArticleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article/${queryArg.articleId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'article', id: queryArg.articleId}],
      extraOptions: {
        requestSchema: GetArticleRequestSchema,
        responseSchema: GetArticleResponseSchema,
      },
    }),
    postArticle: build.mutation<PostArticleResponse, PostArticleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['article'],
      extraOptions: {
        requestSchema: PostArticleRequestSchema,
        responseSchema: PostArticleResponseSchema,
      },
    }),
    patchArticle: build.mutation<PatchArticleResponse, PatchArticleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article/${queryArg.articleId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'article', id: queryArg.articleId}],
      extraOptions: {
        requestSchema: PatchArticleRequestSchema,
        responseSchema: PatchArticleResponseSchema,
      },
    }),
    putArticleCalculation: build.mutation<
      PutArticleCalculationResponse,
      PutArticleCalculationRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article/${queryArg.articleId}/calculation`,
        method: 'PUT',
        body: queryArg.body,
        signal: queryArg.signal,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'article', id: queryArg.articleId}],
      extraOptions: {
        requestSchema: PutArticleCalculationRequestSchema,
        responseSchema: PutArticleCalculationResponseSchema,
      },
    }),
    deleteArticle: build.mutation<DeleteArticleResponse, DeleteArticleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/article/bulk-delete`,
        method: 'PATCH',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: ['article'],
      extraOptions: {
        requestSchema: DeleteArticleRequestSchema,
        responseSchema: DeleteArticleResponseSchema,
      },
    }),
    getArticleIdByManufacturerNumberAndManufacturerId: build.query<
      GetArticleIdByManufacturerNumberAndManufacturerIdResponse,
      GetArticleIdByManufacturerNumberAndManufacturerIdRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/manufacturer-id/${queryArg.manufacturerId}/manufacturer-number`,
        params: {manufacturerNumber: queryArg.manufacturerNumber},
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetArticleIdByManufacturerNumberAndManufacturerIdRequestSchema,
        responseSchema: GetArticleIdByManufacturerNumberAndManufacturerIdResponseSchema,
      },
    }),
    patchArticleDefaultSupplier: build.mutation<
      PatchArticleDefaultSupplierResponse,
      PatchArticleDefaultSupplierRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/article/${queryArg.articleId}/set-default-supplier`,
        body: queryArg.body,
        method: 'PATCH',
        queryArg,
      }),
      extraOptions: {
        requestSchema: PatchArticleDefaultSupplierRequestSchema,
        responseSchema: PatchArticleDefatilSupplierResponseSchema,
      },
    }),
    patchArticleInfo: build.mutation<PatchArticleInfoResponse, PatchArticleInfoRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/article/${queryArg.articleId}/update-article-info`,
        body: queryArg.body,
        method: 'PATCH',
        queryArg,
      }),
      extraOptions: {
        requestSchema: PatchArticleInfoRequestSchema,
        responseSchema: PatchArticleInfoResponseSchema,
      },
    }),
    getSupplierArticle: build.query<GetSupplierArticleResponse, GetSupplierArticleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse-delivery-quantity-mapping/${queryArg.deliveryQuantityMappingId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: ['supplierArticles'],
      extraOptions: {
        requestSchema: GetSupplierArticleRequestSchema,
        responseSchema: GetSupplierArticleResponseSchema,
      },
    }),
  }),
});

export const {
  useGetArticleQuery,
  useLazyGetArticleQuery,
  usePostArticleMutation,
  usePatchArticleMutation,
  useDeleteArticleMutation,
  useGetArticleIdByManufacturerNumberAndManufacturerIdQuery,
  useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery,
  usePutArticleCalculationMutation,
  usePatchArticleDefaultSupplierMutation,
  usePatchArticleInfoMutation,
  useGetSupplierArticleQuery,
} = metadaWarehouseArticleApi;
