import {Card, Form, FormField, FormSubmitHandler} from 'platform/components';
import {Grid, Space} from 'platform/foundation';
import {object} from 'yup';

import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  usePostMeasurementUnitLabourOperationMutation,
  useGetMeasurementUnitLabourOperationByIdQuery,
  usePatchMeasurementUnitLabourOperationMutation,
  handleApiError,
  EMPTY_PLACEHOLDER,
  useGetTenantQuery,
  useGetAuthorizationProfilesQuery,
} from '@omnetic-dms/shared';

import {useNavigate, yupNumber, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type MeasurementUnitFormType = {
  authorizationProfileId: string;
  mulo: string;
  conversionRatio: number;
};

export function WorkshopMeasurementUnitDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {data: tenant} = useGetTenantQuery();

  const isCreating = isNil(id);

  const {data, isLoading, isError} = useGetMeasurementUnitLabourOperationByIdQuery(
    {muloId: id ?? ''},
    {skip: isCreating}
  );
  const {data: authorizationProfiles, isLoading: isAuthorizationProfilesLoading} =
    useGetAuthorizationProfilesQuery(
      {'x-tenant': tenant?.id ?? ''},
      {
        skip: isNil(tenant),
        selectFromResult: (result) => ({
          ...result,
          data: result.data?.map((profile) => ({
            label: profile?.name,
            value: profile?.id,
          })),
        }),
      }
    );
  const [postMeasurementUnitLabourOperation] = usePostMeasurementUnitLabourOperationMutation();
  const [patchMeasurementUnitLabourOperation] = usePatchMeasurementUnitLabourOperationMutation();

  const handleSubmit: FormSubmitHandler<MeasurementUnitFormType> = (data) => {
    const request = isCreating
      ? postMeasurementUnitLabourOperation({body: data})
      : patchMeasurementUnitLabourOperation({muloId: id ?? '', body: data});

    return request
      .unwrap()
      .then(() => navigate(settingsRoutes.workshopMeasurementUnits))
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        title: isCreating
          ? i18n.t('page.settings.labels.newMeasurementUnit')
          : (authorizationProfiles?.find(
              (profile) => profile.value === data?.authorizationProfileId
            )?.label ?? EMPTY_PLACEHOLDER),
        breadcrumbs: [
          {
            label: i18n.t('page.settings.labels.measurementUnits'),
            href: settingsRoutes.workshopMeasurementUnits,
          },
        ],
      }}
      isError={isError}
      isLoading={isLoading}
      isCreating={isCreating}
      data-testid={testIds.settings.workshopMeasurementUnitDetail('template')}
    >
      <Form<MeasurementUnitFormType>
        defaultValues={{
          mulo: data?.mulo,
          conversionRatio: data?.conversionRatio,
          authorizationProfileId: data?.authorizationProfileId,
        }}
        onSubmit={handleSubmit}
        schema={formSchema}
      >
        {(control) => (
          <SettingsSection>
            <Grid columns={2} spacing={4}>
              <FormField
                control={control}
                name="authorizationProfileId"
                type="choice"
                isRequired
                options={authorizationProfiles}
                isLoading={isAuthorizationProfilesLoading}
                label={i18n.t('entity.settings.labels.authorizationProfile')}
                data-testid={testIds.settings.workshopMeasurementUnitDetail('authorizationProfile')}
              />
              <Space vertical={6} />
              <Card title={i18n.t('entity.settings.labels.converter')} variant="inlineGrey">
                <FormField
                  control={control}
                  name="mulo"
                  type="text"
                  isRequired
                  label={i18n.t('entity.settings.labels.measurementUnitOfLabourOperation')}
                  data-testid={testIds.settings.workshopMeasurementUnitDetail('mulo')}
                />
                <Space vertical={4} />
                <FormField
                  control={control}
                  name="conversionRatio"
                  type="number"
                  isRequired
                  label={i18n.t('entity.settings.labels.coversionRatio')}
                  helperText={i18n.t('entity.settings.labels.coversionRatioHelperText')}
                  data-testid={testIds.settings.workshopMeasurementUnitDetail('conversionRatio')}
                />
              </Card>
              <SettingsFooter
                actions={[
                  {
                    type: 'button',
                    variant: 'secondary',
                    title: i18n.t('general.actions.discard'),
                    onClick: () => navigate(settingsRoutes.workshopMeasurementUnits),
                  },
                  {
                    control,
                    type: 'form-button',
                    buttonType: 'submit',
                    title: isCreating
                      ? i18n.t('general.actions.create')
                      : i18n.t('general.actions.saveChanges'),
                  },
                ]}
              />
            </Grid>
          </SettingsSection>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const formSchema = object({
  authorizationProfileId: yupString.required(),
  mulo: yupString.required().max(10),
  conversionRatio: yupNumber.required(),
});
