import {
  Button,
  Card,
  Form,
  FormField,
  OptionType,
  ButtonGroup,
  DataStatus,
  FormSubmitHandler,
  showNotification,
  FormButton,
} from 'platform/components';
import {Box, HStack, Space, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter} from 'platform/locale';
import {array, boolean, object} from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {defaultTo, isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  getDecimalFromPercentage,
  getPercentageFromDecimal,
  handleApiError,
  PatchCustomerContractIndividualPriceLabourApiArg,
  useGetCustomerContractIndividualPriceLabourQuery,
  useGetLabourCatalogGridFilterCatalogCategoryQuery,
  useGetLabourCatalogItemQuery,
  useGetTenantQuery,
  useLazyGetLabourCatalogItemByCatalogNumberQuery,
  usePatchCustomerContractIndividualPriceLabourMutation,
  usePostCustomerContractIndividualPriceLabourMutation,
} from '@omnetic-dms/shared';

import {CurrencyCodeType, suffixTestId, yupNumber, yupString, TestIdProps} from 'shared';

import {IndividualWorkPriceFormType} from '../types/IndividualWorkPriceFormType';
import {IndividualWorkPriceAllowedVariant} from './IndividualWorkPriceAllowedVariant';

interface IndividualWorkPriceFormProps extends TestIdProps {
  individualPriceLabourId?: string;
  customerContractId?: string;
  onClose: VoidFunction;
  afterSubmit: VoidFunction;
}

export function IndividualWorkPriceForm(props: IndividualWorkPriceFormProps) {
  const formatCurrencySymbol = useCurrencySymbolFormatter();

  const {data, isLoading, isError} = useGetCustomerContractIndividualPriceLabourQuery(
    {
      customerContractId: props.customerContractId ?? '',
      individualPriceLabourId: props.individualPriceLabourId ?? '',
    },
    {skip: isNil(props.customerContractId) || isNil(props.individualPriceLabourId)}
  );
  const {data: tenant} = useGetTenantQuery();
  const {data: catalogues, isLoading: isCataloguesLoading} =
    useGetLabourCatalogGridFilterCatalogCategoryQuery();
  const {data: labourItem, isLoading: isLabourItemLoading} = useGetLabourCatalogItemQuery(
    {labourCatalogItemId: data?.labourCatalogId ?? ''},
    {skip: !data}
  );

  const [patchIndividualLabourPrice] = usePatchCustomerContractIndividualPriceLabourMutation();
  const [postIndividualLabourPrice] = usePostCustomerContractIndividualPriceLabourMutation();

  const [getLaboutCatalogItem] = useLazyGetLabourCatalogItemByCatalogNumberQuery();

  const handleCheckItem = (formApi: UseFormReturn<IndividualWorkPriceFormType>) => {
    const labourCatalogId = formApi.getValues('labourCatalogCategoryId');
    const catalogNumber = formApi.getValues('catalogNumber');

    if (isNil(labourCatalogId) || isNil(catalogNumber)) {
      return;
    }

    getLaboutCatalogItem({
      catalogNumber,
      labourCatalogId,
    })
      .unwrap()
      .then((item) => {
        if (isNil(item)) {
          return formApi.setError('catalogNumber', {
            message: i18n.t('entity.customerContract.labels.workPositionError'),
          });
        }

        showNotification.success(i18n.t('entity.customerContract.labels.workPositionFound'));
        formApi.clearErrors('catalogNumber');
        formApi.setValue('name', item?.name ?? undefined);
      })
      .catch(handleApiError);
  };

  const handleSubmit: FormSubmitHandler<IndividualWorkPriceFormType> = async (data, setErrors) => {
    const labourCatalogId = await getLaboutCatalogItem({
      catalogNumber: data.catalogNumber ?? '',
      labourCatalogId: data.labourCatalogCategoryId ?? '',
    })
      .unwrap()
      .then((item) => {
        if (isNil(item)) {
          return setErrors([
            {
              name: 'catalogNumber',
              message: i18n.t('entity.customerContract.labels.workPositionError'),
            },
          ]);
        }

        return item?.id;
      })
      .catch(handleApiError);

    if (!labourCatalogId) {
      return;
    }

    const body: PatchCustomerContractIndividualPriceLabourApiArg['body'] = {
      labourCatalogId,
      discountSettingsType: data.discountSettingsType ?? 'DISCOUNT_PERCENTAGE',
      discountSettingsValue:
        data.discountSettingsType === 'DISCOUNT_PERCENTAGE' && isNotNil(data.discountSettingsValue)
          ? (getDecimalFromPercentage(data.discountSettingsValue) ?? 0)
          : (data.discountSettingsValue ?? 0),
      isDirectSaleVariant: data.isDirectSaleVariant,
      directSaleVariantIds: data.isDirectSaleVariant
        ? data.directSaleVariantIds?.filter(isNotNil)
        : undefined,
      isServiceOrderVariant: data.isServiceOrderVariant,
      serviceOrderVariantIds: data.isServiceOrderVariant
        ? data.serviceOrderVariantIds?.filter(isNotNil)
        : undefined,
      labourCatalogCategoryId: data.labourCatalogCategoryId ?? '',
    };

    await (
      isNotNil(props.individualPriceLabourId)
        ? patchIndividualLabourPrice({
            customerContractId: props.customerContractId ?? '',
            individualPriceLabourId: props.individualPriceLabourId ?? '',
            body,
          })
        : postIndividualLabourPrice({
            customerContractId: props.customerContractId ?? '',
            body,
          })
    )
      .unwrap()
      .then(() => {
        props.onClose();
        props.afterSubmit();
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isLoading || isLabourItemLoading} isError={isError} minHeight={120}>
      <Form<IndividualWorkPriceFormType>
        defaultValues={{
          ...data,
          discountSettingsType: data?.discountSettingsType ?? 'DISCOUNT_PERCENTAGE',
          discountSettingsValue:
            data?.discountSettingsType === 'DISCOUNT_PERCENTAGE' &&
            isNotNil(data?.discountSettingsValue)
              ? getPercentageFromDecimal(data?.discountSettingsValue)
              : data?.discountSettingsValue,
          isServiceOrderVariant: defaultTo(true)(data?.isServiceOrderVariant),
          isDirectSaleVariant: defaultTo(true)(data?.isDirectSaleVariant),
          catalogNumber: labourItem?.number ?? '',
          name: labourItem?.name ?? '',
        }}
        schema={formSchema}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  name="labourCatalogCategoryId"
                  type="choice"
                  label={i18n.t('entity.addWork.lables.catalogue')}
                  isRequired
                  options={catalogues}
                  isLoading={isCataloguesLoading}
                  data-testid={suffixTestId('catalog', props)}
                />
              </Box>
              <Space fillAvailable />
            </HStack>
            <HStack spacing={4} align="flex-end">
              <Box flex={1}>
                <FormField
                  control={control}
                  name="catalogNumber"
                  type="text"
                  label={i18n.t('entity.warehouse.labels.catalogueNumber')}
                  isRequired
                  isDisabled={isNil(formApi.watch('labourCatalogCategoryId'))}
                  data-testid={suffixTestId('catalogNumber', props)}
                />
              </Box>
              <Box flex={1}>
                <Button
                  title={i18n.t('general.labels.checkItem')}
                  variant="secondary"
                  onClick={() => handleCheckItem(formApi)}
                  isDisabled={isNil(formApi.watch('catalogNumber'))}
                  data-testid={suffixTestId('checkItem', props)}
                />
                <Space fillAvailable />
              </Box>
            </HStack>

            <FormField
              control={control}
              name="name"
              type="text"
              label={i18n.t('general.labels.name')}
              isDisabled
              data-testid={suffixTestId('name', props)}
            />

            <IndividualWorkPriceAllowedVariant
              control={control}
              data-testid={suffixTestId('individualWorkPriceAllowedVariant', props)}
            />
            <Card
              variant="inlineGrey"
              title={i18n.t('entity.workshopCustomerGroup.labels.discountSettings')}
            >
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="discountSettingsType"
                    type="radio"
                    options={getDiscountOptions(
                      formatCurrencySymbol(tenant?.currency as CurrencyCodeType)
                    )}
                    direction="column"
                    spacing={4}
                    data-testid={suffixTestId('discountSettingsType', props)}
                  />
                  <Space vertical={4} />
                  <FormField
                    control={control}
                    name="discountSettingsValue"
                    type="number"
                    isStepperVisible
                    minStepperValue={0}
                    decimalPlaces={2}
                    helperText={i18n.t('entity.customerContract.labels.discountDescription')}
                    data-testid={suffixTestId('discountSettingsValue', props)}
                  />
                </Box>
                <Space fillAvailable />
              </HStack>
            </Card>
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discard')}
                onClick={props.onClose}
                variant="secondary"
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                control={control}
                title={
                  isNotNil(props.individualPriceLabourId)
                    ? i18n.t('general.actions.saveChanges')
                    : i18n.t('general.actions.create')
                }
                type="submit"
                data-testid={suffixTestId('submit', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const formSchema = object({
  labourCatalogCategoryId: yupString.required(),
  catalogNumber: yupString.required(),
  serviceOrderVariantIds: array().of(yupString).optional(),
  discountSettingsValue: yupNumber.when('discountSettingsType', {
    is: 'DISCOUNT_PERCENTAGE',
    then: yupNumber.min(0).max(100).required(),
    otherwise: yupNumber.min(0).required(),
  }),
  isServiceOrderVariant: boolean()
    .required()
    .test(
      'atLeastOneTrueServiceOrderVariant',
      i18n.t('entity.customerContract.labels.variantValidation'),
      (value, context) => value || context.parent.isDirectSaleVariant
    ),
  isDirectSaleVariant: boolean()
    .required()
    .test(
      'atLeastOneTrueDirectSaleVariant',
      i18n.t('entity.customerContract.labels.variantValidation'),
      (value, context) => value || context.parent.isServiceOrderVariant
    ),
});

const getDiscountOptions = (
  currency: string
): OptionType<IndividualWorkPriceFormType['discountSettingsType']>[] => [
  {
    value: 'DISCOUNT_PERCENTAGE',
    label: i18n.t('entity.customerContract.labels.percentageDiscount'),
  },
  {
    value: 'DISCOUNT_HOURLY_RATE',
    label: i18n.t('entity.customerContract.labels.discountedHourlyRate', {currency}),
  },
  {
    value: 'DISCOUNT_FIXED_PRICE',
    label: i18n.t('entity.customerContract.labels.fixedPrice', {currency}),
  },
];
