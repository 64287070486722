import {createApi} from '@reduxjs/toolkit/query/react';

import {metadaBaseQuery} from '../baseQuery/metadaBaseQuery';

export const metadaApi = createApi({
  baseQuery: metadaBaseQuery,
  reducerPath: 'rtk_metadaApi',
  tagTypes: [
    'serviceCase',
    'orderVariants',
    'serviceOrder',
    'serviceOrderCommercial',
    'serviceOrderComplaint',
    'serviceOrderContract',
    'serviceOrderInsurance',
    'serviceOrderInternal',
    'serviceOrderWarranty',
    'carisExport',
    'serviceOrderVariants',
    'handOver',
    'checkIn',
    'serviceJobs',
    'dgv4-preset',
    'company',
    'warehouses',
    'warehouse',
    'accountingDictionary',
    'suppliers',
    'employeeLink',
    'supplierOrders',
    'supplierOrderVariants',
    'manufacturers',
    'priceList',
    'stockSvailabilityId',
    'serviceCaseType',
    'serviceJob',
    'serviceJobActions',
    'serviceOrders',
    'serviceOrderJobs',
    'serviceOrderJob',
    'serviceOrderType',
    'serviceOrderVariant',
    'workSplitting',
    'applicationLawList',
    'costCentersList',
    'insuranceCompaniesList',
    'insuranceTypesList',
    'providersList',
    'technicansList',
    'labourCatalogItem',
    'workBasketItems',
    'catalogCategoryList',
    'workClassificationList',
    'workType',
    'workTypeList',
    'workBasketItem',
    'dictionary',
    'postponeJob',
    'postponeJobs',
    'orderActions',
    'vehicleCustomer',
    'orderDiscount',
    'serviceCaseTotalAmount',
    'serviceOrderTotalAmount',
    'serviceOrderJobTotalAmount',
    'authorizationProfiles',
    'serviceActions',
    'materialBasketItems',
    'itemsBasketActions',
    'employeeOrganisationalData',
    'employeePersonalData',
    'enums',
    'employeeActions',
    'checkout',
    'goodwillItemRatio',
    'employeeCompetence',
    'employeeCompetences',
    'employeeCostCenter',
    'employeeCostCenters',
    'vehicleGroup',
    'vehicleGroups',
    'employeeWorkTeam',
    'employeeWorkTeams',
    'employeeProfession',
    'employeeProfessions',
    'tireSet',
    'tireOrder',
    'materialDiscountGroup',
    'materialDiscountGroupItem',
    'tireManufacturers',
    'tireLocations',
    'abroadSale',
    'serviceOrderMandatoryFields',
    'serviceOrderCheckoutRestriction',
    // Warehouse
    'receiveNote',
    'deliveryNote',
    'receiveNoteCorrections',
    'receiveNoteCorrectionBasket',
    'receiveNoteCorrectionItems',
    'article',
    'availabilityArticle',
    'articleCategory',
    'partsRequest',
    'receiveNoteTotalPrice',
    'receiveNoteItemNew',
    'receiveNoteCorrectionActions',
    'supportedPriceList',
    'handlingUnits',
    'directSaleVariants',
    'directSales',
    'directSaleMaterialItems',
    'directSaleLabourItems',
    'directSaleMaterialBasket',
    'directSaleLabourBasket',
    'partsRequestReturnBasket',
    'workRateTypes',
    'materialPriceTypes',
    'directSaleTypes',
    'reservations',
    'directSaleDiscount',
    'serviceOrderIssueVariants',
    'serviceOrderIssueNotes',
    'serviceOrderIssueNoteBasket',
    'issueNotes',
    'supplerOrderVariants',
    'supplerOrderVariantSuppliers',
    'supplierOrderItem',
    'supplierOrderMaterialBasket',
    'supplierOrderType',
    'supplierArticles',
    // Insurance
    'insurance',
    'orderProfitability',
    'labourCatalogCategory',
    'treeFolder',
    'minorUnitMaterial',
    'materialDiscountGroups',
    'materialDiscountGroupDiscounts',
    'customerContract',
    'customerContractLabourDiscount',
    'customerContractMaterialDiscount',
    'invidualPriceLabour',
    'invidualPriceMaterial',
    'customerContractAssignedCustomer',
    'customerContractAssignedCustomerValidity',
    'profitabilityHistory',
    'tiresInventoryGeneralSettings',
    'orderCustomerContract',
    'customerCustomerContracts',
    'generalSettingsStorageLocation',
    'vehicleCampaign',
    'materialOrderItem',
    'customerPaymentSettings',
    'packageCategory',
    'servicePackage',
    'serviceRequestDetail',
    'orderIntegration',
    'servicePackageLabourItem',
    'servicePackageMaterialItem',
    'servicePackageLabourItems',
    'servicePackageMaterialItems',
    'servicePackages',
    'servicePackageCategories',
    'measurementUnit',
    'mandatoryFields',
    'labourRate',
    'xentryOrders',
    'previousRequestsCount',
    'tireIds',
    'paymentTypes',
    'serviceItemsExternalHistory',
  ],
  endpoints: () => ({}),
});
