import i18n from '@omnetic-dms/i18n';
import {DefinitionValueType} from '@omnetic-dms/shared';

export const VALUE_TYPE_OPTIONS: {value: DefinitionValueType; label: string}[] = [
  {value: 'integer', label: i18n.t('page.generalSettings.labels.integer')},
  {value: 'string', label: i18n.t('page.generalSettings.labels.string')},
  {value: 'money', label: i18n.t('page.generalSettings.labels.money')},
  {value: 'date', label: i18n.t('page.generalSettings.labels.date')},
  {value: 'datetime', label: i18n.t('page.generalSettings.labels.datetime')},
  // TODO: will be added back with https://carvago.atlassian.net/browse/T20-59204
  // {value: 'boolean', label: i18n.t('page.generalSettings.labels.boolean')},
  {value: 'decimal', label: i18n.t('page.generalSettings.labels.decimal')},
] as const;
