import {parseISO} from 'date-fns';
import {Column, DataStatus, Flag, IconButton, Label, Table, TableRow} from 'platform/components';
import {Box, HStack, Hide, Link, Show, Text} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';

import {isEmpty, isNil, isNotNil} from 'ramda';
import {isFalse, isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  EMPTY_PLACEHOLDER,
  InvoiceProformaPaymentResponseBody,
  OrderPaymentResponseBody,
  OrderResponseBody,
  handleApiError,
  useGetBusinessCaseQuery,
  useGetInvoicePaymentListQuery,
  useGetInvoiceProformaPaymentListQuery,
  useGetOrderQuery,
  useRenderCorrectiveTaxDocumentMutation,
  useRenderExpenseCashReceiptDocumentMutation,
  useRenderIncomeCashReceiptDocumentMutation,
  useRenderTaxDocumentForPaymentDocumentMutation,
  useWithdrawalPaymentMutation,
  useWithdrawalPurchasePaymentMutation,
} from '@omnetic-dms/shared';

import {
  TestIdProps,
  buildArray,
  convertStringToCamelCase,
  openFile,
  suffixTestId,
  useRequiredParams,
} from 'shared';

import {useGetCreateTaxDocumentForPayment} from '../../PaymentForm/hooks/useGetCreateTaxDocumentForPayment';

interface PaymentsTableProps extends TestIdProps {
  payment: OrderPaymentResponseBody;
  orderDiscriminator: OrderResponseBody['orderDiscriminator'];
  checkoutId: string;
  orderId: string;
}

export function PaymentsTable(props: PaymentsTableProps) {
  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useFormatCurrency();

  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});

  const isBusinessCaseClosed = businessCase?.businessCaseState === 'CLOSED';

  const [withdrawPayment, {isLoading: isWithdrawalPending}] = useWithdrawalPaymentMutation();
  const [withdrawPurchasePayment] = useWithdrawalPurchasePaymentMutation();

  const {data: order} = useGetOrderQuery({checkoutId: props.checkoutId, orderId: props.orderId});

  const {
    data: proformaPayments,
    isLoading: isLoadingProformaPayments,
    isError: isErrorProformaPayments,
  } = useGetInvoiceProformaPaymentListQuery(
    {invoiceId: props.payment.proformaInvoiceId ?? ''},
    {skip: isNil(props.payment.proformaInvoiceId)}
  );

  const {
    data: invoicePayments,
    isLoading: isLoadingInvoicePayments,
    isError: isErrorInvoicePayments,
  } = useGetInvoicePaymentListQuery(
    {invoiceId: props.payment.invoiceId ?? ''},
    {skip: isNil(props.payment.invoiceId)}
  );

  const [createTaxDocumentForPayment, taxDocumentForPaymentIssuedType] =
    useGetCreateTaxDocumentForPayment(order, businessCaseId);

  const isPaidPurchasePayment =
    props.payment.paymentDiscriminator === 'PURCHASE' && props.payment.paymentState === 'PAID';

  const [renderTaxDocumentForPayment, {isLoading: isRenderingTaxDocumentForPayment}] =
    useRenderTaxDocumentForPaymentDocumentMutation();

  const [renderIncomeCashReceipt, {isLoading: isRenderingIncomeCashReceipt}] =
    useRenderIncomeCashReceiptDocumentMutation();

  const [renderCorrectiveTaxDocument, {isLoading: isRenderingCorrectiveTaxDocument}] =
    useRenderCorrectiveTaxDocumentMutation();

  const [renderExpenseCashReceipt, {isLoading: isRenderingExpenseCashReceipt}] =
    useRenderExpenseCashReceiptDocumentMutation();

  const openTaxDocumentForPayment = (documentId: string) =>
    renderTaxDocumentForPayment({renderTaxDocumentForPaymentDocumentRequestBody: {documentId}})
      .unwrap()
      .then((data) => data.pdfUrl)
      .then(openFile)
      .catch(handleApiError);

  const openCashReceipt = (cashRegisterDocumentId: string) => {
    const renderAction =
      props.orderDiscriminator === 'PURCHASE' ? renderExpenseCashReceipt : renderIncomeCashReceipt;

    renderAction({
      renderExpenseCashReceiptDocumentRequestBody: {cashRegisterDocumentId},
      renderIncomeCashReceiptDocumentRequestBody: {cashRegisterDocumentId},
    })
      .unwrap()
      .then((data) => data.pdfUrl)
      .then(openFile)
      .catch(handleApiError);
  };

  const openCorrectiveTaxDocument = (documentId: string) =>
    renderCorrectiveTaxDocument({renderCorrectiveTaxDocumentRequestBody: {documentId}})
      .unwrap()
      .then((data) => data.pdfUrl)
      .then(openFile)
      .catch(handleApiError);

  const handleWithdrawPayment = (transactionId: string) => {
    withdrawPayment({
      checkoutId: props.checkoutId,
      orderId: props.orderId,
      paymentId: props.payment.id,
      prescriptionId: transactionId,
    })
      .unwrap()
      .then()
      .catch(handleApiError);
  };

  const handleWithdrawPurchasePayment = () => {
    withdrawPurchasePayment({
      checkoutId: props.checkoutId,
      orderId: props.orderId,
      paymentId: props.payment.id,
    })
      .unwrap()
      .then()
      .catch(handleApiError);
  };

  const isPaymentDeposit = props.payment.paymentDiscriminator === 'DEPOSIT';
  const isLoading = isLoadingProformaPayments || isLoadingInvoicePayments;
  const isError = isErrorProformaPayments || isErrorInvoicePayments;
  const isRendering =
    isRenderingTaxDocumentForPayment ||
    isRenderingIncomeCashReceipt ||
    isRenderingExpenseCashReceipt ||
    isRenderingCorrectiveTaxDocument;

  const payments = [...(proformaPayments ?? []), ...(invoicePayments ?? [])];
  const arePaymentsEmpty = isNilOrEmpty(payments) && !isPaidPurchasePayment;

  const isAnyBalanceIssued = order?.payments?.some(
    (item) => item.paymentDiscriminator === 'BALANCE' && item.paymentState !== 'CONCEPT'
  );

  const columns = buildArray<Column>()
    .when(isFalse(isPaidPurchasePayment), {
      width: 20,
      element: (
        <Box paddingHorizontal={2}>
          <Label>{i18n.t('general.labels.type')}</Label>
        </Box>
      ),
    })
    .add({
      width: 35,
      element: (
        <Box paddingHorizontal={2}>
          <Label>{i18n.t('entity.bank.labels.paymentType')}</Label>
        </Box>
      ),
    })
    .add({
      width: 35,
      element: (
        <Box paddingHorizontal={2}>
          <Label>{i18n.t('entity.checkout.labels.amount')}</Label>
        </Box>
      ),
    })
    .add({
      width: 30,
      element: (
        <Box paddingHorizontal={2}>
          <Label>{i18n.t('entity.invoice.labels.dateOfPayment')}</Label>
        </Box>
      ),
    })
    .add({
      element: (
        <Box paddingHorizontal={2}>
          <Label>{i18n.t('entity.checkout.labels.file')}</Label>
        </Box>
      ),
    })
    .add({
      width: 18,
    });

  return (
    <DataStatus isEmpty={arePaymentsEmpty} isLoading={isLoading} isError={isError} spacing={5}>
      <Table
        tableLayout="fixed"
        columns={columns}
        data-testid={suffixTestId('payment-table', props)}
      >
        {payments?.map((item) => {
          const isPaymentCancelledOrCancelling =
            isNotNil(item.cancelingTransactionId) || isNotNil(item.cancelledTransactionId);
          const shouldHideRowActions =
            (isPaymentDeposit && isAnyBalanceIssued) ||
            isPaymentCancelledOrCancelling ||
            isBusinessCaseClosed;
          const hasCashReceiptId = isNotNil(item.cashRegisterDocumentId);
          const hasTaxDocumentForPaymentId = hasTaxDocumentForPayment(item);
          const hasCorrectiveTaxDocumentId = isNotNil(
            item.correctiveTaxDocumentForTaxDocumentForPaymentId
          );

          return (
            <TableRow key={item.paymentId} data-testid={suffixTestId('table-row', props)}>
              <Box padding={2}>
                <Flag
                  label={i18n.t(`entity.checkout.labels.${item.source}`)}
                  colorScheme={item.source === 'external' ? 'yellow' : 'green'}
                  isSubtle
                  data-testid={suffixTestId('transaction-source', props)}
                />
              </Box>

              <Box padding={2}>
                <Text size="small">
                  {i18n.t(
                    `entity.invoice.paymentMethod.${convertStringToCamelCase(item.paymentType)}`
                  )}
                </Text>
              </Box>
              <Box padding={2}>
                <Text size="small">
                  {formatCurrency(parseFloat(item.amount.amount), item.amount.currency, 2)}
                </Text>
              </Box>
              <Box padding={2}>
                <Text size="small">{formatDateTime('dateShort', parseISO(item.paymentDate))}</Text>
              </Box>

              <Box padding={2}>
                {hasTaxDocumentForPaymentId && (
                  <Link
                    isDisabled={isRendering}
                    size="small"
                    title={`${i18n.t('entity.accounting.labels.taxDocumentForPayment')} ${
                      item.taxDocumentForPaymentNumber
                    }`}
                    onClick={() => openTaxDocumentForPayment(item.taxDocumentForPaymentId!)}
                    data-testid={suffixTestId('transaction-taxDocumentForPayment', props)}
                  />
                )}

                <Show when={hasCashReceiptId}>
                  <Link
                    isDisabled={isRendering}
                    size="small"
                    title={`${i18n.t('entity.checkout.labels.cashReceipt')} ${
                      item.cashRegisterDocumentNumber
                    }`}
                    onClick={() => openCashReceipt(item.cashRegisterDocumentId!)}
                    data-testid={suffixTestId('transaction-cashReceipt', props)}
                  />
                </Show>

                <Show when={hasCorrectiveTaxDocumentId}>
                  <Link
                    isDisabled={isRendering}
                    size="small"
                    title={`${i18n.t('entity.checkout.labels.correctiveTaxDocument')} ${
                      item.correctiveTaxDocumentForTaxDocumentForPaymentNumber
                    }`}
                    onClick={() =>
                      openCorrectiveTaxDocument(
                        item.correctiveTaxDocumentForTaxDocumentForPaymentId!
                      )
                    }
                    data-testid={suffixTestId('transaction-correctiveTaxDocument', props)}
                  />
                </Show>

                <Hide
                  when={
                    hasTaxDocumentForPaymentId || hasCashReceiptId || hasCorrectiveTaxDocumentId
                  }
                >
                  {EMPTY_PLACEHOLDER}
                </Hide>
              </Box>
              <HStack justify="flex-end">
                <Hide when={shouldHideRowActions}>
                  <Show
                    when={
                      taxDocumentForPaymentIssuedType === 'manual' &&
                      isNil((item as InvoiceProformaPaymentResponseBody).taxDocumentForPaymentId) &&
                      isPaymentDeposit
                    }
                  >
                    <IconButton
                      icon="editor/insert_drive_file"
                      onClick={() => createTaxDocumentForPayment(item.transactionId)}
                      data-testid={suffixTestId('payment-createTaxDocumentForPayment', props)}
                    />
                  </Show>
                  <Hide when={item.source === 'external'}>
                    <IconButton
                      severity="danger"
                      icon="action/delete"
                      onClick={() => handleWithdrawPayment(item.transactionId)}
                      isDisabled={isWithdrawalPending}
                      data-testid={suffixTestId('transaction-delete', props)}
                    />
                  </Hide>
                </Hide>
              </HStack>
            </TableRow>
          );
        })}
        {/* HACK: there is not partial payment support for purchase yet, so we must assume there is only
        one payment per order (in full amount) */}
        {/* TODO: https://carvago.atlassian.net/browse/T20-41167 */}
        <Show when={isPaidPurchasePayment}>
          <TableRow data-testid={suffixTestId('payment-purchase-row', props)}>
            <Box padding={2}>
              <Text size="small">
                {i18n.t(
                  `entity.invoice.paymentMethod.${convertStringToCamelCase(
                    props.payment.paymentMethod
                  )}`
                )}
              </Text>
            </Box>
            <Box padding={2}>
              <Text size="small">
                {isNotNil(props.payment.amount)
                  ? formatCurrency(
                      parseFloat(props.payment.amount.amount),
                      props.payment.amount.currency
                    )
                  : EMPTY_PLACEHOLDER}
              </Text>
            </Box>
            <Box padding={2}>
              <Text size="small">
                {props.payment.payDate
                  ? formatDateTime('dateShort', parseISO(props.payment.payDate))
                  : EMPTY_PLACEHOLDER}
              </Text>
            </Box>

            <Box padding={2}>
              {isEmpty(props.payment.cashReceipts)
                ? EMPTY_PLACEHOLDER
                : props.payment.cashReceipts.map((cashReceipt, index) => (
                    <Link
                      key={cashReceipt.createdAt}
                      size="small"
                      onClick={() => openFile(cashReceipt.fileUri)}
                      title={cashReceipt.name}
                      data-testid={suffixTestId(`payment-cashReceipt-[${index}]`, props)}
                    />
                  ))}
            </Box>

            <Hide when={isBusinessCaseClosed}>
              <IconButton
                severity="danger"
                icon="action/delete"
                onClick={handleWithdrawPurchasePayment}
                isDisabled={isWithdrawalPending}
                data-testid={suffixTestId('payment-delete', props)}
              />
            </Hide>
          </TableRow>
        </Show>
      </Table>
    </DataStatus>
  );
}

const hasTaxDocumentForPayment = (val: unknown): val is InvoiceProformaPaymentResponseBody =>
  isNotNil((val as InvoiceProformaPaymentResponseBody)?.taxDocumentForPaymentId);
