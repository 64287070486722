import {Button, FormControl, FormField, IconButton, Tooltip} from 'platform/components';
import {Align, Grid, HStack, VStack} from 'platform/foundation';

import {
  ArrayPath,
  FieldArray,
  FieldValues,
  Path,
  PathValue,
  useFieldArray,
  UseFormReturn,
} from 'react-hook-form';

import {findIndex, isNil, last, isNotEmpty} from 'ramda';
import {isNotNil, lengthLte} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {BaseSupplier, getOptionsFromSuppliers, WarehouseSupplier} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

type SupplierFieldListProps<TFieldValues extends FieldValues = FieldValues> = {
  control: FormControl<TFieldValues>;
  formApi: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  isRequired?: boolean;
  isDisabled?: boolean;
  suppliers: BaseSupplier[] | Nullish;
} & TestIdProps;

const MINIMUM_NUMBER_OF_SUPPLIERS = 1;
// First supplier in array is required
const INDEX_OF_REQUIRED_SUPPLIER = 0;

const EMPTY_SUPPLIER = {id: null};

export function SupplierFieldList<TFieldValues extends FieldValues = FieldValues>(
  props: SupplierFieldListProps<TFieldValues>
) {
  const {fields, remove, append} = useFieldArray({
    control: props.control,
    name: props.name as ArrayPath<TFieldValues>,
  });

  const defaultSupplierValue = props.formApi.watch('defaultSupplierId' as Path<TFieldValues>);

  const selectedSuppliers: WarehouseSupplier[] =
    props.formApi.watch('suppliers' as Path<TFieldValues>) ?? [];

  const defaultFields = isNotEmpty(fields) ? fields : [null];

  const indexOfDefaultValue = findIndex(
    (field: WarehouseSupplier) => field?.id === defaultSupplierValue
  )(selectedSuppliers);

  const isLastSupplierUndefined = isNil(last(selectedSuppliers));
  const isDeleteButtonDisabled = lengthLte(MINIMUM_NUMBER_OF_SUPPLIERS, fields);

  const suppliersErrorMessage = props.formApi.formState.errors[props.name]?.message;

  const handleSelectDefault = (newDefaultIndex: number) => {
    const newDefaultValue: WarehouseSupplier = props.formApi.getValues(
      'suppliers' as Path<TFieldValues>
    )[newDefaultIndex];

    if (isNil(newDefaultValue)) {
      return;
    }

    props.formApi.setValue(
      'defaultSupplierId' as Path<TFieldValues>,
      newDefaultValue.id as PathValue<TFieldValues, Path<TFieldValues>>
    );
  };

  const handleSupplierChange = (supplierId: PathValue<TFieldValues, Path<TFieldValues>>) => {
    // reset the suppliers validation and clear all errors after selecting any supplier
    props.formApi.trigger('suppliers' as Path<TFieldValues>);
    props.formApi.clearErrors('suppliers' as Path<TFieldValues>);

    if (!defaultSupplierValue) {
      props.formApi.setValue('defaultSupplierId' as Path<TFieldValues>, supplierId);
    }
  };

  return (
    <VStack spacing={4} data-testid={`${props['data-testid']}-wrapper`}>
      {defaultFields?.map((field, index) => (
        <Grid key={field?.id} columns={8}>
          <FormField
            isRequired={props.isRequired && index === INDEX_OF_REQUIRED_SUPPLIER}
            isNotClearable
            control={props.control}
            type="choice"
            errorMessage={suppliersErrorMessage as string}
            name={`${props.name}.${index}.id` as Path<TFieldValues>}
            label={isFirstItem(index) ? i18n.t('entity.warehouse.labels.supplier') : undefined}
            options={getOptionsFromSuppliers(props.suppliers)}
            onChange={(value) =>
              handleSupplierChange(value as PathValue<TFieldValues, Path<TFieldValues>>)
            }
            data-testid={suffixTestId(`supplier-[${index}]`, props)}
          />

          <HStack>
            <HStack spacing={4} align="flex-end">
              <IconButton
                icon="action/delete"
                isDisabled={isDeleteButtonDisabled}
                severity="danger"
                onClick={() => remove(index)}
                data-testid={suffixTestId(`remove-[${index}]`, props)}
              />
            </HStack>

            <HStack spacing={4} align="flex-end">
              <Tooltip
                isDisabled={isNotNil(selectedSuppliers[index])}
                description={i18n.t('general.actions.firstlyFulfillSupplierInLastField')}
              >
                <IconButton
                  icon={
                    indexOfDefaultValue === index
                      ? 'toggle/radio_button_checked'
                      : 'toggle/radio_button_unchecked'
                  }
                  isDisabled={isNil(defaultFields[index])}
                  severity="informational"
                  onClick={() => handleSelectDefault(index)}
                  data-testid={suffixTestId(`setDefault-[${index}]`, props)}
                />
              </Tooltip>
            </HStack>
          </HStack>
        </Grid>
      ))}

      <Align left>
        <Tooltip
          isDisabled={!isLastSupplierUndefined}
          description={i18n.t('general.actions.firstlyFulfillSupplierInLastField')}
        >
          <Button
            isDisabled={isLastSupplierUndefined}
            leftIcon="content/add_circle"
            title={i18n.t('general.actions.addAnother')}
            size="small"
            variant="link"
            onClick={() =>
              append(EMPTY_SUPPLIER as FieldArray<TFieldValues, ArrayPath<TFieldValues>>)
            }
            data-testid={suffixTestId('addAnother', props)}
          />
        </Tooltip>
      </Align>
    </VStack>
  );
}

const isFirstItem = (index: number) => index === 0;
