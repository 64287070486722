import {
  GetDirectSaleLabourItemRequest,
  GetDirectSaleLabourItemRequestSchema,
  GetDirectSaleLabourItemResponse,
  GetDirectSaleLabourItemResponseSchema,
  PatchDirectSaleLabourItemRequest,
  PatchDirectSaleLabourItemRequestSchema,
  PatchDirectSaleLabourItemResponse,
  PatchDirectSaleLabourItemResponseSchema,
  PostDirectSaleLabourItemCalculatePriceRequest,
  PostDirectSaleLabourItemCalculatePriceRequestSchema,
  PostDirectSaleLabourItemCalculatePriceResponse,
  PostDirectSaleLabourItemCalculatePriceResponseSchema,
} from '../types/api/metadaWarehouseDirectSaleItemLabour';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseDirectSaleItemLabourApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getDirectSaleLabourItem: build.query<
      GetDirectSaleLabourItemResponse,
      GetDirectSaleLabourItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-labour/${queryArg.itemId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: ['directSaleLabourItems'],
      extraOptions: {
        requestSchema: GetDirectSaleLabourItemRequestSchema,
        responseSchema: GetDirectSaleLabourItemResponseSchema,
      },
    }),
    patchDirectSaleLabourItem: build.mutation<
      PatchDirectSaleLabourItemResponse,
      PatchDirectSaleLabourItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-labour/${queryArg.itemId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSales', 'directSaleLabourBasket'],
      extraOptions: {
        requestSchema: PatchDirectSaleLabourItemRequestSchema,
        responseSchema: PatchDirectSaleLabourItemResponseSchema,
      },
    }),
    postDirectSaleLabourItemCalculatePrice: build.query<
      PostDirectSaleLabourItemCalculatePriceResponse,
      PostDirectSaleLabourItemCalculatePriceRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-labour/${queryArg.itemId}/calculate-price`,
        method: 'POST',
        body: queryArg.body,
      }),
      extraOptions: {
        requestSchema: PostDirectSaleLabourItemCalculatePriceRequestSchema,
        responseSchema: PostDirectSaleLabourItemCalculatePriceResponseSchema,
      },
    }),
  }),
});

export const {
  useGetDirectSaleLabourItemQuery,
  useLazyGetDirectSaleLabourItemQuery,
  usePatchDirectSaleLabourItemMutation,
  usePostDirectSaleLabourItemCalculatePriceQuery,
} = metadaWarehouseDirectSaleItemLabourApi;
