import 'currency-flags/dist/currency-flags.css';
import {
  Card,
  Flag,
  FormControl,
  FormField,
  isCurrency,
  Label,
  Separator,
} from 'platform/components';
import {GridItem, Grid, Hide, HStack, Show, Space, Text, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter, useFormatCurrency} from 'platform/locale';

import {isNegative, isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  CashRegisterDocumentResponseBody,
  MoneyResponseBody,
  useGetTenantQuery,
} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {AccountingDocumentFormValues} from '../types';

interface BasicInformationProps extends TestIdProps {
  control: FormControl<AccountingDocumentFormValues>;
  cashReceipt: CashRegisterDocumentResponseBody | Nullish;
}

export function BasicInformationDetail(props: BasicInformationProps) {
  const formatCurrencySymbol = useCurrencySymbolFormatter();
  const formatCurrency = useFormatCurrency();
  const {data: tenant} = useGetTenantQuery();

  const formatPrice = (val: MoneyResponseBody | Nullish): string =>
    val ? (formatCurrency(parseFloat(val.amount), val.currency, 2) ?? '') : '';

  const currency =
    isNotNil(props.cashReceipt) &&
    isCurrency(props.cashReceipt?.cashRegisterBalanceBefore?.currency)
      ? props.cashReceipt.cashRegisterBalanceBefore?.currency
      : undefined;

  const summaryTotalPrice =
    props.cashReceipt?.type === 'expense'
      ? parseFloat(`-${Math.abs(parseFloat(props.cashReceipt?.totalPriceWithVat.amount ?? '0'))}`)
      : parseFloat(props.cashReceipt?.totalPriceWithVat.amount ?? '0');

  return (
    <Card title={i18n.t('general.labels.basicInformation')}>
      <VStack spacing={4}>
        <Grid columns={4}>
          <FormField
            isRequired
            name="cashRegisterId"
            control={props.control}
            type="text"
            label={i18n.t('entity.checkout.labels.cashRegister')}
            isDisabled
            data-testid={suffixTestId('cashRegister', props)}
          />
          <VStack>
            <Label>{i18n.t('general.labels.currency')}</Label>
            <Space vertical={1} />
            <HStack align="center" spacing={2}>
              <CountryFlag currency={currency} />
              <Text size="small">{formatCurrencySymbol(currency)}</Text>
            </HStack>
          </VStack>

          <VStack>
            <Label>{i18n.t('entity.cashRegister.parameters.initialState')}</Label>
            <Space vertical={1} />

            <HStack justify="space-between">
              <Text alternative>{formatPrice(props.cashReceipt?.cashRegisterBalanceBefore)}</Text>

              <Hide
                when={
                  props.cashReceipt?.paymentMethod === 'CARD' ||
                  parseFloat(props.cashReceipt?.totalPriceWithVat.amount ?? '0') === 0
                }
              >
                <Flag
                  isSubtle
                  colorScheme={
                    isNegative(summaryTotalPrice) || props.cashReceipt?.type === 'expense'
                      ? 'red'
                      : 'green'
                  }
                  label={formatCurrency(summaryTotalPrice, currency ?? '', 2) ?? ''}
                />
              </Hide>
            </HStack>
          </VStack>

          <VStack>
            <Label>{i18n.t('entity.newCashRegisterDocument.cashBalanceFinal.label')}</Label>
            <Space vertical={1} />
            <Text alternative>{formatPrice(props.cashReceipt?.cashRegisterBalanceAfter)}</Text>
          </VStack>
        </Grid>

        <Separator spacing={0} />

        <Grid columns={4}>
          <FormField
            isRequired
            label={i18n.t('entity.newCashRegisterDocument.kindOfPayment.label')}
            name="paymentInfo.paymentType"
            control={props.control}
            type="text"
            isDisabled
          />

          <FormField
            isRequired
            label={i18n.t('general.labels.date')}
            name="issueDate"
            control={props.control}
            type="apiDate"
            isDisabled
          />

          <FormField
            label={i18n.t('entity.lineItems.labels.variableSymbol')}
            name="paymentInfo.variableSymbol"
            control={props.control}
            type="text"
            helperText={i18n.t('entity.newCashRegisterDocument.variableSymbolOrInvoiceNumber')}
            isDisabled
          />

          <FormField
            label={i18n.t('entity.bank.labels.paymentType')}
            name="paymentInfo.paymentMethod"
            control={props.control}
            type="text"
            isDisabled
          />

          <GridItem span={2}>
            <FormField
              label={i18n.t('entity.lineItems.labels.purpose')}
              name="reason"
              control={props.control}
              isDisabled
              type="textarea"
            />
          </GridItem>
          <Show when={tenant?.currency !== currency}>
            <FormField
              label={i18n.t('general.labels.amount')}
              name="exchangeRateRatio.amount"
              control={props.control}
              type="currency"
              currency={currency}
              isDisabled
            />
            <FormField
              label={i18n.t('general.labels.exchangeRate')}
              name="exchangeRateRatio.ratio"
              control={props.control}
              type="currency"
              isDisabled
              currency={isCurrency(tenant?.currency) ? tenant?.currency : undefined}
              decimalPlaces={3}
              data-testid={suffixTestId('foreign-currency-ratio', props)}
            />
          </Show>
        </Grid>
      </VStack>
    </Card>
  );
}

// TODO: replace with platform component T20-27406
const CountryFlag = ({currency}: {currency: string | Nullish}) =>
  currency ? (
    <div
      style={{borderRadius: '2px'}}
      className={`currency-flag currency-flag-lg currency-flag-${currency?.toLowerCase()}`}
    />
  ) : null;
