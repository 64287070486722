import {DataGrid, DataGridRef, QueryFilterObject} from 'platform/datagrid';
import {Box} from 'platform/foundation';

import {RefObject, useCallback, useEffect} from 'react';

import {mergeAll} from 'ramda';

import {
  useGetDocumentDatagridActions,
  useGetSaleVehicleWarehouseInformationQuery,
} from '@omnetic-dms/shared';

interface VehicleWarehouseOperationsCardProps {
  vehicleId: string;
  saleVehicleId: string;
  dataGridRef?: RefObject<DataGridRef>;
}

export function VehicleWarehouseOperationsCard(props: VehicleWarehouseOperationsCardProps) {
  const {data: warehouseInfo} = useGetSaleVehicleWarehouseInformationQuery({
    vehicleId: props.vehicleId,
    saleVehicleId: props.saleVehicleId,
  });

  const [actionCallback] = useGetDocumentDatagridActions();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {saleVehicle: [props.saleVehicleId]}]),
    [props.saleVehicleId]
  );

  useEffect(() => {
    props.dataGridRef?.current?.refreshData();
  }, [warehouseInfo, props.dataGridRef]);

  return (
    <Box minHeight={40}>
      <DataGrid
        ref={props.dataGridRef}
        gridCode="sale_vehicle_warehouse_movement"
        autoHeight
        actionCallback={actionCallback}
        queryModifier={queryModifier}
        data-testid="warehouse-movement-datagrid"
      />
    </Box>
  );
}
