import {
  GetArticleStockAvailabilityOverviewRequest,
  GetArticleStockAvailabilityOverviewResponse,
  GetArticleStockItemOverviewRequest,
  GetArticleStockItemOverviewResponse,
  GetArticleStockAvailabilityResponse,
  GetArticleStockAvailabilityRequest,
  GetArticleStockAvailabilityOverviewRequestSchema,
  GetArticleStockAvailabilityOverviewResponseSchema,
  GetArticleStockItemOverviewRequestSchema,
  GetArticleStockItemOverviewResponseSchema,
  GetArticleStockAvailabilityRequestSchema,
  GetArticleStockAvailabilityResponseSchema,
} from '../types/api/metadaWarehouseArticleStock';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseArticleStockApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getArticleStockAvailability: build.query<
      GetArticleStockAvailabilityResponse,
      GetArticleStockAvailabilityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/article/${queryArg.articleId}/overview`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetArticleStockAvailabilityRequestSchema,
        responseSchema: GetArticleStockAvailabilityResponseSchema,
      },
    }),
    getArticleStockItemOverview: build.query<
      GetArticleStockItemOverviewResponse,
      GetArticleStockItemOverviewRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article/${queryArg.articleId}/stock-item/overview`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetArticleStockItemOverviewRequestSchema,
        responseSchema: GetArticleStockItemOverviewResponseSchema,
      },
    }),
    getArticleStockAvailabilityOverview: build.query<
      GetArticleStockAvailabilityOverviewResponse,
      GetArticleStockAvailabilityOverviewRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}/article/${queryArg.articleId}/stock-availability/overview`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetArticleStockAvailabilityOverviewRequestSchema,
        responseSchema: GetArticleStockAvailabilityOverviewResponseSchema,
      },
    }),
  }),
});

export const {
  useGetArticleStockItemOverviewQuery,
  useGetArticleStockAvailabilityQuery,
  useGetArticleStockAvailabilityOverviewQuery,
} = metadaWarehouseArticleStockApi;
