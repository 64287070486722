import {startOfToday} from 'date-fns';
import {format as formatDate} from 'date-fns/format';
import {Button, showNotification} from 'platform/components';
import {Hide, HStack, Show} from 'platform/foundation';

import {useCallback, useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import {head, path} from 'ramda';
import {isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  SourcingVehicleListItemResponseBody,
  useSourcingBulkCreateUserComparisonMutation,
  useSourcingBulkCreateUserFavouriteMutation,
  useSourcingBulkCreateUserHiddenMutation,
  useSourcingBulkCreateUserToBuyMutation,
  useSourcingBulkDeleteUserFavouriteMutation,
  useSourcingBulkDeleteUserHiddenMutation,
  useSourcingBulkDeleteUserToBuyMutation,
  useSourcingExportVehiclesToCsvMutation,
  useSourcingPushVehiclesToSaleMutation,
} from '@omnetic-dms/shared';
import {
  afterLastSlash,
  downloadCsv,
  SourcingSubPage,
  UserListPage,
  useSourcingCurrency,
  useSourcingTargetCountry,
} from '@omnetic-dms/teas';

import {isNilOrZero} from 'shared';

import {useCurrentWorkspace} from '../../../hooks/useCurrentWorkspace';

export type DataViewBulkActionDrawerProps = {
  pathToVehicleAdId: string[] | null;
  selectedRows: SourcingVehicleListItemResponseBody[];
  onUnselectSelectedRows: (rowIds: string[]) => void;
};

const baseActions = {
  buy: i18n.t('general.actions.buy'),
  comparison: i18n.t('general.actions.addToComparison'),
  export: null as unknown,
  favourites: i18n.t('general.actions.addToFavourites'),
  hide: i18n.t('general.actions.hide'),
  buyNow: null as unknown,
};

const buttonTranslations = {
  [SourcingSubPage.classifieds]: baseActions,

  [UserListPage.CART_PAGE]: {
    buy: i18n.t('general.actions.removeFromBasket'),
    comparison: i18n.t('general.actions.comparison'),
    export: i18n.t('general.actions.export'),
    favourites: i18n.t('general.actions.favourites'),
    hide: i18n.t('general.actions.hide'),
    buyNow: i18n.t('general.actions.buyNow'),
  },

  [UserListPage.HIDDEN_VEHICLE_PAGE]: {
    buy: null as unknown,
    comparison: null as unknown,
    export: null as unknown,
    favourites: null as unknown,
    hide: i18n.t('general.actions.removeFromHidden'),
    buyNow: null as unknown,
  },

  [UserListPage.FAVORITES_VEHICLE_PAGE]: {
    buy: i18n.t('general.actions.buy'),
    comparison: i18n.t('general.actions.comparison'),
    export: null as unknown,
    favourites: i18n.t('general.actions.removeFromFavourites'),
    hide: i18n.t('general.actions.hide'),
    buyNow: null as unknown,
  },
};

export function DataViewBulkActionDrawer(props: DataViewBulkActionDrawerProps) {
  const currency = useSourcingCurrency();
  const currentWorkspace = useCurrentWorkspace();
  const targetCountry = useSourcingTargetCountry();

  const [createUserFavourite] = useSourcingBulkCreateUserFavouriteMutation();
  const [deleteUserFavourite] = useSourcingBulkDeleteUserFavouriteMutation();
  const [createUserComparison] = useSourcingBulkCreateUserComparisonMutation();
  const [createUserHidden] = useSourcingBulkCreateUserHiddenMutation();
  const [deleteUserHidden] = useSourcingBulkDeleteUserHiddenMutation();
  const [createUserToBuy] = useSourcingBulkCreateUserToBuyMutation();
  const [deleteUserToBuy] = useSourcingBulkDeleteUserToBuyMutation();
  const [pushVehiclesToSale] = useSourcingPushVehiclesToSaleMutation();
  const [exportVehicleList] = useSourcingExportVehiclesToCsvMutation();

  const {pathname} = useLocation();
  const route = afterLastSlash(pathname) as keyof typeof buttonTranslations;
  const selectedVehiclesCount = props.selectedRows.length;
  const selectorKey = head(props.pathToVehicleAdId ?? []) as string;

  const isSold = useMemo(
    () =>
      props.selectedRows.some((row) => {
        const availability =
          path([selectorKey, 'isAvailable'], row) ?? path([selectorKey, 'atAuction'], row);
        return availability == null ? true : !availability;
      }),
    [props.selectedRows, selectorKey]
  );

  const getIds = useCallback(
    (type: 'favourite' | 'comparison' | 'inCart' | 'hidden', isChecked = false): string[] =>
      props.selectedRows
        .filter((vehicle) => vehicle[type] !== !isChecked)
        .map((vehicle) => path(props.pathToVehicleAdId as string[], vehicle)) as string[],
    [props.selectedRows, props.pathToVehicleAdId]
  );

  const isSourcing = route === SourcingSubPage.classifieds;
  const isCart = route === UserListPage.CART_PAGE;
  const isHidden = route === UserListPage.HIDDEN_VEHICLE_PAGE;
  const isFavourite = route === UserListPage.FAVORITES_VEHICLE_PAGE;

  const cartButtonIds = getIds('inCart', isCart);
  const onClickVehicleCartButton = () => {
    if (isCart) {
      deleteUserToBuy({body: {externalIds: cartButtonIds}})
        .unwrap()
        .then(() => props.onUnselectSelectedRows(cartButtonIds))
        .catch(handleApiError);
    } else {
      createUserToBuy({body: {externalIds: cartButtonIds}})
        .unwrap()
        .then(() =>
          showNotification.success(
            `${i18n.t('entity.vehicle.notifications.vehiclesAddedToCard')} (${
              cartButtonIds.length
            })`
          )
        )
        .catch(handleApiError);
    }
  };

  const handleExport = () =>
    exportVehicleList({
      externalIds: getIds('inCart', true),
      currency: currency?.code,
      buyerCountry: targetCountry?.code,
    })
      .unwrap()
      .then((csvContent) => {
        if (isString(csvContent)) {
          downloadCsv(
            csvContent,
            `${currentWorkspace?.name}_${formatDate(startOfToday(), 'yyyyMMdd')}.csv`
          );
        } else {
          throw new Error('CSV content must be string.');
        }
      })
      .catch(handleApiError);

  const handleBuy = () => {
    pushVehiclesToSale({
      externalIds: getIds('inCart', true),
    })
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('entity.vehicle.notifications.vehiclesPushedToSales'))
      )
      .catch(handleApiError);
    props.onUnselectSelectedRows(cartButtonIds);
  };

  const CartButton = (
    <Button
      title={`${buttonTranslations[route]?.buy} (${cartButtonIds.length})`}
      leftIcon="action/shopping_cart"
      variant={isFavourite ? 'primary' : 'outlined'}
      isDisabled={isNilOrZero(cartButtonIds.length)}
      onClick={onClickVehicleCartButton}
      data-testid={testIds.sourcing.classifieds('bulkActionDrawer-cartButton')}
    />
  );

  const favouriteButtonIds = getIds('favourite', isFavourite);
  const onClickFavouriteButton = () => {
    if (isFavourite) {
      deleteUserFavourite({body: {externalIds: favouriteButtonIds}})
        .unwrap()
        .then(() => props.onUnselectSelectedRows(favouriteButtonIds))
        .catch(handleApiError);
    } else {
      createUserFavourite({body: {externalIds: favouriteButtonIds}})
        .unwrap()
        .then(() =>
          showNotification.success(
            `${i18n.t('entity.vehicle.notifications.vehiclesAddedToFavourite')} (${
              favouriteButtonIds.length
            })`
          )
        )
        .catch(handleApiError);
    }
  };

  const FavouriteButton = (
    <Button
      title={`${buttonTranslations[route]?.favourites} (${favouriteButtonIds.length})`}
      leftIcon="toggle/star"
      variant={isSourcing ? 'primary' : 'outlined'}
      isDisabled={isNilOrZero(favouriteButtonIds.length)}
      onClick={onClickFavouriteButton}
      data-testid={testIds.sourcing.classifieds('bulkActionDrawer-favouriteButton')}
    />
  );

  if (isSold && isCart) {
    return (
      <HStack spacing={2} justify="flex-end">
        {CartButton}
      </HStack>
    );
  }

  if (isSold && isFavourite) {
    return (
      <HStack spacing={2} justify="flex-end">
        {FavouriteButton}
      </HStack>
    );
  }

  const hiddenButtonIds = getIds('hidden', isHidden);
  const comparisonButtonIds = getIds('comparison', false);
  const onClickHideButton = () => {
    if (isHidden) {
      deleteUserHidden({body: {externalIds: hiddenButtonIds}})
        .unwrap()
        .then(() => props.onUnselectSelectedRows(hiddenButtonIds))
        .catch(handleApiError);
    } else {
      createUserHidden({body: {externalIds: hiddenButtonIds}})
        .unwrap()
        .then(() => props.onUnselectSelectedRows(hiddenButtonIds))
        .then(() =>
          showNotification.success(
            `${i18n.t('entity.vehicle.notifications.vehiclesAddedToHidden')} (${
              hiddenButtonIds.length
            })`
          )
        )
        .catch(handleApiError);
    }
  };
  const onClickComparisonButton = () =>
    createUserComparison({body: {externalIds: comparisonButtonIds}})
      .unwrap()
      .then(() =>
        showNotification.success(
          `${i18n.t('entity.vehicle.notifications.vehiclesAddedToComparison')} (${
            comparisonButtonIds.length
          })`
        )
      );

  return (
    <HStack spacing={2} justify="flex-end">
      <Hide when={isCart}>
        <Button
          title={`${buttonTranslations[route]?.hide} (${hiddenButtonIds.length})`}
          leftIcon="action/visibility_off"
          isDisabled={isNilOrZero(hiddenButtonIds.length)}
          variant="outlined"
          onClick={onClickHideButton}
          data-testid={testIds.sourcing.classifieds('bulkActionDrawer-hideButton')}
        />
      </Hide>
      <Hide when={isHidden}>
        <Button
          title={`${buttonTranslations[route]?.comparison} (${comparisonButtonIds.length})`}
          variant="outlined"
          leftIcon="action/compare_arrows"
          isDisabled={isNilOrZero(comparisonButtonIds.length)}
          onClick={onClickComparisonButton}
          data-testid={testIds.sourcing.classifieds('bulkActionDrawer-comparisonButton')}
        />
        {CartButton}
        {FavouriteButton}
        <Show when={isCart}>
          <Button
            title={`${buttonTranslations[route]?.export} (${selectedVehiclesCount})`}
            variant="outlined"
            leftIcon="content/save_alt"
            onClick={handleExport}
            data-testid={testIds.sourcing.classifieds('bulkActionDrawer-exportButton')}
          />
          <Button
            title={`${buttonTranslations[route]?.buyNow} (${selectedVehiclesCount})`}
            variant="primary"
            leftIcon="maps/local_mall"
            onClick={handleBuy}
            data-testid={testIds.sourcing.classifieds('bulkActionDrawer-buyNowButton')}
          />
        </Show>
      </Hide>
    </HStack>
  );
}
