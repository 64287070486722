import {
  Card,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, Heading, Space, VStack} from 'platform/foundation';
import {array, boolean, object} from 'yup';

import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {defaultTo, isNil, not, pipe, reject} from 'ramda';
import {isNumber, isTrue} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  selectActiveBranchId,
  useCurrencies,
  useGetEmployeeMechanicsByBranchQuery,
  useGetSmallUnitMaterialApiQuery,
  useGetSettingsServiceOrderVariantsQuery,
  useGetTenantQuery,
  usePostSmallUnitMaterialApiMutation,
  usePatchSmallUnitMaterialApiMutation,
  useTenant,
} from '@omnetic-dms/shared';

import {useNavigate, yupNumber, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

const PERCENTAGE_BASE = 100;

type FormType = {
  name: string;
  orderVariantIds: string[];
  amountType:
    | 'SMALLUNITMATERIAL_FROM_MATERIAL_LABOUR'
    | 'SMALLUNITMATERIAL_FROM_LABOUR'
    | 'SMALLUNITMATERIAL_FROM_MATERIAL';
  amountValue: number;
  isMaxValue: boolean;
  maxValue: number;
  mechanicId: string;
};

const rejectNullish = pipe<[(string | null)[] | undefined], (string | null)[], string[]>(
  defaultTo([]),
  reject(isNil)
);

export function WorkshopSmallUnitMaterialDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const branchId = useSelector(selectActiveBranchId);
  const {getCurrencySymbol} = useCurrencies();
  const {tenantCurrency} = useTenant();

  const isCreating = isNil(id);

  const {data: {currency: defaultCurrency} = {}} = useGetTenantQuery();
  const {data, isLoading, isError} = useGetSmallUnitMaterialApiQuery(
    {
      smallUnitMaterialId: id ?? '',
    },
    {skip: isCreating}
  );
  const [patchSmallUnitMaterial] = usePatchSmallUnitMaterialApiMutation();
  const [postSmallUnitMaterial] = usePostSmallUnitMaterialApiMutation();

  const {data: mechanicOptions, isLoading: isMechanicOptionsLoading} =
    useGetEmployeeMechanicsByBranchQuery({branchId});
  const {data: orderVariantsOptions, isLoading: isOrderVariantsLoading} =
    useGetSettingsServiceOrderVariantsQuery();

  const handleSubmit: FormSubmitHandler<FormType> = async (data) => {
    const {amountValue, maxValue, ...restBody} = data;

    const body = {
      ...restBody,
      maxValue: maxValue
        ? {
            currency: tenantCurrency,
            amount: maxValue,
          }
        : undefined,
      amountValue: amountValue / PERCENTAGE_BASE,
    };

    const request = isCreating
      ? postSmallUnitMaterial({body})
      : patchSmallUnitMaterial({smallUnitMaterialId: id, body});

    await request
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.workshopSmallUnitMaterial))
      .catch(handleApiError);
  };

  const amountOptions = [
    {
      label: i18n.t('entity.workshopMinorUnitMaterial.labels.unitFromMaterial'),
      value: 'SMALLUNITMATERIAL_FROM_MATERIAL',
    },
    {
      label: i18n.t('entity.workshopMinorUnitMaterial.labels.unitFromLabour'),
      value: 'SMALLUNITMATERIAL_FROM_LABOUR',
    },
    {
      label: i18n.t('entity.workshopMinorUnitMaterial.labels.unitFromMaterialAndLabour'),
      value: 'SMALLUNITMATERIAL_FROM_MATERIAL_LABOUR',
    },
  ];

  const header: SettingsTemplateHeader = {
    title: isCreating
      ? i18n.t('page.settings.actions.newSmallUnitMaterial')
      : i18n.t('entity.workshopMinorUnitMaterial.labels.minorUnitMaterial'),
    breadcrumbs: [
      {
        label: i18n.t('entity.workshopMinorUnitMaterial.labels.minorUnitMaterial'),
        href: settingsRoutes.workshopSmallUnitMaterial,
      },
    ],
  };

  return (
    <SettingsTemplate
      header={header}
      data-testid={testIds.settings.workshopSmallUnitMaterialDetail('page')}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<FormType>
          schema={schema}
          onSubmit={handleSubmit}
          defaultValues={{
            name: data?.name ?? '',
            amountType: data?.amountType ?? 'SMALLUNITMATERIAL_FROM_MATERIAL',
            amountValue: isNumber(data?.amountValue)
              ? Number(data?.amountValue) * PERCENTAGE_BASE
              : undefined,
            isMaxValue: data?.isMaxValue ?? false,
            maxValue: data?.maxValue?.amount ?? undefined,
            mechanicId: data?.mechanicId ?? undefined,
            orderVariantIds: rejectNullish(data?.orderVariantIds),
          }}
        >
          {(control, formApi) => (
            <>
              <SettingsSection>
                <VStack spacing={4}>
                  <FormField
                    control={control}
                    name="name"
                    type="text"
                    label={i18n.t('general.labels.name')}
                    isRequired
                    data-testid={testIds.settings.workshopSmallUnitMaterialDetail('name')}
                  />
                  <FormField
                    control={control}
                    name="orderVariantIds"
                    type="multiChoice"
                    options={orderVariantsOptions}
                    isLoading={isOrderVariantsLoading}
                    label={i18n.t('entity.workshopMinorUnitMaterial.labels.orderVariant')}
                    helperText={i18n.t(
                      'entity.workshopMinorUnitMaterial.labels.orderVariantHelper'
                    )}
                    isRequired
                    isNotClearable
                    data-testid={testIds.settings.workshopSmallUnitMaterialDetail('orderVariant')}
                  />
                  <Card variant="inlineGrey">
                    <VStack spacing={4}>
                      <Heading size={4}>{i18n.t('general.labels.amount')}</Heading>
                      <FormField
                        control={control}
                        name="amountType"
                        type="radio"
                        direction="column"
                        spacing={3}
                        options={amountOptions}
                        data-testid={testIds.settings.workshopSmallUnitMaterialDetail('amountType')}
                      />
                      <Box width={82}>
                        <FormField
                          control={control}
                          name="amountValue"
                          type="number"
                          isStepperVisible
                          minStepperValue={0}
                          maxStepperValue={100}
                          data-testid={testIds.settings.workshopSmallUnitMaterialDetail(
                            'amountValue'
                          )}
                        />
                      </Box>
                    </VStack>
                  </Card>
                  <FormField
                    control={control}
                    name="isMaxValue"
                    type="checkbox"
                    label={i18n.t('entity.workshopMinorUnitMaterial.labels.enableMaximumAmount', {
                      currency: getCurrencySymbol(defaultCurrency),
                    })}
                    data-testid={testIds.settings.workshopSmallUnitMaterialDetail('isMaxValue')}
                  />
                  <Box width={92}>
                    <FormField
                      control={control}
                      name="maxValue"
                      type="number"
                      isDisabled={not(formApi.watch('isMaxValue'))}
                      helperText={i18n.t(
                        'entity.workshopMinorUnitMaterial.labels.amountMustNotExceedAmountSelectedPerOrder'
                      )}
                      minStepperValue={0}
                      data-testid={testIds.settings.workshopSmallUnitMaterialDetail('maxValue')}
                    />
                  </Box>
                  <Box width={92}>
                    <FormField
                      control={control}
                      name="mechanicId"
                      type="choice"
                      options={mechanicOptions}
                      isLoading={isMechanicOptionsLoading}
                      label={i18n.t('entity.workshopMinorUnitMaterial.labels.mechanic')}
                      helperText={i18n.t(
                        'entity.workshopMinorUnitMaterial.labels.minorUnitMaterialChargeToTheSelectedMechanic'
                      )}
                      data-testid={testIds.settings.workshopSmallUnitMaterialDetail('mechanicId')}
                    />
                  </Box>
                </VStack>
              </SettingsSection>
              <Space vertical={20} />
              <SettingsFooter
                actions={[
                  {
                    control,
                    type: 'form-button',
                    buttonType: 'submit',
                    title: isCreating
                      ? i18n.t('general.actions.create')
                      : i18n.t('general.actions.saveChanges'),
                  },
                ]}
                data-testid={testIds.settings.workshopSmallUnitMaterialDetail('saveChanges')}
              />
            </>
          )}
        </Form>
      </DataStatus>
    </SettingsTemplate>
  );
}

const schema = object({
  orderVariantIds: array()
    .of(yupString)
    .when('enabled', {
      is: isTrue,
      then: array().of(yupString).min(1, i18n.t('general.errors.mixed.required')).required(),
      otherwise: array().of(yupString).optional(),
    }),
  amountType: yupString.when('enabled', {
    is: isTrue,
    then: yupString.required(),
    otherwise: yupString.optional(),
  }),
  amountValue: yupNumber.when('enabled', {
    is: isTrue,
    then: yupNumber.min(0).max(100).required(),
    otherwise: yupNumber.optional(),
  }),
  isMaxValue: boolean().optional(),
  maxValue: yupNumber.min(0).optional(),
  mechanicId: yupString,
});
