import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {isEmpty, isNil} from 'ramda';
import {isNotArray} from 'ramda-adjunct';

import {AuditDataVideoAssetsFilesBody, InspectionType} from '@omnetic-dms/shared';

import {AuditDataAssetsFilesBody} from '../../types/AuditDataAssetsFilesBody';
import {AuditStructure} from '../../types/AuditStructure';
import {BasicStructure} from '../../types/BasicStructure';
import {AuditCategoriesWithError, TConditionFormValue} from '../../types/ConditionTypes';
import {CreateTypeOfControlSettings} from '../../types/CreateTypeOfControlSettings';
import {LoadAuditDataResponseItemBody} from '../../types/LoadAuditDataResponseItemBody';
import {SpecificallyAuditDataItem} from '../../types/SpecificallyAuditDataItem';
import {Template} from '../../types/Template';
import {TemplateV2} from '../../types/TemplateV2';
import {getFormFieldName} from '../../utils/getFormFieldName';
import {AsyncThunkState, getAsyncThunkReducer} from '../../utils/reduxThunkUtils';
import {
  deleteAuditAsset,
  finishAudit,
  getActualAuditData,
  getAllAuditStructureTemplates,
  getAudit,
  getAuditExportTemplates,
  getAuditStructure,
  getAuditStructureForStructureSettings,
  getAuditStructureSettings,
  getCheckinInspections,
  getComplaintInspections,
  getCondition,
  getHandoverInspections,
  getNewestAuditStructure,
  getNonValidationInspections,
  getValidationInspections,
  getVehicleOverallConditions,
  imagesUpload,
  updateAudit,
  updateAuditStructureSettings,
} from './actions';
import {AuditData, AuditFields, getInitialAuditData} from './reducerUtils';

/**
 * CarAudit module state interface
 */
export interface CarAuditState extends AsyncThunkState {
  audit: AuditData | null;
  validationInspections: LoadAuditDataResponseItemBody[];
  nonValidationInspections: LoadAuditDataResponseItemBody[];
  complaintInspections: LoadAuditDataResponseItemBody[];
  checkinInspections: LoadAuditDataResponseItemBody[];
  handoverInspections: LoadAuditDataResponseItemBody[];

  auditStructures: Record<string, AuditStructure>;
  newestAuditStructure: AuditStructure | null;
  newestConditionSettingsStructure: BasicStructure | null;
  auditSettingStructure: BasicStructure | null;

  auditsLocalData: Record<string, Record<string, string>>;
  auditExportTemplates: TemplateV2[];

  auditSettingBasicStructures: Record<string, BasicStructure>;
  auditStructureSettings: CreateTypeOfControlSettings | null;
  auditStructureTemplates: Template[] | null;
  vehicleOverallConditions: Array<SpecificallyAuditDataItem>;
}

/* Initial state */
const initialState: CarAuditState = {
  loading: {},
  errors: {},
  audit: null,

  validationInspections: [],
  nonValidationInspections: [],
  complaintInspections: [],
  checkinInspections: [],
  handoverInspections: [],

  auditStructures: {},

  auditSettingBasicStructures: {},
  auditStructureSettings: null,
  auditStructureTemplates: null,

  newestAuditStructure: null,
  newestConditionSettingsStructure: null,
  auditSettingStructure: null,

  auditsLocalData: {},
  auditExportTemplates: [],

  vehicleOverallConditions: [],
};

interface UpdateFieldProps {
  categoryId: string;
  paramDefinitionId: string;
  value: TConditionFormValue;
}

type AuditUpdateStatePayloadT = {
  state: LoadAuditDataResponseItemBody.state;
};

const updateField =
  (state: CarAuditState) =>
  ({categoryId, paramDefinitionId, value}: UpdateFieldProps) => {
    const fieldName = getFormFieldName(categoryId, paramDefinitionId);

    if (!state.audit) {
      return;
    }
    state.audit.fields[fieldName] = value;
  };

/* Create slice */
const name = 'carAudit';
const carAudit = createSlice({
  name,
  initialState,
  reducers: {
    setAuditLocalData: (state, {payload: data}) => {
      const auditId = state.audit?.id;
      if (!auditId) {
        return;
      }

      state.auditsLocalData = {
        [auditId]: {
          ...(state.auditsLocalData?.[auditId] ?? {}),
          ...data,
        },
      };
    },
    deleteAssets: (state, {payload: {categoryId, paramDefinitionId, imageIds}}) => {
      const name = getFormFieldName(categoryId, paramDefinitionId);
      imageIds.forEach((imageId: string) => {
        if (!state.audit?.assets?.[name]) {
          return;
        }

        delete state.audit?.assets[name][imageId];

        if (isEmpty(state.audit.assets[name])) {
          delete state.audit.assets[name];
        }
      });
    },
    addAssets: (state, {payload: {categoryId, paramDefinitionId, assets}}) => {
      const name = getFormFieldName(categoryId, paramDefinitionId);

      assets.forEach((asset: AuditDataAssetsFilesBody) => {
        if (!state.audit) {
          return;
        }

        if (!state.audit?.assets?.[name]) {
          state.audit.assets[name] = {};
        }

        if (asset.imageId) {
          state.audit.assets[name][asset.imageId] = asset;
        }
      });
    },
    deleteVideoAssets: (state, {payload: {categoryId, paramDefinitionId, videoIds}}) => {
      const name = getFormFieldName(categoryId, paramDefinitionId);
      videoIds.forEach((videoId: string) => {
        if (!state.audit?.videoAssets?.[name]) {
          return;
        }

        delete state.audit?.videoAssets[name][videoId];

        if (isEmpty(state.audit.videoAssets[name])) {
          delete state.audit.videoAssets[name];
        }
      });
    },
    addVideoAssets: (state, {payload: {categoryId, paramDefinitionId, assets}}) => {
      const name = getFormFieldName(categoryId, paramDefinitionId);

      assets.forEach((asset: AuditDataVideoAssetsFilesBody) => {
        if (!state.audit) {
          return;
        }

        if (!state.audit?.videoAssets?.[name]) {
          state.audit.videoAssets[name] = {};
        }

        if (asset.videoId) {
          state.audit.videoAssets[name][asset.videoId] = asset;
        }
      });
    },
    updateAssetSrc: (state, {payload: {categoryId, paramDefinitionId, imageId, resizeUrl}}) => {
      const name = getFormFieldName(categoryId, paramDefinitionId);
      if (!state.audit?.assets?.[name]?.[imageId]) {
        return;
      }
      state.audit.assets[name][imageId].resizeUrl = resizeUrl;
    },
    updateVideoAssetData: (state, {payload: {categoryId, paramDefinitionId, videoId, asset}}) => {
      const name = getFormFieldName(categoryId, paramDefinitionId);
      if (!state.audit?.videoAssets?.[name]?.[videoId]) {
        return;
      }
      state.audit.videoAssets[name][videoId] = asset;
    },
    updateAuditData: (state, {payload: {auditData}}) => {
      state.audit = auditData;
    },
    setAuditForCreate: (state, {payload}) => {
      state.audit = payload;
    },
    updateAuditFields: (state, {payload}: PayloadAction<AuditFields>) => {
      if (!state.audit) {
        return;
      }
      state.audit.fields = {
        ...state.audit?.fields,
        ...payload,
      };
    },
    clearAuditFields: (state) => {
      if (!state.audit) {
        return;
      }
      state.audit.fields = {};
    },
    clearAuditAssets: (state) => {
      if (!state.audit) {
        return;
      }
      state.audit.assets = {};
    },
    setErrorCategories: (state, {payload}: PayloadAction<AuditCategoriesWithError>) => {
      if (!state.audit) {
        return;
      }
      state.audit.errorCategories = payload;
    },
    updateAuditState: (state, {payload}: PayloadAction<AuditUpdateStatePayloadT>) => {
      if (!state.audit) {
        return;
      }
      state.audit.state = payload.state;
    },
  },
  extraReducers(builder) {
    const {asyncThunkReducer, handleThunkStates} = getAsyncThunkReducer(builder);

    asyncThunkReducer(getComplaintInspections.action, (state, {payload}) => {
      state.complaintInspections = payload;
    });

    asyncThunkReducer(getValidationInspections.action, (state, {payload}) => {
      state.validationInspections = payload;
    });

    asyncThunkReducer(getNonValidationInspections.action, (state, {payload}) => {
      state.nonValidationInspections = payload;
    });

    asyncThunkReducer(getCheckinInspections.action, (state, {payload}) => {
      state.checkinInspections = payload;
    });

    asyncThunkReducer(getHandoverInspections.action, (state, {payload}) => {
      state.handoverInspections = payload;
    });

    asyncThunkReducer(
      getCondition.action,
      (
        state,
        {
          payload: [audit],
          meta: {
            arg: {withoutReducer},
          },
        }
      ) => {
        if (withoutReducer) {
          return;
        }

        state.audit = getInitialAuditData(audit);
      }
    );

    asyncThunkReducer(getAudit.action, (state, {payload: [audit]}) => {
      state.audit = getInitialAuditData(audit);
    });

    asyncThunkReducer(getActualAuditData.action, (state, {payload: [audit]}) => {
      state.audit = getInitialAuditData(audit);
    });

    asyncThunkReducer(getAuditStructure.action, (state, {payload}) => {
      const structures: Record<string, AuditStructure> = {};
      payload.forEach((structure) => {
        structures[structure.id] = structure;
      });

      state.auditStructures = {
        ...state.auditStructures,
        ...structures,
      };
    });

    asyncThunkReducer(getNewestAuditStructure.action, (state, {payload}) => {
      state.newestAuditStructure = payload?.[0] ?? null;
    });

    asyncThunkReducer(
      updateAudit.action,
      (
        state,
        {
          payload,
          meta: {
            arg: {userName, withoutReducer},
          },
        }
      ) => {
        if (withoutReducer) {
          return;
        }

        const {categories, state: responseAuditState} = payload;

        categories.forEach(({id: categoryId, fields}) => {
          fields.forEach((field) => {
            updateField(state)({
              categoryId,
              ...field,
            });
          });
        });

        if (!state.audit) {
          return;
        }

        state.audit = {
          ...state.audit,
          state: responseAuditState,
          user: payload.user ?? userName ?? null,
          updateAt: new Date().toISOString(),
        };
      }
    );

    asyncThunkReducer(
      deleteAuditAsset.action,
      (
        state,
        {
          meta: {
            arg: {userName, withoutReducer},
          },
        }
      ) => {
        if (withoutReducer || !state.audit) {
          return;
        }
        state.audit.state = LoadAuditDataResponseItemBody.state.IN_PROGRESS;
        state.audit.user = userName ?? null;
      }
    );

    asyncThunkReducer(
      imagesUpload.action,
      (
        state,
        {
          meta: {
            arg: {userName, withoutReducer},
          },
        }
      ) => {
        if (withoutReducer || !state.audit) {
          return;
        }

        state.audit.state = LoadAuditDataResponseItemBody.state.IN_PROGRESS;
        state.audit.user = userName ?? null;
      }
    );

    asyncThunkReducer(
      finishAudit.action,
      (
        state,
        {
          meta: {
            arg: {finishedAt, userName},
          },
        }
      ) => {
        if (!finishedAt || !state.audit) {
          return;
        }

        state.audit = {
          ...state.audit,
          state: LoadAuditDataResponseItemBody.state.FINISHED_AUDIT,
          updateAt: finishedAt,
          finishedAt,
          user: userName ?? null,
        };
      }
    );

    asyncThunkReducer(getAuditExportTemplates.action, (state, {payload}) => {
      if (isNil(payload) || isNotArray(payload)) {
        return;
      }
      state.auditExportTemplates = payload?.filter(
        (template) =>
          template.documentKindCode === 'vehicle-condition' ||
          template.documentKindCode === 'inspection'
      );
    });

    asyncThunkReducer(
      getAuditStructureForStructureSettings.action,
      (
        state,
        {
          payload,
          meta: {
            arg: {templateId, inspectionType},
          },
        }
      ) => {
        const structure = payload?.[0];
        if (!structure) {
          return;
        }

        if (inspectionType === InspectionType.VEHICLE_CONDITION) {
          state.newestConditionSettingsStructure = structure;
        }

        state.auditSettingBasicStructures = {
          ...state.auditSettingBasicStructures,
          [templateId ?? 'newest']: structure,
        };
      }
    );

    asyncThunkReducer(
      getAuditStructureSettings.action,
      (state, {payload}) => {
        state.auditStructureSettings = payload;
      },
      (state) => {
        state.auditStructureSettings = null;
      }
    );

    asyncThunkReducer(getAllAuditStructureTemplates.action, (state, {payload}) => {
      state.auditStructureTemplates = payload;
    });

    asyncThunkReducer(updateAuditStructureSettings.action, (state, {payload}) => {
      state.auditStructureSettings = payload;
    });

    asyncThunkReducer(getVehicleOverallConditions.action, (state, {payload}) => {
      state.vehicleOverallConditions = payload;
    });

    handleThunkStates();
  },
});

const {reducer, actions} = carAudit;

export const {
  addAssets,
  addVideoAssets,
  setAuditLocalData,
  deleteAssets,
  deleteVideoAssets,
  updateAuditFields,
  updateAssetSrc,
  updateVideoAssetData,
  clearAuditFields,
  clearAuditAssets,
  setAuditForCreate,
  setErrorCategories,
  updateAuditState,
  updateAuditData,
} = actions;

export const carAuditReducer = reducer;
