import {
  Action,
  Actions,
  Alert,
  AlertProps,
  DataStatus,
  Flag,
  FlagProps,
  IconButton,
  openDialog,
  Parameter,
  Parameters,
  PreviewAction,
  Tooltip,
} from 'platform/components';
import {Box, Heading, HStack, Show, Space, Text, ThemeIconKey, VStack} from 'platform/foundation';

import {and, defaultTo, or} from 'ramda';
import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {VehicleConditionEvaluationType} from '../../hooks/useVehicleConditionEvaluation';
import i18n from '../../i18n/i18n';
import {EntityResourceIds} from '../../types/api/api';
import {SendEmail} from '../SendEmail/SendEmail';
import {SendSms} from '../SendSms/SendSms';
import {EntityHeaderControl, EntityHeaderControls} from './components/EntityHeaderControls';
import {EntityHeaderImage} from './components/EntityHeaderImage';
import {ListEditableTags} from './components/labels/ListEditableTags';
import {SnippetProps} from './components/Snippet';
import {SnippetPanel} from './components/SnippetPanel';
import {VehicleConditionEvaluation} from './components/VehicleConditionEvaluation';

export interface EntityHeaderFlag extends Omit<FlagProps, 'size'> {}

export interface EntityHeaderProps extends TestIdProps {
  image?: string;
  icon?: ThemeIconKey;
  iconActions?: PreviewAction[];
  flags?: EntityHeaderFlag[];
  title?: string;
  parameters?: Parameter[];
  primaryParameter?: string;
  secondaryParameter?: string;
  tertiaryParameter?: string;
  snippets?: SnippetProps[];
  isLoadingSnippets?: boolean;
  actions?: Action[];
  resourceId?: EntityResourceIds;
  recordId?: string;
  imageActions?: PreviewAction[];
  controls?: EntityHeaderControl[];
  vehicleConditionEvaluation?: VehicleConditionEvaluationType;
  isTagReadable?: boolean;
  isTagCreatable?: boolean;
  isTagUpdatable?: boolean;
  isTagDeletable?: boolean;
  isTagAssignable?: boolean;
  isTagUnassignable?: boolean;
  alert?: AlertProps;
}

export function EntityHeader(props: EntityHeaderProps) {
  return (
    <>
      <Box padding={4} backgroundColor="palettes.white.10.100">
        <VStack>
          <HStack spacing={4} align="stretch">
            <EntityHeaderImage
              icon={props.icon}
              image={props.image}
              imageActions={props.imageActions}
              iconActions={props.iconActions}
              data-testid={suffixTestId('entityHeader-image', props)}
            />
            <VStack spacing={2}>
              <Show
                when={or(
                  isNotNil(props.flags),
                  and(or(isNotNil(props.resourceId), isNotNil(props.recordId)))
                )}
              >
                <HStack spacing={1} align="center" wrap>
                  {props.flags?.map((flag: FlagProps, index) => (
                    <Flag
                      {...flag}
                      key={`${flag.label}-${flag.colorScheme}-${index}`}
                      data-testid={suffixTestId(`entityHeader-flags-flag-[${index}]`, props)}
                    />
                  ))}
                  <Show when={props.recordId && props.resourceId}>
                    <ListEditableTags
                      size="default"
                      buttonVariant="secondary"
                      recordId={props.recordId as string}
                      resourceId={props.resourceId as EntityResourceIds}
                      data-testid={suffixTestId('entityHeader-editable-tags', props)}
                      isTagDeletable={props.isTagDeletable}
                      isTagUpdatable={props.isTagUpdatable}
                      isTagReadable={props.isTagReadable}
                      isTagCreatable={props.isTagCreatable}
                      isTagAssignable={props.isTagAssignable}
                      isTagUnassignable={props.isTagUnassignable}
                    />
                  </Show>
                </HStack>
              </Show>
              <Heading size={2} data-testid={suffixTestId('entityHeader-title', props)}>
                {props.title}
              </Heading>
              <HStack spacing={1}>
                <Parameters
                  size="small"
                  color="secondary"
                  parameters={props.parameters}
                  data-testid={suffixTestId('entityHeader-parameters', props)}
                />
                <VehicleConditionEvaluation
                  size="small"
                  color="secondary"
                  vehicleCondition={props.vehicleConditionEvaluation}
                  data-testid={suffixTestId('entityHeader-vehicle-condition', props)}
                />
              </HStack>
              <Show when={and(isNotNil(props.recordId), props?.controls?.length)}>
                <EntityHeaderControls
                  controls={props.controls!}
                  resourceId={props.resourceId}
                  recordId={props.recordId as string}
                  data-testid={suffixTestId('entityHeader-controls', props)}
                />
              </Show>
            </VStack>
            <Space fillAvailable />
            <VStack align="flex-end" justify="space-between" spacing={2}>
              <Show when={isNotNilOrEmpty(props?.actions)}>
                <Actions
                  actions={props.actions}
                  data-testid={suffixTestId('entityHeader-actions', props)}
                />
              </Show>
              <Show
                when={props.primaryParameter || props.secondaryParameter || props.tertiaryParameter}
              >
                <VStack spacing={1} align="flex-end">
                  <Show when={props.primaryParameter}>
                    <HStack spacing={1} align="center">
                      <Heading
                        size={3}
                        isSingleLine
                        data-testid={suffixTestId('entityHeader-primaryParameter', props)}
                      >
                        {props.primaryParameter}
                      </Heading>
                      <Show
                        when={
                          props.resourceId === 'CUSTOMER' &&
                          props.primaryParameter !== i18n.t('entity.customer.labels.noPhoneNumber')
                        }
                      >
                        <Tooltip label={i18n.t('general.actions.sendMessage')} placement="top-end">
                          <IconButton
                            severity="informational"
                            data-testid="open-send-message-modal"
                            icon="communication/textsms"
                            onClick={() =>
                              openDialog(
                                <SendSms
                                  data-testid={`${props['data-testid']}-sendSmsModal`}
                                  customerId={defaultTo('', props.recordId)}
                                  selectedContactId={defaultTo('', props.recordId)}
                                />,
                                {
                                  scrollBehavior: 'outside',
                                  title: i18n.t('general.labels.smsMessage'),
                                }
                              )
                            }
                          />
                        </Tooltip>
                      </Show>
                      <Show when={isNotNilOrEmpty(props.tertiaryParameter)}>
                        <Heading
                          size={3}
                          isSingleLine
                          data-testid={suffixTestId('entityHeader-tertiaryParameter', props)}
                        >
                          {`/ ${props.tertiaryParameter ?? ''}`}
                        </Heading>
                      </Show>
                    </HStack>
                  </Show>
                  <Show when={props.secondaryParameter}>
                    <HStack spacing={1} align="center">
                      <Text
                        size="base"
                        color="secondary"
                        noWrap
                        data-testid={suffixTestId('entityHeader-secondaryParameter', props)}
                      >
                        {props.secondaryParameter}
                      </Text>
                      <Show
                        when={
                          props.resourceId === 'CUSTOMER' &&
                          props.secondaryParameter !== i18n.t('entity.customer.labels.noEmail')
                        }
                      >
                        <Tooltip label={i18n.t('general.actions.sendEmail')} placement="top-end">
                          <IconButton
                            severity="informational"
                            data-testid="open-send-email-modal"
                            icon="communication/email"
                            onClick={() =>
                              openDialog(
                                <SendEmail
                                  data-testid={`${props['data-testid']}-sendEmailModal`}
                                  customerId={props.recordId}
                                />,
                                {
                                  scrollBehavior: 'outside',
                                  title: i18n.t('general.labels.email'),
                                }
                              )
                            }
                          />
                        </Tooltip>
                      </Show>
                    </HStack>
                  </Show>
                </VStack>
              </Show>
            </VStack>
          </HStack>
          <Show when={props.snippets}>
            <DataStatus minHeight={23} isLoading={props.isLoadingSnippets}>
              <Space vertical={4} />
              <SnippetPanel
                snippets={props.snippets}
                data-testid={suffixTestId('entityHeader-snippets', props)}
              />
            </DataStatus>
          </Show>
        </VStack>
      </Box>
      <Show when={props.alert}>
        <Alert {...props.alert} data-testid={suffixTestId('entityHeader-alert', props)} />
      </Show>
    </>
  );
}
