import {moduleKeys} from '@omnetic-dms/config';
import {tiresInventoryRoutes} from '@omnetic-dms/routes';
import {Module, featureFlags, permissions} from '@omnetic-dms/shared';

import {CreateTireOrder} from './pages/CreateTireOrder/CreateTireOrder';
import {TireOrderDetail} from './pages/TireOrderDetail/TireOrderDetail';
import {TiresInventoryOverview} from './pages/TiresInventoryOverview/TiresInventoryOverview';

export const TiresInventoryModule: Module = {
  id: moduleKeys.tiresInventory,
  layoutProps: {
    title: 'module.tiresInventory.title',
    icon: 'modules/pneu_hotel',
    iconV2: 'sidebar/wheel',
  },
  routerProps: {
    path: tiresInventoryRoutes.overview,
    element: <TiresInventoryOverview />,
  },
  requiredPermission: permissions.pneuhotelRead,
  requiredFeatureFlag: featureFlags.PNEUHOTEL_BASIC_VIEW,
  content: [
    {
      id: 'tiresInventory-tireOrders',
      routerProps: {
        path: tiresInventoryRoutes.tireOrders,
        element: <TiresInventoryOverview />,
      },
      layoutProps: {
        title: 'page.tiresInventory.labels.tireOrders',
        icon: 'sidebar/pneuhotel',
      },
    },
    {
      id: 'tiresInventory-listOfTires',
      routerProps: {
        path: tiresInventoryRoutes.tireList,
        element: <TiresInventoryOverview />,
      },
      layoutProps: {
        title: 'page.tiresInventory.labels.tireList',
        icon: 'sidebar/wheel_list',
      },
    },
    {
      id: 'tiresInventory-createTireOrder',
      routerProps: {
        path: tiresInventoryRoutes.createTireOrder,
        element: <CreateTireOrder />,
      },
      layoutProps: {
        title: 'page.tiresInventory.labels.createTireOrder',
        isPartOfCreateMenuItem: true,
      },
    },
    {
      id: 'tiresInventory-tireOrder-detail',
      routerProps: {
        path: tiresInventoryRoutes.tireOrderDetail,
        element: <TireOrderDetail />,
      },
    },
  ],
};
