import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import styled from 'styled-components';

import {StyledTextareaProps, TextAreaStyles} from '../../Textarea/Textarea';

export const StyledContentEditable = styled(ContentEditable)<StyledTextareaProps>`
  ${TextAreaStyles}

  .mentions {
    font-size: ${({theme}) => theme.fontSizes.text.small};
    background-color: ${({theme}) => theme.colors.palettes.blue[30][100]};
  }

  .editor-link {
    color: rgb(33, 111, 219);
    text-decoration: none;
  }
`;
