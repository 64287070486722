import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  ValidationErrors,
} from 'platform/components';
import {Grid, GridItem, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {object} from 'yup';

import {useState} from 'react';
import {DeepPartial, UseFormReset, UseFormReturn} from 'react-hook-form';

import {find, head, indexBy, isNil, isNotNil, map, pipe, propOr} from 'ramda';
import {isFalsy, isNumber} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  DEFAULT_CURRENCY,
  EMPTY_PLACEHOLDER,
  handleApiError,
  Money,
  Rate,
  UpsellRequestBody,
  UpsellResponseBody,
  useBusinessCaseActions,
  useCreateUpsellMutation,
  useFinishPricesUpsellMutation,
  useGetBusinessCaseQuery,
  useGetTenantQuery,
  useGetVatRatesQuery,
  usePatchUpsellMutation,
  VatRate,
  VatTypeEnum,
} from '@omnetic-dms/shared';

import {
  Nullish,
  suffixTestId,
  TestIdProps,
  useDebouncedCallback,
  yupNumber,
  yupString,
} from 'shared';

interface UpsellDialogProps extends TestIdProps {
  initialValues?: UpsellResponseBody;
  businessCaseId: string;
  saleVehicleId: string | Nullish;
}

interface UpsellFormValues {
  name: string;
  vatCode: string;
  discountPercentage: number | Nullish;
  discountPrice:
    | {
        withVat: Money | Nullish;
        withoutVat: Money | Nullish;
      }
    | Nullish;
  catalogPrice:
    | {
        withVat: Money | Nullish;
        withoutVat: Money | Nullish;
      }
    | Nullish;
  purchasePrice:
    | {
        withVat: Money | Nullish;
        withoutVat: Money | Nullish;
      }
    | Nullish;
  sellingPrice:
    | {
        withVat: Money | Nullish;
        withoutVat: Money | Nullish;
      }
    | Nullish;
}

export function BusinessCaseUpsellForm(props: UpsellDialogProps) {
  const formatCurrency = useFormatCurrency();
  const [isCatalogPriceFromWithVat, setIsCatalogPriceFromWithVat] = useState(
    props.initialValues?.catalogPrice?.direction === 'FROM_PRICE_WITH_VAT'
  );
  const [isPurchasePriceFromWithVat, setIsPurchasePriceFromWithVat] = useState(
    props.initialValues?.purchasePrice?.direction === 'FROM_PRICE_WITH_VAT'
  );

  const {data: tenant} = useGetTenantQuery();
  const {data: vatRates} = useGetVatRatesQuery();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId: props.businessCaseId});
  const offer = head(businessCase?.offers ?? []);
  const {isActionEnabled} = useBusinessCaseActions(props.businessCaseId);

  const [finishPrices] = useFinishPricesUpsellMutation();
  const [createUpsell] = useCreateUpsellMutation();
  const [editUpsell] = usePatchUpsellMutation();

  const currency = tenant?.currency ?? DEFAULT_CURRENCY;

  const vatRatesOptions = pipe(
    find((item: VatRate) => item.code === tenant?.country),
    propOr([], 'rates'),
    map((rate: Rate) => ({
      ...rate,
      value: rate.type,
      label: `${rate.rate} % ${rate.name}`,
    }))
  )(vatRates ?? []);

  const vatRatesByCode = indexBy((vatRate) => vatRate.type, vatRatesOptions);

  const setCatalogPriceFromWithVat = () => setIsCatalogPriceFromWithVat(true);
  const setCatalogPriceFromWithoutVat = () => setIsCatalogPriceFromWithVat(false);
  const setPurchasePriceFromWithVat = () => setIsPurchasePriceFromWithVat(true);
  const setPurchasePriceFromWithoutVat = () => setIsPurchasePriceFromWithVat(false);

  const getUpsellBody = (values: UpsellFormValues, isSave = false): UpsellRequestBody => {
    const upsell = {
      name: values.name ?? null,

      purchaseVatRate: vatRatesByCode[values.vatCode]?.rate ?? null,
      purchaseVatCode: values.vatCode ?? null,
      purchasePrice: isNotNil(values.purchasePrice?.withoutVat?.amount)
        ? String(values.purchasePrice!.withoutVat!.amount)
        : null,
      purchasePriceWithVat: isNotNil(values.purchasePrice?.withVat?.amount)
        ? String(values.purchasePrice!.withVat!.amount)
        : null,

      catalogVatRate: vatRatesByCode[values.vatCode]?.rate ?? null,
      catalogVatCode: values.vatCode ?? null,
      catalogPrice: isNotNil(values.catalogPrice?.withoutVat?.amount)
        ? String(values.catalogPrice!.withoutVat!.amount)
        : null,
      catalogPriceWithVat: isNotNil(values.catalogPrice?.withVat?.amount)
        ? String(values.catalogPrice!.withVat!.amount)
        : null,

      sellingVatRate: isSave ? vatRatesByCode[values.vatCode]?.rate : null,
      sellingVatCode: isSave ? values.vatCode : null,
      sellingPrice: isSave ? values.sellingPrice?.withoutVat?.amount : null,
      sellingPriceWithVat: isSave ? values.sellingPrice?.withVat?.amount : null,

      discountVatRate: !isSave ? (vatRatesByCode[values.vatCode]?.rate ?? null) : null,
      discountVatCode: !isSave ? (values.vatCode ?? null) : null,
      discountPercentage:
        !isSave && isNotNil(values.discountPercentage) ? String(values.discountPercentage) : null,
      discountPrice: null,
      discountPriceWithVat:
        !isSave && isNotNil(values.discountPrice?.withVat?.amount)
          ? String(values.discountPrice!.withVat!.amount)
          : null,
    };

    if (isCatalogPriceFromWithVat) {
      upsell.catalogPrice = null;
      upsell.sellingPrice = null;
    } else {
      upsell.catalogPriceWithVat = null;
      upsell.sellingPriceWithVat = null;
    }

    if (isPurchasePriceFromWithVat) {
      upsell.purchasePrice = null;
    } else {
      upsell.purchasePriceWithVat = null;
    }

    return upsell;
  };

  const getFinishPricesBody = (
    values: UpsellFormValues,
    formApi: UseFormReturn<UpsellFormValues>
  ): UpsellRequestBody => {
    const {dirtyFields} = formApi.formState;

    const upsell = getUpsellBody(values);

    const isNonDiscountFieldDirty =
      !('discountPercentage' in dirtyFields) && !('discountPrice' in dirtyFields);

    if ('discountPercentage' in dirtyFields || isNonDiscountFieldDirty) {
      upsell.discountPriceWithVat = null;
    }

    if ('discountPrice' in dirtyFields && !isNonDiscountFieldDirty) {
      upsell.discountPercentage = null;
    }

    return upsell;
  };

  const handleFinishPrices = (
    formApi: UseFormReturn<UpsellFormValues>,
    values: UpsellFormValues
  ) => {
    const upsellBody = getFinishPricesBody(values, formApi);

    if (
      isNil(upsellBody.catalogPrice) &&
      isNil(upsellBody.catalogPriceWithVat) &&
      isNil(upsellBody.purchasePrice) &&
      isNil(upsellBody.purchasePriceWithVat)
    ) {
      return;
    }

    return finishPrices({
      businessCaseId: props.businessCaseId,
      offerId: offer?.id ?? '',
      offerSaleVehicleId: props.saleVehicleId ?? '',
      body: upsellBody,
    })
      .unwrap()
      .then((data) => {
        formApi.setValue('catalogPrice.withVat', data.catalogPrice?.withVat);
        formApi.setValue('catalogPrice.withoutVat', data.catalogPrice?.withoutVat);
        formApi.setValue('purchasePrice.withVat', data.purchasePrice?.withVat);
        formApi.setValue('purchasePrice.withoutVat', data.purchasePrice?.withoutVat);
        formApi.setValue('sellingPrice.withVat', data.sellingPrice?.withVat);
        formApi.setValue('sellingPrice.withoutVat', data.sellingPrice?.withoutVat);

        formApi.setValue(
          'discountPrice.withVat',
          isNotNil(data.discountPrice?.withVat?.amount) &&
            parseInt(data.discountPrice!.withVat!.amount) !== 0
            ? data.discountPrice?.withVat
            : null
        );
        formApi.setValue(
          'discountPrice.withoutVat',
          isNotNil(data.discountPrice?.withoutVat?.amount) &&
            parseInt(data.discountPrice!.withoutVat!.amount) !== 0
            ? data.discountPrice?.withoutVat
            : null
        );

        formApi.setValue(
          'discountPercentage',
          isNotNil(data.discountPrice?.percentage) && parseInt(data.discountPrice!.percentage) !== 0
            ? Number(data.discountPrice?.percentage)
            : null
        );

        formApi.reset({...formApi.getValues()});
      })
      .catch(handleApiError);
  };

  const handleOnChange = useDebouncedCallback(
    (
      values: UpsellFormValues,
      setErrors: (errors: ValidationErrors | null) => void,
      reset: UseFormReset<UpsellFormValues>,
      formApi: UseFormReturn<UpsellFormValues>
    ) => {
      handleFinishPrices(formApi, values);
    },
    DEBOUNCE_DELAY,
    DEBOUNCE_MAX_WAIT
  );

  const handleSubmit: FormSubmitHandler<UpsellFormValues> = (values) =>
    (props.initialValues?.id
      ? editUpsell({
          businessCaseId: props.businessCaseId,
          upsellId: props.initialValues.id,
          offerId: offer?.id ?? '',
          offerSaleVehicleId: props.saleVehicleId ?? '',
          body: getUpsellBody(values, true),
        })
      : createUpsell({
          businessCaseId: props.businessCaseId,
          offerId: offer?.id ?? '',
          offerSaleVehicleId: props.saleVehicleId ?? '',
          body: getUpsellBody(values, true),
        })
    )
      .unwrap()
      .then(closeCurrentDialog)
      .catch(handleApiError);

  const defaultValues: DeepPartial<UpsellFormValues> = {
    ...props.initialValues,
    name: props.initialValues?.name ?? undefined,
    vatCode: props.initialValues?.sellingPrice?.vatCode ?? VatTypeEnum.S,
    discountPrice:
      isNumber(props.initialValues?.discountPrice?.withVat?.amount) ||
      (!isNaN(Number(props.initialValues?.discountPrice?.withVat?.amount)) &&
        Number(props.initialValues?.discountPrice?.withVat?.amount) !== 0)
        ? props.initialValues?.discountPrice
        : null,
    discountPercentage:
      isNumber(props.initialValues?.discountPrice?.percentage) ||
      (!isNaN(Number(props.initialValues?.discountPrice?.percentage)) &&
        Number(props.initialValues?.discountPrice?.percentage) !== 0)
        ? Number(props.initialValues?.discountPrice?.percentage)
        : null,
  };

  return (
    <Form<UpsellFormValues>
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      onChange={handleOnChange}
      schema={schema}
    >
      {(control, formApi) => {
        const catalogPrice = formApi.watch('catalogPrice.withVat.amount');
        const sellingPrice = formApi.watch('sellingPrice.withVat.amount');

        return (
          <VStack spacing={4}>
            <Grid columns={4}>
              <GridItem span={2}>
                <FormField
                  name="name"
                  label={i18n.t('entity.businessCase.labels.upsellItemName')}
                  type="text"
                  isRequired
                  isDisabled={isFalsy(isActionEnabled('EDIT_UPSELL_ITEM'))}
                  control={control}
                  data-testid={suffixTestId('name', props)}
                />
              </GridItem>
              <FormField
                name="vatCode"
                label={i18n.t('general.labels.vatRate')}
                options={vatRatesOptions}
                type="choice"
                isDisabled={isFalsy(isActionEnabled('EDIT_UPSELL_ITEM'))}
                control={control}
                isNotClearable
                data-testid={suffixTestId('vatCode', props)}
              />
            </Grid>
            <Grid columns={2}>
              <FormField
                name="catalogPrice.withoutVat.amount"
                label={i18n.t('general.labels.sellingPriceWithoutVat')}
                currency={currency}
                isRequired
                isDisabled={isFalsy(isActionEnabled('EDIT_UPSELL_ITEM'))}
                onChange={setCatalogPriceFromWithoutVat}
                type="currency"
                control={control}
                data-testid={suffixTestId('catalogPrice-withoutVat-amount', props)}
              />
              <FormField
                name="catalogPrice.withVat.amount"
                label={i18n.t('general.labels.sellingPrice')}
                currency={currency}
                isRequired
                isDisabled={isFalsy(isActionEnabled('EDIT_UPSELL_ITEM'))}
                onChange={setCatalogPriceFromWithVat}
                type="currency"
                control={control}
                data-testid={suffixTestId('catalogPrice-withVat-amount', props)}
              />
            </Grid>
            <Grid columns={2}>
              <FormField
                name="purchasePrice.withoutVat.amount"
                label={i18n.t('general.labels.buyingPriceWithoutVat')}
                helperText={i18n.t('entity.businessCase.labels.upsellPurchasePriceHelper')}
                currency={currency}
                isDisabled={isFalsy(isActionEnabled('EDIT_UPSELL_ITEM'))}
                onChange={setPurchasePriceFromWithoutVat}
                type="currency"
                control={control}
                data-testid={suffixTestId('purchasePrice-withoutVat-amount', props)}
              />
              <FormField
                name="purchasePrice.withVat.amount"
                label={i18n.t('general.labels.purchasePrice')}
                currency={currency}
                isDisabled={isFalsy(isActionEnabled('EDIT_UPSELL_ITEM'))}
                onChange={setPurchasePriceFromWithVat}
                type="currency"
                control={control}
                data-testid={suffixTestId('purchasePrice-withVat-amount', props)}
              />
            </Grid>
            <Grid columns={6}>
              <FormField
                name="discountPercentage"
                label={i18n.t('page.businessCase.labels.discountPercentage')}
                type="number"
                control={control}
                minStepperValue={0}
                maxStepperValue={100}
                decimalPlaces={2}
                data-testid={suffixTestId('discountPercentage', props)}
              />
              <GridItem span={2}>
                <FormField
                  name="discountPrice.withVat.amount"
                  label={i18n.t('page.businessCase.labels.discountAmount')}
                  currency={currency}
                  isDisabled={isFalsy(isActionEnabled('EDIT_UPSELL_ITEM'))}
                  onChange={setCatalogPriceFromWithVat}
                  control={control}
                  type="currency"
                  data-testid={suffixTestId('discountAmount', props)}
                />
              </GridItem>
              <GridItem span={3}>
                <VStack spacing={2}>
                  <Text size="xSmall" color="secondary">
                    {i18n.t('page.businessCase.labels.discountedSellingPrice')}
                  </Text>
                  <Text alternative>
                    {formatCurrency(parseFloat(sellingPrice), currency, 2) ?? EMPTY_PLACEHOLDER}
                  </Text>
                </VStack>
              </GridItem>
            </Grid>
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={closeCurrentDialog}
                title={i18n.t('general.actions.cancel')}
                data-testid={suffixTestId('upsell-cancel', props)}
              />
              <FormButton
                title={
                  props.initialValues?.id
                    ? i18n.t('general.actions.updateItem')
                    : i18n.t('general.actions.addItem')
                }
                type="submit"
                control={control}
                data-testid={suffixTestId('upsell-submit', props)}
              />
            </ButtonGroup>
          </VStack>
        );
      }}
    </Form>
  );
}

const schema = object({
  name: yupString.required(),
  vatCode: yupString.required(),
  catalogPrice: object({
    withoutVat: object({
      amount: yupString.required(),
    }).nullable(),
    withVat: object({
      amount: yupString.required(),
    }).nullable(),
  }).nullable(),
  purchasePrice: object({
    withoutVat: object({
      amount: yupString,
    }).nullable(),
    withVat: object({
      amount: yupString,
    }).nullable(),
  }).nullable(),
  discountPrice: object({
    withoutVat: object({
      amount: yupString,
    }).nullable(),
    withVat: object({
      amount: yupString,
    }).nullable(),
  }).nullable(),
  discountPercentage: yupNumber.min(0).max(100).nullable(),
});

const DEBOUNCE_DELAY = 700;
const DEBOUNCE_MAX_WAIT = 10000;
