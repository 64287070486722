import {format as formatDate, startOfToday} from 'date-fns';
import {Button, showNotification} from 'platform/components';
import {Heading, HStack} from 'platform/foundation';

import {head, isEmpty, isNotNil} from 'ramda';
import {isString} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  COUNTRY_CZE,
  CURRENCY_CZK,
  formatDocumentTitle,
  handleApiError,
  useGetUserStatsQuery,
  useSourcingExportAllUserToBuyToCsvMutation,
  useSourcingListUserToBuyQuery,
  useSourcingPushVehiclesToSaleMutation,
} from '@omnetic-dms/shared';
import {
  classifiedsDataView,
  DEFAULT_ORDER_ID,
  downloadCsv,
  useSourcingCurrency,
  useSourcingTargetCountry,
} from '@omnetic-dms/teas';

import {SourcingDataStatus} from '../../components/SourcingDataStatus/SourcingDataStatus';
import {SourcingLayout} from '../../components/SourcingLayout/SourcingLayout';
import {useSourcingTable} from '../../components/SourcingTable/hooks/useSourcingTable';
import {useSourcingTableColumns} from '../../components/SourcingTable/hooks/useSourcingTableColumns';
import {SourcingTable} from '../../components/SourcingTable/SourcingTable';
import {VehiclePreview} from '../../components/VehiclePreview/VehiclePreview';
import {useCurrentWorkspace} from '../../hooks/useCurrentWorkspace';
import {getSourcingVehicleId} from '../../utils/getSourcingVehicleId';

export function UserToBuyPage() {
  const currency = useSourcingCurrency();
  const targetCountry = useSourcingTargetCountry();
  const currentWorkspace = useCurrentWorkspace();
  const [state, dispatch] = useSourcingTable('user-to-buy', DEFAULT_ORDER_ID);
  const columns = useSourcingTableColumns();

  const sortByOptions =
    classifiedsDataView.sortByOptions?.map((item) => ({
      value: item.id,
      label: item.name,
      orderBy: item.orderBy,
    })) ?? [];
  const orderBy = sortByOptions.find((item) => item.value === state.orderBy)?.orderBy;

  const {data, isLoading, isFetching, error} = useSourcingListUserToBuyQuery({
    orderBy: isEmpty(orderBy) ? undefined : orderBy?.join(','),
    currency: currency?.code ?? CURRENCY_CZK,
    buyerCountry: targetCountry?.code ?? COUNTRY_CZE,
    size: 1000,
  });

  const {data: userStatistics} = useGetUserStatsQuery();

  const [pushVehiclesToSale] = useSourcingPushVehiclesToSaleMutation();
  const [exportUserToBuyToCsv, {isFetching: isExporting}] =
    useSourcingExportAllUserToBuyToCsvMutation();

  const handleExport = () => {
    exportUserToBuyToCsv({
      currency: currency?.code,
      buyerCountry: targetCountry?.code,
    })
      .unwrap()
      .then((csvContent) => {
        if (isString(csvContent)) {
          downloadCsv(
            csvContent,
            `${currentWorkspace?.name}_${formatDate(startOfToday(), 'yyyyMMdd')}.csv`
          );
          return;
        }

        throw new Error('CSV content must be string.');
      })
      .catch(handleApiError);
  };

  const handleBuy = () => {
    pushVehiclesToSale({
      externalIds: rows
        .filter((row) => row.sourcingVehicle.isAvailable)
        .map(({sourcingVehicle}) => sourcingVehicle?.adId),
    })
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('entity.vehicle.notifications.vehiclesPushedToSales'))
      )
      .catch(handleApiError);
  };

  const rows = data?.data ?? [];

  return (
    <SourcingDataStatus
      iconUrl="/assets/images/empty_images/empty-basket.svg"
      text={i18n.t('entity.vehicle.notifications.nothingToBuy')}
      isEmpty={!isLoading && isEmpty(rows)}
      isError={isNotNil(error)}
    >
      <SourcingLayout
        header={
          <HStack align="center" justify="space-between">
            <Heading size={2} alternative>
              {i18n.t('entity.vehicle.labels.toBuyPage')}
            </Heading>
            <HStack spacing={2}>
              <Button
                title={`${i18n.t('general.actions.exportAll')} (${
                  userStatistics?.userStats?.toBuyCount ?? 0
                })`}
                variant="outlined"
                leftIcon="content/save_alt"
                isDisabled={isExporting}
                isLoading={isExporting}
                onClick={handleExport}
                data-testid={testIds.sourcing.classifieds('shoppingList-exportButton')}
              />
              <Button
                variant="primary"
                leftIcon="maps/local_mall"
                title={`${i18n.t('general.actions.buyNowAll')} (${
                  userStatistics?.userStats?.toBuyCount ?? 0
                })`}
                onClick={handleBuy}
                data-testid={testIds.sourcing.classifieds('shoppingList-buyButton')}
              />
            </HStack>
          </HStack>
        }
        content={
          <SourcingTable
            state={state}
            dispatch={dispatch}
            columns={columns}
            sortByOptions={sortByOptions}
            data={rows}
            isLoading={isLoading || isFetching}
            error={error}
          />
        }
        iframe={
          <VehiclePreview vehicleId={getSourcingVehicleId(state.selectedRow ?? head(rows))} />
        }
        pageTitle={formatDocumentTitle(
          i18n.t('entity.vehicle.labels.toBuyPage'),
          i18n.t('page.sourcing.labels.classifieds'),
          i18n.t('page.sourcing.labels.sourcing')
        )}
      />
    </SourcingDataStatus>
  );
}
