import {Button, Dropdown, DropdownItem} from 'platform/components';
import {Box, Right} from 'platform/foundation';
import styled from 'styled-components';

import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {testIds, vehiclesRoutes} from '@omnetic-dms/routes';
import {
  InspectionType,
  useCreateAuditMutation,
  useGetInspectionTemplatesQuery,
  usePermissions,
} from '@omnetic-dms/shared';

import {composePath, useRequiredParams} from 'shared';

export const ScrollAreaWrapper = styled.div`
  overflow-y: auto;
  max-height: 18.75rem;
`;

interface NewInspectionButtonProps {
  vehicleId?: string;
  openLinkInNewTab?: boolean;
}

export const NewInspectionButton = (props: NewInspectionButtonProps) => {
  const {id: vehicleId} = useRequiredParams();
  const navigate = useNavigate();
  // const inspectionTemplates = useSelector(selectInspectionsStructureTemplates);
  const [createAuditV2, {isLoading: isCreateAuditLoading}] = useCreateAuditMutation();
  const {data: inspectionTemplates} = useGetInspectionTemplatesQuery({isActive: true});

  const filteredInspectionTemplates = useMemo(
    () =>
      inspectionTemplates?.filter(
        (template) => template.inspectionType !== InspectionType.VEHICLE_CONDITION
      ),
    [inspectionTemplates]
  );
  const [hasCreateInspectionPermission] = usePermissions({
    permissionKeys: ['createInspection'],
  });

  const handleCreateInspection = async (
    auditStructureId: string,
    inspectionType: InspectionType
  ) => {
    const saneVehicleId = props.vehicleId ? props.vehicleId : vehicleId;

    const response = await createAuditV2({
      vehicleId: saneVehicleId,
      body: {
        inspectionType,
        auditStructureId,
      },
    }).unwrap();

    const newLink = composePath(vehiclesRoutes.inspectionDetailTab, {
      params: {
        id: saneVehicleId,
        inspectionId: response.id,
        inspectionTab: '_',
      },
    });

    if (props.openLinkInNewTab) {
      return window.open(newLink, '_blank');
    }

    navigate(newLink);
  };

  if (!filteredInspectionTemplates || !hasCreateInspectionPermission) {
    return null;
  }

  return (
    <Box paddingBottom={6}>
      <Right>
        <Dropdown
          data-testid={testIds.vehicles.conditions('newInspection')}
          placement="bottom-end"
          dropdownControl={
            <Button
              data-testid={testIds.vehicles.conditions('newInspection-dropdownControl')}
              title={i18n.t('entity.condition.labels.newInspection')}
              isLoading={isCreateAuditLoading}
            />
          }
        >
          <ScrollAreaWrapper data-testid={testIds.vehicles.conditions('scrollAreaWrapper')}>
            {filteredInspectionTemplates.map((template, index) => (
              <DropdownItem
                key={`$template-${template.id}`}
                data-testid={testIds.vehicles.conditions(`newInspection-[${index}]`)}
                label={template.name}
                onClick={() => handleCreateInspection(template.id, template.inspectionType)}
              />
            ))}
          </ScrollAreaWrapper>
        </Dropdown>
      </Right>
    </Box>
  );
};
