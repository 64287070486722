import {Radio, Option, NumberInput, Label, FormControl} from 'platform/components';
import {Box, Show, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {Fragment} from 'react';
import {UseFormReturn, useWatch} from 'react-hook-form';

import {always, defaultTo, isNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {DEFAULT_CURRENCY, SalesPriceCalculationEnum} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {WarehouseSettingsForm} from '../types/WarehouseSettingsForm';

type FieldName =
  | 'salesPriceCalculation'
  | 'salePriceMarginPercent'
  | 'salePriceMarkupPercent'
  | 'salePriceProfitWithoutVat';

interface SalesPriceCalculationProps extends RequiredTestIdProps {
  control: FormControl<WarehouseSettingsForm>;
  formApi: UseFormReturn<WarehouseSettingsForm>;
  calculationSalesPrice: Option[] | Nullish;
}

const PERCENTAGE_SIGN = '%';
const MARGIN_ON_BASE = 'MARGIN_ON_BASE';
const MARKUP_ON_BASE = 'MARKUP_ON_BASE';
const PROFIT_ON_BASE = 'PROFIT_ON_BASE';

export function SalesPriceCalculation(props: SalesPriceCalculationProps) {
  const [
    currency,
    salesPriceCalculation,
    salePriceMarginPercent,
    salePriceMarkupPercent,
    salePriceProfitWithoutVat,
  ] = useWatch({
    control: props.control,
    name: [
      'currency',
      'salesPriceCalculation',
      'salePriceMarginPercent',
      'salePriceMarkupPercent',
      'salePriceProfitWithoutVat',
    ],
  });

  const errorMessage = props.formApi.getFieldState('salesPriceCalculation').error?.message;

  const handleChange = (field: FieldName, value: string | number | null) => {
    const typeofValue = typeof value;

    const fieldValue = match(typeofValue)
      .returnType<SalesPriceCalculationEnum | number | null>()
      .with('string', always(value as SalesPriceCalculationEnum))
      .with('number', always(value as number))
      .otherwise(always(null));

    props.formApi.setValue(field, fieldValue);
  };

  return (
    <VStack data-testid={props['data-testid']}>
      <Label isRequired tooltip={i18n.t('entity.warehouse.labels.salesPriceCalculationTooltip')}>
        {i18n.t('entity.warehouse.labels.salesPriceCalculation')}
      </Label>
      <VStack spacing={3}>
        {props.calculationSalesPrice?.map((option, index) => (
          <Fragment key={option.value}>
            <Radio
              value={salesPriceCalculation}
              onChange={(value) => handleChange('salesPriceCalculation', value)}
              options={[{label: option.label, value: option.value}]}
              data-testid={suffixTestId(`radio-[${index}]`, props)}
            />

            <Show when={salesPriceCalculation === option.value}>
              <Box maxWidth="35%">
                {option.value === MARGIN_ON_BASE && (
                  <NumberInput
                    label={i18n.t('entity.warehouse.labels.defaultMargin')}
                    value={defaultTo(0, salePriceMarginPercent)}
                    onChange={(value) => handleChange('salePriceMarginPercent', value)}
                    suffix={PERCENTAGE_SIGN}
                    data-testid={suffixTestId('defaultMargin', props)}
                  />
                )}

                {option.value === MARKUP_ON_BASE && (
                  <NumberInput
                    label={i18n.t('entity.warehouse.labels.defaultMarkup')}
                    value={defaultTo(0, salePriceMarkupPercent)}
                    onChange={(value) => handleChange('salePriceMarkupPercent', value)}
                    suffix={PERCENTAGE_SIGN}
                    data-testid={suffixTestId('defaultMarkup', props)}
                  />
                )}

                {option.value === PROFIT_ON_BASE && (
                  <NumberInput
                    label={i18n.t('entity.warehouse.labels.defaultProfit')}
                    value={defaultTo(0, salePriceProfitWithoutVat)}
                    onChange={(value) => handleChange('salePriceProfitWithoutVat', value)}
                    suffix={defaultTo(DEFAULT_CURRENCY, currency)}
                    data-testid={suffixTestId('defaultProfit', props)}
                  />
                )}
              </Box>
            </Show>
          </Fragment>
        ))}
      </VStack>

      <Show when={isNil(salesPriceCalculation)}>
        <Box paddingTop={1}>
          <Text color="danger" size="xSmall">
            {errorMessage}
          </Text>
        </Box>
      </Show>
    </VStack>
  );
}
