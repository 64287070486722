import {isNotNil} from 'ramda';

import {AccountingDocument, InvoiceV3ResponseBody, Money} from '@omnetic-dms/shared';

type AccountingDocumentWithExchangeRate = InvoiceV3ResponseBody & {
  exchangeRateRatioDocumentSummary: {priceWithVat: Money};
};

export const getAccountingDocumentTotalPrice = (document: AccountingDocument) =>
  hasExchangeRateRatioDocumentSummary(document)
    ? document.exchangeRateRatioDocumentSummary.priceWithVat
    : (document?.totalPriceWithVat ?? null);

const hasExchangeRateRatioDocumentSummary = (
  document: AccountingDocument
): document is AccountingDocumentWithExchangeRate =>
  isNotNil((document as InvoiceV3ResponseBody)?.exchangeRateRatioDocumentSummary?.priceWithVat);
