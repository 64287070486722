import {Box, BoxProps, HStack, Space, VStack} from 'platform/foundation';

import {Outlet} from 'react-router-dom';

import {isTrue} from 'ramda-adjunct';

import {CommonSkeleton, MenuItemProps, useGetUserMenuPinsQuery} from '@omnetic-dms/shared';

import {moveItemToLast, suffixTestId, TestIdProps} from 'shared';

import {SidebarMenu} from './components/SidebarMenu';

interface LayoutProps extends TestIdProps {
  menuItems: MenuItemProps[];
  createMenuItems: MenuItemProps[];
}

const borderProps: BoxProps = {borderColor: 'general.separator', borderBottom: '1px solid'};

export function LayoutV2(props: LayoutProps) {
  const {data: pinnedMenuItems, isLoading} = useGetUserMenuPinsQuery();

  const menuItems = moveItemToLast<MenuItemProps>(
    (item) => isTrue(item.layoutProps?.isLastMenuItem),
    props.menuItems ?? []
  );

  if (isLoading) {
    return <CommonSkeleton />;
  }

  return (
    <VStack height="100%" width="100%" data-testid={props['data-testid']}>
      <Box
        position="fixed"
        width="100%"
        top={0}
        contain="layout"
        zIndex="MAIN_MENU"
        backgroundColor="general.white"
        data-testid={suffixTestId('headerWrapper', props)}
      >
        <HStack width="100%">
          <SidebarMenu
            data-testid={props['data-testid']}
            menuItems={menuItems}
            pinnedMenuItems={pinnedMenuItems}
          />
          <Box {...borderProps}>
            <Space horizontal={4} />
          </Box>

          <Box flex={1} {...borderProps}>
            TODO: T20-59677
          </Box>
        </HStack>
      </Box>
      <Box
        position="fixed"
        width={12}
        top={12}
        left={0}
        bottom={0}
        contain="layout"
        zIndex="MAIN_MENU"
        backgroundColor="palettes.neutral.10.100"
        data-testid={suffixTestId('menuWrapper', props)}
      >
        TODO: T20-59676
      </Box>
      <Box
        position="fixed"
        top={12}
        left={12}
        right={0}
        bottom={0}
        overflowY="auto"
        backgroundColor="palettes.neutral.10.100"
        data-testid={suffixTestId('layout-content', props)}
      >
        <Outlet />
      </Box>
    </VStack>
  );
}
