import {
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  IconButton,
  DataStatus,
  showNotification,
} from 'platform/components';
import {VStack, HStack} from 'platform/foundation';
import * as Yup from 'yup';

import {suffixTestId, TestIdProps} from 'shared';

import {
  useApplyCostPresetMutation,
  useApplyEarningPresetMutation,
  useDeleteCostPresetMutation,
  useDeleteEarningPresetMutation,
  useGetCostPresetListQuery,
  useGetEarningPresetListQuery,
} from '../../api/saleVehicleApi';
import i18n from '../../i18n/i18n';
import {
  ApplyCostPresetRequestBody,
  ApplyEarningPresetRequestBody,
  CostPresetBody,
} from '../../types/api/saleVehicle';
import {handleApiError} from '../../utils/handleApiError';

interface ApplyVehiclePresetFormProps extends TestIdProps {
  vehicleId: string;
  entity: 'earning' | 'cost';
  onCancel: VoidFunction;
  onSuccess: VoidFunction;
  businessCaseId?: string;
}

export function ApplyVehiclePresetForm(props: ApplyVehiclePresetFormProps) {
  const {
    data: costPresets,
    isLoading: isCostLoading,
    isError: isCostError,
  } = useGetCostPresetListQuery();
  const {
    data: earningPresets,
    isLoading: isEarningLoading,
    isError: isEarningError,
  } = useGetEarningPresetListQuery();
  const [deleteCost, {isLoading: isCostDeleting}] = useDeleteCostPresetMutation();
  const [deleteEarning, {isLoading: isEarningDeleting}] = useDeleteEarningPresetMutation();
  const [applyCostPreset] = useApplyCostPresetMutation();
  const [applyEarningPreset] = useApplyEarningPresetMutation();

  const isCost = 'cost' === props.entity;
  const options = isCost ? costPresets : earningPresets;

  const deletePreset = (preset: CostPresetBody) => {
    const showDeletedNotification = () =>
      showNotification.success(
        i18n.t('entity.earningsCosts.notifications.presetDeleted', {
          name: preset.label,
        })
      );

    if (isCost) {
      return deleteCost({costPresetId: preset.value})
        .unwrap()
        .then(showDeletedNotification)
        .catch(handleApiError);
    }

    deleteEarning({earningPresetId: preset.value})
      .unwrap()
      .then(showDeletedNotification)
      .catch(handleApiError);
  };

  const onSubmit: FormSubmitHandler<
    ApplyCostPresetRequestBody & ApplyEarningPresetRequestBody
  > = async (body) => {
    const applyPreset = isCost ? applyCostPreset : applyEarningPreset;
    await applyPreset({
      body: {...body, ...(props.businessCaseId && {businessCaseId: props.businessCaseId})},
      vehicleId: props.vehicleId,
    })
      .unwrap()
      .then(props.onSuccess)
      .catch(handleApiError);
  };

  return (
    <DataStatus
      isEmpty={!costPresets?.length && !earningPresets?.length}
      isLoading={isCostLoading || isEarningLoading}
      isError={isCostError || isEarningError}
    >
      <Form onSubmit={onSubmit} schema={schema}>
        {(control) => (
          <VStack spacing={4}>
            <HStack spacing={4} justify="space-between">
              <VStack>
                <FormField
                  control={control}
                  options={options}
                  type="checkboxes"
                  direction="vertical"
                  name={isCost ? 'costPresetIds' : 'earningPresetIds'}
                  data-testid={suffixTestId('presetIds', props)}
                />
              </VStack>
              <VStack spacing={3}>
                {options?.map((preset, index) => (
                  <IconButton
                    data-testid={suffixTestId(`iconButton-${index}`, props)}
                    size="small"
                    key={preset.value}
                    icon="action/delete"
                    onClick={() => deletePreset(preset)}
                    isDisabled={isCostDeleting || isEarningDeleting}
                  />
                ))}
              </VStack>
            </HStack>

            <ButtonGroup align="right">
              <FormButton
                variant="secondary"
                title={i18n.t('general.actions.cancel')}
                onClick={props.onCancel}
                control={control}
                data-testid={suffixTestId('cancel', props)}
              />
              <FormButton
                type="submit"
                variant="primary"
                control={control}
                title={i18n.t('general.actions.add')}
                data-testid={suffixTestId('add', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const schema = Yup.object().shape({
  costPresetIds: Yup.array()
    .nullable()
    .test(
      'costPresetIds',
      i18n.t('general.validations.chooseAtLeastOne'),
      (value, context) => !!(context.parent.earningPresetIds?.length || value?.length)
    ),
  earningPresetIds: Yup.array()
    .nullable()
    .test(
      'earningPresetIds',
      i18n.t('general.validations.chooseAtLeastOne'),
      (value, context) => !!(context.parent.costPresetIds?.length || value?.length)
    ),
});
