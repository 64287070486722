import {Option} from 'platform/components';

import {partition} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  FALLBACK_LANGUAGE,
  useGetCustomTenantCatalogueQuery,
  useGetMakeModelWithMakeQuery,
  useGetTopMakesQuery,
  VehicleTypeEnumObject,
} from '@omnetic-dms/shared';

import {GroupType} from '../types/GroupType';

interface UseVehicleMakeProps {
  vehicleType: VehicleTypeEnumObject;
  unknownMakes?: Option[];
}

export function useVehicleMake(props: UseVehicleMakeProps) {
  const unknownMakes = props.unknownMakes || [];

  const vehicleMakesQuery = useGetMakeModelWithMakeQuery(
    {
      vehicleType: props.vehicleType,
      lang: [i18n.resolvedLanguage ?? FALLBACK_LANGUAGE],
    },
    {skip: !props.vehicleType}
  );

  const customTenantMakeModelsQuery = useGetCustomTenantCatalogueQuery();
  const topMakesQuery = useGetTopMakesQuery();

  const isLoading =
    vehicleMakesQuery.isLoading || customTenantMakeModelsQuery.isLoading || topMakesQuery.isLoading;

  const isError =
    vehicleMakesQuery.isError || customTenantMakeModelsQuery.isError || topMakesQuery.isError;

  const globalMakes =
    vehicleMakesQuery.data?.map<Option>((item) => ({
      label: item.default_label,
      value: item.make,
    })) || [];

  const tenantCustomMakeSlice = customTenantMakeModelsQuery.data?.[props.vehicleType]?.makes;

  const customMakes = tenantCustomMakeSlice
    ? Object.entries(tenantCustomMakeSlice).reduce<Option[]>(
        (acc, [key, value]) => (value.isCustom ? [...acc, {label: key, value: key}] : acc),
        []
      )
    : [];

  const flatMakesOptions = [...globalMakes, ...customMakes];

  const [topMakesRaw, otherMakesRaw] = partition(
    (option) => topMakesQuery.data?.some((item) => item.code === option.value) || false,
    flatMakesOptions
  );

  const topMakes = topMakesRaw.sort((a, b) => a.label.localeCompare(b.label));
  const otherMakes = otherMakesRaw.sort((a, b) => a.label.localeCompare(b.label));

  const flatMakes = [...topMakes, ...otherMakes, ...unknownMakes];

  const groupedMakeOptions: GroupType[] = [
    {
      label: i18n.t('general.labels.topMakes'),
      options: topMakes,
    },
    {
      label: i18n.t('general.labels.allMakes'),
      options: [...otherMakes, ...unknownMakes],
    },
  ];

  return {
    groupedMakeOptions,
    detail: {
      globalMakes,
      customMakes,
      flatMakes,
    },
    isLoading,
    isError,
  };
}
