import {openDialog} from 'platform/components';
import {Show, Text, VStack} from 'platform/foundation';

import {Nullish} from 'shared';

import i18n from '../../i18n/i18n';
import {FileResponseBody} from '../../types/api/api';
import {SendEmail} from '../SendEmail/SendEmail';
import {DocumentItem} from './components/DocumentItem';

interface DocumentsDialogProps {
  documents: FileResponseBody[];
  text?: string;
  customerId?: string | Nullish;
  isDocxFormat?: boolean;
}

export function DocumentsDialog(props: DocumentsDialogProps) {
  const handleSendEmail = (document: FileResponseBody) => {
    const documentData = {
      title: document.pdfFilename,
      fileUri: document.pdfUri,
      fileName: document.pdfFilename,
      fileId: document.id,
    };

    openDialog(
      <SendEmail
        data-testid="documents-dialog"
        customerId={props.customerId}
        documents={[documentData]}
      />,
      {
        scrollBehavior: 'outside',
        title: i18n.t('general.labels.email'),
        size: 'small',
      }
    );
  };

  return (
    <VStack spacing={6}>
      <Show when={props.text}>
        <Text size="small" color="secondary">
          {props.text}
        </Text>
        <VStack spacing={4}>
          {props.documents.map((document) => (
            <DocumentItem
              key={document.id}
              document={document}
              onSendEmail={handleSendEmail}
              data-testid="document-dialog-item"
              isDocxFormat={props.isDocxFormat}
            />
          ))}
        </VStack>
      </Show>
    </VStack>
  );
}
