import {
  Alert,
  Button,
  Card,
  Choice,
  DataStatus,
  openDeleteDialog,
  Separator,
} from 'platform/components';
import {HStack, Hide, Scroll, Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {always, defaultTo, equals, head, isNil, not, reject} from 'ramda';
import {isArray, isNilOrEmpty} from 'ramda-adjunct';

import {Either, Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import i18n from '../../i18n/i18n';
import {Basket} from '../../types/basket/Basket';
import {BasketMechanic} from '../../types/basket/BasketMechanic';
import {EitherQuantityOrError} from '../../types/basket/EitherQuantityOrError';
import {HiddenMechanic} from '../../types/basket/HiddenMechanic';
import {MappedBasketItem} from '../../types/basket/MappedBasketItem';
import {getOptionsFromMechanics} from '../../utils/getOptionsFromMechanics';
import {mergeBasketItems} from '../../utils/mergeBasketItems';
import {BasketSummary} from '../BasketSummary/BasketSummary';
import {AfterSalesCorrectionBasketItem} from './components/AfterSalesCorrectionBasketItem';
import {CorrectionBasketItem} from './types/CorrectionBasketItem';
import {CorrectionBasketItemEditingDetails} from './types/CorrectionBasketItemEditingDetails';

interface AfterSalesCorrectionBasketProps<T extends CorrectionBasketItem>
  extends RequiredTestIdProps {
  basket: Basket<T>;
  mechanic: Either<BasketMechanic, HiddenMechanic>;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
  onEdit: (editingDetails: CorrectionBasketItemEditingDetails) => void;
  onDelete: (itemsIds: string[]) => Promise<void>;
}

export function AfterSalesCorrectionBasket<T extends CorrectionBasketItem>(
  props: AfterSalesCorrectionBasketProps<T>
) {
  const newBasketItems = defaultTo([], props.basket?.items);

  const [basketItems, setBasketItems] = useState<MappedBasketItem<T>[] | Nullish>(() => {
    const initialState = reject(isNil, newBasketItems).map((basketItem) => ({
      ...basketItem,
      isSelected: false,
    }));
    return initialState;
  });

  const [isManuallyCleared, setIsManuallyCleared] = useState<boolean>(false);

  const mergedBasketItems = mergeBasketItems<T, MappedBasketItem<T>>(newBasketItems, basketItems);

  // Update the local mapped basket items to ensure latest state with mapped isSelected flag
  if (not(equals(basketItems, mergedBasketItems))) {
    setBasketItems(mergedBasketItems);
  }

  const basketItemsCount = defaultTo(0, basketItems?.length);

  const basketTotalPrice = props.basket?.totalPrice;

  const basketTitle = `${i18n.t('general.labels.addedItems')} (${basketItemsCount})`;

  const selectedMechanicId = props.mechanic?.assignedMechanic
    ? props.mechanic?.assignedMechanic?.id
    : null;

  const hasSomeBasketItemsSelected = basketItems?.some((basketItem) => basketItem.isSelected);

  const hasAllBasketItemsSelected = basketItems?.every((basketItem) => basketItem.isSelected);

  const assignedMechanicId = match([isNil(selectedMechanicId), isManuallyCleared])
    .with([true, true], always(null))
    .with([false, false], always(selectedMechanicId))
    .otherwise(always(selectedMechanicId));

  const handleMechanicChange = (mechanicId: string | number | string[] | null) => {
    if (props.mechanic?.isMechanicHidden) {
      return;
    }

    const parsedValue = (isArray(mechanicId) ? head(mechanicId) : mechanicId) as string | null;
    setIsManuallyCleared(isNil(parsedValue));

    const selectedMechanic = props.mechanic?.mechanics?.employees?.find(
      (mechanic) => mechanic?.id === parsedValue
    );

    if (isNil(selectedMechanic)) {
      return props.mechanic.onMechanicChange && props.mechanic.onMechanicChange(null);
    }

    props.mechanic.onMechanicChange &&
      props.mechanic.onMechanicChange({
        id: selectedMechanic.id!,
        isDefault: true,
        costCenterId: selectedMechanic.costCenterId!,
      });
  };

  const handleToggleSelection = () => {
    setBasketItems((prevBasketItems) =>
      prevBasketItems?.map((basketItem) => ({
        ...basketItem,
        isSelected: !hasAllBasketItemsSelected,
      }))
    );
  };

  const handleSelectBasketItem = (id: string, isSelected: boolean) => {
    setBasketItems((prevBasketItems) =>
      prevBasketItems?.map((basketItem) =>
        basketItem.id === id ? {...basketItem, isSelected} : basketItem
      )
    );
  };

  const handleBulkDelete = () => {
    const itemsIds = reject(isNil, basketItems ?? [])
      .filter((basketItem) => basketItem.isSelected)
      .map((selectedBasketItem) => selectedBasketItem.id);

    openDeleteDialog({onConfirm: () => props.onDelete(itemsIds)});
  };

  return (
    <Card title={basketTitle} isFullHeight data-testid={suffixTestId('wrapper', props)}>
      <VStack height="100%" spacing={3}>
        <Hide when={props.mechanic?.isMechanicHidden}>
          <Choice
            label={i18n.t('entity.order.labels.mechanic')}
            value={defaultTo('', assignedMechanicId)}
            options={defaultTo([], getOptionsFromMechanics(props.mechanic?.mechanics))}
            onChange={handleMechanicChange}
            errorMessage={props.mechanic?.mechanicError}
            isRequired={props.mechanic?.isMechanicRequired}
            data-testid={suffixTestId('assignedMechanic', props)}
          />
        </Hide>

        <Show when={props.basket.hasInvalidItems}>
          <Alert
            type="inline"
            variant="error"
            title={i18n.t('general.notifications.basketHasInvalidItems')}
            data-testid={suffixTestId('alert.hasInvalidItems', props)}
          />
        </Show>

        <HStack justify="space-between">
          <Hide when={isNilOrEmpty(basketItems)}>
            <Button
              type="button"
              variant="link"
              leftIcon="action/done_all"
              title={
                hasAllBasketItemsSelected
                  ? i18n.t('general.actions.unselectAll')
                  : i18n.t('general.actions.selectAll')
              }
              onClick={handleToggleSelection}
              data-testid={suffixTestId('actions.toggleSelect', props)}
            />
          </Hide>

          <Show when={hasSomeBasketItemsSelected}>
            <Button
              type="button"
              variant="dangerLink"
              leftIcon="navigation/close"
              title={i18n.t('general.actions.remove')}
              isLoading={props.basket.isDeletingItems}
              onClick={handleBulkDelete}
              data-testid={suffixTestId('actions.remove', props)}
            />
          </Show>
        </HStack>

        <Scroll flex={1} auto>
          <VStack height="100%" spacing={4}>
            <DataStatus
              isLoading={props.basket.isLoading}
              isError={props.basket.hasError}
              isEmpty={basketItemsCount === 0}
              emptyMessage={i18n.t('general.notifications.noItemsSelected')}
              minHeight="100%"
            >
              {basketItems?.map((basketItem, index) => (
                <AfterSalesCorrectionBasketItem
                  key={basketItem.id}
                  item={basketItem}
                  onSelect={handleSelectBasketItem}
                  onQuantityChange={props.onQuantityChange}
                  onEdit={props.onEdit}
                  onDelete={props.onDelete}
                  data-testid={suffixTestId(`basketItem-[${index}]`, props)}
                />
              ))}
            </DataStatus>
          </VStack>
        </Scroll>

        <Separator />

        <BasketSummary totalPrice={basketTotalPrice} data-testid={suffixTestId('summary', props)} />
      </VStack>
    </Card>
  );
}
