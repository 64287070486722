import {Option} from 'platform/components';
import {Nullish} from 'utility-types';

import {groupBy} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  FALLBACK_LANGUAGE,
  useGetCustomTenantCatalogueQuery,
  useGetMakeModelWithMakeQuery,
  VehicleTypeEnumObject,
} from '@omnetic-dms/shared';

import {GroupType} from '../types/GroupType';

const DEFAULT_MODEL_GROUP = 'MODEL_DEFAULT';

interface UseVehicleModelProps {
  vehicleType: VehicleTypeEnumObject;
  make: string | Nullish;
  unknownModels?: Option[];
}

export function useVehicleModel(props: UseVehicleModelProps) {
  const unknownModels = props.unknownModels || [];

  const vehicleModelQuery = useGetMakeModelWithMakeQuery(
    {
      vehicleType: props.vehicleType,
      make: props.make || '',
      lang: [i18n.resolvedLanguage ?? FALLBACK_LANGUAGE],
    },
    {skip: !props.vehicleType || !props.make}
  );

  const customTenantMakeModelsQuery = useGetCustomTenantCatalogueQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const isLoading = vehicleModelQuery.isLoading || customTenantMakeModelsQuery.isLoading;
  const isError = vehicleModelQuery.isError || customTenantMakeModelsQuery.isError;

  const makeLabel =
    vehicleModelQuery.data?.[0]?.default_label || // global make label
    props.make || // custom make label
    i18n.t('entity.vehicle.labels.otherLabel'); // fallback make label

  const customTenantModelSlice =
    customTenantMakeModelsQuery.data?.[props.vehicleType]?.makes?.[props.make || '']?.models;

  const customModels = customTenantModelSlice
    ? Object.keys(customTenantModelSlice).map((key) => ({label: key, value: key}) as Option)
    : [];

  const globalModelsRaw = vehicleModelQuery.data?.[0]?.models || [];
  const globalModels = globalModelsRaw.map(
    (item) => ({label: item.default_label, value: item.model}) as Option
  );

  const modelsGroupedRaw = groupBy(
    (model) => model.model_group?.group || DEFAULT_MODEL_GROUP,
    globalModelsRaw
  );

  const groupedAllModelOptions: GroupType[] = Object.entries(modelsGroupedRaw).map(
    ([key, value]) => {
      const label = value?.[0]?.model_group?.default_label || makeLabel; // group label

      const optionsRaw =
        value?.map<Option>((item) => ({label: item.default_label, value: item.model})) || [];

      const defaultGroupOptions = [...optionsRaw, ...customModels, ...unknownModels];

      return {
        label,
        options: key === DEFAULT_MODEL_GROUP ? defaultGroupOptions : optionsRaw,
      };
    }
  );

  const flatModels = [...globalModels, ...customModels, ...unknownModels];

  const getGroupedModelOptions = () => {
    if (groupedAllModelOptions.length) {
      return groupedAllModelOptions;
    }

    if (customModels.length || unknownModels.length) {
      return [
        {
          label: makeLabel,
          options: [...customModels, ...unknownModels],
        },
      ];
    }

    return [];
  };

  return {
    groupedModelOptions: getGroupedModelOptions(),
    detail: {
      globalModels,
      customModels,
      flatModels,
    },
    isLoading,
    isError,
  };
}
