import {DataStatus, Form, FormField} from 'platform/components';
import {FormToDataGridConnectorProps} from 'platform/datagrid';
import {VStack} from 'platform/foundation';

import {suffixTestId, RequiredTestIdProps} from 'shared';

import {useGetWarehousesQuery} from '../../api/metadaWarehouseApi';
import {useGetBranchListQuery} from '../../api/tenantApi';
import i18n from '../../i18n/i18n';
import {getOptionsFromBranches} from '../../utils/getOptionsFromBranches';
import {getOptionsFromWarehouses} from '../../utils/getOptionsFromWarehouses';

type MaterialArticleTabExternalFiltersForm = Partial<{
  branchId: string[];
  warehouseId: string[];
  isAvailable: boolean;
  isAvailableWarehouses: boolean;
  isStockQuantity: boolean;
}>;

type RequestTabMaterialWarehouseTabExternalFiltersProps = {
  directSaleVariantId?: string;
} & FormToDataGridConnectorProps &
  RequiredTestIdProps;

export function RequestTabMaterialWarehouseTabExternalFilters(
  props: RequestTabMaterialWarehouseTabExternalFiltersProps
) {
  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery({
    directSaleVariantId: props.directSaleVariantId,
  });

  const {
    data: branches,
    isLoading: branchesLoading,
    isError: isBranchesError,
  } = useGetBranchListQuery();

  const isLoading = isWarehousesLoading || branchesLoading;
  const isError = isWarehousesError || isBranchesError;

  const formValues: MaterialArticleTabExternalFiltersForm = {
    isAvailable: false,
    isAvailableWarehouses: false,
    isStockQuantity: false,
    branchId: [],
    warehouseId: [],
    ...props.values,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<MaterialArticleTabExternalFiltersForm> values={formValues} onChange={props.onChange}>
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="branchId"
              type="multiChoice"
              label={i18n.t('entity.branch.labels.branch')}
              options={getOptionsFromBranches(branches)}
              data-testid={suffixTestId('branch', props)}
            />
            <FormField
              control={control}
              name="warehouseId"
              type="multiChoice"
              label={i18n.t('entity.warehouse.labels.warehouse')}
              options={getOptionsFromWarehouses(warehouses)}
              data-testid={suffixTestId('warehouse', props)}
            />
            <FormField
              control={control}
              name="isAvailable"
              label={i18n.t('entity.warehouse.labels.availableOnly')}
              type="checkbox"
              data-testid={suffixTestId('availableOnly', props)}
            />
            <FormField
              control={control}
              name="isAvailableWarehouses"
              label={i18n.t('entity.warehouse.labels.availableWarehousesOnly')}
              type="checkbox"
              data-testid={suffixTestId('availableOnly', props)}
            />
            <FormField
              control={control}
              name="isStockQuantity"
              label={i18n.t('entity.warehouse.labels.inStock')}
              type="checkbox"
              data-testid={suffixTestId('inStock', props)}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
