import {z} from 'zod';

import {
  BasePriceSchema,
  BasketTooltipSchema,
  PriceWithAndWithoutVatSchema,
} from './aftersalesCommon';
import {AbortSignalSchema, BaseVoidResponseSchema, CreatedAndUpdatedBySchema} from './base';
import {GetArticleResponseSchema} from './metadaWarehouseArticle';

/**
 * Base types
 */
const BaseSupplierOrderSchema = z
  .object({
    orderId: z.string(),
    orderNumber: z.string(),
    orderExternalNumber: z.string(),
    supplierId: z.string(),
    state: z.string(),
    orderVariantId: z.string(),
    type: z.string(),
    completedAt: z.string(),
    orderedAt: z.string(),
    referenceNumber: z.string(),
    warehouseId: z.string(),
    currency: z.string(),
    exchangeRate: z.number(),
    ncConversion: z.string(),
  })
  .merge(CreatedAndUpdatedBySchema);

export type BaseSupplierOrder = z.infer<typeof BaseSupplierOrderSchema>;

const TotalPriceWithAndWithoutVatSchema = z.object({
  totalWithoutVat: BasePriceSchema,
  totalWithVat: BasePriceSchema,
});

export type TotalPriceWithAndWithoutVat = z.infer<typeof TotalPriceWithAndWithoutVatSchema>;

export const BaseSupplierOrderMaterialItemSchema = z.object({
  id: z.string(),
  warehouse_id: z.string(),
  itemEditingAllowed: z.boolean(),
  priceType: z.string(),
  requestType: z.string(),
  name: z.string(),
  number: z.string(),
  quantity: z.number(),
  unit: z.string(),
  dispensingUnit: z.number(),
  quantityEditingAllowed: z.boolean(),
  unitPrice: PriceWithAndWithoutVatSchema,
  discount: z.object({rate: z.number()}).merge(PriceWithAndWithoutVatSchema),
  totalPrice: PriceWithAndWithoutVatSchema,
  tooltip: z.array(BasketTooltipSchema),
});
export type BaseSupplierOrderMaterialItem = z.infer<typeof BaseSupplierOrderMaterialItemSchema>;

/**
 * GET requests
 */
export const GetSupplierOrderRequestSchema = z.object({
  orderId: z.string(),
});
export type GetSupplierOrderRequest = z.infer<typeof GetSupplierOrderRequestSchema>;

export const GetSupplierOrderResponseSchema = z.object({
  order: BaseSupplierOrderSchema,
  totals: TotalPriceWithAndWithoutVatSchema,
});
export type GetSupplierOrderResponse = z.infer<typeof GetSupplierOrderResponseSchema>;

export const GetSupplierOrderItemRequestSchema = z.object({
  orderId: z.string(),
  itemId: z.string(),
});
export type GetSupplierOrderItemRequest = z.infer<typeof GetSupplierOrderItemRequestSchema>;

export const GetSupplierOrderItemResponseSchema = z.object({
  orderItemId: z.string(),
  orderId: z.string(),
  orderDetail: z.object({
    supplierQuantity: z.number(),
    supplierUnit: z.string(),
    supplierUnitPrice: z.number(),
    supplierTotalPrice: z.number(),
    name: z.string(),
    catalogueNumber: z.string(),
    manufacturerId: z.string(),
    supplierOrderingNumber: z.string(),
    vat: z.string(),
    vin: z.string(),
    keyCode: z.string(),
    isInteligentPart: z.boolean(),
    currency: z.string(),
  }),
  requestDetail: z.object({
    warehouseRequestedQuantity: z.number(),
    warehouseUnitPurchasePrice: z.number(),
  }),
  supplierToWarehouseMapping: z.object({
    supplierQuantityMapping: z.number(),
    supplierUnitMapping: z.string(),
    warehouseQuantityMapping: z.number(),
    warehouseUnitMapping: z.string(),
  }),
  article: GetArticleResponseSchema,
});
export type GetSupplierOrderItemResponse = z.infer<typeof GetSupplierOrderItemResponseSchema>;

export const GetSupplierOrderMaterialBasketRequestSchema = z.object({orderId: z.string()});
export type GetSupplierOrderMaterialBasketRequest = z.infer<
  typeof GetSupplierOrderMaterialBasketRequestSchema
>;

export const GetSupplierOrderMaterialBasketResponseSchema = z.object({
  sparePartsBasketItem: z.array(BaseSupplierOrderMaterialItemSchema),
  sparePartsBasketTotalPrice: PriceWithAndWithoutVatSchema,
});
export type GetSupplierOrderMaterialBasketResponse = z.infer<
  typeof GetSupplierOrderMaterialBasketResponseSchema
>;

export const SupplierOrderTypeSchema = z.object({
  label: z.string(),
  value: z.string(),
  coefficient: z.number(),
});
export type SupplierOrderType = z.infer<typeof SupplierOrderTypeSchema>;

export const GetSupplierOrderTypeRequestSchema = z.object({
  supplierOrderVariantId: z.string(),
  supplierId: z.string(),
});
export type GetSupplierOrderTypeRequest = z.infer<typeof GetSupplierOrderTypeRequestSchema>;

export const GetSupplierOrderTypeResponseSchema = z.array(SupplierOrderTypeSchema);
export type GetSupplierOrderTypeResponse = z.infer<typeof GetSupplierOrderTypeResponseSchema>;

/**
 * POST requests
 */
export const PostSupplierOrderRequestSchema = z.object({
  orderVariantId: z.string(),
  supplierId: z.string(),
  orderType: z.string().nullish(),
});
export type PostSupplierOrderRequest = z.infer<typeof PostSupplierOrderRequestSchema>;

export const PostSupplierOrderResponseSchema = z.object({
  orderId: z.string().nullish(),
});
export type PostSupplierOrderResponse = z.infer<typeof PostSupplierOrderResponseSchema>;

export const BulkPostSupplierOrderRequestSchema = z
  .object({
    requestItemId: z.array(z.string()),
  })
  .merge(PostSupplierOrderRequestSchema);
export type BulkPostSupplierOrderRequest = z.infer<typeof BulkPostSupplierOrderRequestSchema>;

export const BulkPostSupplierOrderResponseSchema = z
  .object({
    orderItemId: z.array(z.string()),
  })
  .merge(PostSupplierOrderResponseSchema);
export type BulkPostSupplierOrderResponse = z.infer<typeof BulkPostSupplierOrderResponseSchema>;

export const BulkPostSupplierOrderItemsRequestSchema = z.object({
  orderId: z.string(),
  body: z.object({
    requestItemId: z.array(z.string()),
    itemState: z.union([z.literal('BASKET'), z.literal('ORDER')]),
  }),
});
export type BulkPostSupplierOrderItemsRequest = z.infer<
  typeof BulkPostSupplierOrderItemsRequestSchema
>;

export const BulkPostSupplierOrderItemsResponseSchema = z.object({
  orderItemId: z.array(z.string()),
});
export type BulkPostSupplierOrderItemsResponse = z.infer<
  typeof BulkPostSupplierOrderItemsResponseSchema
>;

export const PostSupplierOrderMaterialBasketItemRequestSchema = z.object({
  orderId: z.string(),
  body: z.object({articleId: z.string()}),
});
export type PostSupplierOrderMaterialBasketItemRequest = z.infer<
  typeof PostSupplierOrderMaterialBasketItemRequestSchema
>;

export const PostSupplierOrderMaterialBasketItemResponseSchema = z.object({
  orderItemId: z.string(),
});
export type PostSupplierOrderMaterialBasketItemResponse = z.infer<
  typeof PostSupplierOrderMaterialBasketItemResponseSchema
>;

export const PostSupplierOrderBasketCheckoutRequestSchema = z.object({orderId: z.string()});
export type PostSupplierOrderBasketCheckoutRequest = z.infer<
  typeof PostSupplierOrderBasketCheckoutRequestSchema
>;

export const PostSupplierOrderBasketCheckoutResponseSchema = z.object({
  requestItemId: z.array(z.object({id: z.string().nullish()})),
});
export type PostSupplierOrderBasketCheckoutResponse = z.infer<
  typeof PostSupplierOrderBasketCheckoutResponseSchema
>;

/**
 * PATCH request
 */
export const PatchSupplierOrderRequestSchema = z.object({
  orderId: z.string(),
  body: z.object({
    warehouseId: z.string(),
    supplierId: z.string().nullable(),
    orderVariantId: z.string(),
    orderExternalNumber: z.string(),
    referenceNumber: z.string(),
    type: z.string(),
    currency: z.string(),
    exchangeRate: z.number(),
    ncConversion: z.string(),
  }),
});
export type PatchSupplierOrderRequest = z.infer<typeof PatchSupplierOrderRequestSchema>;

export const PatchSupplierOrderResponseSchema = BaseVoidResponseSchema;
export type PatchSupplierOrderResponse = z.infer<typeof PatchSupplierOrderResponseSchema>;

export const PatchSupplierOrderStateRequestSchema = z.object({
  orderId: z.string(),
  body: z.object({
    orderState: z.union([z.literal('PENDING'), z.literal('CLOSED'), z.literal('SENT')]),
  }),
});
export type PatchSupplierOrderStateRequest = z.infer<typeof PatchSupplierOrderStateRequestSchema>;

export const PatchSupplierOrderStateResponseSchema = BaseVoidResponseSchema;
export type PatchSupplierOrderStateResponse = z.infer<typeof PatchSupplierOrderStateResponseSchema>;

export const PatchSupplierOrderItemRequestSchema = z.object({
  orderId: z.string(),
  itemId: z.string(),
  body: z.object({
    article: z.object({id: z.string()}),
    orderDetail: z.object({
      supplierQuantity: z.number(),
      supplierUnit: z.string(),
      supplierUnitPrice: z.number(),
      supplierTotalPrice: z.number(),
      supplierOrderingNumber: z.string(),
      vat: z.string(),
      vin: z.string(),
      keyCode: z.string(),
      isIntelligentPart: z.boolean(),
      currency: z.string(),
    }),
    requestDetail: z.object({
      warehouseRequestedQuantity: z.number(),
      warehouseUnitPurchasePrice: z.number(),
    }),
    supplierToWarehouseMapping: z.object({
      supplierQuantityMapping: z.number(),
      supplierUnitMapping: z.string(),
      warehouseQuantityMapping: z.number(),
      warehouseUnitMapping: z.string(),
    }),
  }),
});
export type PatchSupplierOrderItemRequest = z.infer<typeof PatchSupplierOrderItemRequestSchema>;

export const PatchSupplierOrderItemResponseSchema = BaseVoidResponseSchema;
export type PatchSupplierOrderItemResponse = z.infer<typeof PatchSupplierOrderItemResponseSchema>;

export const PatchSupplierOrderMaterialBasketItemQuantityRequestSchema = z.object({
  orderId: z.string(),
  itemId: z.string(),
  body: z.object({quantity: z.number()}),
});
export type PatchSupplierOrderMaterialBasketItemQuantityRequest = z.infer<
  typeof PatchSupplierOrderMaterialBasketItemQuantityRequestSchema
>;

export const PatchSupplierOrderMaterialBasketItemQuantityResponseSchema = BaseVoidResponseSchema;
export type PatchSupplierOrderMaterialBasketItemQuantityResponse = z.infer<
  typeof PatchSupplierOrderMaterialBasketItemQuantityResponseSchema
>;

/**
 * DELETE requests
 */
export const DeleteSupplierOrderRequestSchema = z.object({
  body: z.object({
    orderId: z.array(z.string()),
  }),
});
export type DeleteSupplierOrderRequest = z.infer<typeof DeleteSupplierOrderRequestSchema>;

export const DeleteSupplierOrderResponseSchema = BaseVoidResponseSchema;
export type DeleteSupplierOrderResponse = z.infer<typeof DeleteSupplierOrderResponseSchema>;

export const DeleteSupplierOrderItemsRequestSchema = z.object({
  orderId: z.string(),
  body: z.object({
    itemId: z.array(z.string()),
  }),
});
export type DeleteSupplierOrderItemsRequest = z.infer<typeof DeleteSupplierOrderItemsRequestSchema>;

export const DeleteSupplierOrderItemsResponseSchema = BaseVoidResponseSchema;
export type DeleteSupplierOrderItemsResponse = z.infer<
  typeof DeleteSupplierOrderItemsResponseSchema
>;

export const DeleteSupplierOrderMaterialBasketItemsRequestSchema = z.object({orderId: z.string()});
export type DeleteSupplierOrderMaterialBasketItemsRequest = z.infer<
  typeof DeleteSupplierOrderMaterialBasketItemsRequestSchema
>;

export const DeleteSupplierOrderMaterialBasketItemsResponseSchema = BaseVoidResponseSchema;
export type DeleteSupplierOrderMaterialBasketItemsResponse = z.infer<
  typeof DeleteSupplierOrderMaterialBasketItemsResponseSchema
>;

/**
 * PUT requests
 */
const SupplierOrderItemChangedFieldSchema = z.enum([
  'supplierQuantity',
  'warehouseRequestedQuantity',
  'supplierUnitPrice',
  'supplierQuantityMapping',
  'warehouseQuantityMapping',
]);
export type SupplierOrderItemChangedField = z.infer<typeof SupplierOrderItemChangedFieldSchema>;

export const PutSupplierOrderItemCalculationRequestSchema = z.object({
  orderItemId: z.string(),
  body: z.object({
    changedField: z.object({
      field: SupplierOrderItemChangedFieldSchema,
    }),
    orderDetail: z.object({
      supplierQuantity: z.number().nullish(),
      supplierUnitPrice: z.number().nullish(),
    }),
    requestDetail: z.object({
      warehouseRequestedQuantity: z.number().nullish(),
      warehouseUnitPurchasePrice: z.number().nullish(),
    }),
    supplierToWarehouseMapping: z.object({
      supplierQuantityMapping: z.number().nullish(),
      warehouseQuantityMapping: z.number().nullish(),
    }),
  }),
  signal: AbortSignalSchema,
});
export type PutSupplierOrderItemCalculationRequest = z.infer<
  typeof PutSupplierOrderItemCalculationRequestSchema
>;

export const PutSupplierOrderItemCalculationResponseSchema = z.object({
  orderDetail: z.object({
    supplierQuantity: z.number(),
    supplierUnitPrice: z.number(),
    supplierTotalPrice: z.number(),
  }),
  requestDetail: z.object({
    warehouseRequestedQuantity: z.number(),
    warehouseUnitPurchasePrice: z.number(),
  }),
  supplierToWarehouseMapping: z.object({
    supplierQuantityMapping: z.number(),
    warehouseQuantityMapping: z.number(),
  }),
});
export type PutSupplierOrderItemCalculationResponse = z.infer<
  typeof PutSupplierOrderItemCalculationResponseSchema
>;
