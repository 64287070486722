import {moduleKeys} from '@omnetic-dms/config';
import {quickPurchaseRoutes} from '@omnetic-dms/routes';
import {Module, featureFlags, permissions} from '@omnetic-dms/shared';

import {AuctionsList} from './pages/AuctionsList/AuctionsList';

export const QuickPurchaseModule: Module = {
  requiredFeatureFlag: featureFlags.QUICK_PURCHASE_MODULE,
  requiredPermission: permissions.quickPurchaseRead,
  id: moduleKeys.quickPurchase,
  layoutProps: {
    title: 'module.quickPurchase.title',
    icon: 'action/shopping_cart',
    iconV2: 'sidebar/add',
  },
  routerProps: {
    path: quickPurchaseRoutes.auctionsList,
    element: <AuctionsList />,
  },
  content: [],
};
