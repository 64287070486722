import {
  GetReceiveNoteCorrectionActionsRequest,
  GetReceiveNoteCorrectionActionsResponse,
  GetReceiveNoteCorrectionRequest,
  GetReceiveNoteCorrectionRequestSchema,
  GetReceiveNoteCorrectionResponse,
  GetReceiveNoteCorrectionResponseSchema,
  PatchReceiveNoteCorrectionDeleteRequest,
  PatchReceiveNoteCorrectionDeleteRequestSchema,
  PatchReceiveNoteCorrectionDeleteResponse,
  PatchReceiveNoteCorrectionDeleteResponseSchema,
  PatchReceiveNoteCorrectionRequest,
  PatchReceiveNoteCorrectionRequestSchema,
  PatchReceiveNoteCorrectionResponse,
  PatchReceiveNoteCorrectionResponseSchema,
  PostReceiveNoteCorrectionRequest,
  PostReceiveNoteCorrectionRequestSchema,
  PostReceiveNoteCorrectionResponse,
  PostReceiveNoteCorrectionResponseSchema,
  GetReceiveNoteCorrectionActionsRequestSchema,
  GetReceiveNoteCorrectionActionsResponseSchema,
  PutReceiveNoteCorrectionActionResponse,
  PutReceiveNoteCorrectionActionRequest,
  PutReceiveNoteCorrectionActionRequestSchema,
  PutReceiveNoteCorrectionActionResponseSchema,
} from '../types/api/metadaWarehouseReceiveNoteCorrection';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseReceiveNoteCorrectionApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getReceiveNoteCorrection: build.query<
      GetReceiveNoteCorrectionResponse,
      GetReceiveNoteCorrectionRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/${queryArg.creditNoteId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'receiveNoteCorrections', id: queryArg.creditNoteId},
      ],
      extraOptions: {
        requestSchema: GetReceiveNoteCorrectionRequestSchema,
        responseSchema: GetReceiveNoteCorrectionResponseSchema,
      },
    }),
    getReceiveNoteCorrectionAction: build.query<
      GetReceiveNoteCorrectionActionsResponse,
      GetReceiveNoteCorrectionActionsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/${queryArg.id}/actions`,
        method: 'GET',
        queryArg,
      }),
      providesTags: ['receiveNoteCorrectionActions'],
      extraOptions: {
        requestSchema: GetReceiveNoteCorrectionActionsRequestSchema,
        responseSchema: GetReceiveNoteCorrectionActionsResponseSchema,
      },
    }),
    putReceiveNoteCorrectionAction: build.mutation<
      PutReceiveNoteCorrectionActionResponse,
      PutReceiveNoteCorrectionActionRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/${queryArg.id}/action`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'receiveNoteCorrectionActions',
        {type: 'receiveNoteCorrections', id: queryArg.id},
      ],
      extraOptions: {
        requestSchema: PutReceiveNoteCorrectionActionRequestSchema,
        responseSchema: PutReceiveNoteCorrectionActionResponseSchema,
      },
    }),
    postReceiveNoteCorrection: build.mutation<
      PostReceiveNoteCorrectionResponse,
      PostReceiveNoteCorrectionRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v1/credit-note/manual',
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['receiveNoteCorrections'],
      extraOptions: {
        requestSchema: PostReceiveNoteCorrectionRequestSchema,
        responseSchema: PostReceiveNoteCorrectionResponseSchema,
      },
    }),
    patchReceiveNoteCorrection: build.mutation<
      PatchReceiveNoteCorrectionResponse,
      PatchReceiveNoteCorrectionRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/${queryArg.creditNoteId}/manual`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNoteCorrections', id: queryArg.creditNoteId},
      ],
      extraOptions: {
        requestSchema: PatchReceiveNoteCorrectionRequestSchema,
        responseSchema: PatchReceiveNoteCorrectionResponseSchema,
      },
    }),
    deleteReceiveNoteCorrection: build.mutation<
      PatchReceiveNoteCorrectionDeleteResponse,
      PatchReceiveNoteCorrectionDeleteRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/delete`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['receiveNoteCorrections'],
      extraOptions: {
        requestSchema: PatchReceiveNoteCorrectionDeleteRequestSchema,
        responseSchema: PatchReceiveNoteCorrectionDeleteResponseSchema,
      },
    }),
  }),
});

export const {
  useGetReceiveNoteCorrectionQuery,
  useGetReceiveNoteCorrectionActionQuery,
  usePutReceiveNoteCorrectionActionMutation,
  usePostReceiveNoteCorrectionMutation,
  usePatchReceiveNoteCorrectionMutation,
  useDeleteReceiveNoteCorrectionMutation,
} = metadaWarehouseReceiveNoteCorrectionApi;
