import {MakeFieldDefinitionResponseBody} from '@omnetic-dms/shared';

export const getReorderedDefinitionIds = (
  items: MakeFieldDefinitionResponseBody[],
  movedItem: MakeFieldDefinitionResponseBody,
  direction: 'up' | 'down'
) => {
  const index = items.indexOf(movedItem);

  if (index === -1 || items.length < 2) {
    return items;
  }

  const reordered = [...items];

  if (direction === 'up' && index > 0) {
    [reordered[index], reordered[index - 1]] = [reordered[index - 1], reordered[index]];
  } else if (direction === 'down' && index < items.length - 1) {
    [reordered[index], reordered[index + 1]] = [reordered[index + 1], reordered[index]];
  }

  return reordered;
};
