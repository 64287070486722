import {Show} from 'platform/foundation';
import {Lightbox} from 'platform/lightbox';

import {useSelector} from 'react-redux';

import {isNil} from 'ramda';

import {testIds} from '@omnetic-dms/routes';
import {
  EntityHeader,
  EntityResourceIds,
  featureFlags,
  selectSaleVehicle,
  useGetComplexPermissions,
  useGetVehicleLocalActionNoteQuery,
  useGetVehicleV2Query,
  usePermissions,
  useVehicleConditionEvaluation,
} from '@omnetic-dms/shared';

import {isFeatureEnabled, suffixTestId, TestIdProps} from 'shared';

import {useGetVehicleControls} from '../hooks/useGetVehicleControls';
import {useGetVehicleHeaderActions} from '../hooks/useGetVehicleHeaderActions';
import {useGetVehicleHeaderFlags} from '../hooks/useGetVehicleHeaderFlags';
import {useGetVehicleHeaderInformationParameters} from '../hooks/useGetVehicleHeaderInformationParameters';
import {useGetVehicleHeaderParameters} from '../hooks/useGetVehicleHeaderParameters';
import {useGetVehicleSnippets} from '../hooks/useGetVehicleSnippets';
import {useVehicleHeaderImage} from '../hooks/useVehicleHeaderImage';
import {TechnicalInspectionAlert} from './TechnicalInspectionAlert';

interface VehicleHeaderProps extends TestIdProps {
  vehicleId: string;
}

export function VehicleHeader(props: VehicleHeaderProps) {
  const {data: vehicle} = useGetVehicleV2Query({vehicleId: props.vehicleId});
  const {data: saleVehicle} = useSelector(selectSaleVehicle(props.vehicleId));

  const isSaleVehicle = !!saleVehicle;

  const actions = useGetVehicleHeaderActions(props.vehicleId);
  const {primaryParameter, secondaryParameter, tertiaryParameter} =
    useGetVehicleHeaderParameters(saleVehicle);
  const vehicleHeaderParameters = useGetVehicleHeaderInformationParameters(isSaleVehicle, vehicle);
  const flags = useGetVehicleHeaderFlags(props.vehicleId);
  const {snippets, isLoading} = useGetVehicleSnippets(props.vehicleId);
  const [vehicleConditionEvaluation, {isLoading: isAuditLoading}] = useVehicleConditionEvaluation(
    props.vehicleId
  );

  const [
    hasVehicleTagReadPermission,
    hasVehicleTagCreatePermission,
    hasVehicleTagUpdatePermission,
    hasVehicleTagDeletePermission,
    hasVehicleTagAssignPermission,
    hasVehicleTagUnassignPermission,
  ] = usePermissions({
    permissionKeys: [
      'vehicleTagRead',
      'vehicleTagCreate',
      'vehicleTagUpdate',
      'vehicleTagDelete',
      'vehicleTagAssign',
      'vehicleTagUnassign',
    ],
  });

  const {canReadSalePrice} = useGetComplexPermissions();

  const controls = useGetVehicleControls({
    isSaleVehicle,
    vehicleId: props.vehicleId,
    saleVehicle,
  });

  const [lightboxControls, coverPhoto, onCoverPhotoOpen, vehicleImages] = useVehicleHeaderImage(
    props.vehicleId
  );

  const vehicleLocalActionNoteQuery = useGetVehicleLocalActionNoteQuery({
    vehicleId: props.vehicleId,
  });

  const recallMessage = vehicleLocalActionNoteQuery.currentData?.local_action_note_text;

  return (
    <>
      <EntityHeader
        title={vehicle?.title}
        icon={isNil(coverPhoto) ? 'maps/directions_car' : undefined}
        image={coverPhoto}
        imageActions={[{icon: 'action/visibility', onClick: () => onCoverPhotoOpen()}]}
        resourceId={EntityResourceIds.vehicle}
        primaryParameter={canReadSalePrice ? primaryParameter : undefined}
        secondaryParameter={canReadSalePrice ? secondaryParameter : undefined}
        tertiaryParameter={canReadSalePrice ? tertiaryParameter : undefined}
        flags={flags}
        parameters={vehicleHeaderParameters}
        actions={actions}
        recordId={props.vehicleId}
        snippets={snippets}
        isLoadingSnippets={isLoading || isAuditLoading}
        controls={controls}
        vehicleConditionEvaluation={
          isSaleVehicle || !isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)
            ? vehicleConditionEvaluation
            : undefined
        }
        data-testid={suffixTestId('vehicleHeader', props)}
        isTagReadable={hasVehicleTagReadPermission}
        isTagCreatable={hasVehicleTagCreatePermission}
        isTagUpdatable={hasVehicleTagUpdatePermission}
        isTagDeletable={hasVehicleTagDeletePermission}
        isTagAssignable={hasVehicleTagAssignPermission}
        isTagUnassignable={hasVehicleTagUnassignPermission}
        alert={
          !isSaleVehicle && recallMessage ? {type: 'banner', message: recallMessage} : undefined
        }
      />
      <Lightbox
        data-testid={testIds.vehicles.detail('header')}
        controls={lightboxControls}
        images={vehicleImages}
      />

      <Show whenFeatureEnabled={featureFlags.SALES_MOT_VALIDITY}>
        <TechnicalInspectionAlert date={vehicle?.technicalInspectionValidUntil} />
      </Show>
    </>
  );
}
