import {useAnimatedPopper} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {useRef} from 'react';

import {MenuItemProps} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps, useOutsideClick} from 'shared';

import {MenuItem} from '../components/MenuItem';
import {SidebarButton} from '../components/SidebarButton';
import {SidebarMenuIcon} from './SidebarMenuIcon';

interface SidebarMenuProps extends TestIdProps {
  menuItems: Array<MenuItemProps>;
  pinnedMenuItems: Array<string | undefined> | undefined;
}

export function SidebarMenu(props: SidebarMenuProps) {
  const ref = useRef<HTMLDivElement>(null);
  const {togglePopper, closePopper, referenceRef, popperProps, Popper, isOpen} = useAnimatedPopper({
    placement: 'right-start',
    isOutsideClickIgnored: true,
  });

  useOutsideClick({
    ref,
    enabled: isOpen,
    handler: () => {
      closePopper();
    },
  });

  return (
    <div ref={ref}>
      <Box
        ref={referenceRef}
        width={12}
        height={12}
        padding={2}
        backgroundColor="palettes.neutral.900.100"
        data-testid={suffixTestId('hamburgerButton', props)}
      >
        <SidebarButton onClick={togglePopper} isOpen={isOpen}>
          <SidebarMenuIcon isOpen={isOpen} />
        </SidebarButton>
      </Box>
      <Popper {...popperProps}>
        <Box
          overflowY="auto"
          width={62}
          height="100vh"
          padding={2}
          backgroundColor="palettes.neutral.900.100"
          borderLeft="1px solid"
          borderColor="palettes.neutral.600.100"
        >
          <VStack spacing={1}>
            {props.menuItems.map((module) => (
              <MenuItem
                key={module.id}
                isPinned={props.pinnedMenuItems?.includes(module.id)}
                onClick={togglePopper}
                depth={0}
                {...module}
              />
            ))}
          </VStack>
        </Box>
      </Popper>
    </div>
  );
}
