import {Button, openDeleteDialog, showNotification} from 'platform/components';
import {ActionCallback, DataGrid} from 'platform/datagrid';
import {Heading, HStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {
  handleApiError,
  useDeleteWatermarkMutation,
  useGetWatermarkQuery,
} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function PhotosWatermarking() {
  const {isLoading} = useGetWatermarkQuery();
  const navigate = useNavigate();
  const [deleteWatermark] = useDeleteWatermarkMutation();

  const handleActionCallback: ActionCallback = useCallback(
    ({actionKey, rowId, refreshData}) => {
      const id = isArray(rowId) ? head(rowId) : rowId;

      if (isNil(id)) {
        showNotification.error();
        return;
      }

      match(actionKey)
        .with('edit', () =>
          navigate(composePath(settingsRoutes.advertisingWatermarkingDetail, {params: {id}}))
        )
        .with('delete', () =>
          openDeleteDialog({
            onConfirm: () =>
              deleteWatermark({watermarkId: id, deleteWatermarkRequestBody: {}})
                .unwrap()
                .then(refreshData)
                .then(() => showNotification.success())
                .catch(handleApiError),
            text: i18n.t('page.salesSettings.labels.watermarks.delete'),
          })
        )
        .otherwise(() => showNotification.warning('unknown action key'));
    },
    [deleteWatermark, navigate]
  );
  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{
        title: i18n.t('page.salesSettings.labels.watermarks.name'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
        ],
      }}
      description={i18n.t('page.salesSettings.labels.watermarks.description')}
      data-testid="settings-advertising-watermarking"
    >
      <HStack align="center" justify="space-between">
        <Heading size={4}>
          {i18n.t('page.salesSettings.backgroundRemoval.configurationList')}
        </Heading>
        <Button
          leftIcon="content/add"
          title={i18n.t('page.salesSettings.backgroundRemoval.addConfiguration')}
          variant="link"
          onClick={() => navigate(settingsRoutes.advertisingWatermarkingCreate)}
          data-testid="settings-advertising-watermarking-createConfiguration"
        />
      </HStack>
      <DataGrid
        gridCode="watermark"
        actionCallback={handleActionCallback}
        data-testid="settings-advertising-watermarking-datagrid"
      />
    </SettingsTemplate>
  );
}
