import {Card, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {RequiredTestIdProps} from 'shared';

import {useGetHandlingUnitQuery} from '../../../api/metadaWarehouseHandlingUnitApi';
import {EitherQuantityOrError} from '../../../types/basket/EitherQuantityOrError';
import {MappedBasketItem} from '../../../types/basket/MappedBasketItem';
import {CorrectionBasketItem} from '../types/CorrectionBasketItem';
import {CorrectionBasketItemEditingDetails} from '../types/CorrectionBasketItemEditingDetails';
import {AfterSalesCorrectionBasketItemQuantityWithPrice} from './AfterSalesCorrectionBasketItemQuantityWithPrice';
import {AfterSalesCorrectionBasketItemTitleWithActions} from './AfterSalesCorrectionBasketItemTitleWithActions';

interface AfterSalesCorrectionBasketItemProps<T extends CorrectionBasketItem>
  extends RequiredTestIdProps {
  item: MappedBasketItem<T>;
  onSelect: (itemId: string, isSelected: boolean) => void;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
  onEdit: (editingDetails: CorrectionBasketItemEditingDetails) => void;
  onDelete: (itemsIds: string[]) => Promise<void>;
}

export function AfterSalesCorrectionBasketItem<T extends CorrectionBasketItem>(
  props: AfterSalesCorrectionBasketItemProps<T>
) {
  const {data: handlingUnit, isLoading: isHandlingUnitLoading} = useGetHandlingUnitQuery(
    {id: props.item.unit as string},
    {skip: isNilOrEmpty(props.item.unit)}
  );

  return (
    <Card variant="inlineGrey">
      <DataStatus isLoading={isHandlingUnitLoading} minHeight={25}>
        <VStack spacing={1}>
          <AfterSalesCorrectionBasketItemTitleWithActions
            item={props.item}
            onSelect={props.onSelect}
            onEdit={props.onEdit}
            onDelete={props.onDelete}
            data-testid={props['data-testid']}
          />
          <AfterSalesCorrectionBasketItemQuantityWithPrice
            item={props.item}
            handlingUnit={handlingUnit?.name}
            onQuantityChange={props.onQuantityChange}
            data-testid={props['data-testid']}
          />
        </VStack>
      </DataStatus>
    </Card>
  );
}
