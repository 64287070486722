import {$generateHtmlFromNodes} from '@lexical/html';
import {$createAutoLinkNode} from '@lexical/link';
import {
  AutoLinkPlugin,
  createLinkMatcherWithRegExp,
  LinkMatcher,
} from '@lexical/react/LexicalAutoLinkPlugin';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {EditorRefPlugin} from '@lexical/react/LexicalEditorRefPlugin';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {
  $createTextNode,
  $getRoot,
  $getSelection,
  EditorState,
  LexicalEditor,
  TextNode,
} from 'lexical';
import {BeautifulMentionNode, BeautifulMentionsPlugin} from 'lexical-beautiful-mentions';

import {useEffect, useRef} from 'react';

import {suffixTestId} from 'shared';

import {OptionType} from '../../types/FormControlProps';
import {Dropdown} from '../Dropdown/Dropdown';
import {getHighlightsFromText} from '../MentionsText/utils/getHighlightsFromText';
import {CustomMenuItem} from './components/CustomMenuItem';
import {StyledContentEditable} from './components/StyledContentEditable';
import {NoParagraphPlugin} from './plugins/NoParagraphPlugin';
import {OpenLinkPlugin} from './plugins/OpenLinkPlugin';
import {RawPastePlugin} from './plugins/RawPastePlugin';
import {MentionsInputProps} from './types/MentionsInputProps';
import {getEditorConfig} from './utils/getEditorConfig';
import {EMAIL_REGEX, URL_REGEX} from './utils/getFormattedValue';

const MATCHERS: Array<LinkMatcher> = [
  createLinkMatcherWithRegExp(URL_REGEX, (text) =>
    text.startsWith('http') ? text : `https://${text}`
  ),
  createLinkMatcherWithRegExp(EMAIL_REGEX, (text) => `mailto:${text}`),
];

export function MentionsInput<T extends OptionType>(props: MentionsInputProps<T>) {
  const editor = useRef<LexicalEditor>(null);
  const lastState = useRef<string | null>(null);

  const onChange = (state: EditorState, editor: LexicalEditor, tags: Set<string>) => {
    state.read(() => {
      lastState.current = $generateHtmlFromNodes(editor).replace('<div>', '').replace('</div>', ''); // Remove replace after:https://github.com/facebook/lexical/pull/6641
      props.onChange?.(lastState.current);
    });
  };

  useEffect(() => {
    if (props.value === lastState.current) {
      return;
    }

    editor.current?.update(() => {
      $getRoot().clear();
      if (!props.value) {
        return;
      }
      $getRoot().select();
      const selection = $getSelection();
      selection?.insertNodes(
        getHighlightsFromText(props.value).map((textHighlight) => {
          switch (textHighlight.type) {
            case 'email':
            case 'link':
              return $createAutoLinkNode(textHighlight.text || '').append(
                $createTextNode(textHighlight.text || '')
              );
            case 'user':
              return new BeautifulMentionNode('@', textHighlight.text || '');
            default:
              return new TextNode(textHighlight.text || '');
          }
        })
      );
    });
  }, [props.value]);

  return (
    <LexicalComposer initialConfig={getEditorConfig(props)}>
      <RichTextPlugin
        contentEditable={<StyledContentEditable data-testid={props['data-testid']} />}
        placeholder={<div></div>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <EditorRefPlugin editorRef={editor} />
      <LinkPlugin />
      <RawPastePlugin />
      <NoParagraphPlugin />
      <OpenLinkPlugin />
      <OnChangePlugin onChange={onChange} />
      <BeautifulMentionsPlugin
        items={{
          '@':
            props.options?.map(({value, label, ...option}) => ({
              value: label,
              id: value,
              ...option,
            })) || [],
        }}
        menuItemComponent={CustomMenuItem}
        menuComponent={({children}) => {
          return (
            <Dropdown
              isOpen={true}
              dropdownControl={<div />}
              data-testid={suffixTestId('dropdown', props)}
            >
              <ul>{children}</ul>
            </Dropdown>
          );
        }}
        showMentionsOnDelete
      />

      <AutoLinkPlugin matchers={MATCHERS} />
    </LexicalComposer>
  );
}
