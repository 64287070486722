import {addDays, format} from 'date-fns';
import {
  Alert,
  Button,
  CurrencyInput,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  isCurrency,
  openConfirmDialog,
} from 'platform/components';
import {Box, Grid, GridItem, Hide, Right, Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';
import {boolean, mixed, object} from 'yup';

import {startTransition} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {always, head, isNil, isNotNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty, isNumber} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  DEFAULT_CURRENCY,
  handleApiError,
  OrderDiscriminator,
  OrderPaymentResponseBody,
  OrderResponseBody,
  PredefinedNotes,
  useBank,
  useDeleteCheckoutOrderPurchasePaymentMutation,
  useDeleteDepositMutation,
  useForbidDepositMutation,
  useGetBusinessCaseQuery,
  useGetCurrentBranch,
  useGetOrderQuery,
  useGetSaleVehicleQuery,
  useGetVehicleWarehouseManagementSettingsQuery,
  useIssueCheckoutOrderPaymentMutation,
  usePatchCheckoutOrderPaymentMutation,
  usePermissions,
  useVehicleWarehouse,
} from '@omnetic-dms/shared';

import {
  getApiDateString,
  isNilOrZero,
  noop,
  Nullish,
  parseDate,
  suffixTestId,
  TestIdProps,
  useDebouncedCallback,
  useRequiredParams,
  yupNumber,
  yupString,
} from 'shared';

import {getPaymentsByDiscriminator} from '../../utils/getPaymentsByDiscriminator';
import {CustomerBankAccountField} from './components/CustomerBankAccountField';
import {ForeignCurrencyFields} from './components/ForeignCurrencyFields';
import {PaymentAmountSync} from './components/PaymentAmountSync';
import {TenantBankAccountField} from './components/tenantBankAccountField';
import {useGetSubmitBody} from './hooks/useGetSubmitBody';
import {FormValuesType} from './types/FormValuesType';
import {getDepositPaymentFormHandlers} from './utils/getDepositPaymentFormHandlers';
import {getPaymentMethodOptions} from './utils/getPaymentMethodOptions';
import {getPercentage, HUNDRED_PERCENT} from './utils/getPercentage';
import {getPredefinedNote} from './utils/getPredefinedNote';

interface PaymentFormProps extends TestIdProps {
  payment: OrderPaymentResponseBody;
  checkoutId: string;
  order: OrderResponseBody;
  refreshBusinessCaseCheckoutInfo: (customerId: string | Nullish) => void;
  isBrokerageSale?: boolean;
  handleAddAnotherPayment: () => void;
  canAddAnotherPayment: boolean;
}

const DATE_FORMAT = 'yyyy-MM-dd';
const DUE_DATE_OFFSET = 14;
const DEFAULT_PRICE = '0';

export function IssuePaymentForm(props: PaymentFormProps) {
  const {bankAccountOptions, isLoading: isLoadingBank} = useBank();

  const {id: businessCaseId} = useRequiredParams();

  const [
    canIssuePurchasePayment,
    canIssueSellingPayment,
    canIssueDepositPayment,
    canIssueFromVehicleWarehouse,
    canCancelSellingInvoice,
  ] = usePermissions({
    permissionKeys: [
      'issueBusinessCasePurchasePayment',
      'issueBusinessCaseSellingPayment',
      'issueBusinessCaseSellingDeposit',
      'vehicleIssueFromVehicleWarehouse',
      'cancelBusinessCaseSellingInvoice',
    ],
  });

  const isIssuePaymentButtonDisabled =
    props.order.orderDiscriminator === 'PURCHASE' ||
    props.order.orderDiscriminator === 'PURCHASE_BROKERAGE_SALE' ||
    props.order.orderDiscriminator === 'PURCHASE_BROKERAGE_FEES'
      ? !canIssuePurchasePayment
      : !canIssueSellingPayment;

  const isCancelInvoiceButtonDisabled =
    props.order.orderDiscriminator === 'PURCHASE' ||
    props.order.orderDiscriminator === 'PURCHASE_BROKERAGE_SALE' ||
    props.order.orderDiscriminator === 'PURCHASE_BROKERAGE_FEES'
      ? false
      : !canCancelSellingInvoice;

  const {data: businessCaseData} = useGetBusinessCaseQuery({businessCaseId});

  const vehicleId = businessCaseData?.offers?.[0]?.saleVehicles?.[0]?.vehicleId;

  const {data: vehicleData} = useGetSaleVehicleQuery(
    {vehicleId: vehicleId ?? ''},
    {skip: isNilOrEmpty(vehicleId)}
  );

  const {openStockInAlertDialog} = useVehicleWarehouse({
    vehicleId: vehicleId ?? '',
  });

  const {data: branchData, isLoading: isLoadingBranchData} = useGetCurrentBranch();

  const {isLoading: isLoadingOrder} = useGetOrderQuery({
    checkoutId: props.checkoutId,
    orderId: props.order.id,
  });

  const [deleteDeposit, {isLoading: isDeletingPayment}] = useDeleteDepositMutation();
  const [deletePurchase] = useDeleteCheckoutOrderPurchasePaymentMutation();
  const [patchOrderPayment] = usePatchCheckoutOrderPaymentMutation();
  const [issuePayment] = useIssueCheckoutOrderPaymentMutation();
  const [getPaymentBody] = useGetSubmitBody(props.payment.paymentDiscriminator);

  const isAnyDepositUnpaid = props.order.payments.some(
    (payment) => payment.paymentDiscriminator === 'DEPOSIT' && payment.paymentState !== 'PAID'
  );

  const {balanceAndPurchases, deposits} = getPaymentsByDiscriminator(props.order);
  const isPartialPayment =
    props.payment.paymentDiscriminator === 'DEPOSIT' || balanceAndPurchases.length > 1;

  const customerBankAccounts =
    props.order.contractInformation.customerContractInformation.bankAccounts;

  const depositCurrency = isCurrency(props.order.totalPrice.withVat.currency)
    ? props.order.totalPrice.withVat.currency
    : DEFAULT_CURRENCY;

  const paymentPrice = props.payment.amount?.amount ?? DEFAULT_PRICE;
  const orderPrice = props.order.totalPrice.withVat.amount ?? DEFAULT_PRICE;

  const handleSyncAmountAndPercentage = (
    val: string | number | null,
    formApi: UseFormReturn<FormValuesType>,
    initiator: 'amount' | 'percentage'
  ) => {
    const orderPriceNumber = Number(orderPrice);
    const maxPrice = initiator === 'amount' ? orderPriceNumber : HUNDRED_PERCENT;
    const updateInput = initiator === 'amount' ? 'percentage' : 'amount';

    if (!isNumber(val) || val === 0) {
      formApi.setValue(updateInput, 0);
      formApi.setValue('exchangeRateAmount', DEFAULT_PRICE);
      return;
    }

    if (val > maxPrice) {
      return formApi.setError(initiator, {
        message: i18n.t('general.errors.number.max', {max: maxPrice}),
      });
    }

    const percentage =
      initiator === 'amount'
        ? (val / orderPriceNumber) * HUNDRED_PERCENT
        : (val / HUNDRED_PERCENT) * orderPriceNumber;

    formApi.setValue(updateInput, Number(percentage.toFixed(2)));

    formApi.clearErrors(['amount', 'percentage']);
    handleUpdateForeignCurrency(formApi);
  };

  const debouncedHandleSyncAmountAndPercentage = useDebouncedCallback(
    handleSyncAmountAndPercentage,
    300
  );

  const handleSyncPaymentDatesWithPaymentMethod =
    (formApi: UseFormReturn<FormValuesType>) => (value: string | number | null) =>
      startTransition(() => {
        if (isNil(value)) {
          return;
        }
        const {issuedDate} = formApi.getValues();

        match(value)
          .with('CASH', 'PAYMENT_CARD', () => {
            formApi.setValue('dueSinceIssueDateInDays', 0);
            formApi.setValue('dueDate', issuedDate);
          })
          .with('BANK_TRANSFER', () => {
            formApi.setValue('dueSinceIssueDateInDays', DUE_DATE_OFFSET);
            formApi.setValue(
              'dueDate',
              getApiDateString(addDays(parseDate(issuedDate), DUE_DATE_OFFSET))
            );
          })
          .otherwise(noop);
      });

  const isDeductible = props.order?.items.some((item) => item.deductible);
  const isDeductibleAndInland = props.order?.typeOfSale === 'inland' && isDeductible;

  const note = match([
    props.order.orderDiscriminator,
    props.payment.paymentDiscriminator,
    props.isBrokerageSale,
    isDeductibleAndInland,
  ])
    .with(
      ['SALE', 'BALANCE', false, false],
      always(props.payment.comment || getPredefinedNote(props.order.typeOfSale) || '')
    )
    .otherwise(always(props.payment.comment ?? ''));

  const foreignCurrencyAccount = branchData?.billingInformation?.bankAccounts.find(
    (item) => item.currency === props.payment.foreignCurrencyAmount?.currency
  )?.accountNumber;

  const defaultValues: Partial<FormValuesType> = {
    amount: parseFloat(paymentPrice ?? DEFAULT_PRICE),
    percentage: getPercentage(props.payment.amount?.amount, orderPrice),
    paymentMethod: 'BANK_TRANSFER',
    tenantBankAccount: foreignCurrencyAccount ?? head(bankAccountOptions)?.value,
    exchangeRate: props.payment?.exchangeRate ?? null,
    customerBankAccountId: head(customerBankAccounts ?? [])?.id,
    issuedDate: format(new Date(), DATE_FORMAT),
    dateOfTaxableSupply: format(new Date(), DATE_FORMAT),
    dueDate: format(addDays(new Date(), DUE_DATE_OFFSET), DATE_FORMAT),
    dueSinceIssueDateInDays: DUE_DATE_OFFSET,
    supplierInvoiceNumber: props.payment.supplierInvoiceNumber,
    note,
  };
  const {openStockOutDialog} = useVehicleWarehouse({
    vehicleId: vehicleId ?? '',
    vehicleWarehouseId: vehicleData?.vehicleWarehouse?.id ?? '',
  });

  const {data: warehouseManagmentData} = useGetVehicleWarehouseManagementSettingsQuery();

  const hasWarehouseManagementEnabled = warehouseManagmentData?.isEnabled;

  const handleUpdatePayment: FormSubmitHandler<FormValuesType> = async (data) => {
    const orderPaymentRequestBody = getPaymentBody(data);
    const shouldOpenStockoutDialog =
      canIssueFromVehicleWarehouse &&
      isNotNilOrEmpty(vehicleData?.vehicleWarehouse?.id) &&
      orderPaymentRequestBody.paymentDiscriminator === 'BALANCE' &&
      data.percentage === 100;

    const shouldOpenStockInAlertDialog =
      isNilOrEmpty(vehicleData?.vehicleWarehouse?.id) &&
      businessCaseData?.businessCaseType === 'SELLING' &&
      orderPaymentRequestBody.paymentDiscriminator === 'BALANCE' &&
      data.percentage === 100 &&
      vehicleData?.type !== 'BROKERAGE' &&
      isNotNilOrEmpty(vehicleData?.buyingState) &&
      isNotNilOrEmpty(vehicleData?.purchasePrice?.withoutVat) &&
      hasWarehouseManagementEnabled;

    const paymentProps = {
      checkoutId: props.checkoutId,
      orderId: props.order.id,
      paymentId: props.payment.id,
    };

    await patchOrderPayment({
      ...paymentProps,
      orderPaymentRequestBody,
    })
      .unwrap()
      .catch(handleApiError);

    await issuePayment({
      ...paymentProps,
      businessCaseId,
      issueCheckoutOrderPaymentRequestBody: {issuedOn: data.issuedDate},
    })
      .unwrap()
      .then((data) => {
        if (shouldOpenStockoutDialog) {
          openConfirmDialog({
            text: i18n.t('entity.vehicleWarehouse.modal.stockOutDescription'),
            onConfirm: openStockOutDialog,
          });
        }
        if (shouldOpenStockInAlertDialog && isNotNil(data.invoiceId)) {
          openStockInAlertDialog(data.invoiceId, branchData?.id);
        }
      })
      .catch(handleApiError);
  };

  const handleUpdateForeignCurrency = (formApi: UseFormReturn<FormValuesType>) => {
    const data = formApi.getValues();
    const orderPaymentRequestBody = getPaymentBody(data);

    if (isNil(props.payment.exchangeRate)) {
      return;
    }
    if (isNilOrZero(data.amount) || isNilOrZero(parseFloat(data.exchangeRate ?? DEFAULT_PRICE))) {
      formApi.setValue('exchangeRateAmount', DEFAULT_PRICE);
      return;
    }

    patchOrderPayment({
      checkoutId: props.checkoutId,
      orderId: props.order.id,
      paymentId: props.payment.id,
      orderPaymentRequestBody,
    })
      .unwrap()
      .then((data) => {
        const payment = data.payments.find((item) => item.id === props.payment.id);

        formApi.setValue('exchangeRate', payment?.exchangeRate || null);
        formApi.setValue('exchangeRateAmount', payment?.foreignCurrencyAmount?.amount || null);
      })
      .catch(handleApiError);
  };

  const [forbitDeposit] = useForbidDepositMutation();

  const handleDeletePayment = () => {
    const isDepositPayment = props.payment.paymentDiscriminator === 'DEPOSIT';

    if (isDepositPayment && deposits.length <= 1) {
      forbitDeposit({checkoutId: props.checkoutId, orderId: props.order.id})
        .unwrap()
        .then(() => props.refreshBusinessCaseCheckoutInfo(null))
        .catch(handleApiError);

      return;
    }

    const deleteAction = isDepositPayment ? deleteDeposit : deletePurchase;

    deleteAction({
      checkoutId: props.checkoutId,
      orderId: props.order.id,
      depositId: props.payment.id,
      paymentId: props.payment.id,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const isOrderPurchase =
    props.order.orderDiscriminator === 'PURCHASE' ||
    props.order.orderDiscriminator === 'PURCHASE_BROKERAGE_SALE';
  const isLoading = isLoadingBank || isLoadingBranchData || isLoadingOrder;
  const paymentMethodOptions = getPaymentMethodOptions(
    props.order.orderDiscriminator,
    props.payment.paymentDiscriminator
  );

  return (
    <DataStatus isLoading={isLoading} minHeight={180}>
      <Form<FormValuesType>
        onSubmit={handleUpdatePayment}
        defaultValues={defaultValues}
        schema={getSchema(isPartialPayment, props.order.orderDiscriminator)}
      >
        {(control, formApi) => {
          const [issueDate, paymentMethod, note] = formApi.watch([
            'issuedDate',
            'paymentMethod',
            'note',
          ]);

          const updateNote = (val: string) => formApi.setValue('note', val);
          const minDueDate = issueDate ? parseDate(issueDate) : undefined;

          const isCustomerBankAccountIdError = isNotNil(
            formApi.getFieldState('customerBankAccountId')?.error
          );

          const {handleOnDueChange, handleOnDueDateChange, handleOnIssueDateChange} =
            getDepositPaymentFormHandlers(formApi);

          return (
            <VStack spacing={4}>
              <PaymentAmountSync
                formApi={formApi}
                orderId={props.order.id}
                checkoutId={props.checkoutId}
                paymentId={props.payment.id}
                isPartialPayment={isPartialPayment}
              />
              <Grid columns={4}>
                <Hide when={isPartialPayment}>
                  <CurrencyInput
                    value={parseFloat(props.payment.amount?.amount ?? DEFAULT_PRICE)}
                    data-testid={suffixTestId('amount-currency', props)}
                    currency={depositCurrency}
                    isDisabled
                    label={i18n.t('general.labels.amount')}
                  />
                </Hide>
                <Show when={isPartialPayment}>
                  <FormField
                    control={control}
                    data-testid={suffixTestId('amount', props)}
                    name="amount"
                    type="currency"
                    currency={depositCurrency}
                    onChange={(val) =>
                      debouncedHandleSyncAmountAndPercentage(val, formApi, 'amount')
                    }
                    label={i18n.t('general.labels.amount')}
                  />
                </Show>
                <Show when={isPartialPayment}>
                  <FormField
                    control={control}
                    name="percentage"
                    data-testid={suffixTestId('percentage', props)}
                    type="number"
                    maxStepperValue={HUNDRED_PERCENT}
                    minStepperValue={0}
                    isDisabled={isCustomerBankAccountIdError}
                    onChange={(val) =>
                      debouncedHandleSyncAmountAndPercentage(val, formApi, 'percentage')
                    }
                    suffix="%"
                    label={i18n.t('entity.order.labels.percentage')}
                  />
                </Show>

                <ForeignCurrencyFields
                  checkoutId={props.checkoutId}
                  control={control}
                  formApi={formApi}
                  orderId={props.order.id}
                  payment={props.payment}
                  data-testid={props['data-testid']}
                />
              </Grid>
              <Grid columns={4}>
                <FormField
                  control={control}
                  name="issuedDate"
                  onChange={handleOnIssueDateChange}
                  type="apiDate"
                  label={i18n.t('entity.accounting.labels.issueDate')}
                  data-testid={suffixTestId('issueDate', props)}
                  isRequired
                />
                <FormField
                  control={control}
                  name="dueSinceIssueDateInDays"
                  type="number"
                  label={i18n.t('entity.accounting.labels.due')}
                  onChange={handleOnDueChange}
                  data-testid={suffixTestId('dueSinceIssueDateInDays', props)}
                  isStepperVisible
                  step={1}
                  minStepperValue={0}
                />
                <FormField
                  control={control}
                  name="dueDate"
                  data-testid={suffixTestId('dueDate', props)}
                  type="apiDate"
                  label={i18n.t('entity.accounting.labels.dueDate')}
                  minDate={minDueDate}
                  onChange={handleOnDueDateChange}
                  isRequired
                />

                <Hide when={isOrderPurchase}>
                  <FormField
                    control={control}
                    name="dateOfTaxableSupply"
                    data-testid={suffixTestId('dateOfTaxableSupply', props)}
                    type="apiDate"
                    label={i18n.t('entity.accounting.labels.dateOfTaxableSupply')}
                    isRequired
                  />
                </Hide>
                <Show when={isOrderPurchase}>
                  <FormField
                    control={control}
                    name="supplierInvoiceNumber"
                    type="text"
                    label={i18n.t('entity.checkout.labels.supplierInvoiceNumber')}
                    data-testid={suffixTestId('supplierInvoiceNumber', props)}
                  />
                </Show>
              </Grid>

              <Show when={props.order.foreignCurrencyPayment}>
                <GridItem span={4}>
                  <Alert
                    variant="warning"
                    title={i18n.t('entity.checkout.labels.foreignPaymentWarning')}
                    data-testid={suffixTestId('foreignPaymentWarning', props)}
                  />
                </GridItem>
              </Show>

              <Grid columns={4}>
                <GridItem span={2}>
                  <FormField
                    control={control}
                    name="paymentMethod"
                    data-testid={suffixTestId('paymentMethod', props)}
                    type="choice"
                    isNotClearable
                    onChange={handleSyncPaymentDatesWithPaymentMethod(formApi)}
                    options={paymentMethodOptions}
                    label={i18n.t('entity.checkout.labels.paymentMethod')}
                  />
                </GridItem>

                <Show when={paymentMethod === 'BANK_TRANSFER' || paymentMethod === 'OFFSET'}>
                  <GridItem span={2}>
                    <Show
                      when={
                        props.order.orderDiscriminator === 'PURCHASE' ||
                        props.order.orderDiscriminator === 'PURCHASE_BROKERAGE_SALE'
                      }
                    >
                      <CustomerBankAccountField
                        checkoutId={props.checkoutId}
                        control={control}
                        formApi={formApi}
                        order={props.order}
                        data-testid={props['data-testid']}
                      />
                    </Show>

                    <TenantBankAccountField
                      control={control}
                      formApi={formApi}
                      data-testid={props['data-testid']}
                      order={props.order}
                    />
                  </GridItem>
                </Show>

                <Show
                  when={
                    props.order.orderDiscriminator === 'SALE' ||
                    props.order.orderDiscriminator === 'PURCHASE_BROKERAGE_FEES'
                  }
                >
                  <GridItem span={4}>
                    <Box position="relative">
                      <div style={{position: 'absolute', right: '0', top: '0'}}>
                        <PredefinedNotes
                          isLinkVariant
                          note={note}
                          onPrefill={updateNote}
                          resource="BUSINESS_CASE"
                          context="invoice"
                          data-testid={suffixTestId('predefinedNotes', props)}
                        />
                      </div>

                      <FormField
                        name="note"
                        type="textarea"
                        minRows={1}
                        control={control}
                        label={i18n.t('entity.checkout.labels.noteForPrint')}
                        data-testid={suffixTestId('note', props)}
                      />
                    </Box>
                  </GridItem>
                </Show>
              </Grid>

              <Right>
                <Show when={props.canAddAnotherPayment}>
                  <Box flexGrow={1}>
                    <Button
                      variant="link"
                      leftIcon="content/add_circle"
                      onClick={props.handleAddAnotherPayment}
                      title={i18n.t('entity.checkout.addAnotherPayment')}
                      data-testid={testIds.businessCase.checkout('addAnotherPayment')}
                    />
                  </Box>
                </Show>

                <Show when={isPartialPayment}>
                  {/* TODO: tooltip for disabled button cuz of perms */}
                  {/*  https://carvago.atlassian.net/browse/T20-48223 */}
                  <FormButton
                    title={i18n.t('entity.checkout.removePayment')}
                    variant="dangerGhost"
                    control={control}
                    type="button"
                    onClick={handleDeletePayment}
                    data-testid={suffixTestId('removePayment', props)}
                    isLoading={isDeletingPayment}
                    isDisabled={isCancelInvoiceButtonDisabled}
                  />
                </Show>
                {/* TODO: tooltip for disabled button cuz of perms */}
                {/*  https://carvago.atlassian.net/browse/T20-48223 */}
                <FormButton
                  control={control}
                  title={i18n.t('entity.checkout.actions.issuePayment')}
                  data-testid={suffixTestId('issuePayment', props)}
                  type="submit"
                  isDisabled={
                    (isAnyDepositUnpaid && props.payment.paymentDiscriminator !== 'DEPOSIT') ||
                    (props.payment.paymentDiscriminator !== 'DEPOSIT' &&
                      isIssuePaymentButtonDisabled) ||
                    (props.payment.paymentDiscriminator === 'DEPOSIT' && !canIssueDepositPayment)
                  }
                />
              </Right>
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}

// TODO - @martin.slaby / @jakub.bendzala "styled" made cypress build fail
// export const PredefinedNotesButtonWrapper = styled.div`
//   position: absolute;
//   right: 0;
//   top: -9px;
// `;

const getSchema = (isPartialPayment: boolean, orderDiscriminator: OrderDiscriminator) =>
  object({
    amount: mixed().when([], {
      is: () => isPartialPayment,
      then: () => yupNumber.min(0).required(),
    }),
    percentage: mixed().when([], {
      is: () => isPartialPayment,
      then: () => yupNumber.min(0).max(100).required(),
    }),
    issuedDate: yupString.required(),
    dueDate: yupString.required(),
    dateOfTaxableSupply: yupString.required(),
    note: yupString,
    createCashRegisterDocument: boolean()
      .default(false)
      .when('paymentMethod', {
        is: (val: string) => val === 'CASH' || val === 'PAYMENT_CARD',
        then: (val) => val.required(),
      }),
    customerBankAccountId: yupString.when('paymentMethod', {
      is: (val: string) =>
        (val === 'BANK_TRANSFER' || val === 'OFFSET') &&
        (orderDiscriminator === 'PURCHASE' || orderDiscriminator === 'PURCHASE_BROKERAGE_SALE'),
      then: (val) => val.required(),
    }),
  });
