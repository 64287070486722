import {Card, DataStatus, FormControl, FormField, Separator} from 'platform/components';
import {Grid, GridItem, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';
import {
  VinDecoderField,
  VinDecoderResponseBody,
  useVehicleCatalogue,
  yearOptions,
  monthOptions,
  FuelField,
  VehicleTypeEnumObject,
  ServiceVehicleFormType as ServiceVehicleFormTypeLite,
} from '@omnetic-dms/shared';

import {RequiredTestIdProps, getApiDateString, handleFormReset, suffixTestId} from 'shared';

import {ServiceVehicleFormType} from '../../../types/ServiceVehicleFormType';
import {CreatableMakeSelect} from './CreatableMakeSelect';
import {CreatableModelSelect} from './CreatableModelSelect';

interface MainProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
}

export function Main(props: MainProps) {
  const vehicleType = props.formApi.watch('type');
  const [vehicleUtils, {isLoading, isError}] = useVehicleCatalogue(vehicleType.at(0));

  const handleDecodeVin =
    (formApi: UseFormReturn<ServiceVehicleFormType>) => (decodedData?: VinDecoderResponseBody) => {
      if (decodedData?.vehicleType) {
        formApi.setValue('type', [decodedData.vehicleType]);
      }

      if (decodedData?.make) {
        formApi.setValue('formMake', decodedData.make);
        formApi.setValue('make', decodedData.make);
        formApi.setValue('customMake', null);
      }

      if (decodedData?.modelFamily) {
        formApi.setValue('formModelFamily', decodedData.modelFamily);
        formApi.setValue('modelFamily', decodedData.modelFamily);
        formApi.setValue('customModelFamily', null);
      }

      if (decodedData?.trim) {
        formApi.setValue('trim', decodedData.trim);
      }

      if (decodedData?.variant) {
        formApi.setValue('variant', decodedData.variant);
      }

      if (
        decodedData?.firstRegistrationOnYear &&
        decodedData?.firstRegistrationOnMonth &&
        decodedData?.firstRegistrationOnDay
      ) {
        formApi.setValue(
          'firstRegistrationOn',
          getApiDateString(
            new Date(
              parseInt(decodedData.firstRegistrationOnYear, 10),
              parseInt(decodedData.firstRegistrationOnMonth, 10),
              parseInt(decodedData.firstRegistrationOnDay, 10)
            )
          )
        );
      }

      if (decodedData?.manufacturedOnYear) {
        formApi.setValue('manufacturedOnYear', decodedData.manufacturedOnYear);
      }

      if (decodedData?.manufacturedOnMonth) {
        formApi.setValue('manufacturedOnMonth', decodedData.manufacturedOnMonth);
      }

      if (decodedData?.otherRecords) {
        formApi.setValue('note', decodedData.otherRecords);
      }

      if (decodedData?.bodyStyle) {
        formApi.setValue('bodyStyle', [decodedData.bodyStyle]);
      }

      if (decodedData?.fuelType) {
        formApi.setValue('fuelType', [decodedData.fuelType]);
      }

      if (decodedData?.transmission) {
        formApi.setValue('transmission', [decodedData.transmission]);
      }

      if (decodedData?.drive) {
        formApi.setValue('drive', [decodedData.drive]);
      }

      if (decodedData?.exteriorColor) {
        formApi.setValue('exteriorColor.value', decodedData.exteriorColor);
      }

      if (decodedData?.engineCode) {
        formApi.setValue('engineCode', decodedData.engineCode);
      }

      if (decodedData?.power) {
        formApi.setValue('power', parseInt(decodedData.power));
      }

      if (decodedData?.engineVolume) {
        formApi.setValue('engineVolume', parseInt(decodedData.engineVolume));
      }

      if (decodedData?.secondaryFuelType) {
        formApi.setValue('secondaryFuelType', [decodedData.secondaryFuelType]);
      }

      formApi.clearErrors();
    };

  return (
    <Card>
      <DataStatus isLoading={isLoading} isError={isError}>
        <VStack spacing={4}>
          <VinDecoderField<ServiceVehicleFormType>
            control={props.control}
            formApi={props.formApi}
            registrationPlateField={{
              name: 'registrationPlate',
              label: i18n.t('entity.vehicle.labels.licensePlate'),
            }}
            vinField={{name: 'vin', label: i18n.t('entity.vehicle.labels.vin')}}
            onDecodedData={handleDecodeVin(props.formApi)}
            data-testid={suffixTestId('vinDecoder', props)}
          />
          <FormField
            control={props.control}
            type="chips"
            name="type"
            options={vehicleUtils.vehicleTypeOptions ?? []}
            label={i18n.t('entity.vehicle.labels.vehicleType')}
            isRequired
            onChange={() =>
              handleFormReset<ServiceVehicleFormType>(props.formApi, [
                {name: 'formMake', value: null},
                {name: 'make', value: null},
                {name: 'customMake', value: null},
                {name: 'formModelFamily', value: null},
                {name: 'modelFamily', value: null},
                {name: 'customModelFamily', value: null},
                {name: 'trim', value: null},
                {name: 'title', value: null},
                {name: 'variant', value: null},
                {name: 'bodyStyle', value: null},
                {name: 'transmission', value: null},
                {name: 'drive', value: null},
                {name: 'fuelType', value: null},
                {name: 'chargingAC', value: null},
                {name: 'chargingTimeAC', value: null},
                {name: 'chargingDC', value: null},
                {name: 'chargingTimeDC', value: null},
                {name: 'batteryType', value: null},
                {name: 'batteryCapacityWh', value: null},
                {name: 'batteryCapacityAh', value: null},
                {name: 'batteryVoltage', value: null},
                {name: 'batteryCount', value: null},
                {name: 'chargingConnectorType', value: null},
                {name: 'electricRange', value: null},
                {name: 'batteryMileageLimit', value: null},
                {name: 'batteryWarrantyUntil', value: null},
                {name: 'batteryOwnershipType', value: null},
                {name: 'secondaryFuelType', value: null},
                {name: 'hybridType', value: null},
                {name: 'gasRevisionValidUntil', value: null},
                {name: 'pressureVesselValidUntil', value: null},
                {name: 'engineName', value: null},
                {name: 'engineCode', value: null},
                {name: 'power', value: null},
                {name: 'engineVolume', value: null},
              ])
            }
            data-testid={suffixTestId('vehicleType', props)}
          />
          <Grid columns={4}>
            <CreatableMakeSelect
              control={props.control}
              formApi={props.formApi}
              vehicleType={vehicleType.at(0) as VehicleTypeEnumObject}
              data-testid={suffixTestId('make', props)}
            />
            <CreatableModelSelect
              control={props.control}
              formApi={props.formApi}
              vehicleType={vehicleType.at(0) as VehicleTypeEnumObject}
              data-testid={suffixTestId('modelFamily', props)}
            />
            <FormField
              control={props.control}
              type="text"
              name="trim"
              label={i18n.t('entity.vehicle.labels.trimLevel')}
              data-testid={suffixTestId('trim', props)}
            />
            <FormField
              control={props.control}
              type="text"
              name="variant"
              label={i18n.t('entity.vehicle.labels.variantName')}
              data-testid={suffixTestId('variant', props)}
            />
            <GridItem span={2}>
              <FormField
                control={props.control}
                type="text"
                name="title"
                label={i18n.t('entity.vehicle.labels.vehicleTitle')}
                data-testid={suffixTestId('title', props)}
              />
            </GridItem>
          </Grid>
          <Grid columns={4}>
            <FormField
              control={props.control}
              type="apiDate"
              name="firstRegistrationOn"
              label={i18n.t('entity.vehicle.labels.firstRegistration')}
              data-testid={suffixTestId('firstRegistrationOn', props)}
            />
            <FormField
              control={props.control}
              type="choice"
              name="manufacturedOnYear"
              label={i18n.t('entity.vehicle.labels.realProductionYear')}
              options={yearOptions}
              data-testid={suffixTestId('manufacturedOnYear', props)}
            />
            <FormField
              control={props.control}
              type="choice"
              name="manufacturedOnMonth"
              label={i18n.t('entity.vehicle.labels.realProductionMonth')}
              options={monthOptions}
              data-testid={suffixTestId('manufacturedOnMonth', props)}
            />
          </Grid>
          <FormField
            control={props.control}
            type="textarea"
            name="note"
            label={i18n.t('entity.invoice.labels.internalNote')}
            data-testid={suffixTestId('note', props)}
            isResizable
          />
          <Separator spacing={0} />
          <FormField
            control={props.control}
            type="chips"
            name="bodyStyle"
            options={vehicleUtils.vehicleStyleOptions ?? []}
            label={i18n.t('entity.vehicle.labels.body')}
            data-testid={suffixTestId('bodyStyle', props)}
          />
          <FuelField
            control={props.control as FormControl<ServiceVehicleFormTypeLite>}
            formApi={props.formApi as UseFormReturn<ServiceVehicleFormTypeLite>}
            data-testid={suffixTestId('fuel', props)}
          />
          <Grid columns={2}>
            <FormField
              control={props.control}
              type="chips"
              name="transmission"
              options={vehicleUtils.transmissionOptions ?? []}
              label={i18n.t('entity.vehicle.labels.transmission')}
              data-testid={suffixTestId('transmission', props)}
            />
            <FormField
              control={props.control}
              type="chips"
              name="drive"
              options={vehicleUtils.driveOptions ?? []}
              label={i18n.t('entity.vehicle.labels.drive')}
              data-testid={suffixTestId('drive', props)}
            />
            <FormField
              control={props.control}
              type="chips"
              name="exteriorColorSpecification"
              options={vehicleUtils.bodyColorTypeOptions ?? []}
              label={i18n.t('entity.vehicle.labels.bodyColorType')}
              data-testid={suffixTestId('exteriorColorSpecification', props)}
            />
          </Grid>
          <Grid columns={4}>
            <FormField
              control={props.control}
              type="choice"
              name="exteriorColor.value"
              options={vehicleUtils.bodyColorOptions ?? []}
              menuInPortal
              label={i18n.t('entity.vehicle.labels.bodyColor')}
              data-testid={suffixTestId('bodyColor', props)}
            />
            <FormField
              control={props.control}
              type="text"
              name="exteriorColor.name"
              label={i18n.t('entity.vehicle.labels.manufacturerColorName')}
              data-testid={suffixTestId('manufacturerColorName', props)}
            />
            <FormField
              control={props.control}
              type="text"
              name="exteriorColor.code"
              label={i18n.t('entity.vehicle.labels.manufacturerColorCode')}
              data-testid={suffixTestId('manufacturerColorCode', props)}
            />
          </Grid>
        </VStack>
      </DataStatus>
    </Card>
  );
}
