import {
  Alert,
  Button,
  Card,
  Choice,
  DataStatus,
  Dropdown,
  DropdownItem,
  Separator,
} from 'platform/components';
import {HStack, Hide, Scroll, Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {always, defaultTo, equals, head, isNil, not, reject} from 'ramda';
import {isArray, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import i18n from '../../i18n/i18n';
import {Basket} from '../../types/basket/Basket';
import {BasketMechanic} from '../../types/basket/BasketMechanic';
import {EitherQuantityOrError} from '../../types/basket/EitherQuantityOrError';
import {MappedBasketItem} from '../../types/basket/MappedBasketItem';
import {getOptionsFromMechanics} from '../../utils/getOptionsFromMechanics';
import {mergeBasketItems} from '../../utils/mergeBasketItems';
import {BasketSummary} from '../BasketSummary/BasketSummary';
import {AfterSalesLabourBasketItem} from './components/AfterSalesLabourBasketItem';
import {LabourBasketItem} from './types/LabourBasketItem';
import {LabourBasketItemEditingDetails} from './types/LabourBasketItemEditingDetails';

type BulkAction<T extends LabourBasketItem> = {
  label: string;
  action: (basketItems: MappedBasketItem<T>[]) => void;
};

interface AfterSalesLabourBasketProps<T extends LabourBasketItem> extends RequiredTestIdProps {
  basket: Basket<T>;
  mechanic: BasketMechanic;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
  onEdit: (editingDetails: LabourBasketItemEditingDetails) => void;
  onDelete: (itemId: string) => Promise<void>;
  bulkActions?: BulkAction<T>[];
}

export function AfterSalesLabourBasket<T extends LabourBasketItem>(
  props: AfterSalesLabourBasketProps<T>
) {
  const newBasketItems = defaultTo([], props.basket?.items);

  const [basketItems, setBasketItems] = useState<MappedBasketItem<T>[] | Nullish>(() => {
    const initialState = reject(isNil, newBasketItems).map((basketItem) => ({
      ...basketItem,
      isSelected: false,
    }));
    return initialState;
  });

  const [isManuallyCleared, setIsManuallyCleared] = useState<boolean>(false);

  const mergedBasketItems = mergeBasketItems<T, MappedBasketItem<T>>(newBasketItems, basketItems);

  // Update the local mapped basket items to ensure latest state with mapped isSelected flag
  if (not(equals(basketItems, mergedBasketItems))) {
    setBasketItems(mergedBasketItems);
  }

  const basketItemsCount = defaultTo(0, basketItems?.length);

  const basketTotalPrice = props.basket?.totalPrice;

  const basketTitle = `${i18n.t('general.labels.addedItems')} (${basketItemsCount})`;

  const selectedMechanicId = props.mechanic?.assignedMechanic?.id;

  const hasSomeBasketItemsSelected = basketItems?.some((basketItem) => basketItem.isSelected);

  const hasAllBasketItemsSelected = basketItems?.every((basketItem) => basketItem.isSelected);

  const assignedMechanicId = match([isNil(selectedMechanicId), isManuallyCleared])
    .with([true, true], always(null))
    .with([false, false], always(selectedMechanicId))
    .otherwise(always(selectedMechanicId));

  const handleMechanicChange = (mechanicId: string | number | string[] | null) => {
    const parsedValue = (isArray(mechanicId) ? head(mechanicId) : mechanicId) as string | null;
    setIsManuallyCleared(isNil(parsedValue));

    const selectedMechanic = props.mechanic?.mechanics?.employees?.find(
      (mechanic) => mechanic?.id === parsedValue
    );

    if (isNil(selectedMechanic)) {
      return props.mechanic.onMechanicChange && props.mechanic.onMechanicChange(null);
    }

    props.mechanic.onMechanicChange &&
      props.mechanic.onMechanicChange({
        id: selectedMechanic.id!,
        isDefault: true,
        costCenterId: selectedMechanic.costCenterId!,
      });
  };

  const handleToggleSelection = () => {
    setBasketItems((prevBasketItems) =>
      prevBasketItems?.map((basketItem) => ({
        ...basketItem,
        isSelected: !hasAllBasketItemsSelected,
      }))
    );
  };

  const handleSelectBasketItem = (id: string, isSelected: boolean) => {
    setBasketItems((prevBasketItems) =>
      prevBasketItems?.map((basketItem) =>
        basketItem.id === id ? {...basketItem, isSelected} : basketItem
      )
    );
  };

  return (
    <Card title={basketTitle} isFullHeight data-testid={suffixTestId('wrapper', props)}>
      <VStack height="100%" spacing={3}>
        <Choice
          label={i18n.t('entity.order.labels.mechanic')}
          value={defaultTo('', assignedMechanicId)}
          options={defaultTo([], getOptionsFromMechanics(props.mechanic?.mechanics))}
          onChange={handleMechanicChange}
          errorMessage={props.mechanic?.mechanicError}
          isRequired={props.mechanic?.isMechanicRequired}
          data-testid={suffixTestId('assignedMechanic', props)}
        />

        <Show when={props.basket.hasInvalidItems}>
          <Alert
            type="inline"
            variant="error"
            title={i18n.t('general.notifications.basketHasInvalidItems')}
            data-testid={suffixTestId('alert.hasInvalidItems', props)}
          />
        </Show>

        <HStack justify="space-between">
          <Hide when={isNilOrEmpty(basketItems)}>
            <Button
              type="button"
              variant="link"
              leftIcon="action/done_all"
              title={
                hasAllBasketItemsSelected
                  ? i18n.t('general.actions.unselectAll')
                  : i18n.t('general.actions.selectAll')
              }
              onClick={handleToggleSelection}
              data-testid={suffixTestId('bulkSelect', props)}
            />
          </Hide>

          <Show when={hasSomeBasketItemsSelected && isNotNilOrEmpty(props.bulkActions)}>
            <Dropdown
              closeOnBlur
              closeOnSelect
              dropdownControl={
                <Button
                  type="button"
                  variant="link"
                  leftIcon="action/reorder"
                  title={i18n.t('general.actions.bulkActions')}
                  data-testid={suffixTestId('buttonSelectBulkAction', props)}
                />
              }
              data-testid={suffixTestId('selectBulkAction', props)}
            >
              {props.bulkActions?.map(({action, label}) => (
                <DropdownItem
                  key={label}
                  label={label}
                  onClick={() =>
                    basketItems && action(basketItems.filter((basketItem) => basketItem.isSelected))
                  }
                />
              ))}
            </Dropdown>
          </Show>
        </HStack>

        <Scroll flex={1} auto>
          <VStack height="100%" spacing={4}>
            <DataStatus
              isLoading={props.basket.isLoading}
              isError={props.basket.hasError}
              isEmpty={basketItemsCount === 0}
              emptyMessage={i18n.t('general.notifications.noItemsSelected')}
              minHeight="100%"
            >
              {basketItems?.map((basketItem, index) => (
                <AfterSalesLabourBasketItem
                  key={basketItem.id}
                  item={basketItem}
                  onSelect={handleSelectBasketItem}
                  onQuantityChange={props.onQuantityChange}
                  onEdit={props.onEdit}
                  onDelete={props.onDelete}
                  data-testid={suffixTestId(`basketItem-[${index}]`, props)}
                />
              ))}
            </DataStatus>
          </VStack>
        </Scroll>

        <Separator />

        <BasketSummary totalPrice={basketTotalPrice} data-testid={suffixTestId('summary', props)} />
      </VStack>
    </Card>
  );
}
