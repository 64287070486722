import {omit} from 'ramda';

import {
  CarFeaturesResponseBody,
  CreateVehicleV2ApiArg,
  FeatureRequestBody,
  VehicleFeatureResponseBody,
  VehicleTypeEnum,
} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

import {ServiceVehicleFormType} from '../types/ServiceVehicleFormType';
import {formatIsoDate} from './formatIsoDate';

interface ConvertFormVehicleToVehicleV2Props {
  formVehicle: ServiceVehicleFormType;
  givenCarFeatures?: VehicleFeatureResponseBody[] | Nullish;
  staticCarFeatures?: CarFeaturesResponseBody | Nullish;
}

export function convertFormVehicleToVehicleV2(
  props: ConvertFormVehicleToVehicleV2Props
): CreateVehicleV2ApiArg {
  const cleanForm = omit(
    ['note', 'formMake', 'formModelFamily', 'unknownMakes', 'unknownModels'],
    props.formVehicle
  );

  const features: FeatureRequestBody[] =
    props.formVehicle.features?.map((item) => {
      const defaultHighlight = props.staticCarFeatures?.highlightedFeatures.includes(item);
      const highlightOverride = props.givenCarFeatures?.find((feature) => feature.key === item);

      return {
        key: item,
        isHighlighted: highlightOverride ? highlightOverride.isHighlighted : defaultHighlight,
        isPremium: props.staticCarFeatures?.premiumFeatures.includes(item),
      };
    }) || [];

  return {
    ...cleanForm,
    registrationPlate: props.formVehicle.registrationPlate?.toUpperCase(),
    vin: props.formVehicle.vin?.toUpperCase(),
    bodyStyle: props.formVehicle.bodyStyle?.at(0) || null,
    drive: props.formVehicle.drive?.at(0) || null,
    hybridType: props.formVehicle.hybridType?.at(0) || null,
    fuelType: props.formVehicle.fuelType?.at(0) || null,
    secondaryFuelType: props.formVehicle.secondaryFuelType?.at(0) || null,
    transmission: props.formVehicle.transmission?.at(0) || null,
    serviceBookType: props.formVehicle.serviceBookType?.at(0) || null,
    serviceBookState: props.formVehicle.serviceBookState?.at(0) || null,
    type: (props.formVehicle.type?.at(0) as VehicleTypeEnum) || null,
    features,
    exteriorColorSpecification: props.formVehicle.exteriorColorSpecification?.at(0) || null,
    serviceContractUntil: formatIsoDate(props.formVehicle.serviceContractUntil),
    technicalInspectionValidUntil: formatIsoDate(props.formVehicle.technicalInspectionValidUntil),
    warrantyDate: formatIsoDate(props.formVehicle.warrantyDate),
    exteriorColor: {
      value: props.formVehicle.exteriorColor?.value ?? null,
      name: props.formVehicle.exteriorColor?.name ?? null,
      code: props.formVehicle.exteriorColor?.code ?? null,
    },
    manufacturedOn:
      props.formVehicle.manufacturedOnYear &&
      props.formVehicle.manufacturedOnMonth &&
      `${props.formVehicle.manufacturedOnYear}-${props.formVehicle.manufacturedOnMonth.padStart(2, '0')}`,
  };
}
