import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
  UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useParams} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {
  featureFlags,
  handleApiError,
  useCreateWatermarkMutation,
  useGetWatermarkDetailQuery,
  useUpdateWatermarkDetailMutation,
  useUploadFileMutation,
} from '@omnetic-dms/shared';

import {isFeatureEnabled, useNavigate, yupString} from 'shared';

import {WatermarkFormType} from '../types/watermarkForm';
import {getDefaultValue} from '../utils/getDefaultValue';
import {getRequestData} from '../utils/getRequestData';
import {addToOptions, getPositionOptions} from '../utils/radioOptions';
import {WatermarkDetailPreview} from './WatermarkDetailPreview';

export function WatermarkForm() {
  const {id} = useParams();
  const navigate = useNavigate();

  const [uploadFile] = useUploadFileMutation();
  const [updateWatermark] = useUpdateWatermarkDetailMutation();
  const [createWatermark] = useCreateWatermarkMutation();
  const {data: waterMarkData} = useGetWatermarkDetailQuery(
    {watermarkId: id ?? ''},
    {skip: isNilOrEmpty(id)}
  );

  const handleUpload = async (file: File) => {
    const data = await uploadFile({file}).unwrap();

    return {id: data.fileId, url: data.fileUri};
  };

  const handleSubmit: FormSubmitHandler<WatermarkFormType> = async (data) => {
    if (id) {
      await updateWatermark({watermarkId: id, updateWatermarkRequestBody: getRequestData(data)})
        .unwrap()
        .then(() => {
          showNotification.success();
          navigate(
            isFeatureEnabled(featureFlags.SETTINGS_WATERMARKS)
              ? settingsRoutes.advertisingWatermarking
              : settingsRoutes.advertisingWatermarkingOld
          );
        })
        .catch(handleApiError);
    } else {
      await createWatermark({createWatermarkRequestBody: getRequestData(data)})
        .unwrap()
        .then(() => {
          showNotification.success();
          navigate(
            isFeatureEnabled(featureFlags.SETTINGS_WATERMARKS)
              ? settingsRoutes.advertisingWatermarking
              : settingsRoutes.advertisingWatermarkingOld
          );
        })
        .catch(handleApiError);
    }
  };

  const handleDiscard = () =>
    navigate(
      isFeatureEnabled(featureFlags.SETTINGS_WATERMARKS)
        ? settingsRoutes.advertisingWatermarking
        : settingsRoutes.advertisingWatermarkingOld
    );

  const handleUploadError = (error: Error) => {
    if (error instanceof UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField) {
      showNotification.error(i18n.t('entity.watermark.notifications.imageFormatIsNotAllowed'));
    }
  };

  return (
    <Form<WatermarkFormType>
      onSubmit={handleSubmit}
      schema={WatermarkFormSchema}
      defaultValues={getDefaultValue(waterMarkData)}
    >
      {(control, formApi) => (
        <VStack spacing={3}>
          <Box maxWidth="50%">
            <FormField
              label={i18n.t('page.salesSettings.backgroundRemoval.configurationName')}
              control={control}
              name="name"
              type="text"
              isRequired
              data-testid="backgroundRemoval-configurationName"
            />
          </Box>

          <Separator spacing={2} />

          <FormField
            type="uploadImage"
            control={control}
            name="fileId"
            uploadFileRequest={handleUpload}
            onUploadError={handleUploadError}
            helperText={i18n.t('entity.photo.labels.uploadOrDragAndDropImage')}
            uploadingText={i18n.t('general.labels.uploadingWithDots')}
            uploadIcon="image/photo_camera"
            linkValueFormatter={(url) => url.replace('resize', 'get')}
            srcValueFormatter={(url) => `${url}${url.includes('resize') ? '&height=108' : ''}`}
            onPreviewClick={(url) =>
              window.open(`${url.replace('resize', 'get')}&type=inline`, '_blank')
            }
            data-testid="settings-advertising-watermarking-uploadImage"
          />
          <Separator />
          <HStack align="center" spacing={4}>
            <VStack spacing={2} width={80}>
              <FormField
                type="slider"
                name="size"
                control={control}
                min={0}
                max={100}
                step={1}
                ticks={2}
                label={i18n.t('entity.watermark.labels.size')}
                isDisabled={formApi.watch('fitToImage')}
                data-testid="settings-advertising-watermarking-form-size"
              />
            </VStack>
            <FormField
              type="checkbox"
              name="fitToImage"
              control={control}
              label={i18n.t('entity.watermark.labels.fitToImage')}
              data-testid="settings-advertising-watermarking-form-fitToImage"
            />
          </HStack>
          <Separator />
          <VStack spacing={4}>
            <FormField
              label={i18n.t('entity.watermark.labels.position')}
              type="radio"
              name="position"
              control={control}
              options={getPositionOptions('top')}
              data-testid="settings-advertising-watermarking-form-position-top"
            />
            <FormField
              type="radio"
              name="position"
              control={control}
              options={getPositionOptions('center')}
              data-testid="settings-advertising-watermarking-form-position-center"
            />
            <FormField
              type="radio"
              name="position"
              control={control}
              options={getPositionOptions('bottom')}
              data-testid="settings-advertising-watermarking-form-position-bottom"
            />
          </VStack>
          <Separator />
          <FormField
            type="radio"
            name="addOnlyForFirstPhoto"
            control={control}
            options={addToOptions}
            data-testid="settings-advertising-watermarking-form-addOnlyForFirstPhoto"
          />
          <Separator />
          <VStack spacing={4}>
            <WatermarkDetailPreview watermarkData={getRequestData(formApi.watch())} />
          </VStack>
          <Separator />
          <VStack justify="flex-end">
            <ButtonGroup>
              <Button
                variant="secondary"
                onClick={handleDiscard}
                title={i18n.t('general.actions.discard')}
                data-testid="settings-advertising-watermarking-form-discard-btn"
              />
              <FormButton
                type="submit"
                title={i18n.t('general.actions.save')}
                control={control}
                data-testid="settings-advertising-watermarking-form-save-btn"
              />
            </ButtonGroup>
          </VStack>
        </VStack>
      )}
    </Form>
  );
}

const WatermarkFormSchema = Yup.object({
  name: yupString.required(),
  fileId: Yup.object().nullable(),
  size: Yup.number().required(),
  position: Yup.string().required(),
  fitToImage: Yup.boolean(),
  addOnlyForFirstPhoto: Yup.boolean(),
});
