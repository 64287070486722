import {useParams} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {useGetBackgroundRemovalV2Query} from '@omnetic-dms/shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {BackgroundRemovalForm} from './components/BackgroundRemovalForm';

export function BackgroundRemovalDetail() {
  const {id} = useParams();

  const {data} = useGetBackgroundRemovalV2Query({settingId: id ?? ''}, {skip: isNilOrEmpty(id)});
  return (
    <SettingsTemplate
      header={{
        title: data?.name
          ? i18n.t('page.salesSettings.labels.backgroundRemovalDetailName', {name: data?.name})
          : i18n.t('page.salesSettings.backgroundRemoval.common.newConfiguration'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
          {
            label: i18n.t('page.salesSettings.labels.backgroundRemoval'),
            href: settingsRoutes.backgroundRemoval,
          },
        ],
      }}
      data-testid="settings-backgroundRemoval"
      description={i18n.t('page.salesSettings.backgroundRemoval.common.descriptionDetail')}
    >
      <BackgroundRemovalForm />
    </SettingsTemplate>
  );
}
