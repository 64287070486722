import {
  Checkbox,
  CheckboxProps,
  Choice,
  ChoiceProps,
  DataStatus,
  IconButton,
  Separator,
} from 'platform/components';
import {Box, getSize, Hide, HStack, Show, Spinner, Text, VStack} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';
import {css} from 'styled-components';

import {useEffect, useState} from 'react';

import {head, isNil, isNotEmpty} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {keyExtractor, SourcingTableDispatch, SourcingTableState} from '@omnetic-dms/teas';

import {EMPTY_PLACEHOLDER, Nullish, suffixTestId, TestIdProps} from 'shared';

import {getSourcingVehicleId} from '../../utils/getSourcingVehicleId';
import {AdvanceVehicleView} from '../AdvanceVehicleView/AdvanceVehicleView';
import {DataViewBulkActionDrawer} from './components/DataViewBulkActionDrawer';
import {SourcingTableRow} from './components/SourcingTableRow';
import {useHandleKeyboards} from './hooks/useHandleKeyboards';
import {INFINITE_SCROLL_ELEMENT_ID, useInfiniteScroll} from './hooks/useInfiniteScroll';
import {Row} from './types/Row';
import {createRowElementId} from './utils/createRowElementId';
import {Column} from './utils/getSourcingTableColumns';
import {sourcingTableSessionStorage} from './utils/sourcingTableSessionStorage';

interface TableProps extends TestIdProps {
  state: SourcingTableState;
  dispatch: SourcingTableDispatch;
  columns: Column[];
  sortByOptions: ChoiceProps<string>['options'];
  data: Row[];
  isLoading: boolean;
  error: any;
  count?: number | Nullish;
}

const HEIGHT_OF_TABLE_HEADER = 20;
const HEIGHT_OF_TABLE_HEADER_AND_FOOTER = 36;

export function SourcingTable(props: TableProps) {
  const formatNumber = useFormatNumber();
  const [isDetailedView, setIsDetailedView] = useState(false);

  const onToggleDetailView = () => {
    if (!isDetailedView) {
      props.dispatch({type: 'changeCheckedRows', rows: []});
    }
    setIsDetailedView(!isDetailedView);
  };

  useHandleKeyboards(props.state.selectedRow, props.data, props.dispatch);

  const hasMoreRows = (props.count ?? 0) > props.data.length;
  const nextPage = Math.ceil(props.data.length / 20) + 1;
  const LoadMoreElement = useInfiniteScroll(props.dispatch, props.isLoading, hasMoreRows, nextPage);

  const {page, selectedRow, tableName} = props.state;
  const {data, dispatch} = props;

  // Set last visited page if data are still cached, otherwise set first page
  useEffect(() => {
    if (isNil(page) && isNotEmpty(data)) {
      const savedPage = sourcingTableSessionStorage.getLastVisitedPage(tableName);
      dispatch({type: 'changePage', page: savedPage ?? 1});
    }
  }, [data, dispatch, page, tableName]);

  // Select last visited vehicle and then scroll on it
  useEffect(() => {
    if (isNil(selectedRow) && isNotEmpty(data)) {
      const lastVisitedVehicleId = sourcingTableSessionStorage.getLastVisitedVehicleId(tableName);
      const lastVisitedVehicle = data.find(
        (item) => lastVisitedVehicleId === item.sourcingVehicle.adId
      );
      dispatch({type: 'selectRow', row: lastVisitedVehicle ?? head(data)});

      if (lastVisitedVehicle) {
        window.document
          .getElementById(createRowElementId(lastVisitedVehicle))
          ?.scrollIntoView({block: 'nearest'});
      }
    }
  }, [data, dispatch, selectedRow, tableName]);

  // Select first row if selected row is put to hidden list
  useEffect(() => {
    if (
      isNotNil(selectedRow) &&
      !data.some((row) => row.sourcingVehicle.adId === selectedRow.sourcingVehicle.adId)
    ) {
      dispatch({type: 'selectRow', row: head(data)});
    }
  }, [data, dispatch, selectedRow]);

  const handleRowCheckbox =
    (row: Row): NonNullable<CheckboxProps['onChange']> =>
    (value) => {
      if (value) {
        props.dispatch({type: 'changeCheckedRows', rows: [...props.state.checkedRows, row]});
      } else {
        props.dispatch({
          type: 'changeCheckedRows',
          rows: props.state.checkedRows.filter((row1) => keyExtractor(row1) !== keyExtractor(row)),
        });
      }
    };

  const onUncheckCheckedRows = (rowIds: string[]) => {
    props.dispatch({
      type: 'changeCheckedRows',
      rows: props.state.checkedRows.filter(
        (row1) => !rowIds.includes(getSourcingVehicleId(row1) ?? '')
      ),
    });
  };

  const onSetLastVisitedVehicle = (adId: string) => {
    sourcingTableSessionStorage.setLastVisitedVehicleId(props.state.tableName, adId);
  };

  const onSelectRow = (row: Row) => {
    props.dispatch({type: 'selectRow', row});
    const adId = getSourcingVehicleId(row);
    if (isNotNil(adId)) {
      onSetLastVisitedVehicle(adId);
    }
  };

  const isFooterVisible = isNotEmpty(props.state.checkedRows);
  const headerAndFooterHeight = isFooterVisible
    ? HEIGHT_OF_TABLE_HEADER_AND_FOOTER
    : HEIGHT_OF_TABLE_HEADER;

  const isCheckedAll =
    isNotEmpty(props.data) &&
    props.data.every((row) =>
      props.state.checkedRows.find((checkedRow) => keyExtractor(row) === keyExtractor(checkedRow))
    );

  const checkedRows = props.state.checkedRows.map((checkedRow) => {
    const row = props.data.find((row) => keyExtractor(row) === keyExtractor(checkedRow));
    return row ?? checkedRow;
  });

  const isFullscreenLoading = props.isLoading && (props.state.page ?? 1) === 1;
  const isLoadingMore = props.isLoading && (props.state.page ?? 1) > 1;

  return (
    <Box
      backgroundColor="general.white"
      borderRadius="xSmall"
      boxShadow="elevation_1"
      height="100%"
      overflow="hidden"
    >
      <Box padding={2} paddingVertical={2}>
        <HStack justify="flex-end" spacing={2}>
          <HStack align="center" spacing={2}>
            <Text size="xSmall" color="secondary">
              {i18n.t('general.actions.sortBy')}
            </Text>
            <Box width={40}>
              <Choice
                value={props.state.orderBy}
                onChange={(value) => props.dispatch({type: 'sort', column: value as string})}
                options={props.sortByOptions ?? []}
                isNotClearable
                data-testid={suffixTestId('sortByOptions', props)}
              />
            </Box>
          </HStack>
          <Show when={isNotNil(props.count)}>
            <Separator orientation="vertical" spacing={0} />
            <HStack align="center">
              <Text size="xSmall" color="secondary">
                {isNotNil(props.count)
                  ? i18n.t('general.labels.result', {
                      count: props.count,
                      formattedCount: formatNumber(props.count),
                    })
                  : EMPTY_PLACEHOLDER}
              </Text>
            </HStack>
          </Show>
          <Separator orientation="vertical" spacing={0} />
          <IconButton
            icon={isDetailedView ? 'action/vertical_split' : 'action/view_stream'}
            onClick={onToggleDetailView}
            data-testid={suffixTestId('toggleDetailView', props)}
          />
        </HStack>
      </Box>
      <Separator spacing={0} />
      <Hide when={isDetailedView}>
        <Box paddingHorizontal={3} paddingVertical={2}>
          <HStack spacing={2} align="center">
            <Box width={14}>
              <Checkbox
                value={isCheckedAll}
                data-testid={suffixTestId('selectAllCheckbox', props)}
                onChange={(value) =>
                  props.dispatch({type: 'changeCheckedRows', rows: value ? props.data : []})
                }
              />
            </Box>
            {props.columns.map((column) => (
              <Box
                key={column.id}
                width={column.width}
                minWidth={isNil(column.width) ? 65 : 0}
                flex={isNotNil(column.width) ? undefined : 1}
              >
                {column.Header}
              </Box>
            ))}
            <Box width={6} />
          </HStack>
        </Box>
        <Separator spacing={0} />
      </Hide>
      <DataStatus isError={props.error} isLoading={isFullscreenLoading} minHeight={50}>
        <Box height={`calc(100% - ${getSize(headerAndFooterHeight)})`} position="relative">
          <div
            css={css`
              height: 100%;
              overflow-y: scroll;
            `}
            id={INFINITE_SCROLL_ELEMENT_ID}
          >
            <VStack>
              {props.data.map((row, index) => (
                <div key={keyExtractor(row)} id={createRowElementId(row)}>
                  {index === props.data.length - 2 && <LoadMoreElement />}
                  {!isDetailedView ? (
                    <>
                      <SourcingTableRow
                        key={keyExtractor(row)}
                        onClick={() => onSelectRow(row)}
                        index={index}
                        columns={props.columns}
                        row={row}
                        isExpanded={props.state.expandedRowIds.includes(keyExtractor(row))}
                        onExpandedChange={(row, isExpanded) =>
                          props.dispatch({type: 'expandRow', row, isExpanded})
                        }
                        isSelected={
                          isNotNil(props.state.selectedRow) &&
                          keyExtractor(props.state.selectedRow) === keyExtractor(row)
                        }
                        isChecked={props.state.checkedRows.some(
                          (value) => keyExtractor(value) === keyExtractor(row)
                        )}
                        onCheck={handleRowCheckbox(row)}
                        setLastVisitedVehicle={onSetLastVisitedVehicle}
                      />
                      <Show when={index < props.data?.length - 1}>
                        <Separator orientation="horizontal" spacing={0} />
                      </Show>
                    </>
                  ) : (
                    <>
                      <AdvanceVehicleView
                        key={keyExtractor(row)}
                        isLastClickedRow={
                          isNotNil(props.state.selectedRow) &&
                          keyExtractor(props.state.selectedRow) === keyExtractor(row)
                        }
                        onRowClick={() => onSelectRow(row)}
                        vehicle={row as any}
                        setLastVisitedVehicle={onSetLastVisitedVehicle}
                        data-testid={suffixTestId('advanceVehicleView', props)}
                      />
                      <Box height={2} backgroundColor="palettes.neutral.10.100" />
                    </>
                  )}
                </div>
              ))}
              <HStack height={16} align="center" justify="center">
                <Show when={isLoadingMore}>
                  <Spinner />
                </Show>
              </HStack>
            </VStack>
          </div>
        </Box>
        <Show when={isFooterVisible}>
          <Box
            padding={4}
            height={16}
            backgroundColor="general.white"
            borderTop="1px solid"
            borderColor="general.separator"
          >
            <VStack height="100%" justify="center">
              <Box height="100%">
                <DataViewBulkActionDrawer
                  selectedRows={checkedRows}
                  pathToVehicleAdId={['sourcingVehicle', 'adId']}
                  onUnselectSelectedRows={onUncheckCheckedRows}
                />
              </Box>
            </VStack>
          </Box>
        </Show>
      </DataStatus>
    </Box>
  );
}
