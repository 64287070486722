import styled from 'styled-components';

export const SidebarButton = styled.button<{isOpen: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isOpen
      ? props.theme.colors.palettes.neutral[500]['100']
      : props.theme.colors.palettes.neutral[900]['100']};
  width: ${({theme}) => theme.getSize(8)};
  height: ${({theme}) => theme.getSize(8)};
  border-radius: ${({theme}) => theme.getSize(1)};
  &:hover {
    background-color: ${(props) => props.theme.colors.palettes.neutral[500]['100']};
`;
