import {
  DeletePartsRequestReturnItemsRequest,
  DeletePartsRequestReturnItemsRequestSchema,
  DeletePartsRequestReturnItemsResponse,
  DeletePartsRequestReturnItemsResponseSchema,
  PostPartsRequestReturnItemRequest,
  PostPartsRequestReturnItemRequestSchema,
  PostPartsRequestReturnItemResponse,
  PostPartsRequestReturnItemResponseSchema,
} from '../types/api/metadaWarehousePartsRequestReturnItem';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehousePartsRequestReturnItemApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postPartsRequestReturnItem: build.mutation<
      PostPartsRequestReturnItemResponse,
      PostPartsRequestReturnItemRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v1/parts-request-return',
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'partsRequestReturnBasket', id: queryArg.body.originEntityHeaderId},
      ],
      extraOptions: {
        requestSchema: PostPartsRequestReturnItemRequestSchema,
        responseSchema: PostPartsRequestReturnItemResponseSchema,
      },
    }),
    deletePartsRequestReturnItems: build.mutation<
      DeletePartsRequestReturnItemsResponse,
      DeletePartsRequestReturnItemsRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v1/parts-request-return/items/delete',
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'partsRequestReturnBasket', id: queryArg.originEntityHeaderId},
      ],
      extraOptions: {
        requestSchema: DeletePartsRequestReturnItemsRequestSchema,
        responseSchema: DeletePartsRequestReturnItemsResponseSchema,
      },
    }),
  }),
});

export const {usePostPartsRequestReturnItemMutation, useDeletePartsRequestReturnItemsMutation} =
  metadaWarehousePartsRequestReturnItemApi;
