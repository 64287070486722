import {DataStatus, FormSubmitHandler, showNotification} from 'platform/components';
import {Icon, Box, Heading, HStack, Show, VStack, Hide} from 'platform/foundation';

import {find, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  CampaignCard,
  handleApiError,
  useDeleteServiceCaseJobMutation,
  useGetMetadaServiceCaseQuery,
  useGetPostponeJobsQuery,
  useGetServiceCaseJobsQuery,
  useGetServiceCaseOrderVariantsQuery,
  useGetVehicleServiceCampaignQuery,
  usePatchServiceCaseJobMutation,
  usePostServiceCaseJobFromPostponeJobMutation,
  usePostServiceCaseJobMutation,
  usePostServiceCaseJobServiceCampaignMutation,
  JobFormProps,
  PostponedJob,
} from '@omnetic-dms/shared';

import {Job} from '../../../../../components/Job/Job';
import {JobInput, JobInputForm} from '../../../../../components/JobInput/JobInput';

interface JobsProps {
  serviceCaseId: string;
}

export function Jobs(props: JobsProps) {
  const {
    data: jobs,
    isError: isJobError,
    isLoading: isJobLoading,
  } = useGetServiceCaseJobsQuery({
    serviceCaseId: props.serviceCaseId,
  });
  const {
    defaultVariant,
    isError: isVariantError,
    isLoading: isVariantLoading,
  } = useGetServiceCaseOrderVariantsQuery(
    {serviceCaseId: props.serviceCaseId},
    {
      selectFromResult: ({data, isLoading, isError}) => ({
        isLoading,
        isError,
        defaultVariant: find((variant) => !!variant?.default, data ?? []),
      }),
    }
  );
  const {data: postponedJobs} = useGetPostponeJobsQuery({serviceCaseId: props.serviceCaseId});
  const {data: serviceCase} = useGetMetadaServiceCaseQuery({
    serviceCaseId: props.serviceCaseId,
  });
  const {data: campaign} = useGetVehicleServiceCampaignQuery(
    {vehicleId: serviceCase?.vehicleId ?? ''},
    {skip: isNil(serviceCase?.vehicleId) || isJobLoading}
  );

  const [postServiceCaseJob] = usePostServiceCaseJobMutation();
  const [patchServiceCaseJob] = usePatchServiceCaseJobMutation();
  const [deleteServiceCaseJob] = useDeleteServiceCaseJobMutation();
  const [postServiceCaseJobFromPostponeJob] = usePostServiceCaseJobFromPostponeJobMutation();
  const [postServiceCaseJobServiceCampaign] = usePostServiceCaseJobServiceCampaignMutation();

  const onSubmit: FormSubmitHandler<JobInputForm> = async ({job}) => {
    await postServiceCaseJob({
      serviceCaseId: props.serviceCaseId,
      body: {name: job, orderVariantId: defaultVariant?.id},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleJobUpadate: JobFormProps['onSubmit'] = async (data, reset) => {
    const jobId = data?.id;

    if (isNil(jobId)) {
      return;
    }

    await patchServiceCaseJob({
      serviceCaseId: props.serviceCaseId,
      serviceCaseJobId: jobId,
      body: data,
    })
      .unwrap()
      .then(() => {
        reset();
        showNotification.success();
      })
      .catch(handleApiError);
  };

  const handleRemoveJob = (jobId?: string | null) => {
    if (isNil(jobId)) {
      return;
    }

    deleteServiceCaseJob({
      serviceCaseId: props.serviceCaseId,
      serviceCaseJobId: jobId,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleAddPostponedJob = (postponedJobId?: string | null) => {
    if (isNil(postponedJobId) || isNil(defaultVariant)) {
      return;
    }

    postServiceCaseJobFromPostponeJob({
      serviceCaseId: props.serviceCaseId,
      postponeJobId: postponedJobId,
      serviceOrderVariantId: defaultVariant.id ?? '',
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const handleCampaignAdd = (serviceCampaignId: string) =>
    postServiceCaseJobServiceCampaign({
      serviceCaseId: props.serviceCaseId,
      body: {
        serviceCampaign: {
          platformCode: campaign?.platformCode ?? '',
          serviceCode: campaign?.serviceCode ?? '',
          serviceCampaignId,
        },
      },
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const activeCampaigns =
    campaign?.serviceCampaign?.filter(
      (campaign) => campaign.kind === 'ACTIVE_CAMPAIGN' && isNil(campaign.serviceOrder)
    ) ?? [];

  return (
    <Box width="100%">
      <VStack spacing={6}>
        <Hide when={isNilOrEmpty(postponedJobs?.postponeJob) && isNilOrEmpty(activeCampaigns)}>
          <HStack spacing={2} align="center">
            <Icon value="action/lightbulb_outline" size={4} />
            <Heading size={4}>{i18n.t('entity.orderRequest.labels.suggested')}</Heading>
          </HStack>
          <VStack spacing={3}>
            {postponedJobs?.postponeJob?.map(
              (job, index) =>
                job && (
                  <PostponedJob
                    key={job.postponeJobId}
                    serviceCaseId={props.serviceCaseId}
                    postponeJobData={job}
                    onAddJob={() => handleAddPostponedJob(job.postponeJobId)}
                    data-testid={testIds.workshop.createServiceCase(`suggested-[${index}]`)}
                  />
                )
            )}
            {activeCampaigns?.map((campaign, index) => (
              <CampaignCard
                key={campaign.id}
                data={campaign}
                onAdd={() => handleCampaignAdd(campaign.id ?? '')}
                isSuggested
                data-testid={testIds.workshop.createServiceCase(`suggested-campaign-[${index}]`)}
              />
            ))}
          </VStack>
        </Hide>
        <HStack spacing={2} align="center">
          <Icon value="action/build" size={4} />
          <Heading size={4}>{i18n.t('entity.orderRequest.labels.listTitle')}</Heading>
        </HStack>
        <DataStatus
          isError={isVariantError}
          isLoading={isVariantLoading}
          data-testid={testIds.workshop.createServiceCase('variantStatus')}
          minHeight={48}
        >
          <DataStatus
            isError={isJobError}
            isLoading={isJobLoading}
            data-testid={testIds.workshop.createServiceCase('jobsStatus')}
            minHeight={30}
          >
            <Show when={jobs?.length}>
              <VStack spacing={3}>
                {jobs?.map((job, index) => (
                  <Job
                    serviceCaseId={props.serviceCaseId}
                    key={job?.id}
                    jobData={job}
                    onSubmit={handleJobUpadate}
                    onRemove={() => handleRemoveJob(job?.id)}
                    data-testid={testIds.workshop.createServiceCase(`job-[${index}]`)}
                  />
                ))}
              </VStack>
            </Show>
          </DataStatus>
          <JobInput
            onSubmit={onSubmit}
            data-testid={testIds.workshop.createServiceCase('jobInput')}
          />
        </DataStatus>
      </VStack>
    </Box>
  );
}
