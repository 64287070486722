import {DataStatus, Label, isCurrency} from 'platform/components';
import {HStack, Heading, Show, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter, useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {always, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  EMPTY_PLACEHOLDER,
  Money,
  OrderPaymentResponseBody,
  useGetAccountingDocuments,
  useShowPaymentPrescriptionQuery,
} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

import {getPaymentInvoiceInfo} from '../../../utils/getPaymentInvoiceInfo';

export type DepositPriceBoxProps = {
  payment: OrderPaymentResponseBody;
};

/**
 * @deprecated
 * This is kept to satisfy use-case
 * when prescriptionId in checkout is not available
 */
export const PaymentPriceBox = (props: DepositPriceBoxProps) => {
  const formatCurrency = useFormatCurrency();
  const formatCurrencySymbol = useCurrencySymbolFormatter();

  const {id, type} = getPaymentInvoiceInfo(props.payment);

  const {
    data: invoice,
    isError: isErrorInvoice,
    isLoading: isLoadingInvoice,
  } = useGetAccountingDocuments(id, type);

  const {
    data: prescription,
    isLoading: isPrescriptionLoading,
    isError: isPrescriptionError,
  } = useShowPaymentPrescriptionQuery(
    {paymentPrescriptionId: invoice?.paymentPrescriptionId ?? ''},
    {skip: isNilOrEmpty(invoice?.paymentPrescriptionId)}
  );

  const formatSymbol = (val: string | Nullish, showSymbol = true) =>
    match([isCurrency(val), showSymbol])
      .with([true, true], always(`(${isCurrency(val) ? formatCurrencySymbol(val) : val})`))
      .otherwise(always(''));

  const formatPrice = (val: Money | Nullish) =>
    isNotNil(val)
      ? (formatCurrency(parseFloat(val.amount), val.currency, 2) ?? EMPTY_PLACEHOLDER)
      : EMPTY_PLACEHOLDER;

  const isLoading = isLoadingInvoice || isPrescriptionLoading;
  const isError = isErrorInvoice || isPrescriptionError;

  const price = prescription?.price ?? props.payment.foreignCurrencyAmount ?? props.payment.amount;
  const remainsToPay =
    prescription?.remainsToPay ?? props.payment.foreignCurrencyAmount ?? props.payment.amount;

  return (
    <DataStatus isError={isError} isLoading={isLoading} grow={1} spacing={5}>
      <HStack spacing={4}>
        <VStack width={41}>
          <Label>{`${i18n.t('general.labels.amount')} ${formatSymbol(
            price?.currency,
            false
          )}`}</Label>
          <Heading size={4}>{formatPrice(price)}</Heading>
        </VStack>

        <Show when={props.payment.paymentState !== 'PAID'}>
          <VStack width={41}>
            <Label>{`${i18n.t('entity.invoice.labels.remaining')} ${formatSymbol(
              remainsToPay?.currency,
              false
            )}`}</Label>
            <Heading size={4} color="danger">
              {formatPrice(remainsToPay)}
            </Heading>
          </VStack>
        </Show>
      </HStack>
    </DataStatus>
  );
};
