import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  NumberInput,
  RadioItem,
  Separator,
} from 'platform/components';
import {Box, Hide, HStack, Show, VStack, Grid} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';
import {Nullish} from 'utility-types';
import {object, ValidationError} from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {defaultTo, isNil, not, omit, sum} from 'ramda';
import {isFalse, isFalsy, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  getDecimalFromPercentage,
  getPercentageFromDecimal,
  handleApiError,
  ItemPrice,
  useGetServiceOrderJobItemWorkQuery,
  useGetVatRatesOptions,
  usePatchOrderItemForWorkMutation,
  useWorkTypeOptions,
  useTenant,
} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps, yupNumber, yupString} from 'shared';

import {getRatioFromAmount} from '../../utils/getRatioFromAmount';
import {EditItemMechanics} from './components/EditItemMechanics';
import {EditItemFormType} from './types/EditItemFormType';

const TOTAL_RATIO = 100;

interface EditItemProps extends TestIdProps {
  itemId: string;
  serviceCaseId: string;
  serviceOrderId: string;
  serviceJobId: string;
  onClose: () => void;
  onEdited?: (formData: EditItemFormType) => void;
}

export function EditItem(props: EditItemProps) {
  const {tenantCurrency} = useTenant();

  const {
    data: itemData,
    isLoading,
    isFetching,
    isError,
  } = useGetServiceOrderJobItemWorkQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceItemId: props.itemId,
      serviceJobId: props.serviceJobId,
      serviceOrderId: props.serviceOrderId,
    },
    {refetchOnMountOrArgChange: true}
  );
  const [patchOrderItemForWork, {isLoading: isPatchLoading}] = usePatchOrderItemForWorkMutation();

  const [vatOptions] = useGetVatRatesOptions();
  const {getOptionsWithSelectedValue} = useWorkTypeOptions();

  const handleSubmit: FormSubmitHandler<EditItemFormType> = async (data, setErrors) => {
    if (
      data.assignMechanics &&
      sum(data.assignMechanics.map((mechanic) => mechanic?.ratio ?? 0)) !== TOTAL_RATIO
    ) {
      return setErrors(
        data.assignMechanics.map((_, index) => ({
          name: `assignMechanics.${index}.ratio`,
          message: i18n.t('entity.order.labels.ratioSumError'),
        }))
      );
    }

    await patchOrderItemForWork({
      serviceCaseId: props.serviceCaseId,
      serviceItemId: props.itemId,
      serviceJobId: props.serviceJobId,
      serviceOrderId: props.serviceOrderId,
      body: {
        name: data.name ?? '',
        number: data.number,
        workType: data.workType ?? '',
        quantity: data.quantity ?? 0,
        sellingPricePerUnit: data.sellingPricePerUnit ?? 0,
        purchasePricePerUnit: data.purchasePricePerUnit ?? 0,
        isCustomPrice: data.isCustomPrice ?? false,
        sellingPriceVat: {
          freeCode: data.vatFreeCode,
          type: data.vatType,
        },
        isUnitPriceWithVat: itemData?.isUnitPriceWithVat ?? false,
        priceType: itemData?.priceType ?? null,
        isDiscount: !!data.isCustomDiscount,
        isDoNotApplyDiscount: !!data.isDoNotApplyDiscount,
        discount: data.isCustomDiscount
          ? {
              sourceType: data.discountType ?? '',
              source: itemData?.discount?.availableDiscounts?.find(
                (discount) => discount?.sourceType === data.discountType
              )?.source,
              rate:
                (data.discountType === 'manual-discount'
                  ? getDecimalFromPercentage(data.discountRate)
                  : itemData?.discount?.availableDiscounts?.find(
                      (discount) => discount?.sourceType === data.discountType
                    )?.rate) ?? 0,
            }
          : null,
        assignMechanics: data.assignMechanics
          ?.filter((mechanic) => isNotNilOrEmpty(mechanic?.id))
          .map((mechanic, index) => ({
            ...omit(['ratio'], mechanic),
            id: mechanic.id ?? '',
            isDefault: index === 0,
            ratio: mechanic.ratio / 100,
          })),
      },
    })
      .unwrap()
      .then(() => {
        props.onEdited?.(data);
        props.onClose();
      })
      .catch(handleApiError);
  };

  const handleQuantityChange =
    (formApi: UseFormReturn<EditItemFormType>) => (value: number | null) => {
      if (isNil(value)) {
        return;
      }

      validateQuantity(value, formApi);

      const sellingPricePerUnit = formApi.getValues('sellingPricePerUnit');

      formApi.setValue(
        'sellingPrice',
        isNotNil(sellingPricePerUnit) ? value * sellingPricePerUnit : 0
      );

      const mechanics = formApi.getValues('assignMechanics');

      if (mechanics?.length === 1) {
        return formApi.setValue(`assignMechanics.0.amount`, value);
      }

      mechanics?.forEach(
        (mechanic, index) =>
          isNotNil(mechanic.amount) &&
          formApi.setValue(
            `assignMechanics.${index}.ratio`,
            Math.round((mechanic.amount / value) * 100)
          )
      );
    };

  const validateQuantity = (value: number, formApi: UseFormReturn<EditItemFormType>) => {
    const valueToValidate = defaultTo(0, value);

    try {
      formApi.clearErrors('quantity');

      quantitySchema.validateSync(valueToValidate);

      return true;
    } catch (error) {
      if (error instanceof ValidationError) {
        formApi.setError('quantity', {message: error.errors.join(' ')});
      }
      return false;
    }
  };

  const defaultValues: EditItemFormType = {
    name: itemData?.name,
    number: itemData?.number,
    workType: itemData?.workType,
    quantity: itemData?.priceType === 'SVCPRICETYPE_DIRECT_PRICE' ? 1 : itemData?.quantity?.amount,
    originalSellingPricePerUnit: itemData?.baseSellingPricePerUnit?.amount,
    sellingPricePerUnit: itemData?.sellingPricePerUnit?.amount,
    sellingPrice:
      isNotNil(itemData?.sellingPricePerUnit?.amount) && isNotNil(itemData?.quantity?.amount)
        ? itemData?.sellingPricePerUnit?.amount * itemData?.quantity?.amount
        : null,
    originalPurchasePricePerUnit: itemData?.basePurchasePricePerUnit?.amount,
    purchasePricePerUnit: itemData?.purchasePricePerUnit?.amount,
    vatType: itemData?.sellingPriceVat?.type,
    discountRate: getPercentageFromDecimal(
      itemData?.discount?.availableDiscounts?.find(
        (discount) => discount?.sourceType === 'manual-discount'
      )?.rate
    ),
    discountType:
      itemData?.discount?.availableDiscounts?.find((discount) => discount?.isSelected)
        ?.sourceType ?? 'manual-discount',
    isCustomDiscount:
      itemData?.discount?.availableDiscounts?.some((discount) => discount?.isSelected) ?? undefined,
    isCustomPrice: Boolean(itemData?.isCustomPrice),
    vatFreeCode: itemData?.sellingPriceVat?.freeCode ?? undefined,
    assignMechanics: itemData?.assignMechanics?.filter(isNotNil)?.map((mechanic) => ({
      id: mechanic.id ?? null,
      costCenterId: mechanic.costCenterId ?? null,
      amount: mechanic?.amount ?? 0,
      ratio: getRatioFromAmount(mechanic?.amount, itemData.quantity?.amount) ?? 0,
    })) ?? [{id: null, costCenterId: null, amount: 0, ratio: 0}],
    isDoNotApplyDiscount: itemData?.isDoNotApplyDiscount ?? undefined,
    customPriceType: 'perUnit',
  };

  const isTimeNormType = itemData?.priceType === 'SVCPRICETYPE_TIME_NORM';
  const isCooperationType = itemData?.priceType === 'SVCPRICETYPE_COOPERATION';
  const isDirectPriceType = itemData?.priceType === 'SVCPRICETYPE_DIRECT_PRICE';
  const isCalculationPriceType = itemData?.priceType === 'SVCPRICETYPE_CALCULATION_PRICE';
  const isSubcontractPriceType = itemData?.priceType === 'SVCPRICETYPE_SUBCONTRACT';

  const goodwillRatio = itemData?.goodwill?.goodwillRatio ?? 1;
  const hasGoodwill = goodwillRatio !== 1;

  const workTypeOptions = getOptionsWithSelectedValue(itemData?.workType);

  const handleCustomPriceSwitch =
    (formApi: UseFormReturn<EditItemFormType>) => (value: boolean) => {
      if (value) {
        formApi.setValue('customPriceType', 'perUnit');
      } else {
        const quantity = formApi.watch('quantity');
        formApi.setValue(
          'sellingPrice',
          isNotNil(quantity) && isNotNil(itemData?.baseSellingPricePerUnit?.amount)
            ? quantity * itemData.baseSellingPricePerUnit.amount
            : 0
        );
        formApi.setValue('sellingPricePerUnit', itemData?.baseSellingPricePerUnit?.amount ?? 0);
        formApi.setValue('purchasePricePerUnit', itemData?.basePurchasePricePerUnit?.amount);
        formApi.setValue('vatType', itemData?.baseSellingPriceVat?.type ?? '');
      }
    };

  const onCustomPricePerUnitChange =
    (formApi: UseFormReturn<EditItemFormType>) => (value: number | Nullish) => {
      const quantity = formApi.watch('quantity');

      formApi.setValue(
        'sellingPrice',
        isNotNil(quantity) && isNotNil(value) ? value * quantity : 0
      );
    };

  const onCustomPriceChange =
    (formApi: UseFormReturn<EditItemFormType>) => (value: number | Nullish) => {
      const quantity = formApi.watch('quantity');

      formApi.setValue(
        'sellingPricePerUnit',
        isNotNil(quantity) && isNotNil(value) ? value / quantity : 0
      );
    };

  return (
    <DataStatus isLoading={isLoading || isFetching} isError={isError} minHeight={143}>
      <Form<EditItemFormType>
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        schema={formSchema}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <Show when={hasGoodwill}>
              <Alert
                variant="info"
                type="inline"
                title={i18n.t('entity.addWork.lables.itemHasGoodwill')}
                message={i18n.t('entity.addWork.lables.goodwillRatio', {
                  ratio: goodwillRatio * 100,
                })}
                data-testid={suffixTestId('itemHasGoodwill', props)}
              />
            </Show>
            <FormField
              name="name"
              control={control}
              type="text"
              label={i18n.t('general.labels.description')}
              isDisabled={hasGoodwill}
              data-testid={suffixTestId('name', props)}
            />
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  name="number"
                  control={control}
                  type="text"
                  label={i18n.t('general.labels.number')}
                  isDisabled={hasGoodwill}
                  data-testid={suffixTestId('number', props)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  name="workType"
                  control={control}
                  type="choice"
                  options={workTypeOptions}
                  label={i18n.t('entity.addWork.lables.workCategory')}
                  placeholder={i18n.t('general.labels.select')}
                  isDisabled={hasGoodwill}
                  menuInPortal
                  data-testid={suffixTestId('workType', props)}
                />
              </Box>
            </HStack>
            <Card variant="inlineGrey">
              <EditItemMechanics
                control={control}
                formApi={formApi}
                serviceCaseId={props.serviceCaseId}
                serviceOrderId={props.serviceOrderId}
                data-testid={suffixTestId('assignMechanics', props)}
              />
              <Separator />
              <VStack spacing={4}>
                <HStack spacing={4}>
                  <Hide when={isCooperationType || isSubcontractPriceType}>
                    <Box flex={1}>
                      <FormField
                        name="quantity"
                        control={control}
                        type="number"
                        label={i18n.t('entity.order.itemQuantity')}
                        isDisabled={isDirectPriceType || hasGoodwill}
                        onChange={handleQuantityChange(formApi)}
                        suffix={itemData?.quantity?.unit}
                        data-testid={suffixTestId('quantity', props)}
                      />
                    </Box>
                  </Hide>
                  <Show when={isTimeNormType}>
                    <Box flex={1}>
                      <FormField
                        name="originalSellingPricePerUnit"
                        type="number"
                        isDisabled={isFalse(itemData?.sellingPricePerUnit?.isEditingAllowed)}
                        control={control}
                        label={i18n.t('entity.order.itemPurchasePrice')}
                        suffix={tenantCurrency}
                        data-testid={suffixTestId('originalSellingPricePerUnit', props)}
                      />
                    </Box>
                  </Show>
                  <Hide when={isTimeNormType}>
                    <Box flex={1}>
                      <FormField
                        name="originalPurchasePricePerUnit"
                        type="number"
                        control={control}
                        label={
                          isCooperationType || isSubcontractPriceType
                            ? i18n.t('general.labels.purchasePrice')
                            : i18n.t('entity.addWork.lables.purchasePricePerUnit')
                        }
                        isDisabled={isFalse(itemData?.purchasePricePerUnit?.isEditingAllowed)}
                        suffix={tenantCurrency}
                        data-testid={suffixTestId('originalPurchasePricePerUnit', props)}
                      />
                    </Box>
                    <Box flex={1}>
                      <FormField
                        name="originalSellingPricePerUnit"
                        type="number"
                        control={control}
                        label={
                          isCooperationType || isSubcontractPriceType
                            ? i18n.t('general.labels.sellingPrice')
                            : i18n.t('entity.addWork.lables.sellingPricePerUnit')
                        }
                        isDisabled={isFalse(itemData?.sellingPricePerUnit?.isEditingAllowed)}
                        suffix={tenantCurrency}
                        data-testid={suffixTestId('originalSellingPricePerUnit', props)}
                      />
                    </Box>
                  </Hide>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      type="choice"
                      name="vatType"
                      options={vatOptions}
                      label={i18n.t('entity.addWork.lables.vat')}
                      placeholder={i18n.t('general.labels.select')}
                      isDisabled={
                        hasGoodwill ||
                        not(formApi.watch('isCustomPrice')) ||
                        isFalse(itemData?.sellingPriceVat?.isEditingAllowed)
                      }
                      menuInPortal
                      data-testid={suffixTestId('vatType', props)}
                    />
                  </Box>
                  <Show when={isTimeNormType || isCooperationType || isSubcontractPriceType}>
                    <Box flex={1} />
                  </Show>
                </HStack>
                <Box maxWidth="fit-content">
                  <FormField
                    name="isCustomPrice"
                    type="switch"
                    control={control}
                    label={i18n.t('entity.orderItem.labels.customPrice')}
                    onChange={handleCustomPriceSwitch(formApi)}
                    isDisabled={isFalse(itemData?.isCustomPriceEditingAllowed)}
                    data-testid={suffixTestId('isCustomPrice', props)}
                  />
                </Box>
                <Show when={formApi.watch('isCustomPrice') && isTimeNormType}>
                  <Grid columns={4}>
                    <RadioItem
                      value={formApi.watch('customPriceType') === 'perUnit'}
                      onChange={() => formApi.setValue('customPriceType', 'perUnit')}
                    >
                      {i18n.t('entity.warehouse.labels.pricePerUnit')}
                    </RadioItem>
                    <FormField
                      control={control}
                      type="number"
                      name="sellingPricePerUnit"
                      isDisabled={
                        formApi.watch('customPriceType') !== 'perUnit' ||
                        isFalse(itemData?.sellingPricePerUnit?.isEditingAllowed)
                      }
                      onChange={
                        formApi.watch('customPriceType') === 'perUnit'
                          ? onCustomPricePerUnitChange(formApi)
                          : undefined
                      }
                      suffix={tenantCurrency}
                      data-testid={suffixTestId('sellingPricePerUnit', props)}
                    />
                    <RadioItem
                      value={formApi.watch('customPriceType') === 'sellingPrice'}
                      onChange={() => formApi.setValue('customPriceType', 'sellingPrice')}
                    >
                      {i18n.t('general.labels.sellingPrice')}
                    </RadioItem>
                    <FormField
                      control={control}
                      type="number"
                      name="sellingPrice"
                      isDisabled={
                        formApi.watch('customPriceType') !== 'sellingPrice' ||
                        isFalse(itemData?.sellingPricePerUnit?.isEditingAllowed)
                      }
                      onChange={
                        formApi.watch('customPriceType') === 'sellingPrice'
                          ? onCustomPriceChange(formApi)
                          : undefined
                      }
                      suffix={tenantCurrency}
                      data-testid={suffixTestId('sellingPrice', props)}
                    />
                  </Grid>
                </Show>
                <Show when={formApi.watch('isCustomPrice') && !isTimeNormType}>
                  <Grid columns={4}>
                    <FormField
                      control={control}
                      label={i18n.t(
                        isCalculationPriceType
                          ? 'entity.addWork.lables.purchasePricePerUnit'
                          : 'general.labels.purchasePrice'
                      )}
                      type="number"
                      name="purchasePricePerUnit"
                      isDisabled={isFalse(itemData?.purchasePricePerUnit?.isEditingAllowed)}
                      suffix={tenantCurrency}
                      data-testid={suffixTestId('sellingPricePerUnit', props)}
                    />
                    <FormField
                      control={control}
                      label={i18n.t(
                        isCalculationPriceType
                          ? 'entity.addWork.lables.sellingPricePerUnit'
                          : 'general.labels.sellingPrice'
                      )}
                      type="number"
                      name="sellingPricePerUnit"
                      isDisabled={isFalse(itemData?.sellingPricePerUnit?.isEditingAllowed)}
                      suffix={tenantCurrency}
                      data-testid={suffixTestId('sellingPricePerUnit', props)}
                    />
                  </Grid>
                </Show>
                <HStack justify="space-between" align="center">
                  <FormField
                    control={control}
                    type="switch"
                    name="isCustomDiscount"
                    label={i18n.t('general.labels.discount')}
                    isDisabled={isFalsy(itemData?.discount?.isEditingAllowed)}
                    data-testid={suffixTestId('discount', props)}
                  />
                  <FormField
                    control={control}
                    name="isDoNotApplyDiscount"
                    type="checkbox"
                    isDisabled={isFalsy(itemData?.isDoNotApplyDiscountEditable)}
                    label={i18n.t('general.labels.doNotApplyDiscount')}
                    data-testid={suffixTestId('doNotApplyDiscount', props)}
                  />
                </HStack>
                <Show
                  when={
                    formApi.watch('isCustomDiscount') &&
                    not(itemData?.discount?.isDoNotApplyDiscount)
                  }
                >
                  <Grid columns={4}>
                    <Show
                      when={itemData?.discount?.availableDiscounts?.find(
                        (discount) => discount?.sourceType === 'customer-contract'
                      )}
                    >
                      <RadioItem
                        value={formApi.watch('discountType') === 'customer-contract'}
                        onChange={() => formApi.setValue('discountType', 'customer-contract')}
                      >
                        {i18n.t('entity.customerContract.labels.customerContract')}
                      </RadioItem>
                      <NumberInput
                        isDisabled
                        value={
                          getPercentageFromDecimal(
                            itemData?.discount?.availableDiscounts?.find(
                              (discount) => discount?.sourceType === 'customer-contract'
                            )?.rate
                          ) ?? 0
                        }
                        suffix="%"
                        data-testid={suffixTestId('customerContract', props)}
                      />
                    </Show>
                    <Show
                      when={itemData?.discount?.availableDiscounts?.find(
                        (discount) => discount?.sourceType === 'custom-discounts'
                      )}
                    >
                      <RadioItem
                        value={formApi.watch('discountType') === 'custom-discounts'}
                        onChange={() => formApi.setValue('discountType', 'custom-discounts')}
                      >
                        {i18n.t('entity.orderItem.labels.customDiscount')}
                      </RadioItem>
                      <NumberInput
                        isDisabled
                        value={
                          getPercentageFromDecimal(
                            itemData?.discount?.availableDiscounts?.find(
                              (discount) => discount?.sourceType === 'custom-discounts'
                            )?.rate ?? 0
                          ) ?? 0
                        }
                        suffix="%"
                        data-testid={suffixTestId('customDiscount', props)}
                      />
                    </Show>
                    <Show
                      when={itemData?.discount?.availableDiscounts?.find(
                        (discount) => discount?.sourceType === 'manual-discount'
                      )}
                    >
                      <RadioItem
                        value={formApi.watch('discountType') === 'manual-discount'}
                        onChange={() => formApi.setValue('discountType', 'manual-discount')}
                      >
                        {i18n.t('entity.orderItem.labels.manualDiscount')}
                      </RadioItem>
                      <FormField
                        control={control}
                        type="number"
                        name="discountRate"
                        decimalPlaces={2}
                        suffix="%"
                        maxStepperValue={100}
                        minStepperValue={0}
                        isDisabled={formApi.watch('discountType') !== 'manual-discount'}
                        data-testid={suffixTestId('discount', props)}
                      />
                    </Show>
                  </Grid>
                </Show>
              </VStack>
              <Separator />
              <ItemPrice
                itemId={props.itemId}
                serviceCaseId={props.serviceCaseId}
                serviceOrderId={props.serviceOrderId}
                serviceJobId={props.serviceJobId}
                amount={formApi.watch('quantity')}
                purchasePricePerUnit={formApi.watch('purchasePricePerUnit')}
                purchaseCurrency={itemData?.purchasePricePerUnit?.currency}
                sellingPricePerUnit={formApi.watch('sellingPricePerUnit')}
                sellingCurrency={itemData?.sellingPricePerUnit?.currency}
                vatType={formApi.watch('vatType')}
                priceType={itemData?.priceType ?? ''}
                workType={formApi.watch('workType')}
                assignMechanics={formApi.watch('assignMechanics')}
                discountRate={match([
                  formApi.watch('isCustomDiscount'),
                  formApi.watch('discountType'),
                ])
                  .with([false, Pattern.any], () => 0)
                  .with([true, 'manual-discount'], () => formApi.watch('discountRate'))
                  .with([true, Pattern.any], () => {
                    const discountRate = itemData?.discount?.availableDiscounts?.find(
                      (discount) => discount?.sourceType === formApi.watch('discountType')
                    )?.rate;

                    if (!discountRate) {
                      return 0;
                    }

                    return discountRate * 100;
                  })
                  .otherwise(() => 0)}
              />
            </Card>
            <ButtonGroup align="right">
              <Button
                onClick={props.onClose}
                variant="secondary"
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.saveChanges')}
                isLoading={isPatchLoading}
                data-testid={suffixTestId('save', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const quantitySchema = yupNumber.positive();

const formSchema = object({
  quantity: yupNumber.required().positive(),
  sellingPricePerUnit: yupNumber.required(),
  vatType: yupString.required(),
  discountRate: yupNumber.min(0).max(100),
});
