import {Action, FlagProps, Parameter} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {defaultTo, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {
  DetailTemplate,
  DetailTemplateHeader,
  EntityResourceIds,
  handleApiError,
  NavigationItem,
  useGetReceiveNoteCorrectionActionQuery,
  useGetReceiveNoteCorrectionQuery,
  useGetReceiveNoteQuery,
  usePutReceiveNoteCorrectionActionMutation,
} from '@omnetic-dms/shared';

import {
  buildArray,
  composePath,
  EMPTY_PLACEHOLDER,
  generateHashFromObjects,
  Nullish,
  parseDate,
  useNavigate,
} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {Overview} from './(sections)/Overview/Overview';

export function ReceiveNoteCorrectionDetail() {
  const navigate = useNavigate();

  const {receiveNoteId, receiveNoteCorrectionId} = useWarehouseParams();

  const dateTimeFormatter = useDateTimeFormatter();

  const {
    data: receiveNote,
    isLoading: isReceiveNoteLoading,
    isError: hasReceiveNoteError,
  } = useGetReceiveNoteQuery({receiveNoteId}, {skip: isNilOrEmpty(receiveNoteId)});

  // TODO https://carvago.atlassian.net/browse/T20-59030
  const {
    data: receiveNoteCorrectionAction,
    isFetching: isReceiveNoteCorrectionActionFetching,
    isError: hasReceiveNoteCorrectionActionError,
  } = useGetReceiveNoteCorrectionActionQuery(
    {id: receiveNoteCorrectionId},
    {skip: isNilOrEmpty(receiveNoteCorrectionId)}
  );

  const {
    data: receiveNoteCorrection,
    isLoading: isReceiveNoteCorrectionLoading,
    isError: hasReceiveNoteCorrectionError,
  } = useGetReceiveNoteCorrectionQuery(
    {creditNoteId: receiveNoteCorrectionId},
    {skip: isNilOrEmpty(receiveNoteCorrectionId)}
  );

  // TODO https://carvago.atlassian.net/browse/T20-59030
  const [putReceiveNoteCorrectionAction, {isLoading: isPutReceiveNoteCorrectionActionLoading}] =
    usePutReceiveNoteCorrectionActionMutation();

  // TODO https://carvago.atlassian.net/browse/T20-55731
  // const {data: createdByUser, isLoading: isCreatedByUserLoading} = useGetUserQuery(
  //   {id: receiveNoteCorrection?.creditNote?.createdBy!},
  //   {skip: isNil(receiveNoteCorrection?.creditNote?.createdBy)}
  // );

  // TODO https://carvago.atlassian.net/browse/T20-55731
  // const {data: updatedByUser, isLoading: isUpdatedByUserLoading} = useGetUserQuery(
  //   {id: receiveNoteCorrection?.creditNote?.updatedBy!},
  //   {skip: isNil(receiveNoteCorrection?.creditNote?.updatedBy)}
  // );

  // TODO https://carvago.atlassian.net/browse/T20-55731
  const isLoading =
    isReceiveNoteLoading || isReceiveNoteCorrectionLoading; /*|| isCreatedByUserLoading*/
  // TODO https://carvago.atlassian.net/browse/T20-55731
  const hasError =
    hasReceiveNoteError || hasReceiveNoteCorrectionError; /*|| isUpdatedByUserLoading*/

  const isDraftReceiveNoteCorrection = isNil(receiveNoteCorrection);
  const isPendingReceiveNoteCorrection = receiveNoteCorrection?.creditNote?.state === 'PENDING';
  const isCompletedReceiveNoteCorrection = receiveNoteCorrection?.creditNote?.state === 'COMPLETED';

  const handleDiscard = () =>
    navigate(composePath(warehouseRoutes.receiveNoteDetailOverview, {params: {receiveNoteId}}));

  const handleComplete = () => {
    putReceiveNoteCorrectionAction({
      id: receiveNoteCorrection?.creditNote.creditNoteId as string,
      body: {actionKey: 'complete'},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const formatToShortDate = (date: string | Nullish) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return dateTimeFormatter('dateShort', parseDate(date));
  };
  // TODO https://carvago.atlassian.net/browse/T20-55731
  // const createdBy = `${i18n.t('general.labels.createdBy')}: ${getUserName(createdByUser) ?? EMPTY_PLACEHOLDER}`;
  const createdAt = `${i18n.t('general.labels.createdAt')}: ${formatToShortDate(receiveNoteCorrection?.creditNote?.created)}`;
  // TODO https://carvago.atlassian.net/browse/T20-55731
  // const updatedBy = `${i18n.t('general.labels.updatedBy')}: ${getUserName(updatedByUser) ?? EMPTY_PLACEHOLDER}`;
  const updatedAt = `${i18n.t('general.labels.updatedAt')}: ${formatToShortDate(receiveNoteCorrection?.creditNote?.updated)}`;
  // TODO https://carvago.atlassian.net/browse/T20-55731
  // const completedBy = `${i18n.t('general.labels.completedBy')}: ${isCompletedReceiveNoteCorrection ? getUserName(updatedByUser) : EMPTY_PLACEHOLDER}`;
  const completedAt = `${i18n.t('general.labels.completedAt')}: ${isCompletedReceiveNoteCorrection ? formatToShortDate(receiveNoteCorrection?.creditNote?.updated) : EMPTY_PLACEHOLDER}`;

  const headerTitle = isNilOrEmpty(receiveNoteCorrectionId)
    ? i18n.t('entity.warehouse.labels.newReceiveNoteCorrection')
    : `${i18n.t('entity.warehouse.labels.receiveNoteCorrection')} ${defaultTo('', receiveNoteCorrection?.creditNote?.creditNoteNumber)}`;

  const headerParameters = match(isDraftReceiveNoteCorrection)
    .returnType<Parameter[]>()
    .with(true, () => [])
    // TODO https://carvago.atlassian.net/browse/T20-55731
    .otherwise(() => [
      /*createdBy,*/ createdAt,
      /*updatedBy,*/ updatedAt,
      /*completedBy,*/ completedAt,
    ]);

  const headerFlags = match(receiveNoteCorrection?.creditNote?.state)
    .returnType<FlagProps[]>()
    .with('PENDING', () => [{colorScheme: 'blue', label: i18n.t('general.labels.pending')}])
    .with('COMPLETED', () => [{colorScheme: 'green', label: i18n.t('general.labels.completed')}])
    .otherwise(() => [{colorScheme: 'neutral', label: i18n.t('entity.warehouse.labels.draft')}]);

  const headerActions = buildArray<Action>()
    .when(isDraftReceiveNoteCorrection, {
      type: 'button',
      variant: 'secondary',
      title: i18n.t('general.actions.discard'),
      onClick: handleDiscard,
      'data-testid': testIds.warehouse.receiveNoteCorrectionDetailOverview('headerActions.discard'),
    })
    .when(isPendingReceiveNoteCorrection, {
      type: 'button',
      variant: 'primary',
      title: i18n.t('general.actions.complete'),
      onClick: handleComplete,
      // TODO https://carvago.atlassian.net/browse/T20-55679
      //  isDisabled: ,
      // TODO https://carvago.atlassian.net/browse/T20-55679
      // isLoading: ,
      'data-testid':
        testIds.warehouse.receiveNoteCorrectionDetailOverview('headerActions.complete'),
    });

  const header: DetailTemplateHeader = {
    title: headerTitle,
    icon: 'action/deployed',
    parameters: headerParameters,
    recordId: receiveNoteCorrection?.creditNote?.creditNoteId,
    resourceId: EntityResourceIds.receiveNoteCorrection,
    flags: headerFlags,
    actions: headerActions,
    controls: ['ASSIGNEE'],
  };

  const navigation: NavigationItem[] = [
    {
      id: 'receiveNoteCorrectionDetail',
      label: i18n.t('page.warehouse.labels.overview'),
      href: composePath(warehouseRoutes.receiveNoteCorrectionDetailOverview, {
        params: {id: receiveNoteCorrectionId},
      }),
      content: (
        <Overview
          receiveNote={receiveNote}
          receiveNoteCorrection={receiveNoteCorrection}
          isReceiveNoteCorrectionLoading={isReceiveNoteCorrectionLoading}
          data-testid={testIds.warehouse.receiveNoteCorrectionDetailOverview('sections.overview')}
        />
      ),
      'data-testid': testIds.warehouse.receiveNoteCorrectionDetailOverview('navigation.overview'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('entity.warehouse.labels.receiveNoteCorrection')} />
      <DetailTemplate
        key={generateHashFromObjects(receiveNoteCorrection)}
        isLoading={isLoading}
        isError={hasError}
        header={header}
        navigation={navigation}
        data-testid={testIds.warehouse.receiveNoteCorrectionDetailOverview('page')}
      />
    </>
  );
}
