import {Choice, DataStatus, Switch} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';

import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {isNil, isNotEmpty} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {i18n} from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  featureFlags,
  queryParams,
  selectActiveBranchId,
  useGetBranchListQuery,
  useIsPublishingMultiBranchEnabledQuery,
  useSetPublishingMultiBranchEnabledMutation,
} from '@omnetic-dms/shared';

import {isFeatureEnabled, useQueryState} from 'shared';

type BranchOption = {
  label: string | null;
  value: string;
};

export function MultiBranchControl() {
  const [branchId, setBranchId, clearBranchId] = useQueryState(queryParams.BRANCH_ID);
  const activeBranchId = useSelector(selectActiveBranchId);

  const [setMultiBranch, {isLoading: isMultiBranchStoring}] =
    useSetPublishingMultiBranchEnabledMutation();
  const {data: branchList, isLoading: branchListLoading} = useGetBranchListQuery();
  const {
    data: isMultiBranch,
    isLoading: isMultiBranchLoading,
    isFetching: isMultiBranchFetching,
  } = useIsPublishingMultiBranchEnabledQuery();

  const branchOptions: BranchOption[] =
    branchList?.branchListItems?.map((branch) => ({
      label: branch.marketingName,
      value: branch.id,
    })) ?? [];

  useEffect(() => {
    const shouldUpdateBranchId =
      !isMultiBranchLoading && !branchListLoading && isMultiBranch === isNil(branchId);

    if (shouldUpdateBranchId) {
      if (
        isFeatureEnabled(featureFlags.SETTINGS_ADVERTISEMENT_MULTIBRANCH) &&
        isMultiBranch &&
        isNotNil(branchList) &&
        isNotEmpty(branchList.branchListItems)
      ) {
        const isActiveBranchPresent = branchList.branchListItems
          .map((item) => item.id)
          .includes(activeBranchId);

        setBranchId(isActiveBranchPresent ? activeBranchId : branchList.branchListItems[0].id);
      } else {
        clearBranchId();
      }
    }
  }, [
    isMultiBranch,
    branchList,
    isMultiBranchLoading,
    branchListLoading,
    branchId,
    activeBranchId,
    setBranchId,
    clearBranchId,
  ]);

  const isLoading = isMultiBranchFetching || isMultiBranchStoring || branchListLoading;

  return (
    <HStack align="center" spacing={4}>
      <DataStatus isLoading={isMultiBranchLoading}>
        <Switch
          label={i18n.t('entity.advertisingPlatforms.labels.allowMultiBranch')}
          helperText={i18n.t('entity.advertisingPlatforms.labels.allowMultiBranchDescription')}
          value={!!isMultiBranch}
          onChange={setMultiBranch}
          data-testid={testIds.settings.advertisingPlatforms('allowMultiBranch')}
        />
        <Show when={isMultiBranch || isLoading}>
          <Box width={70}>
            <DataStatus isLoading={isLoading}>
              <Choice
                value={branchId}
                onChange={(branchId) => setBranchId(branchId as string)}
                options={branchOptions}
                isNotClearable
                data-testid={testIds.settings.advertisingPlatforms('branch')}
              />
            </DataStatus>
          </Box>
        </Show>
      </DataStatus>
    </HStack>
  );
}
