import {
  Actions,
  Button,
  Card,
  DataStatus,
  Separator,
  closeCurrentDialog,
  openDialog,
} from 'platform/components';
import {Grid, HStack, Heading, Hide, Show, Space, Text} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {always, isNil, isNotNil} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {
  CustomerContractModal,
  SummaryItem,
  useGetServiceOrderCustomerContractDetailsQuery,
} from '@omnetic-dms/shared';

import {TestIdProps, parseDate, suffixTestId} from 'shared';

import {AssignContract} from './AssignContract';
import {DeactivateContract} from './DeactivateContract';

interface CustomerContractSectionProps extends TestIdProps {
  serviceCaseId: string;
  orderId: string;
  isReadOnly?: boolean;
  isAssignDisabled?: boolean;
}

export function CustomerContractSection(props: CustomerContractSectionProps) {
  const formatDateTime = useDateTimeFormatter();
  const [selectedContractId, setSelectedContractId] = useState<string>();

  const {data, isLoading, isError, isFetching} = useGetServiceOrderCustomerContractDetailsQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.orderId,
  });

  const handleDeactivate = () =>
    openDialog(
      <DeactivateContract
        serviceCaseId={props.serviceCaseId}
        orderId={props.orderId}
        onClose={closeCurrentDialog}
        data-testid={suffixTestId('deactivateDialog', props)}
      />,
      {
        title: i18n.t('entity.order.actions.deactivateContract'),
        size: 'small',
      }
    );

  const handleAssign = () =>
    openDialog(
      <AssignContract
        serviceCaseId={props.serviceCaseId}
        orderId={props.orderId}
        onClose={closeCurrentDialog}
        onContractSelect={setSelectedContractId}
        data-testid={suffixTestId('assignDialog', props)}
      />,
      {
        title: i18n.t('entity.order.actions.assignContract'),
      }
    );

  const handleChange = () =>
    openDialog(
      <AssignContract
        serviceCaseId={props.serviceCaseId}
        orderId={props.orderId}
        onClose={closeCurrentDialog}
        isChange
        onContractSelect={setSelectedContractId}
        data-testid={suffixTestId('changeDialog', props)}
      />,
      {
        title: i18n.t('entity.order.actions.changeContract'),
      }
    );

  const customerTypeForContractLabel = match(data?.customerTypeForContract)
    .with('OTHER', always(i18n.t('entity.customerContract.labels.other')))
    .with('DEFAULT', always(i18n.t('entity.customerContract.labels.default')))
    .with('FLEET', always(i18n.t('entity.customerContract.labels.fleet')))
    .with('INSURANCE', always(i18n.t('entity.customerContract.labels.insurance')))
    .with('INVOICE', always(i18n.t('entity.customerContract.labels.invoice')))
    .with('OPERATOR', always(i18n.t('entity.customerContract.labels.operator')))
    .otherwise(always(null));

  return (
    <>
      <DataStatus isLoading={isLoading || isFetching} isError={isError} minHeight={24}>
        <Heading size={4}>{i18n.t('entity.customerContract.labels.customerContract')}</Heading>
        <Space vertical={1} />
        <Text size="small" color="secondary">
          {i18n.t('entity.order.labels.customerContractDescription')}
        </Text>
        <Space vertical={4} />
        <Show when={isNil(data)}>
          <Button
            title={i18n.t('entity.order.actions.assignContract')}
            variant="secondary"
            onClick={handleAssign}
            isDisabled={props.isReadOnly || props.isAssignDisabled}
            data-testid={suffixTestId('assignContract', props)}
          />
        </Show>
        <Hide when={isNil(data)}>
          <Card variant="inlineGrey">
            <HStack justify="space-between">
              <Heading size={4}>
                {i18n.t('entity.customerContract.labels.customerContract')}
              </Heading>
              <Actions
                actions={[
                  {
                    title: i18n.t('general.actions.viewDetail'),
                    leftIcon: 'image/remove_red_eye',
                    variant: 'link',
                    type: 'button',
                    onClick: () => setSelectedContractId(data?.id ?? undefined),
                    'data-testid': suffixTestId('detail', props),
                  },
                  {
                    type: 'button',
                    variant: 'link',
                    leftIcon: 'navigation/refresh',
                    title: i18n.t('entity.order.actions.changeContract'),
                    onClick: handleChange,
                    isDisabled: props.isReadOnly,
                    'data-testid': suffixTestId('changeContract', props),
                  },
                  {
                    type: 'button',
                    variant: 'dangerLink',
                    leftIcon: 'navigation/close',
                    title: i18n.t('entity.order.actions.deactivateContract'),
                    onClick: handleDeactivate,
                    isDisabled: props.isReadOnly,
                    'data-testid': suffixTestId('deactivateContract', props),
                  },
                ]}
                data-testid={suffixTestId('customerContractActions', props)}
              />
            </HStack>
            <Separator />
            <Grid columns={4}>
              <SummaryItem
                isAlternative
                title={i18n.t('general.labels.name')}
                value={data?.name}
                data-testid={suffixTestId('name', props)}
              />
              <SummaryItem
                isAlternative
                title={i18n.t('general.labels.description')}
                value={data?.description}
                data-testid={suffixTestId('description', props)}
              />
              <SummaryItem
                isAlternative
                title={i18n.t('general.labels.validUntil')}
                value={
                  data?.validityUntil && formatDateTime('dateShort', parseDate(data?.validityUntil))
                }
                data-testid={suffixTestId('description', props)}
              />
              <SummaryItem
                isAlternative
                title={i18n.t('general.labels.description')}
                value={customerTypeForContractLabel}
                data-testid={suffixTestId('description', props)}
              />
            </Grid>
            <Space vertical={4} />
          </Card>
        </Hide>
      </DataStatus>
      <Show when={isNotNil(selectedContractId)}>
        <CustomerContractModal
          customerContractId={selectedContractId ?? data?.id ?? ''}
          onClose={() => setSelectedContractId(undefined)}
          data-testid={suffixTestId('detailModal', props)}
        />
      </Show>
    </>
  );
}
